import "./productIndicators.page.scss";
import { Fragment, useContext, useEffect, useState, useRef } from "react";
import { uid } from "uid";
import SettingsActionRequired from "../../../components/settings-action-required/settingsActionRequired";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import firebase from "../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { onSnapshot, doc } from "firebase/firestore";

import noPicture from "../../../img/no-picture.png";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";
import Spinner from "../../../components/spinner/spinner";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import ConnectionError from "../../../components/connection-error/connectionError";

const ProductIndicatorsPage = () => {

    let observedRef = useRef(null);

    const { accountData, accessTo, setPictureInMemory } = useContext(AuthContext);
    const { appLanguage, appTheme, formatRationalNumber, getCategory, getDayForRecharts, getMonthNameAndFullYear } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const [loading, setLoading] = useState(true);

    const [pictureFile, setPictureFile] = useState(null);

    const [request, setRequest] = useState(false);
    const [secondRequest, setSecondRequest] = useState(false);

    const [statisticsListener, setStatisticsListener] = useState(null);
    const [product, setProduct] = useState(null);

    const [productStatistics, setProductStatistics] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();

    const [statisticsView, setStatisticsView] = useState("today");

    const [statisticsViewTable, setStatisticsViewTable] = useState("");

    const [showConnectionError, setShowConnectionError] = useState(false);

    const [monthData, setMonthData] = useState([]);

    const [indicatorsFilters, setIndicatorsFilters] = useState([]);
    const [indicators, setIndicators] = useState({});

    const [todayString, setTodayString] = useState(null);

    const onChangeStatisticsView = (e) => {
        setStatisticsView(e.target.value);
    }

    const onChangeStatisticsViewTable = (e) => {
        setStatisticsViewTable(e.target.value);
    }

    useEffect(() => {
        if (location.state) {
            const itemsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));
            if (itemsDeleted) {
                const productId = location.state.product.id;
                function containsString(arr, target) {
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].includes(target)) {
                            return true;
                        }
                    }
                    return false;
                }
                if (containsString(itemsDeleted, productId)) {
                    if (window.history.state && window.history.state.idx > 0) {
                        navigate(-1);
                    } else {
                        navigate('/', { replace: true });
                    }
                } else {
                    setProduct(location.state.product);
                }
            } else {
                setProduct(location.state.product);
            }
        } else {
            if (window.history.state && window.history.state.idx > 0) {
                navigate(-1);
            } else {
                navigate('/inventario', { replace: true });
            }
        }
        // eslint-disable-next-line
    }, []);

    // using the SideMenuContext calculate the initial state
    const [width, setWidth] = useState(desktopView ? windowDimension.width - (isSideMenuMinimized ? 119.6 : 309.6) : windowDimension.width);

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    const onTryAgain = () => {
        setLoading(true);
        getProductStatistics();
    }

    useEffect(() => {
        if (product && product.picturesLocation) {
            if (product.picturesLocation[0]) {
                checkIfImageExistInPr(product.picturesLocation[0]);
            }
        }
        if (product && accountData) {
            if (!productStatistics.length > 0) {
                setStatisticsListener(true);
            }
        }
        // eslint-disable-next-line
    }, [product]);

    async function getProductStatistics() {
        setShowConnectionError(false);
        try {
            const res = await firebase.useGetProductIndicators({
                "businessId": accountData.id,
                "id": product.id
            });

            if (res === null) {
                throw new Error("Statistics not found");
            }

            const monthsList = [];

            for (let [key] of Object.entries(res)) {
                monthsList.push(res[key])
            }

            let newStatisticsViewTable = "";

            for (let key of Object.keys(monthsList[monthsList.length - 1])) {
                if (isvalidObject(key)) {
                    newStatisticsViewTable = key;
                }
            }

            setStatisticsViewTable(newStatisticsViewTable);
            setProductStatistics(res);

            setMonthData(getMonthData(res, newStatisticsViewTable));
        } catch (error) {
            console.log(error);
            setShowConnectionError(true);
        }
        setLoading(false);
    }

    // Now I have to listeng to the product doc
    useEffect(() => {
        if (request) {
            if (product) {
                const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/products`, product.id), (doc) => {
                    const productUpdated = {
                        ...doc.data(),
                        "id": doc.id,
                    };

                    delete productUpdated.searchKeys;

                    if (doc.data()) {
                        setProduct(productUpdated);
                    }
                }, err => {
                    console.error(err);
                });
                return () => unsub();
            }
        } else {
            setRequest(uid());
        }
        // eslint-disable-next-line
    }, [request]);

    // THen I have to listen to the product statistcs dos
    useEffect(() => {
        if (product && accountData && statisticsListener) {
            if (secondRequest) {
                const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/productsStatistics`, product.id), (doc) => {
                    if (doc.data()) {
                        getProductStatistics();
                    } else {
                        setShowConnectionError(true);
                        setLoading(false);
                    }
                }, err => {
                    console.error(err);
                    setShowConnectionError(true);
                    setLoading(false);
                });
                return () => unsub();
            } else {
                setSecondRequest(uid());
            }
        }
        // eslint-disable-next-line
    }, [statisticsListener, secondRequest]);

    const checkIfImageExistInPr = (path) => {
        const dimensions = "d140x140";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pr-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pr-pictures-store")) {
                db.createObjectStore("hazcuentas-pr-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pr-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pr-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureFile(request.result.key[dimensions]);
                        } else {
                            checkIfImageExistInGe(path);
                        }
                    } else {
                        checkIfImageExistInGe(path);
                    }
                } else {
                    checkIfImageExistInGe(path);
                }
            }

            requestToCheck.onerror = () => {
                checkIfImageExistInGe(path);
            }
        }
    }

    const checkIfImageExistInGe = (path) => {
        const dimensions = "d140x140";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureFile(request.result.key[dimensions]);
                        } else {
                            getPictureFile(path);
                        }
                    } else {
                        getPictureFile(path);
                    }
                } else {
                    getPictureFile(path);
                }
            }

            requestToCheck.onerror = () => {
                getPictureFile(path);
            }
        }
    }

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions
        // of a picture store in firebase store

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        if (dimensions === "_140x140") {
                            setPictureFile(urlData);
                        }
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setPictureInMemory(dbKey, product.id);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                console.error(error);
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.inventory)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        const newState = {
            "product": product,
        }
        navigate(null, { replace: true, state: newState });
        // eslint-disable-next-line
    }, [product]);

    const isvalidObject = (key) => {
        if (
            key === "income" ||
            key === "discountsApplied" ||
            key === "earnings" ||
            key === "returnRate" ||
            key === "quantitySold" ||
            key === "losses" ||
            key === "returns"
        ) {
            return false
        } else {
            return true;
        }
    }

    useEffect(() => {
        if (productStatistics.length > 0) {
            const newIndicatorsFilters = [];
            const newIndicators = {};

            let newTodayString = "";
            let newTodayObject = {};
            let currentPeriodData = null;

            const thisMonthStatistics = productStatistics[productStatistics.length - 1];

            for (let key of Object.keys(thisMonthStatistics)) {
                currentPeriodData = thisMonthStatistics[key];
            }

            for (let key of Object.keys(currentPeriodData)) {
                if (isvalidObject(key)) {
                    newTodayString = key;
                    newTodayObject = currentPeriodData[key];
                }
            }

            newIndicators[newTodayString] = newTodayObject;

            const monthsList = [];

            for (let [key] of Object.entries(productStatistics)) {
                monthsList.push(productStatistics[key])
            }

            for (let i = 0; i < monthsList.length; i++) {
                for (let key of Object.keys(monthsList[i])) {
                    if (isvalidObject(key)) {
                        newIndicatorsFilters.push(key);

                        const statisticsForMonth = monthsList[i][key];

                        newIndicators[key] = {
                            earnings: statisticsForMonth.earnings,
                            income: statisticsForMonth.income,
                            discountsApplied: statisticsForMonth.discountsApplied ? statisticsForMonth.discountsApplied : 0,
                            losses: statisticsForMonth.losses,
                            quantitySold: statisticsForMonth.quantitySold,
                            returnRate: statisticsForMonth.returnRate,
                            returns: statisticsForMonth.returns,
                        };
                    }
                }
            }

            setIndicators(newIndicators);
            setTodayString(newTodayString);
            setIndicatorsFilters(newIndicatorsFilters);
        }
        // eslint-disable-next-line
    }, [productStatistics])

    const getQuantitySold = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return indicators[todayString].quantitySold;
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return indicators[statisticsView].quantitySold;;
            } else {
                return 0;
            }
        }
    }

    const getReturnRate = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return indicators[todayString].returnRate;
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return indicators[statisticsView].returnRate;;
            } else {
                return 0;
            }
        }
    }

    const getDiscountsApplied = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                if (indicators[todayString].discountsApplied) {
                    return indicators[todayString].discountsApplied;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                if (indicators[statisticsView].discountsApplied) {
                    return indicators[statisticsView].discountsApplied;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        }
    }

    const getGrossIncome = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return indicators[todayString].income;
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return indicators[statisticsView].income;;
            } else {
                return 0;
            }
        }
    }

    const getLosses = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return indicators[todayString].losses;
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return indicators[statisticsView].losses;;
            } else {
                return 0;
            }
        }
    }

    const getEarnings = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return indicators[todayString].earnings;
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return indicators[statisticsView].earnings;;
            } else {
                return 0;
            }
        }
    }

    const getMonthData = (productStatistics, statisticsViewTable) => {
        const newMonthData = [];

        if (productStatistics && statisticsViewTable) {
            const thisMonthStatistics = productStatistics.find(obj => statisticsViewTable in obj);

            if (thisMonthStatistics) {
                let currentPeriodData = null;

                for (let key of Object.keys(thisMonthStatistics)) {
                    currentPeriodData = thisMonthStatistics[key];
                }

                for (let key of Object.keys(currentPeriodData)) {
                    if (isvalidObject(key)) {

                        newMonthData.push({
                            name: getDayForRecharts(key),
                            cantidad: currentPeriodData[key].quantitySold,
                        });
                    }
                }
            }
        }
        
        return newMonthData;
    }

    useEffect(() => {
        setMonthData(getMonthData(productStatistics, statisticsViewTable));
    // eslint-disable-next-line
    }, [productStatistics, statisticsViewTable])

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Performance Indicators" : 'Indicadores de Desempeño'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className="page-app-settings-item">
                            {accessTo.inventory ? <Fragment>
                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Performance Indicators" : 'Indicadores de Desempeño'}
                                    />
                                    : null}

                                <div
                                    style={{
                                        height: desktopView ? `calc(100vh - 107px)` : `calc(100vh - 65px)`,
                                        colorScheme: appTheme.dark ? "dark" : "light"
                                    }}
                                    className="customer-details-page-main-container"
                                >
                                    {loading ? <div style={{ marginTop: "90px" }} className="more-details-spinner-container"><Spinner /></div> : <Fragment>
                                        {showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> :
                                            <div ref={observedRef} className="product-details-page-container" style={{ marginTop: 0 }}>
                                                {product ? <Fragment>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            borderRadius: "10px",
                                                            alignItems: "center",
                                                            margin: "20px 0px 0px 0px",
                                                            width: "100%",
                                                            boxShadow: "1px 1px 1px 0.5px rgba(0, 0, 0, 0.2)",
                                                        }}
                                                        className={`${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"}`}
                                                    >
                                                        {pictureFile ?
                                                            <div className="products-details-micro-product-no-img-cont" style={appTheme.dark ? { backgroundColor: "#343434ec" } : { backgroundColor: "rgba(128, 128, 128, 0.171)" }}>
                                                                <img className="products-details-micro-product-yes-img" src={pictureFile} alt="Product" />
                                                            </div>
                                                            :
                                                            <div className="products-details-micro-product-no-img-cont" style={appTheme.dark ? { backgroundColor: "#343434ec" } : { backgroundColor: "rgba(128, 128, 128, 0.171)" }}>
                                                                <img className="products-details-micro-product-no-img" src={noPicture} alt="Product" />
                                                            </div>
                                                        }

                                                        <p className="products-details-micro-p-overflow" style={{ margin: "5px 10px" }}><b>{product.name}</b></p>
                                                        <span style={{ flexGrow: 4 }} />

                                                        {width < 800 ? null :
                                                            <p className="products-details-micro-p-overflow" style={{ margin: "5px 10px", width: "200px" }}>Categoria: <br /><b>{getCategory(product.category)}</b></p>
                                                        }

                                                        {width < 450 ? null :
                                                            <p style={{ margin: "5px 10px", width: "150px", whiteSpace: "nowrap" }}>{appLanguage.en ? "Final price" : "Precio final"}: <br /> <b>RD$ {formatRationalNumber(product.finalPrice)}</b></p>
                                                        }
                                                    </div>

                                                    {width > 600 ?
                                                        <div
                                                            style={{ display: "flex" }}
                                                        >
                                                            <div
                                                                style={{
                                                                    marginLeft: "0px",
                                                                    marginRight: "10px",
                                                                    width: "33.33%",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    textAlign: "center"
                                                                }}
                                                                className={`products-indicators-mini-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Product cost" : "Costo del producto"} <br />  <b>RD$ {formatRationalNumber(product.cost)}</b></p>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    marginLeft: "10px",
                                                                    marginRight: "10px",
                                                                    width: "33.33%",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    textAlign: "center",
                                                                }}
                                                                className={`products-indicators-mini-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Price without ITBIS" : "Precio sin ITBIS"} <br /> <b>RD$ {formatRationalNumber(product.priceWithoutTax)}</b></p>
                                                            </div>

                                                            <div
                                                                style={{
                                                                    marginLeft: "10px",
                                                                    marginRight: "0px",
                                                                    width: "33.33%",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    textAlign: "center",
                                                                }}
                                                                className={`products-indicators-mini-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Profit margin" : "Margen de ganancias"} <br /> <b>{product.profitMargin}</b></p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div
                                                            style={{
                                                                marginLeft: "0",
                                                                marginRight: "0px",
                                                                width: "calc(100% - 20px)",
                                                            }}
                                                            className={`products-indicators-mini-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}
                                                        >
                                                            <p style={{ whiteSpace: "nowrap", margin: "5px" }}>{appLanguage.en ? "Product cost" : "Costo del producto"}: <b style={{ marginLeft: "10px" }}>RD$ {formatRationalNumber(product.cost)}</b></p>
                                                            <p style={{ whiteSpace: "nowrap", margin: "5px" }}>{appLanguage.en ? "Price without ITBIS" : "Precio sin ITBIS"}: <b style={{ marginLeft: "10px" }}>RD$ {formatRationalNumber(product.priceWithoutTax)}</b></p>
                                                            <p style={{ whiteSpace: "nowrap", margin: "5px" }}>{appLanguage.en ? "Profit margin" : "Margen de ganancias"}: <b style={{ marginLeft: "10px" }}>{product.profitMargin}</b></p>
                                                        </div>
                                                    }

                                                    <div className={`products-indicators-sales-num-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}>
                                                        <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    height: "30px",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    marginRight: "25px"
                                                                }}
                                                            >
                                                                <p style={{ padding: "3px" }}><b>{appLanguage.en ? "Quantity sold for " : "Cantidad vendida en "}</b></p>
                                                            </div>

                                                            <div style={{ height: "40px", display: "flex" }}>
                                                                <select
                                                                    style={{
                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                        width: "100%",
                                                                        minWidth: "90px"
                                                                    }}
                                                                    className={`products-indicators-statistics-sales-input ${appTheme.dark ? "dar-pri-bg products-indicators-statistics-sales-input-dark" : "lig-pri-bg products-indicators-statistics-sales-input-light"}`}
                                                                    name="statisticsViewTable"
                                                                    value={statisticsViewTable}
                                                                    onChange={(e) => onChangeStatisticsViewTable(e)}
                                                                >
                                                                    {indicatorsFilters.slice().reverse().map((period) => {
                                                                        return (
                                                                            <option key={period} value={period}>
                                                                                {getMonthNameAndFullYear(period)}
                                                                            </option>
                                                                        )
                                                                    })}

                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div
                                                            style={{
                                                                width: "calc(100% + 18px)",
                                                                height: "180px",
                                                                paddingTop: "10px",
                                                                color: "black",
                                                                marginLeft: "-15px"
                                                            }}
                                                        >
                                                            <ResponsiveContainer width="100%" height="100%">
                                                                <AreaChart
                                                                    width={500}
                                                                    height={400}
                                                                    data={monthData}
                                                                    margin={{
                                                                        top: 10,
                                                                        right: 30,
                                                                        left: 0,
                                                                        bottom: 0,
                                                                    }}
                                                                >
                                                                    <CartesianGrid stroke="#88888877" strokeDasharray="3 3" />
                                                                    <XAxis stroke={appTheme.dark ? "#ffffff" : "#000000"} dataKey="name" />
                                                                    <YAxis stroke={appTheme.dark ? "#ffffff" : "#000000"} />
                                                                    <Tooltip />
                                                                    <Area type="monotone" dataKey="cantidad" stroke="#627890" fill="#627890" />
                                                                </AreaChart>
                                                            </ResponsiveContainer>
                                                        </div>
                                                    </div>

                                                    <div className={`products-indicators-sales-num-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}>
                                                        <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", marginBottom: "10px" }}>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    height: "30px",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    marginRight: "25px"
                                                                }}
                                                            >
                                                                <p style={{ padding: "3px" }}><b>{appLanguage.en ? "Indicators for " : "Indicadores para "}</b></p>
                                                            </div>

                                                            <div style={{ height: "40px", display: "flex" }}>
                                                                <select
                                                                    style={{
                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                        width: "100%",
                                                                        minWidth: "90px"
                                                                    }}
                                                                    className={`products-indicators-statistics-sales-input ${appTheme.dark ? "dar-pri-bg products-indicators-statistics-sales-input-dark" : "lig-pri-bg products-indicators-statistics-sales-input-light"}`}
                                                                    name="statisticsView"
                                                                    value={statisticsView}
                                                                    onChange={(e) => onChangeStatisticsView(e)}
                                                                >
                                                                    <option value="today">
                                                                        {appLanguage.en ? "Today" : "Hoy"}
                                                                    </option>

                                                                    {indicatorsFilters.slice().reverse().map((period) => {
                                                                        return (
                                                                            <option key={period} value={period}>
                                                                                {getMonthNameAndFullYear(period)}
                                                                            </option>
                                                                        )
                                                                    })}

                                                                </select>
                                                            </div>
                                                        </div>

                                                        <hr
                                                            style={{
                                                                backgroundColor: "rgba(109, 109, 109, 0.5490196078)",
                                                                height: "1px",
                                                                border: "none",
                                                            }}
                                                        />

                                                        {width > 700 ?
                                                            <div style={{ display: "flex", width: "100%", }}>
                                                                <div style={{ display: "flex", width: `100%`, flexWrap: "wrap", alignContent: "flex-start" }}>
                                                                    <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Quantity Sold" : "Cantidad Vendida"} {width > 385 ? <br /> : ""} <b>{getQuantitySold()}</b></p>
                                                                    <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Disc. Applied" : "Desc. Aplicados"} {width > 385 ? <br /> : ""} <b>RD$ {formatRationalNumber(getDiscountsApplied())}</b></p>
                                                                    {false ? <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Return Rate" : "Tasa de Devoluciones"} {width > 385 ? <br /> : ""} <b>{getReturnRate()}</b></p> : null}
                                                                    <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Gross Income" : "Ingreso Bruto"} {width > 385 ? <br /> : ""} <b>RD$ {formatRationalNumber(getGrossIncome())}</b></p>
                                                                    {getLosses() > 0 ? <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Losses" : "Pérdidas"} {width > 385 ? <br /> : ""} <b>RD$ {formatRationalNumber(getLosses())}</b></p> : null}
                                                                    <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Earnings" : "Ganancias"} {width > 385 ? <br /> : ""} <b>RD$ {formatRationalNumber(getEarnings())}</b></p>
                                                                </div>
                                                            </div>
                                                        :
                                                            <Fragment>
                                                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                    <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
                                                                        <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Quantity Sold" : "Cantidad Vendida"}{width > 385 ? <br /> : ":"} <b>{getQuantitySold()}</b></p>
                                                                        <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Disc. Applied" : "Desc. Aplicados"}{width > 385 ? <br /> : ":"} <b>RD$ {formatRationalNumber(getDiscountsApplied())}</b></p>
                                                                        {false ? <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Return Rate" : "Tasa de Devoluciones"}{width > 385 ? <br /> : ":"} <b>{getReturnRate()}</b></p> : null} 
                                                                        <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Gross Income" : "Ingreso Bruto"}{width > 385 ? <br /> : ":"} <b>RD$ {formatRationalNumber(getGrossIncome())}</b></p>
                                                                        {getLosses() > 0 ? <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Losses" : "Pérdidas"}{width > 385 ? <br /> : ":"} <b>RD$ {formatRationalNumber(getLosses())}</b></p> : null} 
                                                                        <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Earnings" : "Ganancias"}{width > 385 ? <br /> : ":"} <b>RD$ {formatRationalNumber(getEarnings())}</b></p>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>

                                                    <div style={{ minHeight: "50px" }} />
                                                </Fragment> : null}
                                            </div>
                                        }
                                    </Fragment>}
                                </div>
                            </Fragment> : null}
                        </div>
                        :
                        <div className="page-app-customers-item">
                            <div className="customers-options-container">
                                <div className="customers-options-container-item">
                                    <SettingsActionRequired />
                                </div>
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default ProductIndicatorsPage;