import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

import firebase from "../../../firebase";
import { collection, query, limit, getCountFromServer, getDocsFromServer, where, orderBy, startAt, startAfter, doc, getDoc } from "firebase/firestore";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import gridIconDark from "../../../icons/grid-dark.png";
import gridIconLight from "../../../icons/grid-light.png";
import listIconDark from "../../../icons/list-dark.png";
import listIconLight from "../../../icons/list-light.png";

import saveIcon from "../../../icons/check-dark.png";

import crossIcon from "../../../icons/cross.png";
import crossWhiteIcon from "../../../icons/cross-white.png";
import magnifyingIcon from "../../../icons/magnifying-glass.png";
import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';
import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";
import filterImgDark from "../../../icons/filter-dark.png";
import filterImgLight from "../../../icons/filter-light.png";
import ProductsViewIcons from "../../../appTools/appToolsComponents/productsViewIcons/productsViewIcons";

import "./products.page.scss";
import ProductsViewList from "../../../appTools/appToolsComponents/productsViewList/productsViewList";
import Spinner from "../../../components/spinner/spinner";

const ProductsPage = () => {

    let observedRef = useRef(null);

    const location = useLocation();

    const { accountData, accessTo, products, productsStatistics, setProductListener, userSettings, setUserSettings, saveKeyInIndexedDB } = useContext(AuthContext);
    const { appTheme, appLanguage, formatRationalNumber, isNumber, isTouchEnabled, getCategory, getThisBusinessProductsCategories } = useContext(AppContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const [loadingSettings, setLoadingSettings] = useState(false);
    const [productsView, setProductsView] = useState(userSettings && userSettings.productsView ? userSettings.productsView : null);
    const [tempProductsView, setTempProductsView] = useState(userSettings && userSettings.productsView ? userSettings.productsView : null);

    const changeView = text => {
        setTempProductsView(text);
    }

    const [showSave, setShowSave] = useState(false);

    useEffect(() => {
        if (tempProductsView && productsView) {
            if (tempProductsView === productsView) {
                setShowSave(false);
            } else {
                setShowSave(true);
            }
        }
    }, [tempProductsView, productsView]);

    const [showConnectionError, setShowConnectionError] = useState(false);

    // This prevent the page send a request twice to the backend
    const [generalState] = useState("0000");

    useEffect(() => {
        setProductListener(true);
        // eslint-disable-next-line
    }, [generalState]);

    const recentlyOpenedAccounts = Array.isArray(products) ? products : [];

    const [docsCount, setDocsCount] = useState(null);

    const [loading, setLoading] = useState(true);
    const [empty, setEmpty] = useState(false);
    const [showPageNav, setShowPageNav] = useState(false);

    // Use navControlers.page to initialize
    const [pageNumber, setPageNumber] = useState(null);

    const [searchingText, setSearchingText] = useState("");

    const [searchingByTextActive, setSearchingTextActive] = useState(false);

    // Keep state in the current path
    const [results, setResults] = useState(null);
    const [controler, setControler] = useState(null);
    const [pagesHistory, setPageHistory] = useState([]);
    const [navControlers, setNavControlers] = useState(null);

    // All of this is to avoid requesting data 
    // from the backend twice
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (refresh === 1 && navControlers && controler) {
            setRefresh(0);
            setPageNumber(navControlers.page);
            refreshSearch();
        }
        // eslint-disable-next-line
    }, [refresh]);

    const [activeRef, setActiveRef] = useState(null);
    const [activeId, setActiveId] = useState(null);

    const setActiveReference = (inputElement) => {
        setActiveRef(inputElement);
    }

    useEffect(() => {
        if (activeRef) {
            activeRef.scrollIntoView({
                block: 'center',
                inline: 'nearest'
            });
            // setActiveId(null);
        }
    }, [activeRef]);

    const onClickLink = (id) => {
        let newControler = null;

        if (controler) {
            newControler = {
                ...controler,
                "triggerSearch": false,
            }
        }

        const newState = {
            "results": results,
            "filter": filter,
            "docsCount": docsCount,
            "controler": newControler,
            "pagesHistory": pagesHistory,
            "navControlers": navControlers,
            "showConnectionError": showConnectionError,
            "empty": empty,
            "showPageNav": showPageNav,
            "activeId": id,
            "searchingByTextActive": searchingByTextActive,
            "loading": false,
        }

        navigate(null, { replace: true, state: newState });
    }

    useEffect(() => {
        if (location.state) {

            if (refresh === 0) {
                setRefresh(1)
            }

            setControler(location.state.controler);

            if (location.state.controler) {
                setSearchingText(location.state.controler.searchingText);
            }

            setPageHistory(location.state.pagesHistory);
            setNavControlers(location.state.navControlers);
            setShowConnectionError(location.state.showConnectionError);
            setEmpty(location.state.empty);
            setShowPageNav(location.state.showPageNav);
            setDocsCount(location.state.docsCount)
            setFilter(location.state.filter);
            setActiveId(location.state.activeId);
            setSearchingTextActive(location.state.searchingByTextActive);
            setLoading(location.state.loading);

            let page = null;

            if (location.state.navControlers) {
                setPageNumber(location.state.navControlers.page);
                page = location.state.navControlers.page;
            }

            const itemsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));

            function removeObjectsWithIds(array, idsToRemove) {
                if (Array.isArray((array))) {
                    return array.filter(obj => !idsToRemove.includes(obj.id));
                } else {
                    return null;
                }
            }

            if (location.controler) {
                if (
                    products &&
                    page === 1 &&
                    location.state.controler.filterBy === "all" &&
                    !location.controler.priceFrom &&
                    !location.controler.priceTo &&
                    location.state.controler.sortBy === "name" &&
                    location.state.controler.searchingText.trim() === ""
                ) {
                    const recentlyOpenedAccounts = Array.isArray(products) ? products : [];
                    // filtar
                    const res = recentlyOpenedAccounts;
                    if (itemsDeleted) {
                        const filteredArray = removeObjectsWithIds(res, itemsDeleted);
                        setResults(filteredArray);
                    } else {
                        setResults(res);
                    }
                } else {
                    // filtar
                    const res = location.state.results;

                    if (itemsDeleted) {
                        const filteredArray = removeObjectsWithIds(res, itemsDeleted);
                        setResults(filteredArray);
                    } else {
                        setResults(res);
                    }
                }
            } else {
                // filtar
                const res = location.state.results;

                if (itemsDeleted) {
                    const filteredArray = removeObjectsWithIds(res, itemsDeleted);
                    setResults(filteredArray);
                } else {
                    setResults(res);
                }
            }

            if (!location.state.loading) {
                blink();
            }
        } else {
            setResults(recentlyOpenedAccounts);

            setControler({
                "filterBy": "all",
                "category": "general",
                "availability": "available",
                "priceFrom": null,
                "priceTo": null,
                "sortBy": "name",
                "searchingText": "",
                "triggerSearch": false,
            });

            setPageHistory([]);

            setNavControlers({
                "page": 1,
                "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                "isPrevious": false,
                "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
            });

            setShowConnectionError(false);
            setEmpty(false);
            setShowPageNav(false);
            setPageNumber(1);
            blink();
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (navControlers && productsStatistics) {
            if (
                products &&
                navControlers.page === 1 &&
                controler.filterBy === "all" &&
                !controler.priceFrom &&
                !controler.priceTo &&
                controler.sortBy === "name" &&
                controler.searchingText.trim() === ""
            ) {
                const recentlyOpenedAccounts = Array.isArray(products) ? products : [];
                setResults(recentlyOpenedAccounts);

                setNavControlers({
                    "page": 1,
                    "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
                });
            }
        }

        if (productsStatistics && controler) {
            if ((controler.searchingText.trim() === "") && (!filter)) {
                if (productsStatistics.all > 0) {
                    setEmpty(false);
                    if (productsStatistics.all > 30) {
                        setShowPageNav(true);
                    } else {
                        setShowPageNav(false);
                    }
                } else {
                    setEmpty(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [products, productsStatistics, docsCount]);

    const [width, setWidth] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            } else {
                try {
                    const width = observedRef.current.offsetWidth - 11
                    return width;
                } catch {
                    return;
                }
            }
        } else {
            return;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth - 11);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension, results]);

    const [cardContWith, setCardContWith] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return "974px";
            } else {
                try {
                    const width = observedRef.current.offsetWidth - 11
                    if (width <= 330) {
                        return "164px";
                    } else {
                        if (width <= 492) {
                            return "326px";
                        } else {
                            if (width <= 654) {
                                return "488px";
                            } else {
                                if (width <= 816) {
                                    return "650px";
                                } else {
                                    if (width <= 978) {
                                        return "812px";
                                    } else {
                                        return "974px";
                                    }
                                }
                            }
                        }
                    }
                } catch {
                    return "974px";
                }
            }
        } else {
            return "974px";
        }
    });

    useEffect(() => {
        if (width <= 330) {
            setCardContWith("164px");
        } else {
            if (width <= 492) {
                setCardContWith("326px");
            } else {
                if (width <= 654) {
                    setCardContWith("488px");
                } else {
                    if (width <= 816) {
                        setCardContWith("650px");
                    } else {
                        if (width <= 978) {
                            setCardContWith("812px");
                        } else {
                            setCardContWith("974px");
                        }
                    }
                }
            }
        }
    }, [width]);

    let headerObservedRef = useRef(null);

    const [headerHeight, setHeaderHeight] = useState();
    const [filter, setFilter] = useState(null);

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, filter, results, width, isSideMenuMinimized]);

    const [disabled, setDisabled] = useState(true);

    const [filterBy, setFilterBy] = useState("all");
    const [category, setCategory] = useState("general");
    const [availability, setAvailability] = useState("available");
    const [priceFrom, setPriceFrom] = useState("");
    const [priceTo, setPriceTo] = useState("");
    const [sortBy, setSortBy] = useState("name");

    useEffect(() => {
        if (filterBy === "all" && priceFrom === "" && priceTo === "" && sortBy === "name") {
            if (filter) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            setDisabled(false);
        }
        // eslint-disable-next-line
    }, [filterBy, priceFrom, priceTo, sortBy])

    const [showAlertFrom, setShowAlertFrom] = useState(false);
    const [showAlertTo, setShowAlertTo] = useState(false);

    useEffect(() => {
        if (productsStatistics && controler) {
            if ((controler.searchingText.trim() === "") && (!filter)) {
                setDocsCount(productsStatistics.all);
            }
        }
    }, [filter, productsStatistics, controler]);

    const [filterIcon, setFilterIcon] = useState(appTheme.dark ? filterImgDark : filterImgLight);

    const [viewIcon, setViewIcon] = useState(() => {
        if (productsView) {
            if (productsView === "tableView") {
                return appTheme.dark ? listIconDark : listIconLight;
            } else {
                return appTheme.dark ? gridIconDark : gridIconLight;
            }
        } else {
            return appTheme.dark ? gridIconDark : gridIconLight;
        }
    });

    const [dropDownMenuOpen, setDropDownMenuOpen] = useState(false);
    const [dropDownViewOpen, setDropDownViewOpen] = useState(false);

    useEffect(() => {
        if (userSettings && userSettings.productsView) {
            setProductsView(userSettings.productsView);
            setTempProductsView(userSettings.productsView);
        }
    }, [userSettings, dropDownViewOpen]);

    const onScroll = () => {
        setDropDownMenuOpen(false);
        setDropDownViewOpen(false);
    }

    let menuRef = useRef();
    let viewRef = useRef();

    useEffect(() => {
        if (dropDownMenuOpen) {
            setFilterIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setFilterIcon(appTheme.dark ? filterImgDark : filterImgLight);
        }
    }, [dropDownMenuOpen, appTheme]);

    useEffect(() => {
        if (dropDownViewOpen) {
            setViewIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setViewIcon(() => {
                if (productsView) {
                    if (productsView === "tableView") {
                        return appTheme.dark ? listIconDark : listIconLight;
                    } else {
                        return appTheme.dark ? gridIconDark : gridIconLight;
                    }
                } else {
                    return appTheme.dark ? gridIconDark : gridIconLight;
                }
            });
        }
        // eslint-disable-next-line
    }, [dropDownViewOpen, appTheme]);

    useEffect(() => {
        if (controler) {
            if (
                pageNumber === 1 &&
                products &&
                controler.filterBy === "all" &&
                !controler.priceFrom &&
                !controler.priceTo &&
                controler.sortBy === "name" &&
                controler.searchingText.trim() === ""
            ) {
                if (controler.triggerSearch) {
                    onResetAll();
                }
            } else {
                if (accountData && controler.triggerSearch) {
                    searchSales(controler);
                }
            }
        }
        // eslint-disable-next-line 
    }, [controler]);

    function removeAccents(text) {
        const normalizedString = text.normalize("NFD");
        const chainWithoutAccents = normalizedString.replace(/[\u0300-\u036f]/g, "");
        return chainWithoutAccents;
    }

    function optimizeTextForSearch(text) {
        const chainWithoutAccents = removeAccents(text);
        const lowerCaseText = chainWithoutAccents.toLowerCase();
        const trimText = lowerCaseText.trim();
        return trimText;
    }

    function getQueryOperator(filter) {
        if (filter) {
            if (filter.filterBy === "availability" && filter.availability === "available") {
                return "!=";
            } else {
                return "==";
            }
        } else {
            return "==";
        }
    }

    function getQueryValue(filter) {
        if (filter) {
            if (filter.filterBy === "availability") {
                if (filter.availability === "available") {
                    return "out of stock";
                } else {
                    return filter.availability;
                }
            } else {
                if (filter.filterBy === "category") {
                    return filter.category;
                } else {
                    return null
                }
            }
        } else {
            return "available"
        }
    }

    const searchSales = async (controler) => {
        setActiveId(null);

        if (
            products &&
            navControlers.page === 1 &&
            controler.filterBy === "all" &&
            !controler.priceFrom &&
            !controler.priceTo &&
            controler.sortBy === "name" &&
            controler.searchingText.trim() === ""
        ) { return; }

        // eslint-disable-next-line
        setLoading(true);
        setShowConnectionError(false);

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let q = null;
        let qNoOrder = null;

        if (filter) {
            if (filter.filterBy === "all") {
                if (isNumber(filter.from)) {
                    if (filter.sortBy === "name") {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        }
                    } else {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        }
                    }
                } else {
                    if (filter.sortBy === "name") {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                            );
                        }

                    } else {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                            );
                        }
                    }
                }
            } else {
                if (isNumber(filter.from)) {
                    if (filter.sortBy === "name") {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        }
                    } else {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                where("finalPrice", ">=", filter.from),
                                where("finalPrice", "<=", filter.to),
                            );
                        }
                    }
                } else {
                    if (filter.sortBy === "name") {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("nameInsensitive"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("nameInsensitive"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                orderBy("nameInsensitive"),
                            );
                        }

                    } else {
                        if (searchingBy === "") {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                            );
                        } else {
                            // To get the sales docs
                            q = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                limit(30)
                            );

                            // To get the docsCount to control the page nav
                            qNoOrder = query(
                                collection(firebase.db, `accounts/${accountData.id}/products`),
                                where("searchKeys", "array-contains", searchingBy),
                                orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                            );
                        }
                    }
                }
            }
        } else {
            if (searchingBy === "") {
                // To get the sales docs
                q = query(
                    collection(firebase.db, `accounts/${accountData.id}/products`),
                    orderBy("nameInsensitive"),
                    limit(30)
                );
            } else {
                // To get the sales docs
                q = query(
                    collection(firebase.db, `accounts/${accountData.id}/products`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("nameInsensitive"),
                    limit(30)
                );

                // To get the docsCount to control the page nav
                qNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/products`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("nameInsensitive"),
                );
            }
        }

        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        try {
            const snapshot = await getDocsFromServer(q);
            const res = [];

            let newDocsCount = null;

            if (filter || (searchingBy !== "")) {
                const snapshot = await getCountFromServer(qNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            setPageHistory([]);
            setPageNumber(1);

            snapshot.forEach((doc) => {
                if (doc.data()) {
                    const thisDoc = {
                        ...doc.data(),
                        "id": doc.id,
                    }

                    delete thisDoc.searchKeys;

                    res.push(thisDoc);
                }
            });

            // eslint-disable-next-line
            if (filter || (searchingBy !== "")) {
                setDocsCount(newDocsCount);
                setShowPageNav(productsStatistics ? (Math.ceil(newDocsCount / 30) > 1 ? true : false) : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": productsStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": false,
                    "isNext": productsStatistics ? (Math.ceil(newDocsCount / 30) > 1 ? true : false) : false
                });
            } else {
                setShowPageNav(productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
                });
            }

            if (res.length > 0) {
                setEmpty(false);
                if (filter) {
                    if (filter.sortBy === "name") {
                        updateOrPushHistory({
                            "page": 1,
                            "startAt": res[0].nameInsensitive,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].nameInsensitive,
                            "endAtDocId": res[res.length - 1].id,
                        });
                    } else {
                        updateOrPushHistory({
                            "page": 1,
                            "startAt": res[0].finalPrice,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].finalPrice,
                            "endAtDocId": res[res.length - 1].id,
                        });
                    }
                } else {
                    updateOrPushHistory({
                        "page": 1,
                        "startAt": res[0].nameInsensitive,
                        "startAtDocId": res[0].id,
                        "endAt": res[res.length - 1].nameInsensitive,
                        "endAtDocId": res[res.length - 1].id,
                    });
                }
            } else {
                setEmpty(true);
            }

            setResults(Array.isArray(res) ? res : []);
            setLoading(false);
            // eslint-disable-next-line
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowConnectionError(true);
        }
    }

    const getPageDetailsByNumber = (n) => {
        if (pagesHistory) {
            for (let i = 0; i < pagesHistory.length; i++) {
                if (pagesHistory[i]["page"] === n) {
                    return pagesHistory[i];
                }
            }
        }
        return null;
    }

    // To get the accounts use the pageHistory to call the funcition to get it.
    const updateOrPushHistory = (updatedObject) => {
        const pagesHistoryCopy = pagesHistory ? [...pagesHistory] : [];
        const page = updatedObject.page;
        const index = pagesHistoryCopy.findIndex(obj => obj["page"] === page);

        if (index !== -1) {
            // If the object exists, update it
            pagesHistoryCopy[index] = { ...pagesHistoryCopy[index], ...updatedObject };
            setPageHistory(pagesHistoryCopy);
        } else {
            // If the object doesn't exist, push a new object to the array
            pagesHistoryCopy.push(updatedObject);
            setPageHistory(pagesHistoryCopy);
        }
    }

    const onResetAll = () => {
        setActiveId(null);
        blink();

        setControler({
            "filterBy": "all",
            "category": "general",
            "availability": "available",
            "priceFrom": null,
            "priceTo": null,
            "sortBy": "name",
            "searchingText": "",
            "triggerSearch": false,
        });

        setPageHistory([]);

        setResults(products);

        setShowPageNav(productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false);

        setNavControlers({
            "page": 1,
            "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false,
        });
        setPageNumber(1);
    }

    async function goToPagePrevious(q, pageN) {
        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            if (filter) {
                if (filter.sortBy === "name") {
                    updateOrPushHistory({
                        "page": pageN,
                        "startAt": res[0].nameInsensitive,
                        "startAtDocId": res[0].id,
                        "endAt": res[res.length - 1].nameInsensitive,
                        "endAtDocId": res[res.length - 1].id,
                    });
                } else {
                    updateOrPushHistory({
                        "page": pageN,
                        "startAt": res[0].finalPrice,
                        "startAtDocId": res[0].id,
                        "endAt": res[res.length - 1].finalPrice,
                        "endAtDocId": res[res.length - 1].id,
                    });
                }
            } else {
                updateOrPushHistory({
                    "page": pageN,
                    "startAt": res[0].nameInsensitive,
                    "startAtDocId": res[0].id,
                    "endAt": res[res.length - 1].nameInsensitive,
                    "endAtDocId": res[res.length - 1].id,
                });
            }

            setResults(res);
        }
    }

    // eslint-disable-next-line
    const onClickPrevious = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isPrevious === false) {
            return;
        }

        let startAtText = null;

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        const lastPageNumber = navControlers.page - 1;

        if (lastPageNumber === 1 && (!filter) && searchingBy === "") {
            onResetAll();
            return
        }

        setLoading(true);

        try {
            const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

            let docSnap = null;

            if (!lastPageDetails) {
                console.log("ERROR********************");
            } else {
                startAtText = lastPageDetails.startAt;
                const productRef = collection(firebase.db, `accounts/${accountData.id}/products`);
                docSnap = await getDoc(doc(productRef, lastPageDetails.startAtDocId));
            }

            let lastQuery = null;
            let lastQueryNoOrder = null;

            if (filter) {
                if (filter.filterBy === "all") {
                    if (isNumber(filter.from)) {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        }
                    } else {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                );
                            }
                        }
                    }
                } else {
                    if (isNumber(filter.from)) {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        }
                    } else {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    startAt(docSnap ? docSnap : startAtText),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                );
                            } else {
                                // To get the sales docs
                                lastQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    startAt(docSnap ? docSnap : startAtText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                lastQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                );
                            }
                        }
                    }
                }
            } else {
                if (searchingBy === "") {
                    // To get the sales docs
                    lastQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        orderBy("nameInsensitive"),
                        startAt(docSnap ? docSnap : startAtText),
                        limit(30)
                    );
                } else {
                    // To get the sales docs
                    lastQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        where("searchKeys", "array-contains", searchingBy),
                        orderBy("nameInsensitive"),
                        startAt(docSnap ? docSnap : startAtText),
                        limit(30)
                    );

                    // To get the docsCount to control the page nav
                    lastQueryNoOrder = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        where("searchKeys", "array-contains", searchingBy),
                        orderBy("nameInsensitive"),
                    );
                }
            }


            await goToPagePrevious(lastQuery, navControlers.page - 1);

            let newDocsCount = null;

            if (filter || (searchingBy !== "")) {
                const snapshot = await getCountFromServer(lastQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page - 1;
            setPageNumber(newPageNumber);

            if (filter || (searchingBy !== "")) {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": productsStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": productsStatistics ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });

            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > newPageNumber ? true : false) : false
                })
            }

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }

        setLoading(false);
    }

    async function goToNextPage(q) {

        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            if (pagesHistory) {
                if (!(pagesHistory.length > 0)) {
                    let newHistory = null;
                    if (filter) {
                        if (filter.sortBy === "name") {
                            newHistory = [
                                {
                                    "page": 1,
                                    "startAt": results[0].nameInsensitive,
                                    "startAtDocId": results[0].id,
                                    "endAt": results[results.length - 1].nameInsensitive,
                                    "endAtDocId": results[results.length - 1].id,
                                },
                                {
                                    "page": navControlers.page + 1,
                                    "startAt": res[0].nameInsensitive,
                                    "startAtDocId": res[0].id,
                                    "endAt": res[res.length - 1].nameInsensitive,
                                    "endAtDocId": res[res.length - 1].id,
                                }
                            ];
                        } else {
                            newHistory = [
                                {
                                    "page": 1,
                                    "startAt": results[0].finalPrice,
                                    "startAtDocId": results[0].id,
                                    "endAt": results[results.length - 1].finalPrice,
                                    "endAtDocId": results[results.length - 1].id,
                                },
                                {
                                    "page": navControlers.page + 1,
                                    "startAt": res[0].finalPrice,
                                    "startAtDocId": res[0].id,
                                    "endAt": res[res.length - 1].finalPrice,
                                    "endAtDocId": res[res.length - 1].id,
                                }
                            ];
                        }
                    } else {
                        newHistory = [
                            {
                                "page": 1,
                                "startAt": results[0].nameInsensitive,
                                "startAtDocId": results[0].id,
                                "endAt": results[results.length - 1].nameInsensitive,
                                "endAtDocId": results[results.length - 1].id,
                            },
                            {
                                "page": navControlers.page + 1,
                                "startAt": res[0].nameInsensitive,
                                "startAtDocId": res[0].id,
                                "endAt": res[res.length - 1].nameInsensitive,
                                "endAtDocId": res[res.length - 1].id,
                            }
                        ];
                    }

                    setPageHistory(newHistory);
                } else {
                    if (filter) {
                        if (filter.sortBy === "name") {
                            updateOrPushHistory({
                                "page": navControlers.page + 1,
                                "startAt": res[0].nameInsensitive,
                                "startAtDocId": res[0].id,
                                "endAt": res[res.length - 1].nameInsensitive,
                                "endAtDocId": res[res.length - 1].id,
                            });
                        } else {
                            updateOrPushHistory({
                                "page": navControlers.page + 1,
                                "startAt": res[0].finalPrice,
                                "startAtDocId": res[0].id,
                                "endAt": res[res.length - 1].finalPrice,
                                "endAtDocId": res[res.length - 1].id,
                            });
                        }
                    } else {
                        updateOrPushHistory({
                            "page": navControlers.page + 1,
                            "startAt": res[0].nameInsensitive,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].nameInsensitive,
                            "endAtDocId": res[res.length - 1].id,
                        });
                    }
                }
            } else {
                if (filter) {
                    if (filter.sortBy === "name") {
                        updateOrPushHistory({
                            "page": navControlers.page + 1,
                            "startAt": res[0].nameInsensitive,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].nameInsensitive,
                            "endAtDocId": res[res.length - 1].id,
                        });
                    } else {
                        updateOrPushHistory({
                            "page": navControlers.page + 1,
                            "startAt": res[0].finalPrice,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].finalPrice,
                            "endAtDocId": res[res.length - 1].id,
                        });
                    }
                } else {
                    updateOrPushHistory({
                        "page": navControlers.page + 1,
                        "startAt": res[0].nameInsensitive,
                        "startAtDocId": res[0].id,
                        "endAt": res[res.length - 1].nameInsensitive,
                        "endAtDocId": res[res.length - 1].id,
                    });
                }
            }
            setResults(res);
        }
    }

    // eslint-disable-next-line
    const onClickNext = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isNext === false) {
            return;
        }

        let startAfterOrAfterText = null;

        const nextPageNumber = navControlers.page + 1;
        const nextPageDetails = getPageDetailsByNumber(nextPageNumber);

        let endAtDocId = null;

        if (!nextPageDetails) {
            const lastProduct = results[results.length - 1];
            endAtDocId = lastProduct.id;

            if (filter) {
                if (filter.sortBy === "name") {
                    startAfterOrAfterText = lastProduct.nameInsensitive;
                } else {
                    startAfterOrAfterText = lastProduct.finalPrice;
                }
            } else {
                startAfterOrAfterText = lastProduct.nameInsensitive;
            }
        } else {
            const previousPage = getPageDetailsByNumber(nextPageNumber - 1);
            if (previousPage) {
                endAtDocId = previousPage.endAtDocId;
            }
            startAfterOrAfterText = nextPageDetails.startAt;
        }

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let nextQuery = null;
        let nextQueryNoOrder = null;
        let docSnap = null;

        try {
            setLoading(true);

            if (endAtDocId) {
                const productRef = collection(firebase.db, `accounts/${accountData.id}/products`);
                docSnap = await getDoc(doc(productRef, endAtDocId));
            }

            if (filter) {
                if (filter.filterBy === "all") {
                    if (isNumber(filter.from)) {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        }
                    } else {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                );
                            }
                        }
                    }
                } else {
                    if (isNumber(filter.from)) {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    where("finalPrice", ">=", filter.from),
                                    where("finalPrice", "<=", filter.to),
                                );
                            }
                        }
                    } else {
                        if (filter.sortBy === "name") {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("nameInsensitive"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                );
                            }
                        } else {
                            if (searchingBy === "") {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                );
                            } else {
                                // To get the sales docs
                                nextQuery = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                                    limit(30)
                                );

                                // To get the docsCount to control the page nav
                                nextQueryNoOrder = query(
                                    collection(firebase.db, `accounts/${accountData.id}/products`),
                                    where("searchKeys", "array-contains", searchingBy),
                                    where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                    orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                );
                            }
                        }
                    }
                }
            } else {
                if (searchingBy === "") {
                    // To get the sales docs
                    nextQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        orderBy("nameInsensitive"),
                        endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                        limit(30)
                    );
                } else {
                    // To get the sales docs
                    nextQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        where("searchKeys", "array-contains", searchingBy),
                        orderBy("nameInsensitive"),
                        endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                        limit(30)
                    );

                    // To get the docsCount to control the page nav
                    nextQueryNoOrder = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        where("searchKeys", "array-contains", searchingBy),
                        orderBy("nameInsensitive"),
                    );
                }
            }

            await goToNextPage(nextQuery);

            let newDocsCount = null;

            if (filter || (searchingBy !== "")) {
                const snapshot = await getCountFromServer(nextQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page + 1;
            setPageNumber(newPageNumber);

            if (filter || (searchingBy !== "")) {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": productsStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": productsStatistics ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });

            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > newPageNumber ? true : false) : false
                })
            }

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }
        setLoading(false);
    }

    const navigate = useNavigate();

    const handleChange = e => {
        setSearchingText(e.target.value);
    }

    let inputRef = null;

    const setRefernce = (inputElement) => {
        inputRef = inputElement;
    }

    const cleanInput = () => {
        try {
            if (inputRef) {
                inputRef.focus();
            }
        } catch (error) {
            console.warn(error);
        }

        setSearchingText("");
    }

    useEffect(() => {
        if (dropDownMenuOpen) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    setDropDownMenuOpen(false);
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    useEffect(() => {
        if (dropDownViewOpen) {
            let handler = (e) => {
                if (!viewRef.current.contains(e.target)) {
                    setDropDownViewOpen(false);
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickUserButton = () => {
        dropDownMenuOpen ? setDropDownMenuOpen(false) : setDropDownMenuOpen(true);
    }

    const onClickViewButton = () => {
        dropDownViewOpen ? setDropDownViewOpen(false) : setDropDownViewOpen(true);
    }

    const esPlaceholder = "Escribe palabra clave aquí...";
    const enPlaceholder = "Write keyword here...";

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            setControler({
                ...controler,
                "searchingText": searchingText,
                "triggerSearch": true,
            });
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.products)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const refreshSearch = async () => {
        if (
            products &&
            navControlers.page === 1 &&
            controler.filterBy === "all" &&
            !controler.priceFrom &&
            !controler.priceTo &&
            controler.sortBy === "name" &&
            controler.searchingText.trim() === ""
        ) {
            const recentlyOpenedAccounts = Array.isArray(products) ? products : [];
            setResults(recentlyOpenedAccounts);

            setNavControlers({
                "page": 1,
                "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                "isPrevious": false,
                "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
            });
        } else {
            let startAtText = null;

            // This is like previous, but we usel the current page number
            const lastPageNumber = navControlers.page;

            const searchingBy = optimizeTextForSearch(controler.searchingText);

            if (lastPageNumber === 1 && (!filter) && searchingBy === "") {
                onResetAll();
                return
            }

            try {

                const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

                let docSnap = null;

                if (!lastPageDetails) {
                    console.log("ERROR********************");
                } else {
                    startAtText = lastPageDetails.startAt;
                    const productRef = collection(firebase.db, `accounts/${accountData.id}/products`);
                    docSnap = await getDoc(doc(productRef, lastPageDetails.startAtDocId));
                }

                let lastQuery = null;

                if (filter) {
                    if (filter.filterBy === "all") {
                        if (isNumber(filter.from)) {
                            if (filter.sortBy === "name") {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("nameInsensitive"),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("nameInsensitive"),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            } else {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            }
                        } else {
                            if (filter.sortBy === "name") {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("nameInsensitive"),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("nameInsensitive"),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            } else {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            }
                        }
                    } else {
                        /******************************* */
                        if (isNumber(filter.from)) {
                            if (filter.sortBy === "name") {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("nameInsensitive"),
                                        where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("nameInsensitive"),
                                        where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            } else {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                        where("finalPrice", ">=", filter.from),
                                        where("finalPrice", "<=", filter.to),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            }
                        } else {
                            if (filter.sortBy === "name") {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("nameInsensitive"),
                                        where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("nameInsensitive"),
                                        where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            } else {
                                if (searchingBy === "") {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                } else {
                                    // To get the sales docs
                                    lastQuery = query(
                                        collection(firebase.db, `accounts/${accountData.id}/products`),
                                        where("searchKeys", "array-contains", searchingBy),
                                        orderBy("finalPrice", filter.sortBy === "lower" ? "asc" : "desc"),
                                        where(filter.filterBy, getQueryOperator(filter), getQueryValue(filter)),
                                        startAt(docSnap ? docSnap : startAtText),
                                        limit(30)
                                    );
                                }
                            }
                        }
                    }
                } else {
                    if (searchingBy === "") {
                        // To get the sales docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/products`),
                            orderBy("nameInsensitive"),
                            startAt(docSnap ? docSnap : startAtText),
                            limit(30)
                        );
                    } else {
                        // To get the sales docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/products`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("nameInsensitive"),
                            startAt(docSnap ? docSnap : startAtText),
                            limit(30)
                        );
                    }
                }

                await goToPagePrevious(lastQuery, navControlers.page);

                setLoading(false);

                setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);
            } catch (error) {
                console.error(error);
            }
        }
    }

    const onClickApplyFilter = () => {
        if ((priceFrom === "") !== (priceTo === "")) {
            if (priceFrom === "") {
                setShowAlertFrom(true);
                return;
            }

            if (priceTo === "") {
                setShowAlertTo(true);
                return;
            }
        }

        setFilter({
            "filterBy": filterBy,
            "category": category,
            "availability": availability,
            "from": priceFrom === "" ? "" : Number(priceFrom),
            "to": priceTo === "" ? "" : Number(priceTo),
            "sortBy": sortBy,
        });

        setControler({
            ...controler,
            "filterBy": filterBy,
            "category": category,
            "availability": availability,
            "priceFrom": priceFrom === "" ? "" : Number(priceFrom),
            "priceTo": priceTo === "" ? "" : Number(priceTo),
            "sortBy": sortBy,
            "searchingText": searchingText,
            "triggerSearch": true,
        });

        setPageHistory([]);

        setDropDownMenuOpen(false);
    }

    const onClickCleanFilter = () => {
        setActiveId(null);
        setResults(recentlyOpenedAccounts);

        setControler({
            "filter": "name",
            "category": "all",
            "priceFrom": null,
            "priceTo": null,
            "searchingText": "",
            "triggerSearch": false,
        });

        setPageHistory([]);

        setNavControlers({
            "page": 1,
            "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
        });

        setShowConnectionError(false);

        setEmpty(productsStatistics ? (productsStatistics.all > 0 ? false : true) : true);
        setShowPageNav(productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false);

        setPageNumber(1);

        setSearchingText("");
        setFilter(null);

        setFilterBy("all");
        setCategory("general");
        setAvailability("available");
        setPriceFrom("");
        setPriceTo("");
        setSortBy("name");

        setSearchingTextActive(false);

        blink();
    }

    const search = async () => {
        if (searchingText.trim() !== "") {
            setControler({
                ...controler,
                "searchingText": searchingText,
                "triggerSearch": true,
            });
        }
    }

    const onChangePriceFrom = (e) => {
        const text = e.target.value;
        let amount = "";
        let isPoint = false;

        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        amount += text[i];
                    }
                }
            }
        }

        setPriceFrom(amount);
    }

    const onChangePriceTo = (e) => {
        const text = e.target.value;
        let amount = "";
        let isPoint = false;

        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        amount += text[i];
                    }
                }
            }
        }
        setPriceTo(amount);
    }

    const onChangeSort = (e) => {
        setSortBy(e.target.value);
    }

    const onChangeCategory = (e) => {
        setCategory(e.target.value);
    }

    const onChangeAvailability = (e) => {
        setAvailability(e.target.value);
    }

    const onChangeFilterBy = (e) => {
        setFilterBy(e.target.value);
        setCategory("general");
        setAvailability("available");
    }

    const getFilterByText = (filter) => {
        if (filter.filterBy === "all") {
            const text = appLanguage.en ? "Showing All" : "Mostrando Todo";
            return text;
        } else {
            if (filter.filterBy === "availability") {
                switch (filter.availability) {
                    case "available":
                        return appLanguage.en ? "Showing Available" : "Mostrando Disponible";
                    case "close to out of stock":
                        return appLanguage.en ? "Showing Close to out of stock" : "Mostrando Proximo a agotarse";
                    case "out of stock":
                        return appLanguage.en ? "Showing Out of stock" : "Mostrando Agotado";
                    default:
                        return "break";
                }
            } else {
                const category = getCategory(filter.category);
                const text = `${appLanguage.en ? "Showing " : "Mostrando "} ${category}`;
                return text;
            }
        }
    }

    const getSortByTest = (sortBy) => {
        if (sortBy === "name") {
            const text = appLanguage.en ? "Sorted by name" : "Ordenado por nombre";
            return text;
        } else {
            if (sortBy === "higher") {
                const text = appLanguage.en ? "From highest to lowest price" : "De mayor a menor precio";
                return text;
            } else {
                if (sortBy === "lower") {
                    const text = appLanguage.en ? "From lowest to highest price" : "De menor a mayor precio";
                    return text;
                } else {
                    return sortBy
                }
            }
        }
    }

    const tryAgain = () => {
        setActiveId(null);
        setControler({
            ...controler,
            "triggerSearch": true,
        })
    }

    useEffect(() => {
        if (filter) {
            setFilterBy(filter.filterBy);
            setCategory(filter.category);
            setAvailability(filter.availability);
            setPriceFrom(filter.from);
            setPriceTo(filter.to);
            setSortBy(filter.sortBy);
        } else {
            setFilterBy("all");
            setCategory("general");
            setAvailability("available");
            setPriceFrom("");
            setPriceTo("");
            setSortBy("name");
        }
        setShowAlertFrom(false);
        setShowAlertTo(false);
        // eslint-disable-next-line
    }, [dropDownMenuOpen]);

    const [searchInputFocus, setSearchInputFocus] = useState(false);

    useEffect(() => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
        // eslint-disable-next-line
    }, [document.activeElement]);

    const checkSearchInput = () => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
    }

    const onSaveSettings = async () => {
        setLoadingSettings(true);
        try {
            const res = await firebase.useChangeProductsView({
                "businessId": accountData.id,
                "productsView": tempProductsView,
            });
            setProductsView(res.productsView);

            setUserSettings(res);
            saveKeyInIndexedDB("userSettings", res);

            setDropDownViewOpen(false);
            setSoftAlertActive(true);
            
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: 'Settings saved successfully',
                    es: 'Ajustes guardados exitosamente'
                }
            });
        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error: try again.',
                    es: 'Error: inténtalo de nuevo.',
                }
            });
        }
        setLoadingSettings(false);
    }

    useEffect(() => {
        let newControler = null;

        if (controler) {
            newControler = {
                ...controler,
                "triggerSearch": false,
            }
        }

        const newState = {
            "results": results,
            "filter": filter,
            "docsCount": docsCount,
            "controler": newControler,
            "pagesHistory": pagesHistory,
            "navControlers": navControlers,
            "showConnectionError": showConnectionError,
            "empty": empty,
            "showPageNav": showPageNav,
            "activeId": activeId,
            "searchingByTextActive": searchingByTextActive,
            "loading": false,
        }
        navigate(null, { replace: true, state: newState });

        // eslint-disable-next-line
    }, [results, controler, pagesHistory, navControlers, showConnectionError, empty, showPageNav, activeId, filter, docsCount, searchingByTextActive]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Products' : 'Productos'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo && controler ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.products ? <Fragment>
                                <div ref={headerObservedRef}>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? 'Products' : 'Productos'}
                                        />
                                        : null}
                                    <div className="products-services-first-section">
                                        <div className="products-services-first-item">
                                            <div className="div-search-input-home" style={{ marginTop: 0, maxWidth: "400px" }}>
                                                <input
                                                    ref={setRefernce}
                                                    type="search"
                                                    onBlur={checkSearchInput}
                                                    onFocus={checkSearchInput}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                    className={`search-input-home ${appTheme.dark ? "search-input-home-dar dar-sec-bg" : "search-input-home-lig lig-sec-bg"}`}
                                                    value={searchingText}
                                                    onChange={e => handleChange(e)}
                                                    name="searchingText"
                                                    onKeyDown={e => handleKeyPress(e)}
                                                    maxLength={30}
                                                    placeholder={appLanguage.en ? enPlaceholder : esPlaceholder}
                                                    style={{
                                                        colorScheme: appTheme.dark ? "dark" : "light"
                                                    }}
                                                />
                                                {searchingText === "" ? null :
                                                    <div className={`search-div-delete ${searchInputFocus ? (appTheme.dark ? "search-button-home-dar-focus" : "search-button-home-lig-focus") : (appTheme.dark ? "search-button-home-dar" : "search-button-home-lig")} ${appTheme.dark ? " dar-sec-bg" : "lig-sec-bg"}`}>
                                                        <button onClick={cleanInput} className={appTheme.dark ? "search-div-delete-button-dark" : "search-div-delete-button-light"}>
                                                            <img src={appTheme.dark ? crossWhiteIcon : crossIcon} alt="delete" />
                                                        </button>
                                                    </div>
                                                }
                                                <button
                                                    className="search-button-home"
                                                    type="submit"
                                                    onClick={search}
                                                >
                                                    <img src={magnifyingIcon} alt="Search box" />
                                                </button>
                                            </div>

                                            <div className="sales-filter-botton-main unselectable" style={{ minHeight: "58.4px" }}>
                                                <div ref={viewRef} className="sales-filter-botton-container">
                                                    <button
                                                        onClick={onClickViewButton}

                                                        className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                    >
                                                        <img
                                                            style={{ width: "20px", height: "20px", marginRight: "10px" }}
                                                            src={viewIcon}
                                                            alt="View"
                                                        />
                                                        <p>{appLanguage.en ? "View" : "Vista"}</p>
                                                    </button>

                                                    <span className={`view-products-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownViewOpen ? 'active' : 'inactive'}`} />

                                                    <div
                                                        style={{
                                                            boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                            marginLeft: "-20px",
                                                        }}
                                                        className={`dropdown-view-products ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"}   ${dropDownViewOpen ? 'active' : 'inactive'}`}
                                                    >

                                                        <p style={{ marginTop: "5px", marginLeft: "10px", marginBottom: "5px" }}>{
                                                            appLanguage.en ?
                                                                "This view is also used in the shopping cart."
                                                                :
                                                                "Esta vista es utilizada en el carrito de compras."
                                                        }
                                                        </p>

                                                        {loadingSettings ?
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    minHeight: "70px",
                                                                    paddingBottom: "75px"
                                                                }}
                                                            >
                                                                <Spinner />
                                                            </div>
                                                            : <Fragment>

                                                                <div className="view-products-options-cont">
                                                                    <button
                                                                        htmlFor="iconsView"
                                                                        onClick={() => changeView("iconsView")}
                                                                        style={{ color: appTheme.dark ? "white" : "black" }}
                                                                        className={`view-products-options-btn ${appTheme.dark ? "view-products-options-btn-dark" : "view-products-options-btn-light"}`}
                                                                    >
                                                                        <img src={appTheme.dark ? gridIconDark : gridIconLight} alt="Grid" />
                                                                        <p>{appLanguage.en ? "Icons" : "Iconos"}</p>
                                                                        <div style={{ flexGrow: 2 }} />
                                                                        <input
                                                                            style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                                                            type="radio"
                                                                            readOnly
                                                                            checked={tempProductsView === "iconsView" ? true : false}
                                                                            id="iconsView"
                                                                            name="productsView"
                                                                            value="iconsView"
                                                                        />
                                                                    </button>
                                                                    <button
                                                                        htmlFor="tableView"
                                                                        onClick={() => changeView("tableView")}
                                                                        style={{ color: appTheme.dark ? "white" : "black" }}
                                                                        className={`view-products-options-btn ${appTheme.dark ? "view-products-options-btn-dark" : "view-products-options-btn-light"}`}
                                                                    >
                                                                        <img src={appTheme.dark ? listIconDark : listIconLight} alt="List" />
                                                                        <p>{appLanguage.en ? "List" : "Lista"}</p>
                                                                        <div style={{ flexGrow: 2 }} />
                                                                        <input
                                                                            style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                                                            type="radio"
                                                                            readOnly
                                                                            checked={tempProductsView === "tableView" ? true : false}
                                                                            id="tableView"
                                                                            name="productsView"
                                                                            value="tableView"
                                                                        />
                                                                    </button>
                                                                </div>

                                                                <div
                                                                    className="drop-men-not-save-con"
                                                                    style={{
                                                                        marginTop: "15px",
                                                                        marginBottom: "20px"
                                                                    }}
                                                                >
                                                                    <button
                                                                        onClick={onSaveSettings}
                                                                        disabled={!showSave}
                                                                        className="new-customer-bottons-button-save"
                                                                        style={!showSave ? {
                                                                            backgroundColor: "#3f526698",
                                                                            color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                            cursor: "default"
                                                                        } : null}
                                                                    >
                                                                        <img src={saveIcon} alt="Save" />
                                                                        {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                                    </button>
                                                                </div>
                                                            </Fragment>}
                                                    </div>
                                                </div>

                                                <div ref={menuRef} className="sales-filter-botton-container">
                                                    <button
                                                        onClick={onClickUserButton}
                                                        style={{ margin: "0px 10px 0px 10px" }}
                                                        className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                    >
                                                        <img
                                                            style={{ width: "20px", height: "20px", marginRight: "10px" }}
                                                            src={filterIcon}
                                                            alt="Filter"
                                                        />
                                                        <p>{appLanguage.en ? "Filter" : "Filtrar"}</p>
                                                    </button>

                                                    <span className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownMenuOpen ? 'active' : 'inactive'}`} />

                                                    <div
                                                        style={{
                                                            boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                            marginLeft: "-95px",
                                                        }}
                                                        className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"}   ${dropDownMenuOpen ? 'active' : 'inactive'}`}
                                                    >

                                                        <label className="filter-labels-inputs" htmlFor="filterBy">{appLanguage.en ? "Filter by" : "Filtrar por"}</label>
                                                        <select
                                                            style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                            className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg input-border-box-dark" : "lig-pri-bg input-border-box-light"}`}
                                                            name="filterBy"
                                                            id="filterBy"
                                                            value={filterBy}
                                                            onChange={e => onChangeFilterBy(e)}
                                                        >
                                                            <option value="all">{appLanguage.en ? "All" : "Todo"}</option>
                                                            <option value="category">{appLanguage.en ? "Category" : "Categoría"}</option>
                                                            <option value="availability">{appLanguage.en ? "Availability" : "Disponibilidad"}</option>
                                                        </select>

                                                        {filterBy !== "all" ? <Fragment>
                                                            {filterBy === "category" ? <Fragment>
                                                                <label className="filter-labels-inputs" htmlFor="category">{appLanguage.en ? "Category" : "Categoría"}</label>
                                                                <select
                                                                    style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                                    className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg input-border-box-dark" : "lig-pri-bg input-border-box-light"}`}
                                                                    name="category"
                                                                    id="category"
                                                                    value={category}
                                                                    onChange={e => onChangeCategory(e)}
                                                                >
                                                                    {getThisBusinessProductsCategories(accountData.businessCategory).map((category, index) => {
                                                                        return (
                                                                            <option key={index} value={category}>{getCategory(category)}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </Fragment> : <Fragment>
                                                                <label className="filter-labels-inputs" htmlFor="availability">{appLanguage.en ? "Availability" : "Disponibilidad"}</label>
                                                                <select
                                                                    style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                                    className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg input-border-box-dark" : "lig-pri-bg input-border-box-light"}`}
                                                                    name="availability"
                                                                    id="availability"
                                                                    value={availability}
                                                                    onChange={e => onChangeAvailability(e)}
                                                                >
                                                                    <option value="available">{appLanguage.en ? "Available" : "Disponible"}</option>
                                                                    <option value="close to out of stock">{appLanguage.en ? "Close to out of stock" : "Proximo a agotarse"}</option>
                                                                    <option value="out of stock">{appLanguage.en ? "Out of stock" : "Agotado"}</option>
                                                                </select>
                                                            </Fragment>}
                                                        </Fragment> : null}

                                                        <label className="filter-labels-inputs">{appLanguage.en ? "From " : "Desde "} {showAlertFrom ? (appLanguage.en ? "** invalid **" : "** inválida **") : null}</label>
                                                        <input
                                                            value={priceFrom}
                                                            inputMode="numeric"
                                                            type="text"
                                                            onChange={e => onChangePriceFrom(e)}
                                                            style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                            className={`input-type-filter-price-num-text ${showAlertFrom ? "border-date-alert" : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"} `}
                                                            placeholder={"RD$ 0.00"}
                                                        />

                                                        <label className="filter-labels-inputs">{appLanguage.en ? "Up to" : "Hasta"} {showAlertTo ? (appLanguage.en ? "** invalid **" : "** inválida **") : null}</label>
                                                        <input
                                                            value={priceTo}
                                                            type="text"
                                                            inputMode="numeric"
                                                            onChange={e => onChangePriceTo(e)}
                                                            style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                            className={`input-type-filter-price-num-text ${showAlertTo ? "border-date-alert" : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                            placeholder={"RD$ 0.00"}
                                                        />

                                                        <label className="filter-labels-inputs" htmlFor="seller">{appLanguage.en ? "Sort by" : "Ordenar por"}</label>
                                                        <select
                                                            style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                            className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg input-border-box-dark" : "lig-pri-bg input-border-box-light"}`}
                                                            name="seller"
                                                            id="seller"
                                                            value={sortBy}
                                                            onChange={e => onChangeSort(e)}
                                                        >
                                                            <option value="name">{appLanguage.en ? "Name" : "Nombre"}</option>
                                                            <option value="higher">{appLanguage.en ? "Higher price" : "Mayor precio"}</option>
                                                            <option value="lower">{appLanguage.en ? "Lower price" : "Menor precio"}</option>
                                                        </select>

                                                        <div className="filter-button-action-div">
                                                            <button
                                                                disabled={disabled}
                                                                onClick={onClickApplyFilter}
                                                                className="filter-button-btn-div"
                                                                style={disabled ? {
                                                                    backgroundColor: "#3f526698",
                                                                    color: appTheme.dark ? "rgb(173, 173, 173)" : "rgba(255, 255, 255, 0.712)",
                                                                    cursor: "default",
                                                                    boxShadow: "none",
                                                                } : null}
                                                            >
                                                                {appLanguage.en ? "Apply" : "Aplicar"}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {filter ?
                                                <div
                                                    className={`filter-sales-details-main-con ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    <div className="filter-sales-details-data">
                                                        <p style={{ fontSize: "15px" }}>{getFilterByText(filter)}</p>
                                                        {isNumber(filter.from) ? <Fragment>
                                                            {filter.from > filter.to ?
                                                                <p style={{ fontSize: "12px", color: "rgba(255, 0, 0, 0.76)" }}><b>*** {appLanguage.en ? "Invalid price range" : "Rango de precios no válido"} ***</b></p>
                                                                :
                                                                <p style={{ fontSize: "12px" }}><b>RD$ {formatRationalNumber(filter.from)} - RD$ {formatRationalNumber(filter.to)}</b></p>
                                                            }
                                                        </Fragment> : <Fragment>
                                                            <p style={{ fontSize: "12px" }}><b>{getSortByTest(filter.sortBy)}</b></p>
                                                        </Fragment>}
                                                    </div>
                                                    <button className="filter-sales-details-clean-filter-btn" onClick={onClickCleanFilter}>
                                                        <img
                                                            style={{ width: "16px", height: "16px", marginRight: "16px" }}
                                                            src={appTheme.dark ? crossWhiteIcon : crossIcon}
                                                            alt="Clean filter"
                                                        />
                                                    </button>
                                                </div>
                                                :
                                                searchingByTextActive ?
                                                    <div
                                                        className={`filter-sales-details-main-con ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}
                                                        style={{ marginBottom: 0 }}
                                                    >
                                                        <div className="filter-sales-details-data">
                                                            <p style={{ color: appTheme.dark ? "#74b7ff" : "#1083ff" }}><b>{appLanguage.en ? "Search" : "Búsqueda"}</b></p>
                                                            <p>{appLanguage.en ? "Sorted by name" : "Ordenado por nombre"}</p>
                                                        </div>
                                                        <button className="filter-sales-details-clean-filter-btn" onClick={onClickCleanFilter}>
                                                            <img
                                                                style={{ width: "16px", height: "16px", marginRight: "16px" }}
                                                                src={appTheme.dark ? crossWhiteIcon : crossIcon}
                                                                alt="Clean filter"
                                                            />
                                                        </button>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </div>

                                {productsView === "iconsView" ?
                                    <ProductsViewIcons
                                        observedRef={observedRef}
                                        onScroll={onScroll}
                                        headerHeight={headerHeight}
                                        cardContWith={cardContWith}
                                        loading={loading}
                                        showConnectionError={showConnectionError}
                                        tryAgain={tryAgain}
                                        empty={empty}
                                        results={results}
                                        activeId={activeId}
                                        setActiveReference={setActiveReference}
                                        onClickLink={onClickLink}
                                        pageNumber={pageNumber}
                                        navControlers={navControlers}
                                        showPageNav={showPageNav}
                                        onClickNext={onClickNext}
                                        onClickPrevious={onClickPrevious}
                                    />
                                    :
                                    (productsView === "tableView" ? 
                                        <ProductsViewList
                                            observedRef={observedRef}
                                            onScroll={onScroll}
                                            headerHeight={headerHeight}
                                            loading={loading}
                                            showConnectionError={showConnectionError}
                                            tryAgain={tryAgain}
                                            empty={empty}
                                            results={results}
                                            activeId={activeId}
                                            setActiveReference={setActiveReference}
                                            onClickLink={onClickLink}
                                            pageNumber={pageNumber}
                                            navControlers={navControlers}
                                            showPageNav={showPageNav}
                                            onClickNext={onClickNext}
                                            onClickPrevious={onClickPrevious}
                                            containerWidth={width}
                                        />
                                    : null)
                                }
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default ProductsPage;