import "./home.page.scss";
import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from '../../context/AppContext';
import { AuthContext } from "../../firebase/context";
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SideMenuContext } from "../../context/SideMenuContext";

import SettingsActionRequired from "../../components/settings-action-required/settingsActionRequired";

import homeDarkIcon from '../../icons/home-dark.png';
import homeLightIcon from '../../icons/home-light.png';

import productsIcon from "../../img/home/delivery-box.png";
import salesIcon from "../../img/home/cashier-machine.png";

import inventoryIcon from "../../img/home/inventory.png";
import supplyIcon from "../../img/home/logistics.png";

import invoiceIcon from "../../img/home/file.png";
// import purchasingIcon from "../../img/home/purchasing.png";
import statisticsIcon from "../../img/home/statistics.svg";

import SectionForbidden from "../../components/sectionForbidden/sectionForbidden";
import { SoftLoadingContext } from "../../components/soft-loading/softLoadingContext";
import { AlertContext } from "../../components/alert/alertContext";

const HomePage = () => {

    const { appTheme, appLanguage } = useContext(AppContext);
    const { accountData, accessTo } = useContext(AuthContext);
    const { desktopView } = useContext(SideMenuContext);
    const { setSoftLoadingActive } = useContext(SoftLoadingContext);
    const { setAlertData, setAlertActive } = useContext(AlertContext);

    const [isAnyTool, setIsAnyTool] = useState(null);
    const [ numberOfSectionsAvailable, setNumberOfSectionsAvailable ] = useState(0);

    const getContainerSize = (num) => {
        if (num <= 4) {
            return "700px"
        } else {
            if (num <= 6) {
                return "570px"
            } else {
                return "700px"
            }
        }
    }

    useEffect(() => {
        if (accessTo) {
            let i = 0;
            
            // For a simple control
            if (accessTo.salesConsult) {
                i++;
            }

            if (accessTo.products) {
                i++;
            }

            if (accessTo.inventory) {
                i++;
            }

            if (accessTo.suppliers) {
                i++;
            }

            if (accessTo.taxes) {
                // Access to Invoices and Accounting
                i = i + 2;
            }

            /** if (accessTo.purchasesConsult) {
                i++;
            }*/

            setNumberOfSectionsAvailable(i);

            if (i > 0) {
                setIsAnyTool(true);
            } else {
                setIsAnyTool(false);
            }
        }
    }, [accessTo]);

    const navigate = useNavigate();
    const location = useLocation();

    const [theSelectedAccountHasChanged, setTheSelectedAccountHasChanged] = useState(false);

    useEffect(() => {
        if (location.state) {
            if (location.state.theSelectedAccountHasChanged) {
                setTheSelectedAccountHasChanged(location.state.theSelectedAccountHasChanged);
            }
        }
        // eslint-disable-next-line 
    }, []);

    const onReload = () => {
        setSoftLoadingActive(true);
        window.location.reload(false);
    }

    useEffect(() => {
        if (theSelectedAccountHasChanged === "Step 1") {
            navigate(null, { replace: true, state: {theSelectedAccountHasChanged: "Step 2"} });
            onReload();
        } else {
            if (theSelectedAccountHasChanged === "Step 2") {
                setAlertData({
                    type: 'error',
                    title: {
                        en: `A different account was selected in another tab.`,
                        es: `Se seleccionó una cuenta diferente en otra pestaña`
                    },
                    code: "error",
                    description: {
                        en : 'You have opened a different business account in another tab. To avoid errors, if you want to use different business accounts at the same time, please open them in different browsers.',
                        es : 'Has abierto una cuenta de negocios diferente en otra pestaña. Para evitar errores, si deseas usar diferentes cuentas de negocios al mismo tiempo, por favor ábrelas en navegadores distintos.'
                    }
                });
                setAlertActive(true);
                navigate(null, { replace: true, state: null });
            }
        }
    // eslint-disable-next-line
    }, [theSelectedAccountHasChanged])

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Home' : 'Inicio'} </title>
            </Helmet>
            <div
                className={`page-app-home-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}
                style={{
                    colorScheme: appTheme.dark ? "dark" : "light",
                    height: desktopView ? "calc(100vh - 65px)" : "calc(100vh - 135px)",
                }}
            >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item `}>

                            <div className="welcome-to-business-account-cont">
                                <div className="welcome-to-business-account-div">
                                    <img src={appTheme.dark ? homeDarkIcon : homeLightIcon} alt="Business" />
                                    <p className="welcome-to-business-account-title"><b>{accountData.businessName}</b></p>
                                </div>
                            </div>

                            {isAnyTool !== null ? <Fragment>
                                {isAnyTool ? <Fragment>
                                    <div className="page-choose-tools-options-container">
                                        <ul
                                            className="page-choose-tools-options"
                                            style={{ maxWidth: getContainerSize(numberOfSectionsAvailable) }}
                                        >
                                            {accessTo.salesConsult ?
                                                <ToolsItems dark={appTheme.dark} img={salesIcon} text={appLanguage.en ? "Sales" : "Ventas"} path={"/ventas"} />
                                            : null}

                                            {accessTo.products ?
                                                <ToolsItems dark={appTheme.dark} img={productsIcon} text={appLanguage.en ? "Products" : "Productos"} path={"/productos"} />
                                            : null}

                                            {accessTo.inventory ?
                                                <ToolsItems dark={appTheme.dark} img={inventoryIcon} text={appLanguage.en ? "Inventory" : "Inventario"} path={"/inventario"} />
                                            : null}

                                            {/** 
                                                {accessTo.purchasesConsult ? 
                                                        <ToolsItems dark={appTheme.dark} img={purchasingIcon} text={appLanguage.en ? "Purchases" : "Compras"} path={"/compras"} /> 
                                                : null}
                                            */}

                                            {accessTo.taxes ? 
                                                <ToolsItems dark={appTheme.dark} img={invoiceIcon} text={appLanguage.en ? "Invoices" : "Facturas"} path={"/facturas-del-negocio"} />
                                            : null}

                                            {accessTo.suppliers ?
                                                <ToolsItems dark={appTheme.dark} img={supplyIcon} text={appLanguage.en ? "Suppliers" : "Proveedores"} path={'/proveedores'} />
                                            : null}

                                            {accessTo.taxes ? 
                                                <ToolsItems dark={appTheme.dark} img={statisticsIcon} text={appLanguage.en ? "Accounting" : "Contabilidad"} path={"/contabilidad-basica"} />
                                            : null}
                                        </ul>
                                    </div>
                                    <div className={desktopView ? null : "mobile-control-button-space"} />
                                </Fragment> : <SectionForbidden sectionName={appLanguage.en ? "No tools enabled" : "Ninguna herramienta habilitada"} />}
                            </Fragment> : null}
                        </div>
                        :
                        <div className="page-app-home-item">
                            <div className="home-options-container">
                                <div className="home-options-container-item">
                                    <SettingsActionRequired />
                                </div>
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

function ToolsItems({ dark, img, text, path }) {
    return (
        <li className={`page-choose-tools-option ${dark ? 'page-choose-tools-option-dar' : 'page-choose-tools-option-lig'}`}>
            <Link className={`page-choose-tools-link ${dark ? 'dark-text' : 'light-text'}`} to={path}>
                <img src={img} alt={text} />
                <p>{text}</p>
            </Link>
        </li>
    );
}

export default HomePage;