import { Fragment, useContext, useEffect, useRef, useState  } from 'react';
import { uid } from 'uid';

import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../context/AppContext';
import { SideMenuContext } from '../../../context/SideMenuContext';
import { AuthContext } from '../../../firebase/context';
import { useNavigate } from 'react-router-dom';

import firebase from '../../../firebase';
import { doc, onSnapshot } from "firebase/firestore";

import Spinner from '../../../components/spinner/spinner';
import ConnectionError from "../../../components/connection-error/connectionError";

import SettingsHeader2 from '../../../components/settings-header-2/settingsHeader2';
import SettingsTitleBus from '../../../components/settings-title-bus/settingsTitleBus';
import businessIcon from '../../../img/analysis.svg';

import SettingsOption from '../../../components/settings-options/settingsOption';
import SettingsBills from '../../../components/settings-bills/settingsBills';

import settingIconDark from '../../../icons/settings-dark.png';
import settingIconLight from '../../../icons/settings-light.png';

import historyIconDark from '../../../icons/history-dark.png';
import historyIconLight from '../../../icons/history-light.png';

import '../businessAccount.page.scss';
import PictureReceiptViewer from '../../../appTools/appToolsComponents/pictureReceiptViewer/pictureReceiptViewer';

const BillsPage = () => {
    const { appLanguage, appTheme, windowDimension } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { accountData, adminAccess, managerAccess } = useContext(AuthContext);

    const [ bill, setBill ] = useState(null);
    const [ restriction, setRestriction ] = useState(null)
    const [ accountStatus, setAccountStatus ] = useState(null);

    const [ loading, setLoading ] = useState(true);
    const [ showConnectionError, setConnectionError ] = useState(false);

    const [ billError, setBillError ] = useState(false);
    const [ restrictionError, setRestrictionError ] = useState(false);

    const navigate = useNavigate();

    const [ effectControler, setEffectControler ] = useState(100000);

    useEffect(() => {
        if (accountData) {
            const billRef = `accounts/${accountData.id}/billingDetails`;

            const unsub = onSnapshot(doc(firebase.db, billRef, "bill"), (doc) => {
                const billDataUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                if (doc.data()) {
                    if (bill) {
                        if (doc.data()) {
                            setBill(billDataUpdated);
                    
                        }
                        setBillError(false);
                        setRestrictionError(false);
                        if (bill) {
                            setLoading(false);
                        }
                    } else {
                        setBill(billDataUpdated);
                        setBillError(false);
                        setLoading(false);
                    }
                } else {
                    setBillError(true);
                    setLoading(false);
                }
            }, err =>{
                console.error(err);
                setBillError(true);
                setLoading(false);
            });

            return () => unsub(); 
        }
    // eslint-disable-next-line
    }, [effectControler]);

    useEffect(() => {
        if (accountData) {
            const restrictionRef = `accounts/${accountData.id}/billingDetails`;

            const unsub = onSnapshot(doc(firebase.db, restrictionRef, "restriction"), (doc) => {
                const restrictionDataUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                if (doc.data()) {
                    if (restriction) {
                        if (doc.data()) {
                            setRestriction(restrictionDataUpdated);
                        }

                        setRestrictionError(false);

                        if (bill) {
                            setLoading(false);
                        }
                    } else {
                        setRestriction(restrictionDataUpdated);
                        setRestrictionError(false);
                        setLoading(false);
                    }
                } else {
                    setRestrictionError(true);
                    setLoading(false);
                }
            }, err =>{
                console.error(err);
                setRestrictionError(true);
                setLoading(false);
            });

            return () => unsub(); 
        }
    // eslint-disable-next-line
    }, [effectControler]);

    useEffect(() => {
        if (restrictionError || billError) {
            setConnectionError(true);
        } else {
            setConnectionError(false);
        }
    }, [billError, restrictionError]);

    async function getAccountBill() {
        if (accountData) {
            setAccountStatus(accountData.status);
        }
    }

    useEffect(() => {
        getAccountBill();
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!(adminAccess || managerAccess)) {
            navigate("/cuenta-de-negocios", { replace: true })
        }
    });

    const onTryAgain = () => {
        setEffectControler(uid(6))
    }

    const [pictureViewerActive, setPictureViewerActive] = useState(false);
    const [pictureFile, setPictureFile] = useState(null);

    let observedRef = useRef(null);

    const [width, setWidth] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            } else {
                try {
                    return observedRef.current.offsetWidth;
                } catch {
                    return;
                }
            }
        } else {
            return;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }
            const resizeObserver = new ResizeObserver(() => {
                try {
                    setWidth(observedRef.current.offsetWidth)
                } catch { }
            });

            resizeObserver.observe(observedRef.current);
            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    const onClickPicture = (src) => {
        setPictureViewerActive(true);
        setPictureFile(src);
    }

    return (
        <Fragment>
            <Helmet  htmlAttributes={{ lang : appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "My bill" : "Mi factura" } </title>
            </Helmet>
            <div 
                ref={observedRef} 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {(adminAccess || managerAccess) ?
                        <PictureReceiptViewer
                            receiptName={"Recibo"}
                            pictureViewerActive={pictureViewerActive}
                            setPictureViewerActive={setPictureViewerActive}
                            viewerWidth={width}
                            pictureFile={pictureFile}
                        />
                    : null}
                    {desktopView ? 
                        <SettingsHeader2 
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            text={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                            textLink={"/cuenta-de-negocios"}
                            text2={appLanguage.en ? "My bill" : "Mi factura"}
                        />
                    : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            
                            <SettingsTitleBus
                                icon={businessIcon}
                                firstText={accountData ? accountData.businessName : "--"}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                            />
                            {(adminAccess || managerAccess) ?  <Fragment>
                                {loading ? <Spinner /> : <Fragment>
                                    {showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> : <Fragment>
                                        <SettingsBills 
                                            bill={bill} 
                                            restriction={restriction} 
                                            accountStatus={accountStatus} 
                                            accountId={accountData.id}
                                            onClickPicture={onClickPicture}
                                        />
                                        <SettingsOption
                                            description={appLanguage.en ? 'Billing History' : 'Historial de facturación'}
                                            icon={appTheme.dark ? historyIconDark : historyIconLight }
                                            goTo={"/historial-de-facturacion"}
                                        /> 
                                    </Fragment>}
                                </Fragment>}
                            </Fragment> : null }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default BillsPage;