import { useContext, useEffect, useState } from "react";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";

const SalesSelectTable = ({
    onScroll,
    headerHeight,
    results,
    canSelectPrice,
    onClickLink,
    dropDownSettingsOpen,
    dropDownShareOpen,
    dropDownOptionsOpen,
    handleChangePrice
}) => {
    const {
        appTheme,
        formatRationalNumber,
        dropDownCartOpen,
        dropDownProfileMenuOpen,
        isTouchEnabled,
    } = useContext(AppContext);

    const { shoppingCart, calculateShoppingCart, getAmountDataForShoppingCart, getItemDataForShoppingCart, getPriceLetterIfExist } = useContext(AuthContext);

    const { desktopView } = useContext(SideMenuContext);

    const [tableHeight, setTableHeight] = useState("calc(100vh - 320px)");

    useEffect(() => {
        if (headerHeight) {
            setTableHeight(`calc(100vh - ${headerHeight + 90}px)`)
        }
    }, [headerHeight, desktopView]);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownSettingsOpen || dropDownShareOpen || dropDownOptionsOpen || dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownSettingsOpen, dropDownShareOpen, dropDownOptionsOpen, dropDownCartOpen, dropDownProfileMenuOpen]);

    const borderDark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Dark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Light = "2px solid rgba(0, 0, 0, 0.075)";

    const widthQuantity = "60px";
    const widthDescription = "250px";
    const widthPrice = "130px";
    const widthITBIS = "110px";
    const widthValue = "110px";

    const showPriceList = (priceList) => {
        if (canSelectPrice) {
            let showList = false;

            if (priceList.priceB !== null) {
                showList = true;
            }

            if (priceList.priceC !== null) {
                showList = true;
            }

            if (priceList.priceD !== null) {
                showList = true;
            }

            if (priceList.priceE !== null) {
                showList = true;
            }

            return showList 
        } else {
            return false;
        }
    }

    // Calculate what the price would be if the price is changed
    // taking into account if there is a general discount
    const getPriceLetterValue = (letter, item) => {
        const productId = item.product.id;
        let itemPrice =  null;

        if (letter === "A") {
            itemPrice =  item.product.priceWithoutTax;
        } else {
            itemPrice =  item.product.priceList[`price${letter}`];
        }
        
        if (shoppingCart.discount) {
            const items = shoppingCart.items;
            const newItems = []

            for (let i = 0; i < items.length; i++) {
                if (items[i].product.id === productId) {
                    const newPriceLetter = getPriceLetterIfExist(items[i].product, letter);
                    const item = getItemDataForShoppingCart(items[i].product, items[i].quantity, newPriceLetter);
                    newItems.push(item);
                } else {
                    newItems.push(items[i]);
                }
            }

            const amounts = getAmountDataForShoppingCart(newItems);

            let newShoppingCart = {
                ...shoppingCart,
                "items": newItems,
                "subtotal": amounts.subtotal,
                "desc": amounts.desc,
                "itbis": amounts.itbis,
                "total": amounts.total,
            }

            newShoppingCart = calculateShoppingCart(newShoppingCart);

            const index = newShoppingCart.items.findIndex(item => item.product.id === productId);

            const newItem = newShoppingCart.items[index];

            itemPrice =  newItem.unitValue;
        }
        
        return itemPrice >= 0 ? itemPrice : 0;
    }

    return (
        <div
            className={`inventory-table-container unselectable ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
            style={{
                maxHeight: tableHeight,
                height: tableHeight,
                colorScheme: appTheme.dark ? "dark" : "light",
                borderRadius: "10px"
            }}
        >
            <div onScroll={onScroll} className="inventory-table-section-1" style={{overflow: "auto"}}>
                <table className="inventory-table-select-table">
                    <thead>
                        <tr>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthQuantity, maxWidth: widthQuantity }}>
                                <p>Cant.</p>
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthDescription, maxWidth: widthDescription }}>
                                <p>Descripción</p>
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthPrice, maxWidth: widthPrice }}>
                                <p>Precio</p>
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthITBIS, maxWidth: widthITBIS }}>
                                <p>ITBIS</p>
                            </th>
                            <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthValue, maxWidth: widthValue, borderTopRightRadius: "10px" }}>
                                <p>Valor</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {results ?
                            results.map((item) => {
                                return (
                                    <tr
                                        className={appTheme.dark ? (isTouchEnabled() ? "inventory-table-select-price-list-tr-dark-no-hover" : "inventory-table-select-price-list-tr-dark-hover") : (isTouchEnabled() ? "inventory-table-select-price-list-tr-light-no-hover" : "inventory-table-select-price-list-tr-light-hover")}
                                        key={item.product.id}
                                    >
                                        <td 
                                            className={`inventory-table-select-td`} 
                                            style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthQuantity, maxWidth: widthQuantity }}
                                        >
                                            <p style={{ whiteSpace: "nowrap", textAlign: "center" }}>{item.quantity}</p>
                                        </td>
                                        <td
                                            onClick={() => onClickLink(item)} 
                                            className={`inventory-table-select-td ${appTheme.dark ? (isTouchEnabled() ? "inventory-table-select-tr-dark-no-hover " : "inventory-table-select-tr-dark-hover") : (isTouchEnabled() ? "inventory-table-select-tr-light-no-hover" : "inventory-table-select-tr-light-hover")}`} 
                                            style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthDescription, maxWidth: widthDescription, cursor: "pointer" }}
                                        >
                                            <p
                                                style={{
                                                    "WebkitBoxOrient": "vertical",
                                                    "WebkitLineClamp": "1",
                                                    "lineClamp": "1",
                                                    "overflow": "hidden",
                                                    "textOverflow": "ellipsis"
                                                }}
                                            >
                                                {item.product.name}
                                            </p>
                                        </td>

                                        {showPriceList(item.product.priceList) ?
                                            <td>
                                                <select
                                                    className={`inventory-table-create-td-content-select ${appTheme.dark ? (isTouchEnabled() ? "inventory-table-select-tr-dark-no-hover " : "inventory-table-select-tr-dark-hover") : (isTouchEnabled() ? "inventory-table-select-tr-light-no-hover" : "inventory-table-select-tr-light-hover")}`}
                                                    style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthPrice, padding: "11px 0px 11px 8px", cursor: "pointer" }}
                                                    value={item.priceLetter}
                                                    onChange={(e) => handleChangePrice(item, e)}
                                                >
                                                    <option value="A">A - {formatRationalNumber(getPriceLetterValue("A", item))}</option>
                                                    {item.product.priceList.priceB !== null ? <option value="B">B - {formatRationalNumber(getPriceLetterValue("B", item))}</option> : null}
                                                    {item.product.priceList.priceC !== null ? <option value="C">C - {formatRationalNumber(getPriceLetterValue("C", item))}</option> : null}
                                                    {item.product.priceList.priceD !== null ? <option value="D">D - {formatRationalNumber(getPriceLetterValue("D", item))}</option> : null}
                                                    {item.product.priceList.priceE !== null ? <option value="E">E - {formatRationalNumber(getPriceLetterValue("E", item))}</option> : null}
                                                </select>
                                            </td>
                                        :
                                            <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthPrice, maxWidth: widthPrice }}>
                                                <p style={{ whiteSpace: "nowrap" }}>{formatRationalNumber(item.unitValue)}</p>
                                            </td>
                                        }
                                        
                                        <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthITBIS, maxWidth: widthITBIS }}>
                                            <p style={{ whiteSpace: "nowrap" }}>{formatRationalNumber(item.itbis)}</p>
                                        </td>
                                        <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthValue, maxWidth: widthValue }}>
                                            <p style={{ whiteSpace: "nowrap" }}>{formatRationalNumber(item.value)}</p>
                                        </td>
                                    </tr>
                                );
                            })
                            : null}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SalesSelectTable;