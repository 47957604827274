import { Fragment, useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "./productAdvanced.page.scss";

import { AuthContext } from "../../../firebase/context";
import { Helmet } from "react-helmet-async";
import SettingsActionRequired from "../../../components/settings-action-required/settingsActionRequired";
import { AppContext } from "../../../context/AppContext";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import noPicture from "../../../img/no-picture.png";

import editIcon from "../../../icons/signature-dark.png";
import crossWhiteIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";

import checkIcon from '../../../img/check.svg';
import uncheckIcon from '../../../img/uncheck.svg';

import { uid } from "uid";

import firebase from "../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { onSnapshot, doc } from "firebase/firestore";

import { SideMenuContext } from "../../../context/SideMenuContext";
import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import Spinner from "../../../components/spinner/spinner";
import { AlertContext } from "../../../components/alert/alertContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import PriceListViewTable from "../../../appTools/appToolsComponents/priceListViewTable/priceListViewTable";
import PriceListEditTable from "../../../appTools/appToolsComponents/priceListEditTable/priceListEditTable";

const ProductAdvancedPage = () => {

    let observedRef = useRef(null);
    let pageObserverRef = useRef(null);

    const { accountData, accessTo, updateProductInShoppingCart, setPictureInMemory } = useContext(AuthContext);
    const { appLanguage, appTheme, getCategory, formatRationalNumber, isTouchEnabled } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const [fieldAlert, setFieldAlert] = useState(null);

    const [fieldAlertRef, setFieldAlertRef] = useState(null);
    const fieldAlertReference = (inputElement) => {
        setFieldAlertRef(inputElement);
    }

    const navigate = useNavigate();
    const location = useLocation();

    const [product, setProduct] = useState(null);

    const [priceList, setPriceList] = useState(null);

    const [priceListTemp, setPriceListTemp] = useState([
        { "letter": "A", "price": "", "itbis": "", "finalPrice": "" },
        { "letter": "B", "price": "", "itbis": "", "finalPrice": "" },
        { "letter": "C", "price": "", "itbis": "", "finalPrice": "" },
        { "letter": "D", "price": "", "itbis": "", "finalPrice": "" },
        { "letter": "E", "price": "", "itbis": "", "finalPrice": "" },
    ]);

    const resetPriceListTemp = () => {

        if (!priceList) {
            return;
        }

        const newPriceListTemp = [
            { "letter": "A", "price": formatRationalNumber(product.priceWithoutTax), "itbis": formatRationalNumber(product.itbisAmount), "finalPrice": formatRationalNumber(product.finalPrice) }
        ];

        let taxPercentage = 0;

        if (product.itbisPercentage === "16%") {
            taxPercentage = 0.16;
        } else {
            if (product.itbisPercentage === "18%") {
                taxPercentage = 0.18;
            }
        }

        if (priceList.priceB !== null) {
            const tempItbis = priceList.priceB * taxPercentage;
            newPriceListTemp.push(
                { "letter": "B", "price": formatRationalNumber(priceList.priceB), "itbis": formatRationalNumber(tempItbis), "finalPrice": formatRationalNumber(priceList.priceB + tempItbis) }
            )
        } else {
            newPriceListTemp.push(
                { "letter": "B", "price": "", "itbis": "", "finalPrice": "" }
            )
        }

        if (priceList.priceC !== null) {
            const tempItbis = priceList.priceC * taxPercentage;
            newPriceListTemp.push(
                { "letter": "C", "price": formatRationalNumber(priceList.priceC), "itbis": formatRationalNumber(tempItbis), "finalPrice": formatRationalNumber(priceList.priceC + tempItbis) }
            )
        } else {
            newPriceListTemp.push(
                { "letter": "C", "price": "", "itbis": "", "finalPrice": "" }
            )
        }

        if (priceList.priceD !== null) {
            const tempItbis = priceList.priceD * taxPercentage;
            newPriceListTemp.push(
                { "letter": "D", "price": formatRationalNumber(priceList.priceD), "itbis": formatRationalNumber(tempItbis), "finalPrice": formatRationalNumber(priceList.priceD + tempItbis) }
            )
        } else {
            newPriceListTemp.push(
                { "letter": "D", "price": "", "itbis": "", "finalPrice": "" }
            )
        }

        if (priceList.priceE !== null) {
            const tempItbis = priceList.priceE * taxPercentage;
            newPriceListTemp.push(
                { "letter": "E", "price": formatRationalNumber(priceList.priceE), "itbis": formatRationalNumber(tempItbis), "finalPrice": formatRationalNumber(priceList.priceE + tempItbis) }
            )
        } else {
            newPriceListTemp.push(
                { "letter": "E", "price": "", "itbis": "", "finalPrice": "" }
            )
        }

        setPriceListTemp(newPriceListTemp);
    }

    useEffect(() => {
        if (product) {
            resetPriceListTemp();
            setPriceList(product.priceList)
        }
        // eslint-disable-next-line
    }, [product])

    const [eligibleForCommissions, setEligibleForCommissions] = useState(null);
    const [closeToOutOfStock, setCloseToOutStock] = useState(null);

    const [disabledInOptions, setDisabledInOptions] = useState(true);
    const [editingInOptions, setEditingInOptions] = useState(false);

    const [disabledInTable, setDisabledInTable] = useState(true);
    const [editingInTable, setEditingInTable] = useState(false);

    const [request, setRequest] = useState("0000");

    const [loadingInOptions, setLoadingInOptions] = useState(false);
    // eslint-disable-next-line
    const [loadingInTable, setLoadingInTable] = useState(false);

    const [pictureFile, setPictureFile] = useState(null);

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.inventory)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    useEffect(() => {
        if (location.state) {
            const itemsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));

            if (itemsDeleted) {
                const productId = location.state.product.id;

                function containsString(arr, target) {
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].includes(target)) {
                            return true;
                        }
                    }
                    return false;
                }

                if (containsString(itemsDeleted, productId)) {
                    if (window.history.state && window.history.state.idx > 0) {
                        navigate(-1);
                    } else {
                        navigate('/', { replace: true });
                    }
                } else {
                    if (location.state.product) {
                        setProduct(location.state.product);
                        setPriceList(location.state.product.priceList);
                        setEligibleForCommissions(location.state.product.eligibleForCommissions);
                        setCloseToOutStock(location.state.product.closeToOutOfStock);
                    }
                    setRequest(uid());
                }

            } else {
                if (location.state.product) {
                    setProduct(location.state.product);
                    setPriceList(location.state.product.priceList);
                    setEligibleForCommissions(location.state.product.eligibleForCommissions);
                    setCloseToOutStock(location.state.product.closeToOutOfStock);
                }
                setRequest(uid());
            }

        } else {
            navigate("/productos", { replace: true })
        }
        // eslint-disable-next-line 
    }, []);

    const checkIfImageExistInPr = (path) => {
        const dimensions = "d140x140";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pr-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pr-pictures-store")) {
                db.createObjectStore("hazcuentas-pr-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pr-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pr-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureFile(request.result.key[dimensions]);
                        } else {
                            checkIfImageExistInGe(path);
                        }
                    } else {
                        checkIfImageExistInGe(path);
                    }
                } else {
                    checkIfImageExistInGe(path);
                }
            }

            requestToCheck.onerror = () => {
                checkIfImageExistInGe(path);
            }
        }
    }

    const checkIfImageExistInGe = (path) => {
        const dimensions = "d140x140";

        const dbId = product.id;

        const indexedDB = window.indexedDB;
        const request = indexedDB.open("hazcuentas-pictures-database", 1);

        request.onerror = function (event) { console.error(event); }

        request.onupgradeneeded = function () {
            const db = request.result;
            if (!db.objectStoreNames.contains("hazcuentas-pictures-store")) {
                db.createObjectStore("hazcuentas-pictures-store", { keyPath: "id" });
            }
        }

        request.onsuccess = function () {
            const db = request.result;
            const transaction = db.transaction('hazcuentas-pictures-store', 'readwrite');

            transaction.onerror = (err) => {
                console.warn(err);
                return false;
            }

            const store = transaction.objectStore('hazcuentas-pictures-store');
            const requestToCheck = store.get(dbId);

            requestToCheck.onsuccess = (ev) => {
                const request = ev.target;
                if (request.result) {
                    if (path === request.result.key.path) {
                        if (request.result.key[dimensions]) {
                            setPictureFile(request.result.key[dimensions]);
                        } else {
                            getPictureFile(path);
                        }
                    } else {
                        getPictureFile(path);
                    }
                } else {
                    getPictureFile(path);
                }
            }

            requestToCheck.onerror = () => {
                getPictureFile(path);
            }
        }
    }

    const getPictureFile = async (path) => {
        // This is a recursive function that download all the dimensions
        // of a picture store in firebase store

        const getPictureByDimensions = async (dimensions) => {
            try {
                const locationPath = `${path}${dimensions}`;
                const imageRef = ref(firebase.storage, locationPath);
                const url = await getDownloadURL(imageRef);
                const xhr = new XMLHttpRequest();

                xhr.responseType = 'blob';
                xhr.onload = () => {
                    const blob = xhr.response;
                    const fr = new FileReader();
                    fr.readAsDataURL(blob);
                    fr.addEventListener('load', () => {
                        const urlData = fr.result;
                        if (dimensions === "_140x140") {
                            setPictureFile(urlData);
                        }
                        if (dimensions === "_140x140") {
                            dbKey.d140x140 = urlData;
                            getPictureByDimensions("_300x300");
                        } else {
                            if (dimensions === "_300x300") {
                                dbKey.d300x300 = urlData;
                                getPictureByDimensions("_600x600");
                            } else {
                                if (dimensions === "_600x600") {
                                    dbKey.d600x600 = urlData;
                                    setPictureInMemory(dbKey, product.id);
                                }
                            }
                        }
                    });
                };
                xhr.open('GET', url);
                xhr.send();
            } catch (error) {
                console.error(error);
            }
        }

        const dbKey = {
            "path": path,
            "d140x140": null,
            "d300x300": null,
            "d600x600": null,
        }

        getPictureByDimensions("_140x140");
    }

    useEffect(() => {
        if (product && product.picturesLocation) {
            if (product.picturesLocation[0]) {
                checkIfImageExistInPr(product.picturesLocation[0]);
            }
        }

        // eslint-disable-next-line
    }, [product]);

    // Now I have to listeng to the product doc
    useEffect(() => {
        if (product && (request !== "0000")) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/products`, product.id), (doc) => {
                const productUpdated = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete productUpdated.searchKeys;

                if (doc.data()) {
                    setProduct(productUpdated);
                    updateProductInShoppingCart(productUpdated);
                    navigate(null, { replace: true, state: { "product": productUpdated } });
                }
            }, err => {
                console.error(err);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [request]);


    // using the SideMenuContext calculate the initial state
    const [width, setWidth] = useState(desktopView ? windowDimension.width - (isSideMenuMinimized ? 137.6 : 327.6) : windowDimension.width);

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension, isSideMenuMinimized]);

    const [quantityAlert, setQuantityAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    useEffect(() => {
        if (fieldAlertRef) {
            fieldAlertRef.scrollIntoView({
                block: 'center',
                inline: 'end', // nearest
                behavior: "smooth"
            });

        }
        // eslint-disable-next-line 
    }, [fieldAlertRef]);

    const onEdictInOptions = () => {
        setEditingInOptions(true);
        onCancelInTable();
    }

    const onEdictInTable = () => {
        setEditingInTable(true);
        onCancelInOptions();
    }

    // eslint-disable-next-line 
    const eligibleForCommissionsHandler = () => {
        if (editingInOptions) {
            setEligibleForCommissions(!eligibleForCommissions);
        }
    }

    const closeToOutOfStockHandler = () => {
        if (editingInOptions) {
            setCloseToOutStock({
                ...closeToOutOfStock,
                "showMark": !closeToOutOfStock.showMark
            })
        }
    }

    const onChangeQuantity = (e) => {
        setQuantityAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        const text = e.target.value;
        let amount = "";
        let isPoint = false;

        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        amount += text[i];
                    }
                }
            }
        }

        setCloseToOutStock({
            ...closeToOutOfStock,
            "quantity": amount
        });
    }

    const onCancelInOptions = () => {
        setEditingInOptions(false);
        setEligibleForCommissions(product.eligibleForCommissions);
        setCloseToOutStock(product.closeToOutOfStock);
        setQuantityAlert({
            alert: false,
            enText: '',
            esText: ''
        });
    }

    const onCancelInTable = () => {
        setEditingInTable(false);
        setFieldAlert(null);
        resetPriceListTemp();
    }

    async function editAbvancedOptions() {
        setLoadingInOptions(true);

        try {
            const res = await firebase.useEditAbvancedOptions({
                "productId": product.id,
                "businessId": accountData.id,
                "eligibleForCommissions": eligibleForCommissions,
                "closeToOutOfStock": {
                    "quantity": closeToOutOfStock.showMark ? closeToOutOfStock.quantity : product.closeToOutOfStock.quantity,
                    "showMark": closeToOutOfStock.showMark,
                }
            });

            setProduct(res);
            updateProductInShoppingCart(res);
            navigate(null, { replace: true, state: { "product": res } });

            setPriceList(res.priceList);
            setEligibleForCommissions(res.eligibleForCommissions);
            setCloseToOutStock(res.closeToOutOfStock);

            setEditingInOptions(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: `Product edited`,
                    es: `Producto editado`,
                }
            });
        } catch (error) {
            const { code } = JSON.parse(JSON.stringify(error));
            console.error(error);
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to edit the product`,
                    es: `Error al intentar editar el producto`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }

        setLoadingInOptions(false);
    }

    function normalizeNumber(value) {
        // If the value is an empty string or null, return null
        if (value === "" || value === null) {
            return null;
        }

        // If the value is already a valid number, return it as-is
        if (typeof value === 'number' && !isNaN(value)) {
            return value;
        }

        // If the value is a string, attempt to parse it to a number
        if (typeof value === 'string') {
            // Remove commas and try to parse the string as a number
            let parsedValue = parseFloat(value.replace(/,/g, ''));

            // If parsing is successful and results in a valid number, return it
            if (!isNaN(parsedValue)) {
                return parsedValue;
            }
        }

        // If it's not a valid number, return null
        return null;
    }

    async function editPriceList() {
        setLoadingInTable(true);

        try {
            const res = await firebase.useEditPriceList({
                "productId": product.id,
                "businessId": accountData.id,
                "priceList": {
                    "priceB": normalizeNumber(priceListTemp[1].price),
                    "priceC": normalizeNumber(priceListTemp[2].price),
                    "priceD": normalizeNumber(priceListTemp[3].price),
                    "priceE": normalizeNumber(priceListTemp[4].price)
                },
            });

            setProduct(res);
            updateProductInShoppingCart(res);
            navigate(null, { replace: true, state: { "product": res } });

            setPriceList(res.priceList);
            setEligibleForCommissions(res.eligibleForCommissions);
            setCloseToOutStock(res.closeToOutOfStock);

            setEditingInTable(false);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: `Product edited`,
                    es: `Producto editado`,
                }
            });
        } catch (error) {
            const { code } = JSON.parse(JSON.stringify(error));
            console.error(error);
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to edit the product`,
                    es: `Error al intentar editar el producto`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }

        setLoadingInTable(false);
    }

    const onSaveInOptions = e => {
        e.preventDefault();

        if (closeToOutOfStock.showMark && (closeToOutOfStock.quantity === "" || parseFloat(closeToOutOfStock.quantity) === 0)) {
            setQuantityAlert({
                alert: true,
                enText: 'Required field',
                esText: 'Campo requerido'
            });
            return;
        }

        editAbvancedOptions()
    }

    const onSaveInTable = e => {
        e.preventDefault();

        if (!priceList || !product) {
            return;
        }

        setFieldAlert(null);
        fieldAlertReference(null);

        for (let index = 0; index < priceListTemp.length; index++) {
            const priceInfo = priceListTemp[index];
            
            if (priceInfo.letter !== "A") {
                const price = normalizeNumber(priceInfo.price);

                if (price > product.priceWithoutTax) {
                    setFieldAlert({
                        x: priceInfo.letter,
                        y: 1,
                    });
                    setSoftAlertActive(true);
                    setSoftAlertData({
                        type: 'error',
                        text: {
                            en: `Price ${priceInfo.letter} cannot be higher than price A.`,
                            es: `El precio ${priceInfo.letter} no puede ser mayor al precio A`,
                        }
                    });
                    return;
                }

                let priceExistence = 0;

                // Second iteration
                priceListTemp.forEach((priceInfo) => {
                    const price2 = normalizeNumber(priceInfo.price);
                    if (price2 !== null) {
                        if (price2 === price) {
                            priceExistence++;
                        }
                    }
                });

                if (priceExistence > 1) {
                    setFieldAlert({
                        x: priceInfo.letter,
                        y: 1,
                    });
                    setSoftAlertActive(true);
                    setSoftAlertData({
                        type: 'error',
                        text: {
                            en: "The price cannot be duplicated",
                            es: "El precio no puede estar duplicado",
                        }
                    });
                    return;
                }
            }
        };

        editPriceList();
    }

    useEffect(() => {
        if (!priceList || !product) {
            setDisabledInTable(true);
            return;
        }

        const noChanges =
            normalizeNumber(priceList.priceB) === normalizeNumber(priceListTemp[1].price) &&
            normalizeNumber(priceList.priceC) === normalizeNumber(priceListTemp[2].price) &&
            normalizeNumber(priceList.priceD) === normalizeNumber(priceListTemp[3].price) &&
            normalizeNumber(priceList.priceE) === normalizeNumber(priceListTemp[4].price);

        setDisabledInTable(noChanges);

        // eslint-disable-next-line
    }, [priceListTemp, priceList]);

    useEffect(() => {
        if (!closeToOutOfStock || !product) {
            setDisabledInOptions(true);
            return;
        }

        const noChanges =
            eligibleForCommissions === product.eligibleForCommissions &&
            closeToOutOfStock.showMark === product.closeToOutOfStock.showMark &&
            parseFloat(closeToOutOfStock.quantity) === parseFloat(product.closeToOutOfStock.quantity)

        setDisabledInOptions(noChanges);

        // eslint-disable-next-line
    }, [closeToOutOfStock, eligibleForCommissions, product]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "Advanced options" : 'Opciones avanzadas'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && product && accessTo ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef} className="page-app-settings-item">
                            {accessTo.inventory ? <Fragment>
                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "Advanced options" : 'Opciones avanzadas'}
                                    />
                                    : null}

                                <div
                                    style={{
                                        height: desktopView ? `calc(100vh - 107px)` : `calc(100vh - 65px)`,
                                        colorScheme: appTheme.dark ? "dark" : "light"
                                    }}
                                    className="customer-details-page-main-container"
                                    ref={observedRef}
                                >

                                    <div className="product-details-page-container" style={{ marginTop: 0, maxWidth: width > 940 ? "1121px" : "573px" }}>
                                        {product ? <Fragment>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    borderRadius: "10px",
                                                    alignItems: "center",
                                                    margin: "20px 0px 0px 0px",
                                                    width: "100%",
                                                    boxShadow: "1px 1px 1px 0.5px rgba(0, 0, 0, 0.2)",
                                                }}
                                                className={`${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"}`}
                                            >
                                                {pictureFile ?
                                                    <div className="products-details-micro-product-no-img-cont" style={appTheme.dark ? { backgroundColor: "#343434ec" } : { backgroundColor: "rgba(128, 128, 128, 0.171)" }}>
                                                        <img className="products-details-micro-product-yes-img" src={pictureFile} alt="Product" />
                                                    </div>
                                                    :
                                                    <div className="products-details-micro-product-no-img-cont" style={appTheme.dark ? { backgroundColor: "#343434ec" } : { backgroundColor: "rgba(128, 128, 128, 0.171)" }}>
                                                        <img className="products-details-micro-product-no-img" src={noPicture} alt="Product" />
                                                    </div>
                                                }

                                                <p className="products-details-micro-p-overflow" style={{ margin: "5px 10px" }}><b>{product.name}</b></p>
                                                <span style={{ flexGrow: 4 }} />

                                                {width <= 940 ? null :
                                                    <p className="products-details-micro-p-overflow" style={{ margin: "5px 10px", width: "200px" }}>Categoria: <br /><b>{getCategory(product.category)}</b></p>
                                                }

                                                {width < 450 ? null :
                                                    <p style={{ margin: "5px 10px", width: "150px", whiteSpace: "nowrap" }}>{appLanguage.en ? "Final price" : "Precio final"}: <br /> <b>RD$ {formatRationalNumber(product.finalPrice)}</b></p>
                                                }
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    alignItems: "flex-start",
                                                    flexDirection: width > 940 ? "row" : "column"
                                                }}
                                            >
                                                {priceList ?
                                                    <div
                                                        style={{
                                                            borderRadius: "10px",
                                                            margin: "20px 0px 0px 0px",
                                                            width: "100%",
                                                            boxShadow: "1px 1px 1px 0.5px rgba(0, 0, 0, 0.2)",
                                                            minHeight: "390px",
                                                            maxWidth: "573px",
                                                            minWidth: width > 940 ? "573px" : "",
                                                        }}
                                                        className={`${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"}`}
                                                    >
                                                        <p style={{ margin: "10px 20px", fontWeight: "bold" }}>Lista de precios</p>

                                                        {loadingInTable ? <Spinner /> : <Fragment>

                                                            {editingInTable ?
                                                                <PriceListEditTable
                                                                    priceList={priceListTemp}
                                                                    fieldAlert={fieldAlert}
                                                                    fieldAlertReference={fieldAlertReference}
                                                                    setFieldAlert={setFieldAlert}
                                                                    itbis={product ? product.itbisPercentage : null}
                                                                    setPriceListTemp={setPriceListTemp}
                                                                    normalizeNumber={normalizeNumber}
                                                                />
                                                                :
                                                                <PriceListViewTable
                                                                    priceList={priceListTemp}
                                                                    itbis={product ? product.itbisPercentage : null}
                                                                />
                                                            }

                                                            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>

                                                                <div
                                                                    className="product-details-page-botttons-section"
                                                                    style={{
                                                                        justifyContent: editingInTable ? "space-between" : "center",
                                                                        marginBottom: "10px",
                                                                        maxWidth: "330px",
                                                                        flexWrap: "nowrap",
                                                                    }}
                                                                >
                                                                    {editingInTable ? <Fragment>
                                                                        <button
                                                                            onClick={onCancelInTable}
                                                                            style={{ margin: "10px 0px 10px 0px" }}
                                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                        >
                                                                            <img src={crossWhiteIcon} alt="Cancel" />
                                                                            {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                                        </button>
                                                                        <button
                                                                            onClick={onSaveInTable}
                                                                            disabled={disabledInTable}
                                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                            style={disabledInTable ? {
                                                                                backgroundColor: "#3f526698",
                                                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                                cursor: "not-allowed",
                                                                                margin: "10px 22px 10px 0px"
                                                                            } : {
                                                                                margin: "10px 22px 10px 0px"
                                                                            }}
                                                                        >
                                                                            <img src={saveIcon} alt="Save" />
                                                                            {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                                        </button>
                                                                    </Fragment> : <Fragment>
                                                                        <button
                                                                            onClick={e => onEdictInTable(e)}
                                                                            disabled={(loadingInOptions || loadingInTable)}
                                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                            style={(loadingInOptions || loadingInTable) ? {
                                                                                backgroundColor: "#3f526698",
                                                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                                cursor: "not-allowed",
                                                                                margin: "10px 22px 10px 0px"
                                                                            } : {
                                                                                margin: "10px 22px 10px 0px"
                                                                            }}
                                                                        >
                                                                            <img src={editIcon} alt="Edit" />
                                                                            {appLanguage.en ? <p>Edit</p> : <p>Editar</p>}
                                                                        </button>
                                                                    </Fragment>}
                                                                </div>
                                                            </div>
                                                        </Fragment>}
                                                    </div>
                                                : null}

                                                {closeToOutOfStock ?
                                                    <div
                                                        style={{
                                                            borderRadius: "10px",
                                                            margin: "20px 0px 0px 0px",
                                                            width: "100%",
                                                            boxShadow: "1px 1px 1px 0.5px rgba(0, 0, 0, 0.2)",
                                                            // minHeight: "209px",
                                                            minHeight: "186px",
                                                            maxWidth: "573px",
                                                            marginLeft: width > 940 ? "20px" : ""
                                                        }}
                                                        className={`${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"}`}
                                                    >
                                                        {loadingInOptions ? <Spinner /> : <Fragment>

                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <div
                                                                    onClick={eligibleForCommissionsHandler}
                                                                    className={`${editingInOptions ? (isTouchEnabled() ? "labelDoNotDefQuaContainer-no-hover" : "labelDoNotDefQuaContainer") : "labelAdvanceOptionProduct"} unselectable`}
                                                                    style={{
                                                                        marginLeft: "15px",
                                                                        marginTop: "15px",
                                                                        maxWidth: "100%"
                                                                    }}
                                                                >
                                                                    <p style={{ whiteSpace: "normal" }}>
                                                                        {editingInOptions ?
                                                                            <input
                                                                                style={{
                                                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                                                    marginRight: "5px"
                                                                                }}
                                                                                className='settings-auth-user-access-input'
                                                                                type="checkbox"
                                                                                checked={eligibleForCommissions}
                                                                                readOnly
                                                                            />
                                                                            :
                                                                            <img
                                                                                style={{ width: "23px", height: "23px", marginRight: "5px" }}
                                                                                src={eligibleForCommissions ? checkIcon : uncheckIcon}
                                                                                alt='Check'
                                                                            />
                                                                        }

                                                                        <label style={{ paddingLeft: "10px", cursor: editingInOptions ? "pointer" : "auto" }}>
                                                                            <b>{appLanguage.en ? 'This product is eligible for seller commissions.' : 'Este producto es elejible para comisiones de vendedores.'}</b>
                                                                        </label>
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <div
                                                                    onClick={closeToOutOfStockHandler}
                                                                    className={`${editingInOptions ? (isTouchEnabled() ? "labelDoNotDefQuaContainer-no-hover" : "labelDoNotDefQuaContainer") : "labelAdvanceOptionProduct"} unselectable`}
                                                                    style={{
                                                                        marginLeft: "15px",
                                                                        marginTop: "15px",
                                                                        maxWidth: "100%"
                                                                    }}
                                                                >
                                                                    <p style={{ whiteSpace: "normal" }}>
                                                                        {editingInOptions ?
                                                                            <input
                                                                                style={{
                                                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                                                    marginRight: "5px"
                                                                                }}
                                                                                className='settings-auth-user-access-input'
                                                                                type="checkbox"
                                                                                checked={closeToOutOfStock.showMark}
                                                                                readOnly
                                                                            />
                                                                            :
                                                                            <img
                                                                                style={{ width: "23px", height: "23px", marginRight: "5px" }}
                                                                                src={closeToOutOfStock.showMark ? checkIcon : uncheckIcon}
                                                                                alt='Check'
                                                                            />
                                                                        }

                                                                        <label style={{ paddingLeft: "10px", cursor: editingInOptions ? "pointer" : "auto" }}>
                                                                            <b>
                                                                                {editingInOptions ?
                                                                                    closeToOutOfStock.showMark ?
                                                                                        appLanguage.en ? `Mark as soon to be sold out when reaching the quantity of: ` : `Marcar como proximo a agotarse al alcanzar la cantidad de: `
                                                                                        :
                                                                                        appLanguage.en ? 'Mark as close to out of stock when reaching a defined quantity.' : 'Marcar como proximo a agotarse al alcanzar una cantidad definida.'
                                                                                    :
                                                                                    closeToOutOfStock.showMark ?
                                                                                        appLanguage.en ? `Mark as soon to be sold out when reaching the quantity of ${closeToOutOfStock.quantity} or less.` : `Marcar como proximo a agotarse al alcanzar la cantidad de ${closeToOutOfStock.quantity} o menos.`
                                                                                        :
                                                                                        appLanguage.en ? 'Mark as close to out of stock when reaching a defined quantity.' : 'Marcar como proximo a agotarse al alcanzar una cantidad definida.'
                                                                                }
                                                                            </b>
                                                                        </label>
                                                                    </p>
                                                                </div>

                                                                {editingInOptions && closeToOutOfStock.showMark ?
                                                                    <input
                                                                        style={{
                                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                                            width: "50px",
                                                                            marginRight: "15px"
                                                                        }}
                                                                        className={`settings-edict-profile-input-text ${quantityAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                                        type="text"
                                                                        name="quantity"
                                                                        id="quantity"
                                                                        maxLength="6"
                                                                        value={closeToOutOfStock.quantity}
                                                                        onChange={e => onChangeQuantity(e)}
                                                                    />
                                                                    : null}
                                                            </div>

                                                            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                                <div
                                                                    className="product-details-page-botttons-section"
                                                                    style={{
                                                                        justifyContent: editingInOptions ? "space-between" : "center",
                                                                        marginBottom: "10px",
                                                                        maxWidth: "330px",
                                                                        flexWrap: "nowrap",
                                                                    }}
                                                                >
                                                                    {editingInOptions ? <Fragment>
                                                                        <button
                                                                            onClick={onCancelInOptions}
                                                                            style={{ margin: "10px 0px 10px 0px" }}
                                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                        >
                                                                            <img src={crossWhiteIcon} alt="Cancel" />
                                                                            {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                                                        </button>
                                                                        <button
                                                                            onClick={onSaveInOptions}
                                                                            disabled={disabledInOptions}
                                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                            style={disabledInOptions ? {
                                                                                backgroundColor: "#3f526698",
                                                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                                cursor: "not-allowed",
                                                                                margin: "10px 22px 10px 0px"
                                                                            } : {
                                                                                margin: "10px 22px 10px 0px"
                                                                            }}
                                                                        >
                                                                            <img src={saveIcon} alt="Save" />
                                                                            {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                                                        </button>
                                                                    </Fragment> : <Fragment>
                                                                        <button
                                                                            onClick={e => onEdictInOptions(e)}
                                                                            disabled={(loadingInOptions || loadingInTable)}
                                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                                            style={(loadingInOptions || loadingInTable) ? {
                                                                                backgroundColor: "#3f526698",
                                                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                                cursor: "not-allowed",
                                                                                margin: "10px 22px 10px 0px"
                                                                            } : {
                                                                                margin: "10px 22px 10px 0px"
                                                                            }}
                                                                        >
                                                                            <img src={editIcon} alt="Edit" />
                                                                            {appLanguage.en ? <p>Edit</p> : <p>Editar</p>}
                                                                        </button>
                                                                    </Fragment>}
                                                                </div>
                                                            </div>
                                                        </Fragment>}
                                                    </div>
                                                : null}
                                            </div>

                                            <div style={{ minHeight: "50px" }} />
                                        </Fragment> : null}
                                    </div>
                                </div>
                            </Fragment> : null}
                        </div>
                        :
                        <div className="page-app-customers-item">
                            <div className="customers-options-container">
                                <div className="customers-options-container-item">
                                    <SettingsActionRequired />
                                </div>
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

export default ProductAdvancedPage;