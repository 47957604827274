import { Fragment, useContext, useEffect, useRef, useState } from "react";
import "./salesPicker.scss";
import { AppContext } from "../../../context/AppContext";

import firebase from "../../../firebase";
// eslint-disable-next-line
import { collection, query, limit, getCountFromServer, getDocsFromServer, where, orderBy, startAt, startAfter, doc, getDoc } from "firebase/firestore";

import crossIcon from "../../../icons/cross.png";
import crossWhiteIcon from "../../../icons/cross-white.png";

import magnifyingIcon from "../../../icons/magnifying-glass.png";
import ProductsViewIcons from "../productsViewIcons/productsViewIcons";
import CustomerListView from "../customerListView/customerListView";
import { SideMenuContext } from "../../../context/SideMenuContext";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../firebase/context";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import ProductsViewList from "../productsViewList/productsViewList";

const SalesPicker = ({ getFlexWrapForSearch, pageWidth }) => {
    let observedRef = useRef(null);

    const location = useLocation();
    const navigate = useNavigate();

    const { appTheme, appLanguage } = useContext(AppContext);
    const { windowDimension, isSideMenuMinimized, desktopView } = useContext(SideMenuContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const {
        accountData,
        setCustomerListener,
        setProductListener,
        products,
        customers,
        productsStatistics,
        customersStatistics,
        setCustomerInShoppingCart,
        shoppingCart,
        removeCustomerFromShoppingCart,
        userSettings
    } = useContext(AuthContext);

    const [docsCount, setDocsCount] = useState(null);

    const [showConnectionError, setShowConnectionError] = useState(false);

    const recentlyOpenProducts = Array.isArray(products) ? products : [];
    const recentlyOpenCustomers = Array.isArray(customers) ? customers : [];

    const [loading, setLoading] = useState(true);
    const [empty, setEmpty] = useState(false);
    const [showPageNav, setShowPageNav] = useState(false);

    // Use navControlers.page to initialize
    const [pageNumber, setPageNumber] = useState(null);

    const [searchingText, setSearchingText] = useState("");

    const [searchingByTextActive, setSearchingTextActive] = useState(false);

    // Keep state in the current path
    const [productsResults, setProductsResults] = useState(null);
    const [customersResults, setCustomersResults] = useState(null);

    const [controler, setControler] = useState(null);
    const [pagesHistory, setPageHistory] = useState([]);
    const [navControlers, setNavControlers] = useState(null);

    // All of this is to avoid requesting data 
    // from the backend twice
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (refresh === 1 && navControlers && controler) {
            setRefresh(0);
            setPageNumber(navControlers.page);
            if (controler.isLookingForProducts) {
                refreshSearchProducts();
            } else {
                refreshSearchCustomers();
            }

        }
        // eslint-disable-next-line
    }, [refresh]);

    const [activeRef, setActiveRef] = useState(null);
    const [activeId, setActiveId] = useState(null);

    const setActiveReference = (inputElement) => {
        setActiveRef(inputElement);
    }

    useEffect(() => {
        if (activeRef) {
            activeRef.scrollIntoView({
                block: 'center',
                inline: 'nearest'
            });
        }
    }, [activeRef]);

    const onClickLink = (id) => {
        let newControler = null;

        if (controler) {
            newControler = {
                ...controler,
                "triggerSearch": false,
            }
        } else {
            newControler = {
                "isLookingForProducts": true,
                "searchingText": "",
                "triggerSearch": false,
            };
        }

        const newState = {
            "productsResults": productsResults,
            "customersResults": customersResults,
            "docsCount": docsCount,
            "controler": newControler,
            "pagesHistory": pagesHistory,
            "navControlers": navControlers,
            "showConnectionError": showConnectionError,
            "empty": empty,
            "showPageNav": showPageNav,
            "activeId": id,
            "searchingByTextActive": searchingByTextActive,
            "loading": false,
        }

        navigate(null, { replace: true, state: newState });
    }

    const iAmSelected = (id) => {
        if (shoppingCart) {
            if (shoppingCart.customer) {
                if (shoppingCart.customer.id === id) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const onClickCustomer = (id) => {
        if (iAmSelected(id)) {
            removeCustomerFromShoppingCart();
        } else {
            const customer = customersResults.find(customer => customer.id === id);
            if (customer) {
                setCustomerInShoppingCart(customer);
            }
        }
    }

    useEffect(() => {
        if (location.state) {
            if (refresh === 0) {
                setRefresh(1)
            }

            setControler(location.state.controler);

            if (location.state.controler) {
                setSearchingText(location.state.controler.searchingText);
            }

            setPageHistory(location.state.pagesHistory);
            setNavControlers(location.state.navControlers);
            setShowConnectionError(location.state.showConnectionError);
            setEmpty(location.state.empty);
            setShowPageNav(location.state.showPageNav);
            setDocsCount(location.state.docsCount);
            setActiveId(location.state.activeId);
            setSearchingTextActive(location.state.searchingByTextActive);
            setLoading(location.state.loading);

            let page = null;

            if (location.state.navControlers) {
                setPageNumber(location.state.navControlers.page);
                page = location.state.navControlers.page;
            }

            const itemsDeleted = JSON.parse(localStorage.getItem('itemsDeleted'));

            function removeObjectsWithIds(array, idsToRemove) {
                if (Array.isArray((array))) {
                    return array.filter(obj => !idsToRemove.includes(obj.id));
                } else {
                    return null;
                }
            }

            if (location.controler) {
                if (
                    products &&
                    page === 1 &&
                    location.state.controler.searchingText.trim() === ""
                ) {
                    const recentlyOpenProducts = Array.isArray(products) ? products : [];
                    const recentlyOpenCustomers = Array.isArray(customers) ? customers : [];

                    const productsRes = recentlyOpenProducts;
                    const customersRes = recentlyOpenCustomers

                    if (itemsDeleted) {
                        const productsFilteredArray = removeObjectsWithIds(productsRes, itemsDeleted);
                        const customersFilteredArray = removeObjectsWithIds(customersRes, itemsDeleted);

                        setProductsResults(productsFilteredArray);
                        setCustomersResults(customersFilteredArray)
                    } else {
                        setProductsResults(productsRes);
                        setCustomersResults(customersRes);
                    }
                } else {

                    const productsRes = location.state.productsResults;
                    const customersRes = location.state.customersResults;

                    if (itemsDeleted) {
                        const productsFilteredArray = removeObjectsWithIds(productsRes, itemsDeleted);
                        const customersFilteredArray = removeObjectsWithIds(customersRes, itemsDeleted);

                        setProductsResults(productsFilteredArray);
                        setCustomersResults(customersFilteredArray)
                    } else {
                        setProductsResults(productsRes);
                        setCustomersResults(customersRes);
                    }
                }
            } else {
                const productsRes = location.state.productsResults;
                const customersRes = location.state.customersResults;

                if (itemsDeleted) {
                    const productsFilteredArray = removeObjectsWithIds(productsRes, itemsDeleted);
                    const customersFilteredArray = removeObjectsWithIds(customersRes, itemsDeleted);

                    setProductsResults(productsFilteredArray);
                    setCustomersResults(customersFilteredArray)
                } else {
                    setProductsResults(productsRes);
                    setCustomersResults(customersRes);
                }
            }

            if (!location.state.loading) {
                blink();
            }
        } else {
            setProductsResults(recentlyOpenProducts);
            setCustomersResults(recentlyOpenCustomers);

            setControler({
                "isLookingForProducts": true,
                "searchingText": "",
                "triggerSearch": false,
            });

            setPageHistory([]);

            setNavControlers({
                "page": 1,
                "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                "isPrevious": false,
                "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
            });

            setShowConnectionError(false);
            setEmpty(false);
            setShowPageNav(false);
            setPageNumber(1);
            blink();
        }
        // eslint-disable-next-line 
    }, []);

    // This prevent the page send a request twice to the backend
    const [generalState, setGeneralState] = useState(false);

    useEffect(() => {
        setGeneralState(true);
    }, []);

    useEffect(() => {
        if (generalState) {
            if (controler) {
                if (controler.isLookingForProducts) {
                    setProductListener(true);
                } else {
                    setCustomerListener(true);
                }
            }
        }
        // eslint-disable-next-line
    }, [generalState, controler]);

    useEffect(() => {
        if (controler) {
            if (controler.isLookingForProducts) {
                if (navControlers && productsStatistics) {
                    if (
                        products &&
                        navControlers.page === 1 &&
                        controler.searchingText.trim() === ""
                    ) {
                        const recentlyOpenProducts = Array.isArray(products) ? products : [];
                        setProductsResults(recentlyOpenProducts);

                        setNavControlers({
                            "page": 1,
                            "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                            "isPrevious": false,
                            "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
                        });
                    }
                }

                if (productsStatistics && controler) {
                    if ((controler.searchingText.trim() === "")) {
                        if (productsStatistics.all > 0) {
                            setEmpty(false);
                            if (productsStatistics.all > 30) {
                                setShowPageNav(true);
                            } else {
                                setShowPageNav(false);
                            }
                        } else {
                            setEmpty(true);
                        }
                    }
                }
            } else {
                if (navControlers && customersStatistics) {
                    if (
                        products &&
                        navControlers.page === 1 &&
                        controler.searchingText.trim() === ""
                    ) {
                        const recentlyOpenCustomers = Array.isArray(customers) ? customers : [];
                        setCustomersResults(recentlyOpenCustomers);

                        setNavControlers({
                            "page": 1,
                            "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
                            "isPrevious": false,
                            "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false
                        });
                    }
                }

                if (customersStatistics && controler) {
                    if ((controler.searchingText.trim() === "")) {
                        if (customersStatistics.all > 0) {
                            setEmpty(false);
                            if (customersStatistics.all > 30) {
                                setShowPageNav(true);
                            } else {
                                setShowPageNav(false);
                            }
                        } else {
                            setEmpty(true);
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [products, productsStatistics, customersStatistics, docsCount, controler]);

    const handleChange = e => {
        setSearchingText(e.target.value);
    }

    let inputRef = null;

    const setRefernce = (inputElement) => {
        inputRef = inputElement;
    }

    const cleanInput = () => {
        try {
            if (inputRef) {
                inputRef.focus();
            }
        } catch (error) {
            console.warn(error);
        }

        setSearchingText("");
    }

    const [searchInputFocus, setSearchInputFocus] = useState(false);

    useEffect(() => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
        // eslint-disable-next-line
    }, [document.activeElement]);

    const checkSearchInput = () => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
    }

    const esPlaceholder = "Escribe palabra clave aquí...";
    const enPlaceholder = "Write keyword here...";

    const onLookForProducts = () => {
        setLoading(true);
        setControler({
            "isLookingForProducts": true,
            "searchingText": "",
            "triggerSearch": true,
        });
        setSearchingTextActive(false);
    }

    const onLookForCustomers = () => {
        setLoading(true);
        setControler({
            "isLookingForProducts": false,
            "searchingText": "",
            "triggerSearch": true,
        });
        setSearchingTextActive(false);
    }

    const onScroll = () => {
        // Nothing happends
    }

    const [width, setWidth] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            } else {
                try {
                    const width = observedRef.current.offsetWidth - 11
                    return width;
                } catch {
                    return;
                }
            }
        } else {
            return;
        }
    });

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth - 11);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension, productsResults]);

    const [cardContWith, setCardContWith] = useState(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return "974px";
            } else {
                try {
                    const width = observedRef.current.offsetWidth - 11
                    if (width <= 330) {
                        return "164px";
                    } else {
                        if (width <= 492) {
                            return "326px";
                        } else {
                            if (width <= 654) {
                                return "488px";
                            } else {
                                if (width <= 816) {
                                    return "650px";
                                } else {
                                    if (width <= 978) {
                                        return "812px";
                                    } else {
                                        return "974px";
                                    }
                                }
                            }
                        }
                    }
                } catch {
                    return "974px";
                }
            }
        } else {
            return "974px";
        }
    });

    useEffect(() => {
        if (width <= 330) {
            setCardContWith("164px");
        } else {
            if (width <= 492) {
                setCardContWith("326px");
            } else {
                if (width <= 654) {
                    setCardContWith("488px");
                } else {
                    if (width <= 816) {
                        setCardContWith("650px");
                    } else {
                        if (width <= 978) {
                            setCardContWith("812px");
                        } else {
                            setCardContWith("974px");
                        }
                    }
                }
            }
        }
    }, [width]);

    let headerObservedRef = useRef(null);

    const [headerHeight, setHeaderHeight] = useState();

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, productsResults, width, isSideMenuMinimized]);

    const search = async () => {
        if (searchingText.trim() !== "") {
            setControler({
                ...controler,
                "searchingText": searchingText,
                "triggerSearch": true,
            });
        }
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            setControler({
                ...controler,
                "searchingText": searchingText,
                "triggerSearch": true,
            });
        }
    }

    const tryAgain = () => {
        setActiveId(null);
        setControler({
            ...controler,
            "triggerSearch": true,
        })
    }

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    useEffect(() => {
        if (controler) {
            if (controler.isLookingForProducts) {
                if (
                    pageNumber === 1 &&
                    products &&
                    controler.searchingText.trim() === ""
                ) {
                    if (controler.triggerSearch) {
                        onResetAllForProducts();
                    }
                } else {
                    if (accountData && controler.triggerSearch) {
                        searchProducts(controler);
                    }
                }
            } else {
                if (
                    pageNumber === 1 &&
                    customers &&
                    controler.searchingText.trim() === ""
                ) {
                    if (controler.triggerSearch) {
                        onResetAllForCustomers();
                    }
                } else {
                    if (accountData && controler.triggerSearch) {
                        searchCustomers(controler);
                    }
                }
            }
        }
        // eslint-disable-next-line 
    }, [controler]);

    function removeAccents(text) {
        const normalizedString = text.normalize("NFD");
        const chainWithoutAccents = normalizedString.replace(/[\u0300-\u036f]/g, "");
        return chainWithoutAccents;
    }

    function optimizeTextForSearch(text) {
        const chainWithoutAccents = removeAccents(text);
        const lowerCaseText = chainWithoutAccents.toLowerCase();
        const trimText = lowerCaseText.trim();
        return trimText;
    }

    const refreshSearchProducts = async () => {
        if (
            products &&
            navControlers.page === 1 &&
            controler.searchingText.trim() === ""
        ) {
            const recentlyOpenProducts = Array.isArray(products) ? products : [];
            setProductsResults(recentlyOpenProducts);

            setNavControlers({
                "page": 1,
                "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                "isPrevious": false,
                "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
            });
        } else {
            let startAtText = null;

            // This is like previous, but we usel the current page number
            const lastPageNumber = navControlers.page;

            const searchingBy = optimizeTextForSearch(controler.searchingText);

            if (lastPageNumber === 1 && searchingBy === "") {
                onResetAllForProducts();
                return
            }

            try {

                const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

                let docSnap = null;

                if (!lastPageDetails) {
                    console.log("ERROR********************");
                } else {
                    startAtText = lastPageDetails.startAt;
                    const productRef = collection(firebase.db, `accounts/${accountData.id}/products`);
                    docSnap = await getDoc(doc(productRef, lastPageDetails.startAtDocId));
                }

                let lastQuery = null;

                if (searchingBy === "") {
                    // To get the sales docs
                    lastQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        orderBy("nameInsensitive"),
                        startAt(docSnap ? docSnap : startAtText),
                        limit(30)
                    );
                } else {
                    // To get the sales docs
                    lastQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/products`),
                        where("searchKeys", "array-contains", searchingBy),
                        orderBy("nameInsensitive"),
                        startAt(docSnap ? docSnap : startAtText),
                        limit(30)
                    );
                }

                await goToPagePreviousForProducts(lastQuery, navControlers.page);

                setLoading(false);

                setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);
            } catch (error) {
                console.error(error);
            }
        }
    }

    const refreshSearchCustomers = async () => {
        if (
            customers &&
            navControlers.page === 1 &&
            controler.searchingText.trim() === ""
        ) {
            const recentlyOpenCustomers = Array.isArray(customers) ? customers : [];
            setCustomersResults(recentlyOpenCustomers);

            setNavControlers({
                "page": 1,
                "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
                "isPrevious": false,
                "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false
            });
        } else {
            let startAtText = null;

            // This is like previous, but we usel the current page number
            const lastPageNumber = navControlers.page;

            const searchingBy = optimizeTextForSearch(controler.searchingText);

            if (lastPageNumber === 1 && searchingBy === "") {
                onResetAllForCustomers();
                return
            }

            try {

                const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

                let docSnap = null;

                if (!lastPageDetails) {
                    console.log("ERROR********************");
                } else {
                    startAtText = lastPageDetails.startAt;
                    const customerRef = collection(firebase.db, `accounts/${accountData.id}/customers`);
                    docSnap = await getDoc(doc(customerRef, lastPageDetails.startAtDocId));
                }

                let lastQuery = null;

                if (searchingBy === "") {
                    // To get the sales docs
                    lastQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/customers`),
                        orderBy("lastTransactionTime", "desc"),
                        startAt(docSnap ? docSnap : startAtText),
                        limit(30)
                    );
                } else {
                    // To get the sales docs
                    lastQuery = query(
                        collection(firebase.db, `accounts/${accountData.id}/customers`),
                        where("searchKeys", "array-contains", searchingBy),
                        orderBy("lastTransactionTime", "desc"),
                        startAt(docSnap ? docSnap : startAtText),
                        limit(30)
                    );
                }

                await goToPagePreviousForCustomers(lastQuery, navControlers.page);

                setLoading(false);

                setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);
            } catch (error) {
                console.error(error);
            }
        }
    }

    const searchProducts = async (controler) => {
        setActiveId(null);

        if (
            products &&
            navControlers.page === 1 &&
            controler.searchingText.trim() === ""
        ) { return; }

        setLoading(true);
        setShowConnectionError(false);

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let q = null;
        let qNoOrder = null;

        if (searchingBy === "") {
            // To get the sales docs
            q = query(
                collection(firebase.db, `accounts/${accountData.id}/products`),
                orderBy("nameInsensitive"),
                limit(30)
            );
        } else {
            // To get the sales docs
            q = query(
                collection(firebase.db, `accounts/${accountData.id}/products`),
                where("searchKeys", "array-contains", searchingBy),
                orderBy("nameInsensitive"),
                limit(30)
            );

            // To get the docsCount to control the page nav
            qNoOrder = query(
                collection(firebase.db, `accounts/${accountData.id}/products`),
                where("searchKeys", "array-contains", searchingBy),
                orderBy("nameInsensitive"),
            );
        }

        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        try {
            const snapshot = await getDocsFromServer(q);
            const res = [];

            let newDocsCount = null;

            if (searchingBy !== "") {
                const snapshot = await getCountFromServer(qNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            setPageHistory([]);
            setPageNumber(1);

            snapshot.forEach((doc) => {
                if (doc.data()) {
                    const thisDoc = {
                        ...doc.data(),
                        "id": doc.id,
                    }

                    delete thisDoc.searchKeys;

                    res.push(thisDoc);
                }
            });


            if (searchingBy !== "") {
                setDocsCount(newDocsCount);
                setShowPageNav(productsStatistics ? (Math.ceil(newDocsCount / 30) > 1 ? true : false) : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": productsStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": false,
                    "isNext": productsStatistics ? (Math.ceil(newDocsCount / 30) > 1 ? true : false) : false
                });
            } else {
                setShowPageNav(productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false
                });
            }

            if (res.length > 0) {
                setEmpty(false);
                updateOrPushHistory({
                    "page": 1,
                    "startAt": res[0].nameInsensitive,
                    "startAtDocId": res[0].id,
                    "endAt": res[res.length - 1].nameInsensitive,
                    "endAtDocId": res[res.length - 1].id,
                });

            } else {
                setEmpty(true);
            }

            setProductsResults(Array.isArray(res) ? res : []);
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowConnectionError(true);
        }
    }

    const searchCustomers = async (controler) => {
        setActiveId(null);
        if (
            customers &&
            navControlers &&
            navControlers.page === 1 &&
            controler.searchingText.trim() === ""
        ) { return; }

        // eslint-disable-next-line
        setLoading(true);
        setShowConnectionError(false);

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let q = null;
        let qNoOrder = null;

        if (searchingBy === "") {
            // To get the sales docs
            q = query(
                collection(firebase.db, `accounts/${accountData.id}/customers`),
                orderBy("lastTransactionTime", "desc"),
                limit(30)
            );
        } else {
            // To get the sales docs
            q = query(
                collection(firebase.db, `accounts/${accountData.id}/customers`),
                orderBy("lastTransactionTime", "desc"),
                where("searchKeys", "array-contains", searchingBy),
                limit(30)
            );
            // To get the docsCount to control the page nav
            qNoOrder = query(
                collection(firebase.db, `accounts/${accountData.id}/customers`),
                orderBy("lastTransactionTime", "desc"),
                where("searchKeys", "array-contains", searchingBy),
            );
        }

        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        try {
            const snapshot = await getDocsFromServer(q);
            const res = [];

            let newDocsCount = null;

            if (searchingBy !== "") {
                const snapshot = await getCountFromServer(qNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            setPageHistory([]);
            setPageNumber(1);

            snapshot.forEach((doc) => {
                if (doc.data()) {
                    const thisDoc = {
                        ...doc.data(),
                        "id": doc.id,
                    }

                    delete thisDoc.searchKeys;

                    res.push(thisDoc);
                }
            });

            if (searchingBy !== "") {
                setDocsCount(newDocsCount);
                setShowPageNav(customersStatistics ? (Math.ceil(newDocsCount / 30) > 1 ? true : false) : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": customersStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": false,
                    "isNext": customersStatistics ? (Math.ceil(newDocsCount / 30) > 1 ? true : false) : false
                });
            } else {
                setShowPageNav(customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
                    "isPrevious": false,
                    "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false
                });
            }

            if (res.length > 0) {
                setEmpty(false);

                updateOrPushHistory({
                    "page": 1,
                    "startAt": res[0].lastTransactionTime,
                    "startAtDocId": res[0].id,
                    "endAt": res[res.length - 1].lastTransactionTime,
                    "endAtDocId": res[res.length - 1].id,
                });

            } else {
                setEmpty(true);
            }

            setCustomersResults(Array.isArray(res) ? res : []);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowConnectionError(true);
        }
    }

    const getPageDetailsByNumber = (n) => {
        if (pagesHistory) {
            for (let i = 0; i < pagesHistory.length; i++) {
                if (pagesHistory[i]["page"] === n) {
                    return pagesHistory[i];
                }
            }
        }
        return null;
    }

    // To get the accounts use the pageHistory to call the funcition to get it.
    const updateOrPushHistory = (updatedObject) => {
        const pagesHistoryCopy = pagesHistory ? [...pagesHistory] : [];
        const page = updatedObject.page;
        const index = pagesHistoryCopy.findIndex(obj => obj["page"] === page);

        if (index !== -1) {
            // If the object exists, update it
            pagesHistoryCopy[index] = { ...pagesHistoryCopy[index], ...updatedObject };
            setPageHistory(pagesHistoryCopy);
        } else {
            // If the object doesn't exist, push a new object to the array
            pagesHistoryCopy.push(updatedObject);
            setPageHistory(pagesHistoryCopy);
        }
    }

    const onResetAllForProducts = () => {
        setActiveId(null);
        blink();

        setControler({
            ...controler,
            "searchingText": "",
            "triggerSearch": false,
        });

        setPageHistory([]);

        setProductsResults(products);

        setShowPageNav(productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false);

        setNavControlers({
            "page": 1,
            "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > 1 ? true : false) : false,
        });
        setPageNumber(1);
    }

    const onResetAllForCustomers = () => {
        setActiveId(null);
        blink();

        setControler({
            ...controler,
            "searchingText": "",
            "triggerSearch": false,
        });

        setPageHistory([]);

        setCustomersResults(customers);

        setShowPageNav(customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false);

        setNavControlers({
            "page": 1,
            "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
            "isPrevious": false,
            "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > 1 ? true : false) : false,
        });
        setPageNumber(1);
    }

    async function goToPagePreviousForProducts(q, pageN) {
        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setProductsResults([]);
        } else {
            updateOrPushHistory({
                "page": pageN,
                "startAt": res[0].nameInsensitive,
                "startAtDocId": res[0].id,
                "endAt": res[res.length - 1].nameInsensitive,
                "endAtDocId": res[res.length - 1].id,
            });
            setProductsResults(res);
        }
    }

    const onClickPreviousForProducts = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isPrevious === false) {
            return;
        }

        let startAtText = null;

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        const lastPageNumber = navControlers.page - 1;

        if (lastPageNumber === 1 && searchingBy === "") {
            onResetAllForProducts();
            return
        }

        setLoading(true);

        try {
            const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

            let docSnap = null;

            if (!lastPageDetails) {
                console.log("ERROR********************");
            } else {
                startAtText = lastPageDetails.startAt;
                const productRef = collection(firebase.db, `accounts/${accountData.id}/products`);
                docSnap = await getDoc(doc(productRef, lastPageDetails.startAtDocId));
            }

            let lastQuery = null;
            let lastQueryNoOrder = null;


            if (searchingBy === "") {
                // To get the sales docs
                lastQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/products`),
                    orderBy("nameInsensitive"),
                    startAt(docSnap ? docSnap : startAtText),
                    limit(30)
                );
            } else {
                // To get the sales docs
                lastQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/products`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("nameInsensitive"),
                    startAt(docSnap ? docSnap : startAtText),
                    limit(30)
                );

                // To get the docsCount to control the page nav
                lastQueryNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/products`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("nameInsensitive"),
                );
            }

            await goToPagePreviousForProducts(lastQuery, navControlers.page - 1);

            let newDocsCount = null;

            if (searchingBy !== "") {
                const snapshot = await getCountFromServer(lastQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page - 1;
            setPageNumber(newPageNumber);

            if (searchingBy !== "") {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": productsStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": productsStatistics ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });

            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > newPageNumber ? true : false) : false
                })
            }
        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }

        setLoading(false);
    }

    async function goToNextPageForProducts(q) {
        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setProductsResults([]);
        } else {
            if (pagesHistory) {
                if (!(pagesHistory.length > 0)) {
                    let newHistory = [
                        {
                            "page": 1,
                            "startAt": productsResults[0].nameInsensitive,
                            "startAtDocId": productsResults[0].id,
                            "endAt": productsResults[productsResults.length - 1].nameInsensitive,
                            "endAtDocId": productsResults[productsResults.length - 1].id,
                        },
                        {
                            "page": navControlers.page + 1,
                            "startAt": res[0].nameInsensitive,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].nameInsensitive,
                            "endAtDocId": res[res.length - 1].id,
                        }
                    ];
                    setPageHistory(newHistory);
                } else {
                    updateOrPushHistory({
                        "page": navControlers.page + 1,
                        "startAt": res[0].nameInsensitive,
                        "startAtDocId": res[0].id,
                        "endAt": res[res.length - 1].nameInsensitive,
                        "endAtDocId": res[res.length - 1].id,
                    });
                }
            } else {
                updateOrPushHistory({
                    "page": navControlers.page + 1,
                    "startAt": res[0].nameInsensitive,
                    "startAtDocId": res[0].id,
                    "endAt": res[res.length - 1].nameInsensitive,
                    "endAtDocId": res[res.length - 1].id,
                });
            }
            setProductsResults(res);
        }
    }

    const onClickNextForProducts = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isNext === false) {
            return;
        }

        let startAfterOrAfterText = null;

        const nextPageNumber = navControlers.page + 1;
        const nextPageDetails = getPageDetailsByNumber(nextPageNumber);

        let endAtDocId = null;

        if (!nextPageDetails) {
            const lastProduct = productsResults[productsResults.length - 1];
            endAtDocId = lastProduct.id;
            startAfterOrAfterText = lastProduct.nameInsensitive;
        } else {
            const previousPage = getPageDetailsByNumber(nextPageNumber - 1);
            if (previousPage) {
                endAtDocId = previousPage.endAtDocId;
            }
            startAfterOrAfterText = nextPageDetails.startAt;
        }

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let nextQuery = null;
        let nextQueryNoOrder = null;
        let docSnap = null;

        try {
            setLoading(true);

            if (endAtDocId) {
                const productRef = collection(firebase.db, `accounts/${accountData.id}/products`);
                docSnap = await getDoc(doc(productRef, endAtDocId));
            }

            if (searchingBy === "") {
                // To get the sales docs
                nextQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/products`),
                    orderBy("nameInsensitive"),
                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                    limit(30)
                );
            } else {
                // To get the sales docs
                nextQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/products`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("nameInsensitive"),
                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                    limit(30)
                );

                // To get the docsCount to control the page nav
                nextQueryNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/products`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("nameInsensitive"),
                );
            }


            await goToNextPageForProducts(nextQuery);

            let newDocsCount = null;

            if (searchingBy !== "") {
                const snapshot = await getCountFromServer(nextQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page + 1;
            setPageNumber(newPageNumber);

            if (searchingBy !== "") {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": productsStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": productsStatistics ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });
            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": productsStatistics ? Math.ceil(productsStatistics.all / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": productsStatistics ? (Math.ceil(productsStatistics.all / 30) > newPageNumber ? true : false) : false
                })
            }

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }
        setLoading(false);
    }

    async function goToPagePreviousForCustomers(q, pageN) {
        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setCustomersResults([]);
        } else {
            updateOrPushHistory({
                "page": pageN,
                "startAt": res[0].lastTransactionTime,
                "startAtDocId": res[0].id,
                "endAt": res[res.length - 1].lastTransactionTime,
                "endAtDocId": res[res.length - 1].id,
            });
            setCustomersResults(res);
        }
    }

    const onClickPreviousForCustomers = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isPrevious === false) {
            return;
        }

        let startAtText = null;

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        const lastPageNumber = navControlers.page - 1;

        if (lastPageNumber === 1 && searchingBy === "") {
            onResetAllForCustomers();
            return
        }

        setLoading(true);

        try {
            const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

            let docSnap = null;

            if (!lastPageDetails) {
                console.log("ERROR********************");
            } else {
                startAtText = lastPageDetails.startAt;
                const customerRef = collection(firebase.db, `accounts/${accountData.id}/customers`);
                docSnap = await getDoc(doc(customerRef, lastPageDetails.startAtDocId));
            }

            let lastQuery = null;
            let lastQueryNoOrder = null;

            if (searchingBy === "") {
                // To get the sales docs
                lastQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/customers`),
                    orderBy("lastTransactionTime", "desc"),
                    startAt(docSnap ? docSnap : startAtText),
                    limit(30)
                );
            } else {
                // To get the sales docs
                lastQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/customers`),
                    orderBy("lastTransactionTime", "desc"),
                    where("searchKeys", "array-contains", searchingBy),
                    startAt(docSnap ? docSnap : startAtText),
                    limit(30)
                );
                // To get the docsCount to control the page nav
                lastQueryNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/customers`),
                    orderBy("lastTransactionTime", "desc"),
                    where("searchKeys", "array-contains", searchingBy),
                );
            }

            await goToPagePreviousForCustomers(lastQuery, navControlers.page - 1);

            let newDocsCount = null;

            if (searchingBy !== "") {
                const snapshot = await getCountFromServer(lastQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page - 1;
            setPageNumber(newPageNumber);

            if (searchingBy !== "") {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": customersStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": customersStatistics ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });

            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > newPageNumber ? true : false) : false
                })
            }

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }

        setLoading(false);
    }

    async function goToNextPageForCustomers(q) {

        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setCustomersResults([]);
        } else {
            if (pagesHistory) {
                if (!(pagesHistory.length > 0)) {
                    let newHistory = [
                        {
                            "page": 1,
                            "startAt": customersResults[0].lastTransactionTime,
                            "startAtDocId": customersResults[0].id,
                            "endAt": customersResults[customersResults.length - 1].lastTransactionTime,
                            "endAtDocId": customersResults[customersResults.length - 1].id,
                        },
                        {
                            "page": navControlers.page + 1,
                            "startAt": res[0].lastTransactionTime,
                            "startAtDocId": res[0].id,
                            "endAt": res[res.length - 1].lastTransactionTime,
                            "endAtDocId": res[res.length - 1].id,
                        }
                    ];
                    setPageHistory(newHistory);
                } else {
                    updateOrPushHistory({
                        "page": navControlers.page + 1,
                        "startAt": res[0].lastTransactionTime,
                        "startAtDocId": res[0].id,
                        "endAt": res[res.length - 1].lastTransactionTime,
                        "endAtDocId": res[res.length - 1].id,
                    });
                }
            } else {
                updateOrPushHistory({
                    "page": navControlers.page + 1,
                    "startAt": res[0].lastTransactionTime,
                    "startAtDocId": res[0].id,
                    "endAt": res[res.length - 1].lastTransactionTime,
                    "endAtDocId": res[res.length - 1].id,
                });
            }
            setCustomersResults(res);
        }
    }

    const onClickNextForCustomers = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isNext === false) {
            return;
        }

        let startAfterOrAfterText = null;

        const nextPageNumber = navControlers.page + 1;
        const nextPageDetails = getPageDetailsByNumber(nextPageNumber);

        let endAtDocId = null;

        if (!nextPageDetails) {
            const lastProduct = customersResults[customersResults.length - 1];
            endAtDocId = lastProduct.id;
            startAfterOrAfterText = lastProduct.nameInsensitive;

        } else {
            const previousPage = getPageDetailsByNumber(nextPageNumber - 1);
            if (previousPage) {
                endAtDocId = previousPage.endAtDocId;
            }
            startAfterOrAfterText = nextPageDetails.startAt;
        }

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let nextQuery = null;
        let nextQueryNoOrder = null;
        let docSnap = null;

        try {
            setLoading(true);

            if (endAtDocId) {
                const customerRef = collection(firebase.db, `accounts/${accountData.id}/customers`);
                docSnap = await getDoc(doc(customerRef, endAtDocId));
            }

            if (searchingBy === "") {
                // To get the sales docs
                nextQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/customers`),
                    orderBy("lastTransactionTime", "desc"),
                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                    limit(30)
                );
            } else {
                // To get the sales docs
                nextQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/customers`),
                    orderBy("lastTransactionTime", "desc"),
                    where("searchKeys", "array-contains", searchingBy),
                    endAtDocId ? startAfter(docSnap) : startAt(startAfterOrAfterText),
                    limit(30)
                );
                // To get the docsCount to control the page nav
                nextQueryNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/customers`),
                    orderBy("lastTransactionTime", "desc"),
                    where("searchKeys", "array-contains", searchingBy),
                );
            }

            await goToNextPageForCustomers(nextQuery);

            let newDocsCount = null;

            if (searchingBy !== "") {
                const snapshot = await getCountFromServer(nextQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page + 1;
            setPageNumber(newPageNumber);

            if (searchingBy !== "") {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": customersStatistics ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": customersStatistics ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });

            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": customersStatistics ? Math.ceil(customersStatistics.all / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": customersStatistics ? (Math.ceil(customersStatistics.all / 30) > newPageNumber ? true : false) : false
                })
            }

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }
        setLoading(false);
    }

    useEffect(() => {
        let newControler = null;

        if (controler) {
            newControler = {
                ...controler,
                "triggerSearch": false,
            }
        } else {
            newControler = {
                "isLookingForProducts": true,
                "searchingText": "",
                "triggerSearch": false,
            };
        }

        const newState = {
            "productsResults": productsResults,
            "customersResults": customersResults,
            "docsCount": docsCount,
            "controler": newControler,
            "pagesHistory": pagesHistory,
            "navControlers": navControlers,
            "showConnectionError": showConnectionError,
            "empty": empty,
            "showPageNav": showPageNav,
            "activeId": activeId,
            "searchingByTextActive": searchingByTextActive,
            "loading": false,
        }
        navigate(null, { replace: true, state: newState });

        // eslint-disable-next-line
    }, [productsResults, customersResults, controler, pagesHistory, navControlers, showConnectionError, empty, showPageNav, activeId, docsCount, searchingByTextActive]);

    return (
        <div
            className={`shopping-cart-item-section`}
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            {controler ? <Fragment>
                <div ref={headerObservedRef} className="unselectable" style={{ display: "flex", width: "100%", flexWrap: getFlexWrapForSearch(), paddingBottom: "15px" }}>
                    <div className="shopp-search-for-cont">
                        <button onClick={onLookForProducts} className={`shopp-search-for-btn-left ${controler.isLookingForProducts ? (appTheme.dark ? "shopp-search-for-btn-active-dark" : "shopp-search-for-btn-active-light") : (appTheme.dark ? "shopp-search-for-btn-inactive-dark" : "shopp-search-for-btn-inactive-light")}`}>{appLanguage.en ? "Products" : "Productos"}</button>
                        <button onClick={onLookForCustomers} className={`shopp-search-for-btn-right ${!controler.isLookingForProducts ? (appTheme.dark ? "shopp-search-for-btn-active-dark" : "shopp-search-for-btn-active-light") : (appTheme.dark ? "shopp-search-for-btn-inactive-dark" : "shopp-search-for-btn-inactive-light")}`}>{appLanguage.en ? "Customers" : "Clientes"}</button>
                    </div>
                    <div className="div-search-input-home" style={{ width: "100%", marginTop: 0, maxWidth: "420px", marginLeft: "20px", paddingRight: "10px" }}>
                        <input
                            ref={setRefernce}
                            type="search"
                            onBlur={checkSearchInput}
                            onFocus={checkSearchInput}
                            autoComplete="off"
                            autoCorrect="off"
                            className={`search-input-home ${appTheme.dark ? "search-input-home-dar dar-sec-bg" : "search-input-home-lig lig-sec-bg"}`}
                            value={searchingText}
                            onChange={e => handleChange(e)}
                            name="searchingText"
                            onKeyDown={e => handleKeyPress(e)}
                            maxLength={30}
                            placeholder={appLanguage.en ? enPlaceholder : esPlaceholder}
                            style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                        />

                        {searchingText === "" ? null :
                            <div className={`search-div-delete ${searchInputFocus ? (appTheme.dark ? "search-button-home-dar-focus" : "search-button-home-lig-focus") : (appTheme.dark ? "search-button-home-dar" : "search-button-home-lig")} ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}>
                                <button onClick={cleanInput} className={appTheme.dark ? "search-div-delete-button-dark" : "search-div-delete-button-light"}>
                                    <img src={appTheme.dark ? crossWhiteIcon : crossIcon} alt="delete" />
                                </button>
                            </div>
                        }

                        <button
                            className="search-button-home"
                            type="submit"
                            onClick={search}
                        >
                            <img src={magnifyingIcon} alt="Search box" />
                        </button>
                    </div>
                </div>

                {controler.isLookingForProducts ?
                    (userSettings && userSettings.productsView === "iconsView" ?
                        <ProductsViewIcons
                            observedRef={observedRef}
                            onScroll={onScroll}
                            headerHeight={headerHeight + 42 + (desktopView ? (pageWidth <= 760 ? 10 : 0) : -42) + (pageWidth > 760 ? 0 : 68)}
                            cardContWith={cardContWith}
                            loading={loading}
                            showConnectionError={showConnectionError}
                            tryAgain={tryAgain}
                            empty={empty}
                            results={productsResults}
                            activeId={activeId}
                            setActiveReference={setActiveReference}
                            onClickLink={onClickLink}
                            pageNumber={pageNumber}
                            navControlers={navControlers}
                            showPageNav={showPageNav}
                            onClickNext={onClickNextForProducts}
                            onClickPrevious={onClickPreviousForProducts}
                        />
                    :
                        (userSettings && userSettings.productsView === "tableView" ? 
                            <ProductsViewList
                                observedRef={observedRef}
                                onScroll={onScroll}
                                headerHeight={headerHeight + 42 + (desktopView ? (pageWidth <= 760 ? 10 : 0) : -42) + (pageWidth > 760 ? 0 : 68)}
                                loading={loading}
                                showConnectionError={showConnectionError}
                                tryAgain={tryAgain}
                                empty={empty}
                                results={productsResults}
                                activeId={activeId}
                                setActiveReference={setActiveReference}
                                onClickLink={onClickLink}
                                pageNumber={pageNumber}
                                navControlers={navControlers}
                                showPageNav={showPageNav}
                                onClickNext={onClickNextForProducts}
                                onClickPrevious={onClickPreviousForProducts}
                                containerWidth={width}
                            />
                        : null)
                    )
                :
                    <CustomerListView
                        observedRef={observedRef}
                        longProcess={false}
                        onScroll={onScroll}
                        headerHeight={headerHeight + 42 + (desktopView ? (pageWidth <= 760 ? 10 : 0) : -42) + (pageWidth > 760 ? 0 : 68)}
                        loading={loading}
                        showConnectionError={showConnectionError}
                        tryAgain={tryAgain}
                        empty={empty}
                        results={customersResults}
                        activeId={activeId}
                        setActiveReference={setActiveReference}
                        onClickLink={onClickCustomer}
                        pageNumber={pageNumber}
                        navControlers={navControlers}
                        showPageNav={showPageNav}
                        onClickNext={onClickNextForCustomers}
                        onClickPrevious={onClickPreviousForCustomers}
                        selecting={true}
                        iAmSelected={iAmSelected}
                    />
                }

            </Fragment> : null}
        </div>
    )
}

export default SalesPicker;