import { Fragment, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { AppContext } from '../../../../context/AppContext';
import { SideMenuContext } from '../../../../context/SideMenuContext';
import { AuthContext } from '../../../../firebase/context';
import { useNavigate } from 'react-router-dom';

import SettingsHeader3 from '../../../../components/settings-header-3/settingsHeader3';
import SettingsTitleBus from '../../../../components/settings-title-bus/settingsTitleBus';

import TakePayment from '../../../../components/take-payment/takePayment';
import businessIcon from '../../../../img/analysis.svg';

import settingIconDark from '../../../../icons/settings-dark.png';
import settingIconLight from '../../../../icons/settings-light.png';

import "./takePayment.page.scss";

const TakePaymentPage = () => {

    const { appLanguage, appTheme } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    
    const { accountData, adminAccess, managerAccess } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if(!(adminAccess || managerAccess)){
            navigate("/cuenta-de-negocios", { replace: true })
        }
    })

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Make payment' : 'Hacer el pago'} </title>
            </Helmet>
            <div 
                className={`page-app-settings-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} 
                style={{ 
                    colorScheme: appTheme.dark ?  "dark" : "light"
                }}
            >
                <div className="page-app-settings-item">
                    {desktopView ?
                        <SettingsHeader3
                            icon={appTheme.dark ? settingIconDark : settingIconLight}
                            textLink1={"/cuenta-de-negocios"}
                            text1={appLanguage.en ? "Business account" : "Cuenta de negocios"}
                            textLink2={"/facturas"}
                            text2={appLanguage.en ? "My bill" : "Mi factura"}
                            text={appLanguage.en ? 'Make payment' : 'Hacer el pago'}
                        />
                        : null}
                    <div className="settings-options-container">
                        <div className="settings-options-container-item">
                            <SettingsTitleBus
                                icon={businessIcon}
                                firstText={accountData ? accountData.businessName : null}
                                secondText={`${appLanguage.en ? "Account" : "Cuenta"} ${accountData ? accountData.id : null}`}
                            />
                            
                            {(adminAccess || managerAccess) ? <Fragment>
                                <TakePayment />
                            </Fragment>  : null}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default TakePaymentPage;