import { useContext, useState, Fragment, useEffect } from 'react';
import firebase from '../../firebase/firebase';
import { AppContext } from '../../context/AppContext';
import { AlertContext } from '../alert/alertContext';
import { AuthContext } from '../../firebase/context';
import crossIcon from "../../icons/cross-white.png";
import saveIcon from "../../icons/check-dark.png";
import editIcon from "../../icons/signature-dark.png";

import './settingsEdictAcc.scss';

import Spinner from '../spinner/spinner';

const SettingsEdictAcc = () => {

    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const { appTheme, appLanguage, isTouchEnabled, getBusinessCategory, getBusinessCategories} = useContext(AppContext);
    const { accountData, setAccountData, saveKeyInIndexedDB } = useContext(AuthContext);
    const [loadingInfo, setLoadingInfo] = useState(false);

    const [edictingAccountData, setEdictingAccountData] = useState(false);

    const [showSave, setShowSave] = useState(false);

    const [businessAcc, setBusinessAcc] = useState({
        businessName: accountData.businessName,
        businessCategory: accountData.businessCategory,
    });

    const [businessNameAlert, setBusinessNameAlert] = useState({
        alert: false
    });

    const onChange = e => {
        if (e.target.name === "businessName") {
            setBusinessNameAlert({
                alert: false,
            });
        }

        setBusinessAcc({
            ...businessAcc,
            [e.target.name]: e.target.value
        });
    };

    async function updateUser() {
        setLoadingInfo(true);
        setShowSave(false);

        try {
            await firebase.useEdictAccount({
                businessId: accountData.id,
                businessName: businessAcc.businessName.trim(),
                businessCategory: businessAcc.businessCategory.trim(),
            });

            setShowSave(false);

            const accounDataUpdated = {
                ...accountData,
                "businessName": businessAcc.businessName.trim(),
                "businessCategory": businessAcc.businessCategory.trim(),
            };

            setAccountData(accounDataUpdated);
            saveKeyInIndexedDB("accountData", accounDataUpdated);

            setAlertData({
                type: 'success',
                title: {
                    en: 'Updated data',
                    es: 'Datos actualizados'
                },
                code: '',
                description: {
                    en: 'Your account data has been successfully updated.',
                    es: 'Los datos de tu cuenta se han actualizado correctamente.'
                }
            });
            setAlertActive(true);
            setLoadingInfo(false);
            setEdictingAccountData(false);

        } catch (error) {
            setLoadingInfo(false);
            setShowSave(true);

            const { code } = JSON.parse(JSON.stringify(error));
            console.error(error);

            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to update account',
                    es: 'Error al intentar actualizar la cuenta'
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    const validar = e => {
        e.preventDefault();

        if (businessAcc.businessName.trim() === "") {
            setBusinessNameAlert({
                alert: true
            });
            return;
        }

        updateUser();
    }

    const cancel = () => {
        setBusinessNameAlert({
            alert: false
        });
        setBusinessAcc({
            businessName: accountData.businessName,
            businessCategory: accountData.businessCategory,
        });
        setShowSave(false);
        setEdictingAccountData(false);
    }

    const onEdict = () => {
        setEdictingAccountData(true);
    }

    useEffect(() => {
        if ((accountData.businessName === businessAcc.businessName) && (accountData.businessCategory === businessAcc.businessCategory)) {
            setShowSave(false);
        } else {
            setShowSave(true);
        }
        // eslint-disable-next-line
    }, [businessAcc]);

    return (
        <div className={`settings-edict-acc-container unselectable ${appTheme.dark ? "dar-sec-bg dar-box-sha-keep-hover input-border-box-dark" : "lig-sec-bg lig-box-sha-keep-hover input-border-box-light"}`}>
            <div className='settings-edict-acc-container-first'>
                {loadingInfo ?
                    <Fragment>
                        <Spinner />
                    </Fragment> :
                    <div className='settings-edict-acc-input-container'>
                        <div className='settings-edict-acc-div-text'>
                            <div className='settings-edict-acc-div-text-div'>
                                <label htmlFor="businessName" className='settings-edict-acc-div-label'>
                                    <b>{appLanguage.en ? 'Business name: ' : 'Nombre del negocio: '}</b> {edictingAccountData ? <b>*</b> : ""}
                                </label>
                                <input
                                    style={
                                        (!edictingAccountData || loadingInfo) ? {
                                            colorScheme: appTheme.dark ? "dark" : "light",
                                            border: "none",
                                            background: "transparent",
                                            backgroundColor: "transparent",
                                            boxShadow: "none"
                                        } : {
                                            colorScheme: appTheme.dark ? "dark" : "light"
                                        }
                                    }
                                    className={`settings-edict-acc-input-text ${businessNameAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                    type="text"
                                    name="businessName"
                                    id="businessName"
                                    maxLength="22"
                                    value={businessAcc.businessName}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                        </div>
                        <br />
                        <div className='settings-edict-acc-div-text'>
                            <div className='settings-edict-acc-div-text-div'>
                                <label htmlFor="businessCategory" className='settings-edict-acc-div-label'>
                                    <b>{appLanguage.en ? 'Business category: ' : 'Categoria del negocio: '}</b> 
                                </label>
                                {edictingAccountData ?
                                    <select
                                        style={{ colorScheme: appTheme.dark ? "dark" : "light" }}
                                        className={`input-cre-acc-content ${appTheme.dark ? "input-border-box-dark" : "input-border-box-light"} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                        name="businessCategory"
                                        value={businessAcc.businessCategory}
                                        onChange={e => onChange(e)}
                                    >
                                        {getBusinessCategories().map((businessCategory, index) => {
                                            return ( 
                                                <option key={index} value={businessCategory}>
                                                    {getBusinessCategory(businessCategory)}
                                                </option>
                                            )
                                        })}
                                    </select>
                                :
                                    <input
                                        style={{
                                            colorScheme: appTheme.dark ? "dark" : "light",
                                            border: "none",
                                            background: "transparent",
                                            backgroundColor: "transparent",
                                            boxShadow: "none"
                                        }}
                                        readOnly
                                        className={`settings-edict-acc-input-text ${businessNameAlert.alert ? 'input-content-alert' : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")} ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                        type="text"
                                        maxLength="22"
                                        value={getBusinessCategory(businessAcc.businessCategory)}
                                        onChange={e => onChange(e)}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className='settings-edict-acc-cont-div-buttons'>
                    <div className="new-customer-bottons-container2">


                        {edictingAccountData ?
                            <Fragment>
                                <button
                                    onClick={cancel}
                                    disabled={loadingInfo ? true : false}
                                    className="new-customer-bottons-button-save"
                                    style={loadingInfo ? {
                                        backgroundColor: "#3f526698",
                                        color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                        cursor: "default"
                                    } : null}
                                >
                                    <img src={crossIcon} alt="Cancel" />
                                    {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                </button>
                                <button
                                    onClick={validar}
                                    disabled={!showSave}
                                    className="new-customer-bottons-button-save"
                                    style={!showSave ? {
                                        backgroundColor: "#3f526698",
                                        color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                        cursor: "default"
                                    } : null}
                                >
                                    <img src={saveIcon} alt="Save" />
                                    {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                </button>

                            </Fragment>
                            :
                            <button
                                onClick={onEdict}
                                disabled={loadingInfo}
                                className={isTouchEnabled() ? "edict-cust-prof-button-no-hover" : "edict-cust-prof-button-hover"}
                                style={loadingInfo ? {
                                    backgroundColor: "#3f526698",
                                    color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                    cursor: "default"
                                } : null}
                            >
                                <img src={editIcon} alt="Save" />
                                {appLanguage.en ? <p>Edit</p> : <p>Editar</p>}
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingsEdictAcc;