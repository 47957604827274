import JsBarcode from "jsbarcode";
import React, { Fragment, useContext, useEffect, useRef } from "react";
import { AuthContext } from "../firebase/context";
import { AppContext } from "../context/AppContext";
import { SideMenuContext } from "../context/SideMenuContext";

const SaleReceiptResponsa = ({ receiptData, receiptRef }) => {
    const { getComprobanteTitleById } = useContext(AppContext);
    const { windowDimension } = useContext(SideMenuContext);
    const { accountPictures } = useContext(AuthContext);

    return (
        <div
            style={{
                backgroundColor: "white",
                padding: windowDimension.width >= 610 ? "0 60px 50px 60px" : "0 20px 50px 20px",
                boxShadow: "3px 3px 3px 2.5px rgba(0, 0, 0, 0.2)"
            }}
        >
            {receiptData ?
                <ReceiptLetter 
                    ref={receiptRef} 
                    receiptData={receiptData} 
                    accountPictures={accountPictures} 
                    windowWidth={windowDimension.width} 
                    getComprobanteTitleById={getComprobanteTitleById}
                />
            : null}
        </div>
    )
}

const CodigoDeBarras = ({ valor, valorSize }) => {
    const barcodeRef = useRef(null);

    useEffect(() => {
        JsBarcode(barcodeRef.current, valor, {
            format: "CODE128",
            lineColor: "#000",
            width: 1.5,
            height: 20,
            displayValue: true,
            font: "Arial",
            fontSize: valorSize,
        });
    }, [valor, valorSize]);

    return (
        <svg ref={barcodeRef} />
    );
};

const ReceiptLetter = React.forwardRef(({ receiptData, accountPictures, windowWidth, getComprobanteTitleById }, ref) => {
    if (!receiptData) return null; // Si no hay datos, no renderiza nada

    const pictureURL = accountPictures ? accountPictures.d600x600 : null;

    function formatPhoneNumber(phone) {
        let temp = "";
        let normalizedPhone = null;

        // Normalazing Phone String
        const normalizedString = phone.normalize("NFD");
        const chainWithoutAccents = normalizedString
            .replace(/[\u0300-\u036f]/g, "");

        normalizedPhone = chainWithoutAccents.trim().toLowerCase();

        if (normalizedPhone[0] === "+" && normalizedPhone[1] === "1" && phone.length > 8) {
            // From    +18099958646
            // To      +1 809 995 8646
            // Them    ["+1", "809", "995", "8646"]

            for (let j = 0; j < normalizedPhone.length; j++) {
                if (j === 2 || j === 5 || j === 8) {
                    temp += " ";
                    temp += normalizedPhone[j];
                } else {
                    temp += normalizedPhone[j];
                }
            }

            const pnArray = temp.split(" ");

            // +1 (809) 995-8646
            const phone2 = `+1 (${pnArray[1]}) ${pnArray[2]}-${pnArray[3]}`;

            return phone2;
        } else {
            return phone;
        }
    }

    function formatRationalNumber(number) {
        if (number === undefined || number === null) {
            return "--";
        }

        // Check if the number is a string to convert it to a number
        if (typeof number === 'string') {
            number = parseFloat(number.split(',').join(''));
        }

        const fixedNumber = number.toFixed(2);
        const finalNumber = parseFloat(fixedNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

        // Format the number with thousands and two decimal separators
        return finalNumber.toString();
    }

    const getShortDate = () => {
        const date = new Date(receiptData.time);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`
    }

    const getHour = () => {
        const date = new Date(receiptData.time);
        const hours = date.getHours();

        const allHours = [
            { hour: 12, me: "a. m." },
            { hour: 1, me: "a. m." },
            { hour: 2, me: "a. m." },
            { hour: 3, me: "a. m." },
            { hour: 4, me: "a. m." },
            { hour: 5, me: "a. m." },
            { hour: 6, me: "a. m." },
            { hour: 7, me: "a. m." },
            { hour: 8, me: "a. m." },
            { hour: 9, me: "a. m." },
            { hour: 10, me: "a. m." },
            { hour: 11, me: "a. m." },
            { hour: 12, me: "p. m." },
            { hour: 1, me: "p. m." },
            { hour: 2, me: "p. m." },
            { hour: 3, me: "p. m." },
            { hour: 4, me: "p. m." },
            { hour: 5, me: "p. m." },
            { hour: 6, me: "p. m." },
            { hour: 7, me: "p. m." },
            { hour: 8, me: "p. m." },
            { hour: 9, me: "p. m." },
            { hour: 10, me: "p. m." },
            { hour: 11, me: "p. m." },
        ];

        const minutes = date.getMinutes();
        return `${allHours[hours].hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${allHours[hours].me}`;
    }

    const getTotalPaid = (payments) => {
        if (payments) {
            let tempPaid = 0;
            payments.forEach((payment) => {
                if (payment.status !== "canceled") {
                    tempPaid += payment.cashAmount;
                }
            });
            return tempPaid;
        } else {
            return 0;
        }
    }

    const getPadding = () => {
        if (receiptData.items) {

            if (windowWidth >= 720) {
                switch (receiptData.items.length) {
                    case (1):
                        return "60px 10px"
                    case (2):
                        return "50px 10px"
                    case (3):
                        return "40px 10px"
                    case (4):
                        return "35px 10px"
                    case (5):
                        return "30px 10px"
                    case (6):
                        return "25px 10px"
                    case (7):
                        return "20px 10px"
                    case (8):
                        return "15px 10px"
                    default:
                        return "10px 10px"
                }
            } else {
                return "10px 10px"
            }
        } else {
            return "10px 10px"
        }
    }

    const getInvoiceTitle = () => {
        if (receiptData.type === "Venta pendiente de pago" || receiptData.type === 'Venta cancelada') {
            return receiptData.type;
        } else {
            return receiptData.comprobante ? getComprobanteTitleById(receiptData.comprobante.tipo).toUpperCase() : "FACTURA";
        }
    }

    return (
        <div ref={ref} className="receipt get-quote-component-font" style={{ display: "flex", width: "100%", justifyContent: "center", color: "black" }}>
            <div className="textFont" style={{ display: "flex", width: "100%", maxWidth: "650px", flexDirection: "column", marginTop: "0px" }}>
                <p style={{ fontSize: "1px", marginBottom: "45px" }}>.</p>

                {/** Cabezera */}
                {receiptData.comprobante ? <Fragment>
                    <div style={{ display: "flex", width: "100%", marginBottom: windowWidth >= 720 ? "20px" : "0px" }}>
                        {/** Datos del negocio */}
                        <div style={{ display: "flex", width: windowWidth >= 720 ? (pictureURL ? "60%" : "75%") : "100%", alignContent: "center" }}>
                            {pictureURL ?
                                <div style={{ width: windowWidth >= 720 ? "130px" : "100px", minWidth: windowWidth >= 720 ? "130px" : "100px", paddingRight: "20px" }}>
                                    <div style={{ height: windowWidth >= 720 ? "125px" : "90px", marginTop: 0 }} className="products-inventory-product-yes-img-big-cont unselectable">
                                        <div style={{ width: windowWidth >= 720 ? "125px" : "90px", height: windowWidth >= 720 ? "125px" : "90px" }} className="pro-inv-prod-yes-img-big-con-div">
                                            <img className={`products-inventory-product-yes-img-big border-box-light`} src={pictureURL} alt="Business" />
                                        </div>
                                    </div>
                                </div>
                            : null}

                            <div style={{ display: "flex", width: windowWidth >= 720 ? (pictureURL ? "60%" : "75%") : "100%", flexDirection: "column" }}>

                                <div style={{ maxWidth: pictureURL ? "300px" : "430px" }}>
                                    <p style={{ fontSize: windowWidth >= 720 ? "14px" : (receiptData.businessName.length > 16 ?  "14px" : "13px"), fontWeight: "bolder", marginTop: "8px" }}>{receiptData.businessName.toUpperCase()}</p>

                                    {receiptData.address ?
                                        <p style={{ fontSize: "13px", marginTop: "8px" }}>{receiptData.address}</p>
                                    : null}
                                </div>

                                {receiptData.phone ?
                                    <p style={{ fontSize: "13px", marginTop: "8px" }}>{formatPhoneNumber(receiptData.phone)}</p>
                                    : null}

                                {windowWidth >= 720 && receiptData.rnc ?
                                    <p style={{ fontSize: "13px", marginTop: "8px" }}>RNC: {receiptData.rnc}</p>
                                : null}

                                {windowWidth >= 720 ? 
                                    <p style={{ fontSize: "13px", marginTop: "8px", whiteSpace: "nowrap" }}>Fecha: {getShortDate(receiptData.time)} &nbsp;&nbsp;&nbsp; Hora: {getHour()}</p>
                                : null}
                            </div>
                        </div>
                        
                        {/** Datos del comprobante */}
                        {windowWidth >= 720 ? 
                            (receiptData.comprobante.ncf && receiptData.comprobante.tipo !== "00" ?
                                <div style={{ display: "flex", width: "40%", flexDirection: "column", alignItems: "flex-end" }}>
                                    <div>
                                        <p style={{ fontSize: "14px", marginTop: "8px", fontWeight: "bolder", }}>{getInvoiceTitle()}</p>
                                        <p style={{ fontSize: "13px", marginTop: "8px" }}>NCF: {receiptData.comprobante.ncf}</p>
                                        <p style={{ fontSize: "13px", marginTop: "8px" }}>Vencimiento: {getShortDate(receiptData.comprobante.expirationDate)}</p>
                                    </div>
                                </div>
                                :
                                <div style={{ display: "flex", width: "40%", flexDirection: "column", alignItems: "flex-end" }}>
                                    <p style={{ fontSize: "14px", marginTop: "8px", fontWeight: "bolder", }}>{getInvoiceTitle()}</p>
                                </div>
                            )
                        : null}
                    </div>

                    {windowWidth < 720 && receiptData.rnc ?
                        <p style={{ fontSize: "13px", marginTop: "8px" }}>RNC: {receiptData.rnc}</p>
                    : null}

                    {windowWidth < 720 ? 
                        <p 
                            style={{ 
                                fontSize: "13px", 
                                marginTop: "8px", 
                                whiteSpace: "nowrap",
                                marginBottom: "15px"
                            }}
                        >
                            Fecha: {getShortDate(receiptData.time)} &nbsp;&nbsp;&nbsp; Hora: {getHour()}
                        </p>
                    : null}

                    <hr style={{ borderTop: "1px solid #ccc" }} />

                    {windowWidth >= 720 ?
                        <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 0px 30px 0px" }}>
                            {/** Datos del cliente */}
                            <div style={{ maxWidth: "430px" }}>
                                {receiptData.comprobante.ncf && receiptData.comprobante.tipo === "01" ?
                                    <p style={{ fontSize: "13px", marginTop: "8px" }}><b>RNC Cliente:</b> {receiptData.comprobante.cliente.rnc}</p>
                                    : null}
                                <p style={{ fontSize: "13px", marginTop: "8px" }}><b>Nombre Cliente:</b> {receiptData.comprobante.cliente.nombre}</p>
                            </div>

                            <div>
                                {receiptData.showSaleBarcode ?
                                    <CodigoDeBarras valor={receiptData.saleId} valorSize={14} />
                                    :
                                    <p style={{ fontSize: "14px", textAlign: "end", fontWeight: "500", marginTop: "0px" }}>Código de venta: <br /> <b>{receiptData.saleId}</b></p>
                                }
                            </div>
                        </div>
                    : 
                        <div>
                            {receiptData.comprobante.ncf ?
                                <p style={{ fontSize: "13px", margin: "8px 0px 3px 0px" }}><b>NCF:</b> {receiptData.comprobante.ncf} &nbsp;&nbsp;&nbsp; <b>Vence:</b> {getShortDate(receiptData.comprobante.expirationDate)}</p>
                            : null}

                            <div style={{ display: "flex", justifyContent: "space-between", margin: "5px 0px 10px 0px" }}>
                                {/** Datos del cliente */}
                                <div style={{ maxWidth: "430px" }}>
                                    {receiptData.comprobante.ncf && receiptData.comprobante.tipo === "01" ?
                                        <p style={{ fontSize: "13px", marginTop: "8px" }}><b>RNC Cliente:</b> {receiptData.comprobante.cliente.rnc}</p>
                                        : null}
                                    <p style={{ fontSize: "13px", marginTop: "8px" }}><b>Nombre Cliente:</b> {receiptData.comprobante.cliente.nombre}</p>
                                </div>
                            </div>

                            <hr style={{ borderTop: "1px solid #ccc" }} />

                            <p style={{ fontSize: "13px", margin: "5px", fontWeight: "bolder", textAlign: "center"}}>{getInvoiceTitle()}</p>

                            <hr style={{ borderTop: "1px solid #ccc" }} />
                            
                            <div style={{display: "flex", justifyContent: "center", marginBottom: "10px"}}>
                                {receiptData.showSaleBarcode ?
                                    <CodigoDeBarras valor={receiptData.saleId} valorSize={14} />
                                    :
                                    <p style={{ fontSize: "14px", textAlign: "end", fontWeight: "500", marginTop: "0px" }}>Código de venta: <br /> <b>{receiptData.saleId}</b></p>
                                }
                            </div>
                        </div>
                    }
                </Fragment> : <Fragment>
                    <div style={{ display: "flex", width: "100%" }}>
                        {pictureURL ?
                            <div style={{ width: windowWidth >= 720 ? "140px" : "100px", minWidth: windowWidth >= 720 ? "140px" : "100px", paddingRight: "20px" }}>
                                <div style={{ height: windowWidth >= 720 ? "130px" : "90px", marginTop: 0 }} className="img-big-cont unselectable">
                                    <div style={{ width: windowWidth >= 720 ? "130px" : "90px", height: windowWidth >= 720 ? "140px" : "90px" }}>
                                        <img className={`img-big border-box-light`} src={pictureURL} alt="Business" />
                                    </div>
                                </div>
                            </div>
                        : null}

                        <div style={{ display: "flex", width: windowWidth >= 720 ? "60%" : "100%", flexDirection: "column" }}>

                            <div style={{ maxWidth: "300px" }}>
                                <p style={{ fontSize: "15px", fontWeight: "bolder", marginTop: "8px" }}>{receiptData.businessName.toUpperCase()}</p>

                                {receiptData.address ?
                                    <p style={{ fontSize: "12px", marginTop: "8px" }}>{receiptData.address.toUpperCase()}</p>
                                    : null}

                            </div>

                            {receiptData.phone ?
                                <p style={{ fontSize: "12px", marginTop: "8px" }}>{formatPhoneNumber(receiptData.phone)}</p>
                                : null}

                            {receiptData.rnc ?
                                <p style={{ fontSize: "12px", marginTop: "8px" }}>RNC: {receiptData.rnc}</p>
                                : null}

                            {windowWidth >= 720 ?
                                <p style={{ fontSize: "12px", marginTop: "8px" }}>Fecha: {getShortDate()} &nbsp;&nbsp;&nbsp; Hora: {getHour()}</p>
                                : null}

                        </div>

                        {windowWidth >= 720 ?
                            <div style={{ display: "flex", flexDirection: "column", width: "40%", alignItems: "flex-end", paddingTop: "0px" }}>
                                {receiptData.showSaleBarcode ?
                                    <CodigoDeBarras valor={receiptData.saleId} valorSize={14} />
                                    :
                                    <p style={{ fontSize: "13px", textAlign: "end", fontWeight: "500", marginTop: "0px", whiteSpace: "nowrap" }}>Código de venta: <b>{receiptData.saleId}</b></p>
                                }

                                <br />

                                {receiptData.finalConsumerName ?
                                    <table cellPadding="3" cellSpacing="3">
                                        <tbody>

                                            <tr>
                                                <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400", width: "20px" }}>
                                                    <p style={{ fontSize: "12px", fontWeight: "500" }}>Cliente:</p>
                                                </td>
                                                <td style={{ textAlign: "left", fontSize: "16px", fontWeight: "400", width: "135px" }}>
                                                    {receiptData.finalConsumerName ?
                                                        <p
                                                            style={{
                                                                fontSize: "12px",
                                                                "WebkitBoxOrient": "vertical",
                                                                "WebkitLineClamp": "1",
                                                                "lineClamp": "1",
                                                                "overflow": "hidden",
                                                                "textOverflow": "ellipsis"
                                                            }}
                                                        >
                                                            {receiptData.finalConsumerName}
                                                        </p>
                                                        : <p>--</p>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    : null}
                            </div>
                            : null}
                    </div>

                    {windowWidth < 720 ?
                        <p style={{ fontSize: "12px", marginTop: "25px" }}>Fecha: {getShortDate()} &nbsp;&nbsp;&nbsp; Hora: {getHour()}</p>
                    : null}

                    {windowWidth < 720 ? <Fragment>
                        {receiptData.finalConsumerName ?
                            <p
                                style={{
                                    fontSize: "12px",
                                    marginTop: "8px",
                                    "WebkitBoxOrient": "vertical",
                                    "WebkitLineClamp": "1",
                                    "lineClamp": "1",
                                    "overflow": "hidden",
                                    "textOverflow": "ellipsis"
                                }}
                            >
                                Cliente: {receiptData.finalConsumerName}
                            </p>
                            : null}

                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                marginTop: "10px"
                            }}
                        >
                            {receiptData.showSaleBarcode ?
                                <CodigoDeBarras valor={receiptData.saleId} valorSize={14} />
                                :
                                <p style={{ fontSize: "13px", textAlign: "end", fontWeight: "500", marginTop: "0px", whiteSpace: "nowrap" }}>Código de venta: <b>{receiptData.saleId}</b></p>
                            }
                        </div>
                    </Fragment> : null}

                    <p style={{ fontSize: "12px", textAlign: "center", fontWeight: "600", margin: windowWidth >= 720 ? "30px 10px" : "15px 10px" }}>{receiptData.type === "Factura de consumo" ? "FACTURA" : receiptData.type.toUpperCase()}</p>
                </Fragment>}

                <div
                    style={{
                        display: "flex",
                        width: windowWidth >= 515 ? "100%" : `${windowWidth - 40}px`,
                        justifyContent: windowWidth >= 515 ? "center" : "flex-start",
                        overflow: "hidden",
                        overflowX: "auto",
                        colorScheme: "light"
                    }}
                >
                    <table cellPadding="5" cellSpacing="5" style={{ borderCollapse: "collapse", width: "100%" }}>
                        <tbody>
                            <tr style={{ backgroundColor: "#f0f0f0", borderBottom: "2px solid #ccc" }}>
                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "50px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Cant.</p>
                                </td>

                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "180px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Descripción</p>
                                </td>

                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "80px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Precio</p>
                                </td>

                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "80px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Itbis</p>
                                </td>

                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "80px", border: "1px solid #ccc" }}>
                                    <p style={{ fontSize: "14px", padding: "3px" }}>Valor</p>
                                </td>
                            </tr>

                            {receiptData.items.map((item, index) => (
                                <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                                    <td style={{ textAlign: "center", fontWeight: "400", minWidth: "50px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{item.quantity}</p>
                                    </td>

                                    <td style={{ textAlign: "left", fontWeight: "400", minWidth: "180px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{item.product.name}</p>
                                    </td>

                                    <td style={{ textAlign: "right", fontWeight: "400", minWidth: "80px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{formatRationalNumber(item.unitValue)}</p>
                                    </td>

                                    <td style={{ textAlign: "right", fontWeight: "400", minWidth: "80px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{formatRationalNumber(item.itbis)}</p>
                                    </td>

                                    <td style={{ textAlign: "right", fontWeight: "400", minWidth: "80px", border: "1px solid #ccc" }}>
                                        <p style={{ fontSize: "14px", padding: getPadding() }}>{formatRationalNumber(item.value)}</p>
                                    </td>
                                </tr>

                            ))}

                        </tbody>
                    </table>
                </div>



                <div style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: "50px" }}>

                    <table cellPadding="5" cellSpacing="5" style={{ borderCollapse: "collapse", width: "100%" }}>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "center", fontSize: "16px", width: "100%" }}>
                                    <div style={{ display: "flex", width: "100%" }}>
                                        <table cellPadding="2" cellSpacing="2">
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontSize: "16px", fontWeight: "400" }}>
                                                        <p style={{ fontSize: "13px" }}>{receiptData.type === 'Venta cancelada' ? 'Pagos reembolsados y/o acreditados:' : 'Pagos recibidos:'}</p>
                                                        {windowWidth < 460 ? <p style={{ fontSize: "12px", fontWeight: "bold", marginTop: "3px" }}>RD$ {formatRationalNumber(getTotalPaid(receiptData.payments))}</p> : null}
                                                    </td>

                                                    {windowWidth >= 460 ?
                                                        <td style={{ fontSize: "16px", fontWeight: "bold", }}>
                                                            <p style={{ fontSize: "13px" }}>RD$ {formatRationalNumber(getTotalPaid(receiptData.payments))}</p>
                                                        </td>
                                                        : null}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr style={{ borderTop: "1px solid #ccc" }} />
                                    {receiptData.footer ?
                                        <p style={{ fontSize: "14px", margin: "10px" }}>{receiptData.footer}</p>
                                        : null}
                                </td>
                                <td style={{ textAlign: "center", fontSize: "16px", fontWeight: "bold", minWidth: "90px" }}>
                                    <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                                        {/** Second table */}
                                        <table cellPadding="4" cellSpacing="4">
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>
                                                        <p style={{ fontSize: "14px" }}>Subtotal:</p>
                                                    </td>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "600", width: "70px" }}>
                                                        <p style={{ fontSize: "14px" }}>{formatRationalNumber(receiptData.subtotal)}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>
                                                        <p style={{ fontSize: "14px" }}>Desc: </p>
                                                    </td>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "600", width: "70px" }}>
                                                        <p style={{ fontSize: "14px" }}>{formatRationalNumber(receiptData.desc)}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>
                                                        <p style={{ fontSize: "14px" }}>Itbis:</p>
                                                    </td>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "600", width: "70px" }}>
                                                        <p style={{ fontSize: "14px" }}>{formatRationalNumber(receiptData.itbis)}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "400" }}>
                                                        <p style={{ fontSize: "14px" }}>Total:</p>
                                                    </td>
                                                    <td style={{ textAlign: "right", fontSize: "16px", fontWeight: "600", width: "70px" }}>
                                                        <p style={{ fontSize: "14px" }}>{formatRationalNumber(receiptData.total)}</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p style={{ fontSize: "1px" }}>.</p>
            </div>
        </div>
    );
});

export default SaleReceiptResponsa;