import "./invoices.page.scss";

import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { SideMenuContext } from "../../../context/SideMenuContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import termsCondIconDark from '../../../icons/terms-and-conditions-dark.png';

import crossIcon from "../../../icons/cross.png";
import crossWhiteIcon from "../../../icons/cross-white.png";

import xCancelDark from "../../../icons/cancel-dark.png";
import xCancelLight from "../../../icons/cancel-light.png";

import arrowDarkIcon from "../../../icons/arrowhead-dark.png";
import arrowLightIcon from "../../../icons/arrowhead-light.png";

import filterImgDark from "../../../icons/filter-dark.png";
import filterImgLight from "../../../icons/filter-light.png";

import adjustmentIconDark from '../../../icons/adjustment-dark.png';
import adjustmentIconLight from '../../../icons/adjustment-light.png';

import magnifyingIcon from "../../../icons/magnifying-glass.png";
import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';
// import addImgDark from "../../../icons/plus-mini-dark.png";

import InputTypeDate from "../../../appTools/appToolsComponents/inputTypeDate/inputTypeDate";
import { InputPickerDateContext } from "../../../appTools/appToolsComponents/inputPickerDate/inputPickerDateContext";
import InputPickerDate from "../../../appTools/appToolsComponents/inputPickerDate/inputPickerDate";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

import firebase from "../../../firebase";
// eslint-disable-next-line
import { collection, query, onSnapshot, orderBy, getCountFromServer, where, limit, startAt, getDocsFromServer, startAfter, doc, getDoc } from "firebase/firestore";
import FacturasSelectTable from "../../../appTools/appToolsComponents/facturasSelectTable/facturasSelectTable";
import { uid } from "uid";

const InvoicesPage = () => {
    const location = useLocation();

    const { accountData, accessTo } = useContext(AuthContext);
    const { appTheme, appLanguage, isTouchEnabled, getComprobanteTitleById, getDateWithMonthShort } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized, smallView } = useContext(SideMenuContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const { currentDate, pickerDateActive, setPickerDateActive, setPickerDateTitle, setCurrentDate, inputName, setInputName } = useContext(InputPickerDateContext);

    const [showConnectionError, setShowConnectionError] = useState(false);

    const [records, setRecords] = useState([]);

    const [effectControler, setEffectControler] = useState(false);

    // This will be save only in Ram Memory
    const [selecting, setSelecting] = useState(false);

    useEffect(() => {
        setActiveId(null);
    }, [selecting]);

    const [selectingAll, setSelectingAll] = useState(false);

    const [recordsSelected, setRecordsSelected] = useState([]);
   
    const [loading, setLoading] = useState(true);

    const [docsCount, setDocsCount] = useState(null);
    const [docsCountNoFilter, setDocsCountNoFilter] = useState(null);

    const [pageNumber, setPageNumber] = useState(null);

    const [empty, setEmpty] = useState(false);

    const [showPageNav, setShowPageNav] = useState(false);

    const [searchingText, setSearchingText] = useState("");

    const [searchingByTextActive, setSearchingTextActive] = useState(false);

    // Keep state in the current path
    const [results, setResults] = useState(null);
    const [controler, setControler] = useState(null);

    // eslint-disable-next-line
    const [pagesHistory, setPageHistory] = useState([]);

    const [navControlers, setNavControlers] = useState(null);

    // All of this is to avoid requesting data 
    // from the backend twice
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        if (refresh === 1 && navControlers && controler) {
            setRefresh(0);
            setPageNumber(navControlers.page);
            // refreshSearch();
        }
        // eslint-disable-next-line
    }, [refresh]);

    const [activeRef, setActiveRef] = useState(null);
    const [activeId, setActiveId] = useState(null);

    const setActiveReference = (inputElement) => {
        setActiveRef(inputElement);
    }

    useEffect(() => {
        if (activeRef) {
            activeRef.scrollIntoView({
                block: 'center',
                inline: 'nearest'
            });
            // setActiveId(null);
        }
    }, [activeRef]);

    useEffect(() => {
        if (results) {
            if (results.every(item => iAmSelected(item.id))) {
                setSelectingAll(true);
            } else {
                setSelectingAll(false);
            }
        } else {
            setSelectingAll(false);
        }
    // eslint-disable-next-line
    }, [results, recordsSelected]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!selecting) {
            setRecordsSelected([]);
        }
    }, [selecting]);

    const onClickSelect = () => {
        if (!selecting && loading) {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error: try again.',
                    es: 'Error: inténtalo de nuevo.',
                }
            });
        } else {
            setSelecting(!selecting);
        }
    }

    const iAmSelected = (idToCheck) => {
        const exists = recordsSelected.some(element => element.id === idToCheck);
        return exists;
    }

    async function getRecordsCounter(q) {
        try {
            const snapshot = await getCountFromServer(q);
            setDocsCountNoFilter(snapshot.data().count);
            setEmpty(snapshot.data().count ? false : true)
            setLoading(false);
        } catch (error) {
            console.warn(error);
            setLoading(false);
        }
    }

    async function verifiedIfComprobantesCollectionIsReallyEmpty(q) {
        try {
            const snapshot = await getCountFromServer(q);

            if (!(snapshot.data().count > 0)) {
                setRecords([]);
                setDocsCountNoFilter(0);
                setEmpty(true);
                setShowConnectionError(false);
                setLoading(false);
            }
        } catch (error) {
            console.warn(error);
            setShowConnectionError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (effectControler) {
            const q = query(
                collection(firebase.db, `accounts/${accountData.id}/facturas`),
                orderBy("time", "desc"),
                limit(30)
            );

            const unsub = onSnapshot(q, (querySnapshot) => {
                const res = [];

                querySnapshot.forEach((doc) => {
                    if (doc.data()) {
                        res.push({
                            ...doc.data(),
                            "id": doc.id,
                        })
                    }
                });

                if (querySnapshot.empty) {
                    verifiedIfComprobantesCollectionIsReallyEmpty(q);
                } else {
                    setRecords(res);
                    setEmpty(false);
                    setShowConnectionError(false);

                    const q2 = query(
                        collection(firebase.db, `accounts/${accountData.id}/facturas`),
                        orderBy("time", "desc"),
                    );

                    getRecordsCounter(q2);
                }
            }, err => {
                console.error(err);
                setShowConnectionError(true);
                setLoading(false);
            });

            return () => unsub();
        }
        // eslint-disable-next-line
    }, [effectControler]);

    useEffect(() => {
        if (
            records &&
            pageNumber === 1 &&
            controler &&
            controler.filterBy === "all" &&
            !controler.dateFrom &&
            !controler.dateTo &&
            controler.searchingText.trim() === ""
        ) {
            const recentlyOpenedAccounts = Array.isArray(records) ? records : [];
            setResults(recentlyOpenedAccounts);
            setNavControlers({
                "page": 1,
                "totalPage": Math.ceil(docsCount / 30),
                "isPrevious": false,
                "isNext": Math.ceil(docsCount / 30) > 1 ? true : false
            });
            const tempDocsCount = docsCountNoFilter;
            setDocsCount(tempDocsCount);
        }

        if (docsCount && controler) {
            if ((controler.searchingText.trim() === "") && (!filter)) {
                if (docsCount > 0) {
                    setEmpty(false);
                    if (docsCount > 30) {
                        setShowPageNav(true);
                    } else {
                        setShowPageNav(false);
                    }
                } else {
                    setEmpty(true);
                }
            }
        }
    // eslint-disable-next-line
    }, [records, controler, docsCount, docsCountNoFilter]);

    const onTryAgain = () => {
        setEffectControler(uid(6));
        setLoading(true);
    }

    const getRecordById = (idToFind) => {
        const result = results.find(element => element.id === idToFind);
        return result;
    }

    const onClickSelectAll = () => {
        if (selectingAll) {
            const stringsToRemove = results.map(obj => obj.id);
            const removalSet = new Set(stringsToRemove);
            const filteredArray = recordsSelected.filter(item => !removalSet.has(item.id));
            setRecordsSelected(filteredArray);
        } else {
            if (results) {
                const temNotSelected = [...recordsSelected];
                for (let i = 0; i < results.length; i++) {
                    // 999 = Limit of 1,000
                    if (temNotSelected.length <= 999) {
                        if (!temNotSelected.some(selected => selected.id === results[i].id)) {
                            temNotSelected.push(results[i]);
                        }
                    } else {
                        setSoftAlertActive(true);
                        setSoftAlertData({
                            type: 'error',
                            text: {
                                en: 'You cannot select more than 1,000 records.',
                                es: 'No puedes seleccionar más de 1,000 registros.',
                            }
                        });
                        break;
                    }
                }
                setRecordsSelected(temNotSelected);
            }
        }
    }

    const onClickLink = (record) => {
        if (selecting) {
            // Check if the notification is selected or not
            if (iAmSelected(record.id)) {
                const temNotSelected = recordsSelected.filter(element => element.id !== record.id);
                setRecordsSelected(temNotSelected);
            } else {
                const temNotSelected = [...recordsSelected];

                // 999 = Limit of 1,000
                if (temNotSelected.length <= 999) {
                    temNotSelected.push(getRecordById(record.id));
                    setRecordsSelected(temNotSelected);
                } else {
                    setSoftAlertActive(true);
                    setSoftAlertData({
                        type: 'error',
                        text: {
                            en: 'You cannot select more than 1,000 records.',
                            es: 'No puedes seleccionar más de 1,000 registros.',
                        }
                    });
                }
            }
        } else {
            let newControler = null;

            if (controler) {
                newControler = {
                    ...controler,
                    "triggerSearch": false,
                }
            }

            const newState = {
                "results": results,
                "records": records,
                "filter": filter,
                "filterBy": filterBy,
                "comprobanteType": comprobanteType,
                "dgiiEstado": dgiiEstado,
                "docsCount": docsCount,
                "docsCountNoFilter": docsCountNoFilter,
                "controler": newControler,
                "pagesHistory": pagesHistory,
                "navControlers": navControlers,
                "showConnectionError": showConnectionError,
                "empty": empty,
                "showPageNav": showPageNav,
                "activeId": record.id,
                "searchingByTextActive": searchingByTextActive,
                "loading": false,
            }

            navigate(null, { replace: true, state: newState });
            navigate("/ver-factura", { state: {"factura": record} });
        }
    }

    useEffect(() => {
        setEffectControler(true);
        if (location.state) {
            if (refresh === 0) {
                setRefresh(1)
            }

            setControler(location.state.controler);

            if (location.state.controler) {
                setSearchingText(location.state.controler.searchingText);
            }

            setPageHistory(location.state.pagesHistory);
            setNavControlers(location.state.navControlers);
            setShowConnectionError(location.state.showConnectionError);
            setEmpty(location.state.empty);
            setShowPageNav(location.state.showPageNav);
            setDocsCount(location.state.docsCount);
            setDocsCountNoFilter(location.state.docsCountNoFilter);
            setFilter(location.state.filter);
            setFilterBy(location.state.filterBy);
            setComprobanteType(location.state.comprobanteType);
            setDgiiEstado(location.state.dgiiEstado);
            setActiveId(location.state.activeId);
            setSearchingTextActive(location.state.searchingByTextActive);
            setLoading(location.state.loading);
            setRecords(location.state.records);

            let page = null;

            if (location.state.navControlers) {
                setPageNumber(location.state.navControlers.page);
                page = location.state.navControlers.page;
            }

            if (location.controler) {
                if (
                    records &&
                    page === 1 &&
                    location.state.controler.filterBy === "all" &&
                    !location.controler.dateFrom &&
                    !location.controler.dateTo &&
                    location.state.controler.searchingText.trim() === ""
                ) {
                    const recentlyOpenedAccounts = Array.isArray(records) ? records : [];
                    setResults(recentlyOpenedAccounts);

                } else {
                    setResults(location.state.results);
                }
            } else {
                setResults(location.state.results);
            }

            if (!location.state.loading) {
                blink();
            }
        } else {
            setControler({
                "filterBy": "all",
                "comprobanteType": "",
                "dgiiEstado": "",
                "dateFrom": null,
                "dateTo": null,
                "searchingText": "",
                "triggerSearch": false,
            });

            setPageHistory([]);

            setNavControlers({
                "page": 1,
                "totalPage": 1,
                "isPrevious": false,
                "isNext": false
            });

            setShowConnectionError(false);
            setEmpty(false);
            setShowPageNav(false);
            setPageNumber(1);
            blink();
        }
    // eslint-disable-next-line 
    }, []);

    const handleChange = e => {
        setSearchingText(e.target.value);
    }

    let inputRef = null;

    const setRefernce = (inputElement) => {
        inputRef = inputElement;
    }

    const cleanInput = () => {
        try {
            if (inputRef) {
                inputRef.focus();
            }
        } catch (error) {
            console.warn(error);
        }

        setSearchingText("");
    }

    const [searchInputFocus, setSearchInputFocus] = useState(false);

    const [disabled, setDisabled] = useState(true);

    const [dateStringFrom, setDateStringFrom] = useState("");
    const [dateStringTo, setDateStringTo] = useState("");

    const [dateObjFrom, setDateObjFrom] = useState(false);
    const [dateObjTo, setDateObjTo] = useState(false);

    const [showAlertComprobanteType, setShowAlertComprobanteType] = useState(false);
    const [showAlertDgiiEstado, setShowAlertDgiiEstado] = useState(false);
    const [showAlertFrom, setShowAlertFrom] = useState(false);
    const [showAlertTo, setShowAlertTo] = useState(false);

    // eslint-disable-next-line
    const [filterBy, setFilterBy] = useState("all");

    const [comprobanteType, setComprobanteType] = useState("");
    const [dgiiEstado, setDgiiEstado] = useState("");

    useEffect(() => {
        if (filterBy === "all" && (!dateObjFrom) && (!dateObjTo)) {
            if (filter) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            setDisabled(false);
        }
        // eslint-disable-next-line
    }, [filterBy, dateObjFrom, dateObjTo])

    useEffect(() => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
        // eslint-disable-next-line
    }, [document.activeElement]);

    const checkSearchInput = () => {
        if (
            inputRef &&
            inputRef.contains(document.activeElement) &&
            document.activeElement instanceof HTMLInputElement
        ) {
            setSearchInputFocus(true);
        } else {
            setSearchInputFocus(false);
        }
    }

    let observedRef = useRef(null);
    let headerObservedRef = useRef(null);

    const [width, setWidth] = useState(windowDimension.width);
    const [headerHeight, setHeaderHeight] = useState();
    const [filter, setFilter] = useState(null);

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, filter, results, isSideMenuMinimized]);

    const [filterIcon, setFilterIcon] = useState(appTheme.dark ? filterImgDark : filterImgLight);
    const [optionsIcon, setOptionsIcon] = useState(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);

    const [dropDownMenuOpen, setDropDownMenuOpen] = useState(false);
    const [dropDownOptionsOpen, setDropDownOptionsOpen] = useState(false);

    // eslint-disable-next-line
    const onScroll = () => {
        setDropDownMenuOpen(false);
        setDropDownOptionsOpen(false);
    }

    let menuRef = useRef();
    let optionsRef = useRef();

    useEffect(() => {
        if (dropDownMenuOpen) {
            setFilterIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setFilterIcon(appTheme.dark ? filterImgDark : filterImgLight);
        }
    }, [dropDownMenuOpen, appTheme]);

    useEffect(() => {
        if (dropDownOptionsOpen) {
            setOptionsIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setOptionsIcon(appTheme.dark ? adjustmentIconDark : adjustmentIconLight);
        }
    }, [dropDownOptionsOpen, appTheme]);

    useEffect(() => {
        if (dropDownMenuOpen && menuRef) {
            if (menuRef.current) {
                let handler = (e) => {
                    if (!menuRef.current.contains(e.target)) {
                        if (!pickerDateActive) {
                            setDropDownMenuOpen(false);
                        }
                    };
                };
                document.addEventListener("mousedown", handler);
                return () => {
                    document.removeEventListener("mousedown", handler);
                }
            }
        }
    });

    useEffect(() => {
        if (dropDownOptionsOpen) {
            let handler = (e) => {
                if (optionsRef.current) {
                    if (!optionsRef.current.contains(e.target)) {
                        setDropDownOptionsOpen(false);
                    };
                } else {
                    setDropDownOptionsOpen(false);
                }
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickUserButton = () => {
        dropDownMenuOpen ? setDropDownMenuOpen(false) : setDropDownMenuOpen(true);
    }

    const onClickOptionsButton = () => {
        dropDownOptionsOpen ? setDropDownOptionsOpen(false) : setDropDownOptionsOpen(true);
    }

    const esPlaceholder = "Escribe palabra clave aquí...";
    const enPlaceholder = "Write keyword here...";

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            setControler({
                ...controler,
                "searchingText": searchingText,
                "triggerSearch": true,
            });
        }
    }

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.taxes)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    const blink = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const onClickPickFrom = () => {
        setPickerDateActive(true);
        setPickerDateTitle(appLanguage.en ? "Select the date from which you want to filter" : "Selecciona la fecha desde la cual deseas filtrar");
        if (dateObjFrom) {
            setCurrentDate(dateObjFrom);
        }
        setInputName("dateFrom");
    }

    const onClickPickTo = () => {
        setPickerDateActive(true);
        setPickerDateTitle(appLanguage.en ? "Select the date up to which you want to filter" : "Selecciona la fecha hasta la cual deseas filtrar");
        if (dateObjTo) {
            setCurrentDate(dateObjTo);
        }
        setInputName("dateTo");
    }

    useEffect(() => {
        if (inputName) {
            switch (inputName) {
                case "dateFrom":
                    setDateStringFrom(`${currentDate.getDate().toString().padStart(2, '0')} / ${(currentDate.getMonth() + 1).toString().padStart(2, '0')} / ${currentDate.getFullYear()}`);
                    setShowAlertFrom(false);
                    break;
                case "dateTo":
                    setDateStringTo(`${currentDate.getDate().toString().padStart(2, '0')} / ${(currentDate.getMonth() + 1).toString().padStart(2, '0')} / ${currentDate.getFullYear()}`);
                    setShowAlertTo(false);
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, [currentDate]);

    useEffect(() => {
        if (filter) {
            setDateObjFrom(filter.from);
            setDateObjTo(filter.to);

            setFilterBy(filter.filterBy);
            setComprobanteType(filter.comprobanteType);
            setDgiiEstado(filter.dgiiEstado);

            if (filter.from) {
                setDateStringFrom(`${filter.from.getDate().toString().padStart(2, '0')} / ${(filter.from.getMonth() + 1).toString().padStart(2, '0')} / ${filter.from.getFullYear()}`);
            } else {
                setDateStringFrom("");
            }

            if (filter.to) {
                setDateStringTo(`${filter.to.getDate().toString().padStart(2, '0')} / ${(filter.to.getMonth() + 1).toString().padStart(2, '0')} / ${filter.to.getFullYear()}`);
            } else {
                setDateStringTo("");
            }
        } else {
            setFilterBy("all");
            setComprobanteType("");
            setDgiiEstado("");
            setDateObjFrom(null);
            setDateObjTo(null);
            setDateStringFrom("");
            setDateStringTo("");
        }
        setShowAlertFrom(false);
        setShowAlertTo(false);
        // eslint-disable-next-line
    }, [dropDownMenuOpen]);

    const onClickApplyFilter = () => {
        let newDateFrom = null;
        let newDateTo = null;

        if (filterBy !== "all") {
            if (filterBy === "comprobanteType") {
                if (comprobanteType.trim() === "") {
                    setShowAlertComprobanteType(true);
                    return;
                }
            } else {
                if (dgiiEstado.trim() === "") {
                    setShowAlertDgiiEstado(true);
                    return;
                }
            }
        }

        if ((!dateObjFrom) !== (!dateObjTo)) {
            if (!dateObjFrom) {
                setShowAlertFrom(true);
                return;
            }
            if (!dateObjTo) {
                setShowAlertTo(true);
                return;
            }
        }

        if (dateObjFrom) {
            newDateFrom = new Date(dateObjFrom.getTime());
            newDateFrom.setHours(0);
        }

        if (dateObjTo) {
            newDateTo = new Date(dateObjTo.getTime());
            newDateTo.setHours(23, 59, 59, 999);
        }

        setFilter({
            "filterBy": filterBy,
            "comprobanteType": comprobanteType,
            "dgiiEstado": dgiiEstado,
            "from": newDateFrom,
            "to": newDateTo,
        });

        setControler({
            ...controler,
            "filterBy": filterBy,
            "comprobanteType": comprobanteType,
            "dgiiEstado": dgiiEstado,
            "dateFrom": newDateFrom ? newDateFrom.getTime() : null,
            "dateTo": newDateTo ? newDateTo.getTime() : null,
            "searchingText": searchingText,
            "triggerSearch": true,
        });

        setPageHistory([]);

        setDropDownMenuOpen(false);
    }

    const onClickCleanFilter = () => {
        setActiveId(null);
        setResults(records);

        setControler({
            "filterBy": "all",
            "comprobanteType": "",
            "dgiiEstado": "",
            "dateFrom": null,
            "dateTo": null,
            "searchingText": "",
            "triggerSearch": false,
        });

        setPageHistory([]);

        setNavControlers({
            "page": 1,
            "totalPage": docsCountNoFilter ? Math.ceil(docsCountNoFilter / 30) : 1,
            "isPrevious": false,
            "isNext": docsCountNoFilter ? (Math.ceil(docsCountNoFilter / 30) > 1 ? true : false) : false
        });

        setShowConnectionError(false);

        setEmpty(docsCountNoFilter ? (docsCountNoFilter ? false : true) : true);
        setShowPageNav(docsCountNoFilter ? (Math.ceil(docsCountNoFilter / 30) > 1 ? true : false) : false);

        setPageNumber(1);

        setSearchingText("");
        setFilter(null);

        setFilterBy("all");
        setComprobanteType("");
        setDgiiEstado("");
        setDateObjFrom(null);
        setDateObjTo(null);
        setDateStringFrom("");
        setDateStringTo("");

        setSearchingTextActive(false);

        blink();
    }

    const search = async () => {
        setControler({
            ...controler,
            "searchingText": searchingText,
            "triggerSearch": true,
        });
    }

    const onChangeFilterBy = (e) => {
        setFilterBy(e.target.value);
        setComprobanteType("");
        setDgiiEstado("");
    }

    const onChangeComprobanteType = (e) => {
        setShowAlertComprobanteType(false);
        setComprobanteType(e.target.value);
    }

    const onChangeDgiiEstado = (e) => {
        setShowAlertDgiiEstado(false);
        setDgiiEstado(e.target.value);
    }

    let moreRef = useRef();

    const [moreIcon, setMoreIcon] = useState(appTheme.dark ? arrowDarkIcon : arrowLightIcon);

    const [dropDownMoreOpen, setDropDownMoreOpen] = useState(false);

    useEffect(() => {
        if (dropDownMoreOpen) {
            setMoreIcon(appTheme.dark ? xCancelDark : xCancelLight);
        } else {
            setMoreIcon(appTheme.dark ? arrowDarkIcon : arrowLightIcon);
        }
    }, [dropDownMoreOpen, appTheme]);

    const onClickMoreButton = () => {
        dropDownMoreOpen ? setDropDownMoreOpen(false) : setDropDownMoreOpen(true);
    }

    useEffect(() => {
        if (dropDownMoreOpen && moreRef) {
            if (moreRef.current) {
                let handler = (e) => {
                    if (!moreRef.current.contains(e.target)) {
                        setDropDownMoreOpen(false);
                    };
                };
                document.addEventListener("mousedown", handler);
                return () => {
                    document.removeEventListener("mousedown", handler);
                }
            }
        }
    });

    useEffect(() => {
        if (controler) {
            if (
                pageNumber === 1 &&
                records &&
                controler.filterBy === "all" &&
                !controler.dateFrom &&
                !controler.dateTo &&
                controler.searchingText.trim() === ""
            ) {
                if (controler.triggerSearch) {
                    onResetAll();
                }
            } else {
                if (accountData && controler.triggerSearch) {
                    searchFacturas(controler);
                }
            }
        }
    // eslint-disable-next-line 
    }, [controler]);

    function removeAccents(text) {
        const normalizedString = text.normalize("NFD");
        const chainWithoutAccents = normalizedString.replace(/[\u0300-\u036f]/g, "");
        return chainWithoutAccents;
    }

    function optimizeTextForSearch(text) {
        const chainWithoutAccents = removeAccents(text);
        // const lowerCaseText = chainWithoutAccents.toLowerCase();
        const lowerCaseText = chainWithoutAccents.toUpperCase();
        const trimText = lowerCaseText.trim();
        return trimText;
    }

    function getQueryValue(filter) {
        if (filter) {
            if (filter.filterBy === "comprobanteType" ) {
                return filter.comprobanteType;
            } else {
                if (filter.filterBy === "dgiiEstado") {
                    return filter.dgiiEstado;
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    }

    const searchFacturas = async (controler) => {
        setActiveId(null);

        if (
            records &&
            navControlers.page === 1 &&
            controler.filterBy === "all" &&
            !controler.dateFrom &&
            !controler.dateTo &&
            controler.searchingText.trim() === ""
        ) { return; }

        setLoading(true);
        setShowConnectionError(false);

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let q = null;
        let qNoOrder = null;

        if (filter) {
            if (filter.filterBy === "all") {
                if (searchingBy === "") {
                    if (filter.from) {
                        // To get the facturas docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                        );
                    } else {
                        // To get the facturas docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                        );
                    }
                } else {
                    if (filter.from) {
                        // To get the facturas docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                        );
                    } else {
                        // To get the facturas docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                        );
                    }
                }
            } else {
                if (searchingBy === "") {
                    if (filter.from) {
                        // To get the facturas docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    } else {
                        // To get the facturas docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    }
                } else {
                    if (filter.from) {
                        // To get the facturas docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    } else {
                        // To get the facturas docs
                        q = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        qNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    }
                }
            }
        } else {
            if (searchingBy === "") {
                // To get the facturas docs
                q = query(
                    collection(firebase.db, `accounts/${accountData.id}/facturas`),
                    orderBy("time", "desc"),
                    limit(30)
                );
            } else {
                // To get the facturas docs
                q = query(
                    collection(firebase.db, `accounts/${accountData.id}/facturas`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("time", "desc"),
                    limit(30)
                );

                // To get the docsCount to control the page nav
                qNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/facturas`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("time", "desc"),
                );
            }
        }

        try {
            if (document.activeElement) {
                document.activeElement.blur();
            }
        } catch (error) {
            console.warn(error);
        }

        try {
            const snapshot = await getDocsFromServer(q);
            const res = [];

            let newDocsCount = null;

            if (filter || (searchingBy !== "")) {
                const snapshot = await getCountFromServer(qNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            setPageHistory([]);
            setPageNumber(1);

            snapshot.forEach((doc) => {
                if (doc.data()) {
                    const thisDoc = {
                        ...doc.data(),
                        "id": doc.id,
                    }

                    delete thisDoc.searchKeys;

                    res.push(thisDoc)
                }
            });

            if (filter || (searchingBy !== "")) {
                setDocsCount(newDocsCount);
                setShowPageNav((Math.ceil(newDocsCount / 30) > 1 ? true : false));
                setNavControlers({
                    "page": 1,
                    "totalPage": newDocsCount ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": false,
                    "isNext": newDocsCount  ? (Math.ceil(newDocsCount / 30) > 1 ? true : false) : false
                });
            } else {
                setShowPageNav(docsCount ? (Math.ceil(docsCount / 30) > 1 ? true : false) : false);
                setNavControlers({
                    "page": 1,
                    "totalPage": docsCount ? Math.ceil(docsCount / 30) : 1,
                    "isPrevious": false,
                    "isNext": docsCount ? (Math.ceil(docsCount / 30) > 1 ? true : false) : false
                });
            }

            if (res.length > 0) {
                // setEmpty(false);

                updateOrPushHistory({
                    page: 1,
                    startAt: res[0].time,
                    endAt: res[res.length - 1].time
                });
            } else {
                // setEmpty(true);
            }

            setResults(Array.isArray(res) ? res : []);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            setShowConnectionError(true);
        }
    }

    const getPageDetailsByNumber = (n) => {
        if (pagesHistory) {
            for (let i = 0; i < pagesHistory.length; i++) {
                if (pagesHistory[i]["page"] === n) {
                    return pagesHistory[i];
                }
            }
        }
        return null;
    }

    // To get the accounts use the pageHistory to call the funcition to get it.
    const updateOrPushHistory = (updatedObject) => {
        const pagesHistoryCopy = pagesHistory ? [...pagesHistory] : [];
        const page = updatedObject.page;
        const index = pagesHistoryCopy.findIndex(obj => obj["page"] === page);

        if (index !== -1) {
            // If the object exists, update it
            pagesHistoryCopy[index] = { ...pagesHistoryCopy[index], ...updatedObject };
            setPageHistory(pagesHistoryCopy);
        } else {
            // If the object doesn't exist, push a new object to the array
            pagesHistoryCopy.push(updatedObject);
            setPageHistory(pagesHistoryCopy);
        }
    }

    const onResetAll = () => {
        setActiveId(null);
        blink();

        setControler({
            "filterBy": "all",
            "comprobanteType": "",
            "dgiiEstado": "",
            "dateFrom": null,
            "dateTo": null,
            "searchingText": "",
            "triggerSearch": false,
        });

        setPageHistory([]);

        setResults(records);

        setShowPageNav(docsCount ? (Math.ceil(docsCount / 30) > 1 ? true : false) : false);

        setNavControlers({
            "page": 1,
            "totalPage": docsCount ? Math.ceil(docsCount / 30) : 1,
            "isPrevious": false,
            "isNext": docsCount ? (Math.ceil(docsCount / 30) > 1 ? true : false) : false,
        });
        setPageNumber(1);
    }

    async function goToPagePrevious(q, pageN) {
        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            updateOrPushHistory({
                page: pageN,
                startAt: res[0].time,
                endAt: res[res.length - 1].time
            });

            setResults(res);
        }
    }

    const onClickPrevious = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isPrevious === false) {
            return;
        }

        let startAtText = null;

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        const lastPageNumber = navControlers.page - 1;

        if (lastPageNumber === 1 && (!filter) && searchingBy === "") {
            onResetAll();
            return
        }

        const lastPageDetails = getPageDetailsByNumber(lastPageNumber);

        if (!lastPageDetails) {
            console.log("ERROR********************");
        } else {
            startAtText = lastPageDetails.startAt;
        }

        let lastQuery = null;
        let lastQueryNoOrder = null;

        if (filter) {
            if (filter.filterBy === "all") {
                if (searchingBy === "") {
                    if (filter.from) {
                        // To get the facturas docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                        );
                    } else {
                        // To get the facturas docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                        );
                    }
                } else {
                    if (filter.from) {
                        // To get the facturas docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                        );
                    } else {
                        // To get the facturas docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                        );
                    }
                }
            } else {
                if (searchingBy === "") {
                    if (filter.from) {
                        // To get the facturas docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    } else {
                        // To get the facturas docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    }
                } else {
                    if (filter.from) {
                        // To get the facturas docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    } else {
                        // To get the facturas docs
                        lastQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            startAt(startAtText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        lastQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    }
                }
            }
        } else {
            if (searchingBy === "") {
                // To get the facturas docs
                lastQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/facturas`),
                    orderBy("time", "desc"),
                    startAt(startAtText),
                    limit(30)
                );
            } else {
                // To get the facturas docs
                lastQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/facturas`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("time", "desc"),
                    startAt(startAtText),
                    limit(30)
                );

                // To get the docsCount to control the page nav
                lastQueryNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/facturas`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("time", "desc"),
                    startAt(startAtText),
                );
            }
        }

        setLoading(true);

        try {
            await goToPagePrevious(lastQuery, navControlers.page - 1);

            let newDocsCount = null;

            if (filter || (searchingBy !== "")) {
                const snapshot = await getCountFromServer(lastQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page - 1;
            setPageNumber(newPageNumber);

            if (filter || (searchingBy !== "")) {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": newDocsCount ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": newDocsCount ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });

            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": docsCount ? Math.ceil(docsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": docsCount ? (Math.ceil(docsCount / 30) > newPageNumber ? true : false) : false
                })
            }

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }

        setLoading(false);
    }

    async function goToNextPage(q) {

        const snapshot = await getDocsFromServer(q);
        const res = [];

        snapshot.forEach((doc) => {
            if (doc.data()) {
                const thisDoc = {
                    ...doc.data(),
                    "id": doc.id,
                }
                delete thisDoc.searchKeys;
                res.push(thisDoc)
            }
        });

        if (!(res.length > 0)) {
            setResults([]);
        } else {
            if (pagesHistory) {
                if (!(pagesHistory.length > 0)) {
                    const newHistory = [
                        {
                            page: 1,
                            startAt: results[0].time,
                            endAt: results[results.length - 1].time
                        },
                        {
                            page: navControlers.page + 1,
                            startAt: res[0].time,
                            endAt: res[res.length - 1].time
                        }
                    ];

                    setPageHistory(newHistory);
                } else {
                    updateOrPushHistory({
                        page: navControlers.page + 1,
                        startAt: res[0].time,
                        endAt: res[res.length - 1].time
                    });
                }
            } else {
                updateOrPushHistory({
                    page: navControlers.page + 1,
                    startAt: res[0].time,
                    endAt: res[res.length - 1].time
                });
            }
            setResults(res);
        }
    }

    const onClickNext = async e => {
        e.preventDefault();

        setActiveId(null);

        if (navControlers.isNext === false) {
            return;
        }

        let startAfterOrAfterText = null;
        let useStartAt = null;

        const nextPageNumber = navControlers.page + 1;
        const nextPageDetails = getPageDetailsByNumber(nextPageNumber);

        if (!nextPageDetails) {
            const lastProduct = results[results.length - 1];
            startAfterOrAfterText = lastProduct.time;
            useStartAt = false;
        } else {
            startAfterOrAfterText = nextPageDetails.startAt;
            useStartAt = true;
        }

        const searchingBy = optimizeTextForSearch(controler.searchingText);

        let nextQuery = null;
        let nextQueryNoOrder = null;

        if (filter) {
            if (filter.filterBy === "all") {
                if (searchingBy === "") {
                    if (filter.from) {
                        // To get the facturas docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                        );
                    } else {
                        // To get the facturas docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                        );
                    }
                } else {
                    if (filter.from) {
                        // To get the facturas docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                        );
                    } else {
                        // To get the facturas docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                        );
                    }
                }
            } else {
                if (searchingBy === "") {
                    if (filter.from) {
                        // To get the facturas docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    } else {
                        // To get the facturas docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    }
                } else {
                    if (filter.from) {
                        // To get the facturas docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where("time", ">=", filter.from.getTime()),
                            where("time", "<=", filter.to.getTime()),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    } else {
                        // To get the facturas docs
                        nextQuery = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                            useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                            limit(30)
                        );

                        // To get the docsCount to control the page nav
                        nextQueryNoOrder = query(
                            collection(firebase.db, `accounts/${accountData.id}/facturas`),
                            where("searchKeys", "array-contains", searchingBy),
                            orderBy("time", "desc"),
                            where(filter.filterBy, "==", getQueryValue(filter)),
                        );
                    }
                }
            }
        } else {
            if (searchingBy === "") {
                // To get the facturas docs
                nextQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/facturas`),
                    orderBy("time", "desc"),
                    useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                    limit(30)
                );
            } else {
                // To get the facturas docs
                nextQuery = query(
                    collection(firebase.db, `accounts/${accountData.id}/facturas`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("time", "desc"),
                    useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                    limit(30)
                );

                // To get the docsCount to control the page nav
                nextQueryNoOrder = query(
                    collection(firebase.db, `accounts/${accountData.id}/facturas`),
                    where("searchKeys", "array-contains", searchingBy),
                    orderBy("time", "desc"),
                    useStartAt ? startAt(startAfterOrAfterText) : startAfter(startAfterOrAfterText),
                );
            }
        }

        setLoading(true);

        try {
            await goToNextPage(nextQuery);

            let newDocsCount = null;

            if (filter || (searchingBy !== "")) {
                const snapshot = await getCountFromServer(nextQueryNoOrder);
                newDocsCount = snapshot.data().count;
            }

            setSearchingTextActive(searchingBy ? (searchingBy !== "" ? true : false) : false);

            const newPageNumber = navControlers.page + 1;
            setPageNumber(newPageNumber);

            if (filter || (searchingBy !== "")) {
                setDocsCount(newDocsCount);
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": newDocsCount ? Math.ceil(newDocsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": newDocsCount ? (Math.ceil(newDocsCount / 30) > newPageNumber ? true : false) : false
                });

            } else {
                setNavControlers({
                    "page": newPageNumber,
                    "totalPage": docsCount ? Math.ceil(docsCount / 30) : 1,
                    "isPrevious": newPageNumber === 1 ? false : true,
                    "isNext": docsCount ? (Math.ceil(docsCount / 30) > newPageNumber ? true : false) : false
                })
            }
        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Connection issues',
                    es: 'Problemas de conexión',
                }
            });
        }
        setLoading(false);
    }

    const getShowMore = () => {
        if (width > 950) {
            return false;
        } else {
            return true;
        }
    }

    const whereToShowComprobantesBtn = () => {
        if (width > 950) {
            return "onTop";
        } else {
            return "onMoreOptions";
        }
    }

    // const whereToShowCreateInvoiceBtn = () => {
    //     if (width > 1055) {
    //         return "onTop";
    //     } else {
    //         return "onMoreOptions";
    //     }
    // }

    const onClickComprobantesBtn = () => {
        navigate("/comprobantes-de-facturas")
    }

    // const onClickCreateInvoiceBtn = () => {
    //     // navigate(null, { state: null })
    // }

    const getFilterByTitle = (filter) => {
        if (filter.filterBy === "comprobanteType") {
            switch (filter.comprobanteType) {
                case "pending":
                    return appLanguage.en ? "Pending comprobante" : "Comprobante pendiente";
                case "00":
                    return "Factura informal";
                case "01":
                    return `01 - ${getComprobanteTitleById("01")}`;
                case "02":
                    return `02 - ${getComprobanteTitleById("02")}`;
                default:
                    return "";
            }
        } else {
            if (filter.filterBy === "dgiiEstado") {
                switch (filter.dgiiEstado) {
                    case "sent":
                        return appLanguage.en ? "Comprobantes sent" : "Comprobantes enviados";
                    default:
                        return appLanguage.en ? "Comprobantes to be send" : "Comprobantes por enviar";
                }
            } else {
                return "";
            }
        }
    }

    useEffect(() => {
        let newControler = null;

        if (controler) {
            newControler = {
                ...controler,
                "triggerSearch": false,
            }
        }

        const newState = {
            "results": results,
            "records": records,
            "filter": filter,
            "filterBy": filterBy,
            "comprobanteType": comprobanteType,
            "dgiiEstado": dgiiEstado,
            "docsCount": docsCount,
            "docsCountNoFilter": docsCountNoFilter,
            "controler": newControler,
            "pagesHistory": pagesHistory,
            "navControlers": navControlers,
            "showConnectionError": showConnectionError,
            "empty": empty,
            "showPageNav": showPageNav,
            "activeId": activeId,
            "searchingByTextActive": searchingByTextActive,
            "loading": false,
        }
        navigate(null, { replace: true, state: newState });

        if (accessTo) {
            if (!accessTo.taxes) {
                navigate("/", { replace: true })
            }
        }
    // eslint-disable-next-line
    }, [results, records, controler, pagesHistory, navControlers, showConnectionError, empty, showPageNav, activeId, filter, filterBy, docsCount, searchingByTextActive, comprobanteType, dgiiEstado]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Business invoices' : 'Facturas del negocio'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo && controler ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`} ref={observedRef}>
                            {accessTo.taxes ? <Fragment>
                                <div>
                                    <InputPickerDate pickerWidth={width} />

                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? 'Business invoices' : 'Facturas del negocio'}
                                        />
                                    : null}

                                    <div ref={headerObservedRef} className="products-services-first-section">
                                        <div className="products-services-first-item" style={{ maxWidth: "950px" }}>
                                            <div className="div-search-input-home" style={{ marginTop: 0, maxWidth: "400px" }}>
                                                <input
                                                    ref={setRefernce}
                                                    type="search"
                                                    onBlur={checkSearchInput}
                                                    onFocus={checkSearchInput}
                                                    autoComplete="off"
                                                    autoCorrect="off"
                                                    className={`search-input-home ${appTheme.dark ? "search-input-home-dar dar-sec-bg" : "search-input-home-lig lig-sec-bg"}`}
                                                    value={searchingText}
                                                    onChange={e => handleChange(e)}
                                                    name="searchingText"
                                                    onKeyDown={e => handleKeyPress(e)}
                                                    maxLength={30}
                                                    placeholder={appLanguage.en ? enPlaceholder : esPlaceholder}
                                                    style={{
                                                        colorScheme: appTheme.dark ? "dark" : "light"
                                                    }}
                                                />
                                                {searchingText === "" ? null :
                                                    <div className={`search-div-delete ${searchInputFocus ? (appTheme.dark ? "search-button-home-dar-focus" : "search-button-home-lig-focus") : (appTheme.dark ? "search-button-home-dar" : "search-button-home-lig")} ${appTheme.dark ? " dar-sec-bg" : "lig-sec-bg"}`}>
                                                        <button onClick={cleanInput} className={appTheme.dark ? "search-div-delete-button-dark" : "search-div-delete-button-light"}>
                                                            <img src={appTheme.dark ? crossWhiteIcon : crossIcon} alt="delete" />
                                                        </button>
                                                    </div>
                                                }
                                                <button
                                                    className="search-button-home"
                                                    type="submit"
                                                    onClick={search}
                                                >
                                                    <img src={magnifyingIcon} alt="Search box" />
                                                </button>
                                            </div>

                                            <div className="sales-filter-botton-main unselectable">

                                                {!selecting ?
                                                    <div ref={menuRef} className="sales-filter-botton-container">

                                                        <button
                                                            onClick={onClickUserButton}
                                                            className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                            style={{
                                                                width: smallView ? "60px" : "100px",
                                                                minHeight: "38.4px"
                                                            }}
                                                        >
                                                            <img
                                                                style={{ width: "20px", height: "20px", marginRight: smallView ? 0 : "12px" }}
                                                                src={filterIcon}
                                                                alt="Filter"
                                                            />
                                                            {smallView ? null : <p style={dropDownMenuOpen ? { fontWeight: "bold" } : {}}>{appLanguage.en ? "Filter" : "Filtrar"}</p>}
                                                        </button>

                                                        <span
                                                            className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownMenuOpen ? 'active' : 'inactive'}`}
                                                            style={{
                                                                marginLeft: smallView ? "43px" : "60px",
                                                            }}
                                                        />

                                                        <div
                                                            style={{
                                                                boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                marginLeft: "25px",
                                                            }}
                                                            className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"} ${dropDownMenuOpen ? 'active' : 'inactive'}`}
                                                        >
                                                            {smallView ? <p style={{ textAlign: "center", marginBottom: "10px" }}><b>{appLanguage.en ? "Filter invoices" : "Filtrar facturas"}</b></p> : null}

                                                            <label className="filter-labels-inputs" htmlFor="filterBy">{appLanguage.en ? "Filter by" : "Filtrar por"}</label>
                                                            <select
                                                                style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                                className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg input-border-box-dark" : "lig-pri-bg input-border-box-light"}`}
                                                                name="filterBy"
                                                                id="filterBy"
                                                                value={filterBy}
                                                                onChange={e => onChangeFilterBy(e)}
                                                            >
                                                                <option value="all">{appLanguage.en ? "All" : "Todo"}</option>
                                                                <option value="comprobanteType">{"Tipo de Comprobante"}</option>
                                                                <option value="dgiiEstado">{"Estado en la DGII"}</option>
                                                            </select>

                                                            {filterBy !== "all" ? <Fragment>
                                                                {filterBy === "comprobanteType" ? <Fragment>
                                                                    <label className="filter-labels-inputs" htmlFor="comprobanteType">{"Seleccione el Tipo"}</label>
                                                                    <select
                                                                        style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                                        className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"} ${showAlertComprobanteType ? "border-date-alert" : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")}`}
                                                                        name="comprobanteType"
                                                                        id="comprobanteType"
                                                                        value={comprobanteType}
                                                                        onChange={e => onChangeComprobanteType(e)}
                                                                    >
                                                                        <option value="">{appLanguage.en ? "-- select --" : "-- seleccionar --"}</option>
                                                                        <option value={"pending"}>{appLanguage.en ? "Pending" : "Pendiente"}</option>
                                                                        <option value={"00"}>{appLanguage.en ? "Informal" : "Informal"}</option>
                                                                        <option value={"01"}>01 - {getComprobanteTitleById("01")}</option>
                                                                        <option value={"02"}>02 - {getComprobanteTitleById("02")}</option>
                                                                    </select>
                                                                </Fragment> : <Fragment>
                                                                    <label className="filter-labels-inputs" htmlFor="dgiiEstado">{"Seleccione el Estado"}</label>
                                                                    <select
                                                                        style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                                                        className={`filter-select-input-sales ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"} ${showAlertDgiiEstado ? "border-date-alert" : (appTheme.dark ? "input-border-box-dark" : "input-border-box-light")}`}
                                                                        name="dgiiEstado"
                                                                        id="dgiiEstado"
                                                                        value={dgiiEstado}
                                                                        onChange={e => onChangeDgiiEstado(e)}
                                                                    >
                                                                        <option value="">{appLanguage.en ? "-- select --" : "-- seleccionar --"}</option>
                                                                        <option value="toSend">{appLanguage.en ? "To send" : "Por enviar"}</option>
                                                                        <option value="sent">{appLanguage.en ? "Sent" : "Enviado"}</option>
                                                                    </select>
                                                                </Fragment>}
                                                            </Fragment> : null}

                                                            <label className="filter-labels-inputs">{appLanguage.en ? "From " : "Desde "} {showAlertFrom ? (appLanguage.en ? "** invalid **" : "** inválida **") : null}</label>
                                                            <InputTypeDate
                                                                dateObj={dateObjFrom}
                                                                setDateObj={setDateObjFrom}
                                                                dateString={dateStringFrom}
                                                                setDateString={setDateStringFrom}
                                                                showAlert={showAlertFrom}
                                                                setShowAlert={setShowAlertFrom}
                                                                onClickPick={onClickPickFrom}
                                                            />

                                                            <label className="filter-labels-inputs">{appLanguage.en ? "To" : "Hasta"} {showAlertTo ? (appLanguage.en ? "** invalid **" : "** inválida **") : null}</label>
                                                            <InputTypeDate
                                                                dateObj={dateObjTo}
                                                                setDateObj={setDateObjTo}
                                                                dateString={dateStringTo}
                                                                setDateString={setDateStringTo}
                                                                showAlert={showAlertTo}
                                                                setShowAlert={setShowAlertTo}
                                                                onClickPick={onClickPickTo}
                                                            />

                                                            <div className="filter-button-action-div">
                                                                <button
                                                                    disabled={disabled}
                                                                    onClick={onClickApplyFilter}
                                                                    className="filter-button-btn-div"
                                                                    style={disabled ? {
                                                                        backgroundColor: "#3f526698",
                                                                        color: appTheme.dark ? "rgb(173, 173, 173)" : "rgba(255, 255, 255, 0.712)",
                                                                        cursor: "default",
                                                                        boxShadow: "none",
                                                                    } : null}
                                                                >
                                                                    {appLanguage.en ? "Apply" : "Aplicar"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}

                                                {selecting ?
                                                    <div ref={optionsRef} className="sales-filter-botton-container">
                                                        <button
                                                            onClick={onClickOptionsButton}
                                                            style={{
                                                                margin: "0px 10px 0px 10px",
                                                                paddingLeft: "8px",
                                                                paddingRight: "8px",
                                                                width: "90px",
                                                            }}
                                                            className={`filer-sales-btn ${appTheme.dark ? `${isTouchEnabled() ? "filer-sales-btn-dark-no-hover" : "filer-sales-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "filer-sales-btn-light-no-hover" : "filer-sales-btn-light"} border-box-light`}`}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: "20px",
                                                                    height: "20px",
                                                                    marginRight: "10px"
                                                                }}
                                                                src={optionsIcon}
                                                                alt="Options"
                                                            />
                                                            <p style={dropDownOptionsOpen ? { fontWeight: "bold" } : {}}>{recordsSelected ? recordsSelected.length : ""}</p>
                                                        </button>

                                                        <span
                                                            className={`sales-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownOptionsOpen ? 'active' : 'inactive'}`}
                                                            style={{
                                                                marginLeft: "45px",
                                                            }}
                                                        />

                                                        <div
                                                            style={{
                                                                boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                marginLeft: "13px",
                                                                width: "210px",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}
                                                            className={`dropdown-menu-sales ${appTheme.dark ? "dropdown-menu-sales-dark border-box-dark" : "dropdown-menu-sales-light border-box-light"}   ${dropDownOptionsOpen ? 'active' : 'inactive'}`}
                                                        >
                                                            <button
                                                                style={{ marginTop: "20px" }}
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={null}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Download 1" : "Descargar 1"} </p>
                                                            </button>

                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={null}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Download 2" : "Descargar 2"} </p>
                                                            </button>

                                                            <button
                                                                className="invent-add-menu-btn"
                                                                disabled={loading ? true : false}
                                                                onClick={null}
                                                            >
                                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Download 3" : "Descargar 3"} </p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    : null}

                                                <button
                                                    className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                    style={{
                                                        margin: "20px 10px 0 10px",
                                                        height: "38.4px"
                                                    }}
                                                    disabled={loading ? true : false}
                                                    onClick={onClickSelect}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={selecting}
                                                        onChange={onClickSelect}
                                                        name='customerAccounts'
                                                        id='customerAccounts'
                                                        style={{
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                            marginRight: "10px",
                                                        }}
                                                    />
                                                    <p>{appLanguage.en ? "Select" : "Seleccionar"}</p>

                                                </button>

                                                {whereToShowComprobantesBtn() === "onTop" ?
                                                    <div className="customer-header-button-div" style={{ marginTop: "20px" }}>
                                                        <Link to={"/comprobantes-de-facturas"} className="customer-header-button-link" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                                            <img src={termsCondIconDark} alt="Comprobantes" />
                                                            <p style={{ whiteSpace: "nowrap" }}><b>{"Comprobantes"}</b></p>
                                                        </Link>
                                                    </div>
                                                    : null}

                                                {/*whereToShowCreateInvoiceBtn() === "onTop" ?
                                                    <div className="customer-header-button-div" style={{ marginTop: "20px" }}>
                                                        <Link to={null} className="customer-header-button-link" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                                            <img src={addImgDark} alt="Create invoice" />
                                                            <p style={{ whiteSpace: "nowrap" }}><b>{appLanguage.en ? "Create invoice" : "Crear factura"}</b></p>
                                                        </Link>
                                                    </div>
                                                : null*/}

                                                {getShowMore() ?
                                                    <div ref={moreRef} className="notification-filter-botton-container unselectable">
                                                        <button
                                                            className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                            onClick={onClickMoreButton}
                                                            style={{
                                                                margin: "20px 10px 0px",
                                                                minHeight: "38.4px",
                                                            }}
                                                        >
                                                            <img
                                                                src={moreIcon}
                                                                style={{
                                                                    width: "18px",
                                                                    height: "18px",
                                                                }}
                                                                alt="More options"
                                                            />
                                                        </button>

                                                        <span
                                                            className={`notification-pointing-up ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"} ${dropDownMoreOpen ? 'active' : 'inactive'}`}
                                                            style={{
                                                                marginLeft: "30px",
                                                                marginTop: "7px",
                                                            }}
                                                        />

                                                        <div
                                                            style={{
                                                                boxShadow: appTheme.dark ? "2px 2px 2px 2px rgba(0, 0, 0, 0.2)" : "1px 1px 1px 0.8px rgba(0, 0, 0, 0.2)",
                                                                marginLeft: "-181px",
                                                                marginTop: "11px",
                                                                width: "220px",
                                                                minHeight: "auto"
                                                            }}
                                                            className={`dropdown-menu-notification ${appTheme.dark ? "dropdown-menu-notification-dark border-box-dark" : "dropdown-menu-notification-light border-box-light"} ${dropDownMoreOpen ? 'active' : 'inactive'}`}
                                                        >
                                                            <div
                                                                style={{
                                                                    paddingTop: "13px"
                                                                }}
                                                            >
                                                                {whereToShowComprobantesBtn() === "onMoreOptions" ?
                                                                    <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                        <button
                                                                            className="invent-add-menu-btn"
                                                                            disabled={loading ? true : false}
                                                                            onClick={onClickComprobantesBtn}
                                                                            style={{
                                                                                justifyContent: "flex-start",
                                                                                marginBottom: "17px"
                                                                            }}
                                                                        >
                                                                            <img
                                                                                style={{
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                    marginRight: "10px",
                                                                                    marginLeft: "18px"
                                                                                }}
                                                                                src={termsCondIconDark}
                                                                                alt=""
                                                                            />
                                                                            <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{"Comprobantes"} </p>
                                                                        </button>
                                                                    </div>
                                                                : null}

                                                                {/*whereToShowCreateInvoiceBtn() === "onMoreOptions" ?
                                                                    <div className="drop-men-not-option-con" style={{ display: "flex", justifyContent: "center" }}>
                                                                        <button
                                                                            className="invent-add-menu-btn"
                                                                            disabled={loading ? true : false}
                                                                            onClick={onClickCreateInvoiceBtn}
                                                                            style={{
                                                                                justifyContent: "flex-start",
                                                                                marginBottom: "15px"
                                                                            }}
                                                                        >
                                                                            <img
                                                                                style={{
                                                                                    width: "20px",
                                                                                    height: "20px",
                                                                                    marginRight: "10px",
                                                                                    marginLeft: "18px"
                                                                                }}
                                                                                src={addImgDark}
                                                                                alt=""
                                                                            />
                                                                            <p style={{ whiteSpace: "nowrap", fontSize: "14px", fontWeight: 500 }}>{appLanguage.en ? "Create invoice" : "Crear factura"} </p>
                                                                        </button>
                                                                    </div>
                                                                : null*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>

                                            {filter ?
                                                <div className={`filter-sales-details-main-con ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}>
                                                    <div className="filter-sales-details-data">
                                                        {getFilterByTitle(filter) === "" ?
                                                            <p style={{ color: appTheme.dark ? "#74b7ff" : "#1083ff", marginBottom: "2px" }}><b>{appLanguage.en ? "Search" : "Búsqueda"}</b></p>
                                                        : null}

                                                        {filter.from ?
                                                            (filter.from.getTime() > filter.to.getTime() ?
                                                                <p style={{ fontSize: "12px", color: "rgba(255, 0, 0, 0.76)" }}><b>*** {appLanguage.en ? "Invalid Date Range" : "Rango de fechas no válido"} ***</b></p>
                                                            :
                                                                <p style={{ fontSize: "12px" }}><b>{getDateWithMonthShort(filter.from)} - {getDateWithMonthShort(filter.to)}</b></p>
                                                            )
                                                        : 
                                                            <p style={{ color: appTheme.dark ? "#74b7ff" : "#1083ff" }}><b>{appLanguage.en ? "Search" : "Búsqueda"}</b></p>
                                                        }
    
                                                        <p style={{ fontSize: "15px" }}>{getFilterByTitle(filter)}</p>
                                                    </div>
    
                                                    <button className="filter-sales-details-clean-filter-btn" onClick={onClickCleanFilter}>
                                                        <img
                                                            style={{ width: "16px", height: "16px", marginRight: "16px" }}
                                                            src={appTheme.dark ? crossWhiteIcon : crossIcon}
                                                            alt="Clean filter"
                                                        />
                                                    </button>
                                                </div>
                                            :
                                                searchingByTextActive ?
                                                    <div className={`filter-sales-details-main-con ${appTheme.dark ? "border-box-dark" : "border-box-light"}`}>
                                                        <div className="filter-sales-details-data">
                                                            <p style={{ color: appTheme.dark ? "#74b7ff" : "#1083ff" }}><b>{appLanguage.en ? "Search" : "Búsqueda"}</b></p>
                                                            <p style={{ fontSize: "15px" }}>{appLanguage.en ? "In all records" : "En todos los registros"}</p>
                                                        </div>
                                                        <button className="filter-sales-details-clean-filter-btn" onClick={onClickCleanFilter}>
                                                            <img
                                                                style={{ width: "16px", height: "16px", marginRight: "16px" }}
                                                                src={appTheme.dark ? crossWhiteIcon : crossIcon}
                                                                alt="Clean filter"
                                                            />
                                                        </button>
                                                    </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="sales-records-main-cont"
                                    style={{
                                        padding: "0px",
                                        width: "100%",
                                        marginTop: "10px"
                                    }}
                                >
                                    <div
                                        style={{
                                            marginTop: 0,
                                            width: "100%",
                                            maxWidth: selecting ? "996px" : "952px",
                                            display: "flex",
                                            flexDirection: "column",
                                            margin: "0px",
                                        }}
                                    >
                                        <FacturasSelectTable
                                            onScroll={onScroll}
                                            headerHeight={headerHeight}
                                            loading={loading}
                                            showConnectionError={showConnectionError}
                                            tryAgain={onTryAgain}
                                            results={results}
                                            onClickLink={onClickLink}
                                            activeId={activeId}
                                            setActiveReference={setActiveReference}
                                            pageNumber={pageNumber}
                                            navControlers={navControlers}
                                            showPageNav={showPageNav}
                                            onClickNext={onClickNext}
                                            onClickPrevious={onClickPrevious}
                                            dropDownMoreOpen={dropDownMoreOpen}
                                            dropDownMenuOpen={dropDownMenuOpen}
                                            dropDownOptionsOpen={dropDownOptionsOpen}
                                            selecting={selecting}
                                            iAmSelected={iAmSelected}
                                            onClickSelectAll={onClickSelectAll}
                                            selectingAll={selectingAll}
                                            empty={empty}
                                        />
                                    </div>
                                </div>
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    );
}

export default InvoicesPage;