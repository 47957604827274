import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const SaleMoreOptionsContext = createContext();

const SaleMoreOptionsProvider = (props) => {

    const location = useLocation();

    const [ saleMoreOptionsActive, setSaleMoreOptionsActive ] = useState(false);
    const [ option, setOption ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        if (saleMoreOptionsActive) {
            if ((location.pathname !== "/resumen-de-venta") && (!option === "leavePending")) {
                setSaleMoreOptionsActive(false);
                resetOptionsState();
            }
        }
    // eslint-disable-next-line
    }, [location, option]);
    
    const [ firstAmountAlert, setFirstAmountAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [ secondAmountAlert, setSecondAmountAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    // For apply discount

    // page #1 Select discount type
    // page #2 Define amount to be discounted
    // page #3 Define percentage to be discounted
    const [ discountOptionPage, setDiscountOptionPage ] = useState(1);

    const [ discountValue, setDiscountValue ] = useState("");
    const [ discountNote, setDiscountNote ] = useState("");

    const [totalWithDiscount, setTotalWithDiscount] = useState("");

    const [ discountValueAlert, setDiscountValueAlert ] = useState({
        alert: false,
        enText: '',
        esText: '',
    });

    const [ discountNoteAlert, setDiscountNoteAlert ] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const resetOptionsState = () => {
        setFirstAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });
        setSecondAmountAlert({
            alert: false,
            enText: '',
            esText: ''
        });
        
        setDiscountOptionPage(1);
        setDiscountValue("");
        setDiscountNote("");
        setTotalWithDiscount("");
        setDiscountValueAlert({
            alert: false,
            enText: '',
            esText: ''
        });
        setDiscountNoteAlert({
            alert: false,
            enText: '',
            esText: ''
        });
    }

    useEffect(() => {
        if (!saleMoreOptionsActive) {
            resetOptionsState();
        }
    }, [saleMoreOptionsActive]);

    return (
        <SaleMoreOptionsContext.Provider
            value={{ 
                saleMoreOptionsActive,
                option,
                loading,
                firstAmountAlert,
                secondAmountAlert,
                discountOptionPage,
                discountValue,
                discountNote,
                discountValueAlert,
                discountNoteAlert,
                totalWithDiscount, 
                setSaleMoreOptionsActive,
                setOption,
                setLoading,
                setFirstAmountAlert,
                setSecondAmountAlert,
                setDiscountOptionPage,
                setDiscountValue,
                setDiscountNote,
                setDiscountValueAlert,
                setDiscountNoteAlert,
                setTotalWithDiscount,
                resetOptionsState
            }}
        >
            {props.children}
        </SaleMoreOptionsContext.Provider>
    )
}

export default SaleMoreOptionsProvider;