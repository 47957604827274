import { Fragment, useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthContext } from "../../../firebase/context";
import { Helmet } from "react-helmet-async";
import SettingsActionRequired from "../../../components/settings-action-required/settingsActionRequired";
import { AppContext } from "../../../context/AppContext";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';

import { uid } from "uid";

import firebase from "../../../firebase";
import { doc, getDocFromServer, getCountFromServer, collection } from "firebase/firestore";

import { SideMenuContext } from "../../../context/SideMenuContext";
import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

import noPicture from "../../../img/no-picture.png";

import crossWhiteIcon from "../../../icons/cross-white.png";
import saveIcon from "../../../icons/check-dark.png";

import closeLightIcon from '../../../icons/cross.png';
import closeDarkIcon from '../../../icons/cross-white.png';

import warningIcon from "../../../img/warning.svg";

import { AlertContext } from "../../../components/alert/alertContext";

import Spinner from "../../../components/spinner/spinner";

import "./createProduct.page.scss";
import ConnectionError from "../../../components/connection-error/connectionError";
import Spinner2 from "../../../components/spinner2/spinner2";

import dollarSignDark from "../../../icons/dollar-sign-dark.png";

const CreateProductPage = () => {

    let observedRef = useRef(null);
    let pageObserverRef = useRef(null);

    let menuRef = useRef();

    const [calculatePriceActive, setCalculatePriceActive] = useState(false);

    useEffect(() => {
        if (calculatePriceActive) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    setCalculatePriceActive(false);
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const onClickCalculatePrice = () => {
        setCalculatePriceActive(true);
    }

    const onClickClose = () => {
        setCalculatePriceActive(false);
    }

    const { accountData, accessTo, addToShoppingCart } = useContext(AuthContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);
    const { appLanguage, appTheme, formatRationalNumber, getCategory, getThisBusinessProductsCategories, getUnitsOfMeasurementFor, getRatePer, isTouchEnabled } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const location = useLocation();

    const { setAlertData, setAlertActive, getErrorDescription } = useContext(AlertContext);

    const [ loading, setLoading ] = useState(true);

    const [loadingBarcode, setLoadingBarcode] = useState(false);

    const [doNotDefineQuantity, setDoNotDefineQuantity] = useState(false);

    const [disabled, setDisabled] = useState(true);
    const [duplicatedBarcode, setDuplicatedBarcode] = useState(null);

    const [productLimits, setProductLimits] = useState(null);
    const [ task, setTask ] = useState(null);
    
    const [showConnectionError, setShowConnectionError] = useState(false);

    const [pageWidth, setPageWidth] = useState(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return windowDimension.width;
            } else {
                try {
                    const width = pageObserverRef.current.offsetWidth
                    return width;
                } catch {
                    return windowDimension.width;
                }
            }
        } else {
            return windowDimension.width;
        }
    });

    useEffect(() => {
        if (pageObserverRef) {
            if (!pageObserverRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (pageObserverRef.current.offsetWidth !== width) {
                        setPageWidth(pageObserverRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(pageObserverRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [pageObserverRef.current, windowDimension]);

    const doNotDefineQuantityHandler = () => {
        setQuantityAlert({
            alert: false,
            enText: '',
            esText: ''
        });
        
        setDoNotDefineQuantity(!doNotDefineQuantity);
    }

    const [ fieldAlertRef, setFieldAlertRef ] = useState(null);
    const fieldAlertReference = (inputElement)=>{
        setFieldAlertRef(inputElement);
    }

    useEffect(() => {
        if (fieldAlertRef) {
            fieldAlertRef.scrollIntoView({behavior: "smooth"});
        }
    // eslint-disable-next-line 
    }, [fieldAlertRef]);

    const navigate = useNavigate();

    const inputsNames = {
        "name": uid(8),
        "description": uid(8),
        "quantity": uid(8),
        "location": uid(8),
        "barcode": uid(8),
        "cost": uid(8),
        "priceWithoutTax": uid(8),
        "itbisPercentage": uid(8),
        "finalPrice": uid(8),
    }

    const getInputName = (uid) => {
        switch (uid) {
            case inputsNames.name:
                return "name";
            case inputsNames.description:
                return "description";
            case inputsNames.quantity:
                return "quantity";
            case inputsNames.location:
                return "location";
            case inputsNames.barcode:
                return "barcode";
            case inputsNames.cost:
                return "cost";
            case inputsNames.priceWithoutTax:
                return "priceWithoutTax";
            case inputsNames.itbisRate:
                return "itbisPercentage";
            case inputsNames.finalPrice:
                return "finalPrice";
            default:
                return uid;
        }
    }

    const [newProduct, setNewProduct] = useState({
        "name": "",
        "category": "general",
        "rate": "perUnit",
        "description": "",
        "quantity": "",
        "location": "",
        "barcode": "",
        "cost": "",
        "priceWithoutTax": "",
        "itbisPercentage": "0%",
        "itbisAmount": "",
        "finalPrice": "",
    });

    useEffect(() => {
        if(
            newProduct.name === "" &&
            newProduct.category === "general" &&
            newProduct.rate === "perUnit" &&
            newProduct.description === "" &&
            newProduct.quantity === "" &&
            newProduct.location === "" &&
            newProduct.barcode === "" &&
            newProduct.cost === "" &&
            newProduct.priceWithoutTax === "" &&
            newProduct.itbisPercentage === "0%" &&
            newProduct.itbisAmount === "" &&
            newProduct.finalPrice === ""
        ) {
            setDisabled(true);
        } else {
            setDisabled(false)
        }
    }, [newProduct]);

    useEffect(() => {
        if (accountData) {
            if (!accountData.active) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accountData]);

    useEffect(() => {
        if (accessTo) {
            if (!(accessTo.inventory)) {
                navigate("/", { replace: true })
            }
        }
        // eslint-disable-next-line
    }, [accessTo]);

    // using the SideMenuContext calculate the initial state
    const [width, setWidth] = useState(desktopView ? windowDimension.width - (isSideMenuMinimized ? 137.6  : 327.6) : windowDimension.width);

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    let headerObservedRef = useRef(null);
    const [headerHeight, setHeaderHeight] = useState(68);
    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, width, isSideMenuMinimized]);

    function calculatePriceWithoutTax(totalWithTax, taxPercentage) {
        // Convert the tax percentage to a decimal
        let taxRate = taxPercentage / 100;
        // Calculate the price without tax
        let priceWithoutTax = totalWithTax / (1 + taxRate);
        return priceWithoutTax;
    }

    useEffect(() => {
        if(location.state) {
            if (location.state.task) {
                setTask(location.state.task)
            }

            if (location.state.product) {
                setNewProduct(location.state.product);
            }
        }
    // eslint-disable-next-line
    }, [])

    const [nameAlert, setNameAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [quantityAlert, setQuantityAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const [priceAlert, setPriceAlert] = useState({
        alert: false,
        enText: '',
        esText: ''
    });

    const onSelectPrice = (newCost, newPrice) => {
        setPriceAlert({
            alert: false,
            enText: '',
            esText: ''
        });

        const cost = parseFloat(newCost.replace(/,/g, ""));
        const price = parseFloat(newPrice.replace(/,/g, ""));

        if (
            (typeof cost === 'number' && cost >= 0) &&
            (typeof price === 'number' && price >= 0)
        ) {
            let itbisPercentage = 0;

            if (newProduct.itbisPercentage === "16%") {
                itbisPercentage = 0.16;
            } else {
                if (newProduct.itbisPercentage === "18%") {
                    itbisPercentage = 0.18;
                }
            }

            const itbisAmount = price * itbisPercentage;

            setNewProduct({
                ...newProduct,
                "cost": newCost,
                "priceWithoutTax": newPrice,
                "finalPrice": formatRationalNumber(price + itbisAmount),
                "itbisAmount": formatRationalNumber(itbisAmount)
            });

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: `Price calculated`,
                    es: `Precio calculado`,
                }
            });

            onClickClose();
        } else {
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: `Error calculating price`,
                    es: `Error al calcular el precio`,
                }
            });
        }
    }

    const onChangeForm = e => {
        const inputName = getInputName(e.target.name);

        if (inputName === "name") {
            setNameAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (inputName === "quantity") {
            setQuantityAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (inputName === "priceWithoutTax" || inputName === "finalPrice") {
            setPriceAlert({
                alert: false,
                enText: '',
                esText: ''
            });
        }

        if (inputName === "priceWithoutTax") {
            const text = e.target.value;
            let amount = "";
            let isPoint = false;

            for (let i = 0; i < text.length; i++) {
                if (text[i - 3] !== ".") {
                    if (text[i] === ".") {
                        if (!isPoint) {
                            amount += text[i];
                            isPoint = true;
                        }
                    } else {
                        if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                            amount += text[i];
                        }
                    }
                }
            }

            if (!isNaN(amount.trim()) && amount.trim() !== "") {
                let itbisPercentage = 0;

                if (newProduct.itbisPercentage === "16%") {
                    itbisPercentage = 0.16;
                } else {
                    if (newProduct.itbisPercentage === "18%") {
                        itbisPercentage = 0.18;
                    }
                }

                const itbisAmount = Number(amount) * itbisPercentage;

                setNewProduct({
                    ...newProduct,
                    "priceWithoutTax": amount,
                    "finalPrice": formatRationalNumber(Number(amount) + itbisAmount),
                    "itbisAmount": formatRationalNumber(itbisAmount)
                });
            } else {
                setNewProduct({
                    ...newProduct,
                    "priceWithoutTax": "",
                    "finalPrice": "",
                    "itbisAmount": ""
                });
            }
        } else {
            if (inputName === "finalPrice") {
                const text = e.target.value;

                let amount = "";
                let isPoint = false;

                let taxPercentage = 0;

                if (newProduct.itbisPercentage === "16%") {
                    taxPercentage = 16;
                } else {
                    if (newProduct.itbisPercentage === "18%") {
                        taxPercentage = 18;
                    }
                }

                for (let i = 0; i < text.length; i++) {
                    if (text[i - 3] !== ".") {
                        if (text[i] === ".") {
                            if (!isPoint) {
                                amount += text[i];
                                isPoint = true;
                            }
                        } else {
                            if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                                amount += text[i];
                            }
                        }
                    }
                }

                const priceWithoutTax = calculatePriceWithoutTax(Number(amount), taxPercentage);
                const itbisAmount = Number(amount) - priceWithoutTax;

                if (!isNaN(amount.trim()) && amount.trim() !== "") {
                    setNewProduct({
                        ...newProduct,
                        "priceWithoutTax": formatRationalNumber(priceWithoutTax),
                        "finalPrice": amount,
                        "itbisAmount": formatRationalNumber(itbisAmount)
                    });
                } else {
                    setNewProduct({
                        ...newProduct,
                        "priceWithoutTax": "",
                        "finalPrice": "",
                        "itbisAmount": ""
                    });
                }
            } else {
                if (inputName === "quantity") {
                    const text = e.target.value;
                    let amount = "";
                    let isPoint = false;

                    for (let i = 0; i < text.length; i++) {
                        if (text[i - 3] !== ".") {
                            if (text[i] === ".") {
                                if (!isPoint) {
                                    amount += text[i];
                                    isPoint = true;
                                }
                            } else {
                                if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                                    amount += text[i];
                                }
                            }
                        }
                    }
                    setNewProduct({
                        ...newProduct,
                        [inputName]: amount
                    });
                } else {
                    if (inputName === "barcode") {
                        const text = e.target.value;
                        let amount = "";
                        for (let i = 0; i < text.length; i++) {
                            if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                                amount += text[i];
                            }
                        }
                        setNewProduct({
                            ...newProduct,
                            [inputName]: amount
                        });
                    } else {
                            if (inputName === "itbisPercentage") {
                            onClickTaxes(e.target.value);
                        } else {
                            if (inputName === "category") {
                                // To manage the units for categories
                                setNewProduct({
                                    ...newProduct,
                                    [inputName]: e.target.value,
                                    "rate": getUnitsOfMeasurementFor(e.target.value)[0]
                                });
                            } else {
                                if (inputName === "cost") {
                                    const text = e.target.value;
                                    let amount = "";
                                    let isPoint = false;
                        
                                    for (let i = 0; i < text.length; i++) {
                                        if (text[i - 3] !== ".") {
                                            if (text[i] === ".") {
                                                if (!isPoint) {
                                                    amount += text[i];
                                                    isPoint = true;
                                                }
                                            } else {
                                                if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                                                    amount += text[i];
                                                }
                                            }
                                        }
                                    }
                                    setNewProduct({
                                        ...newProduct,
                                        [inputName]: amount
                                    });
                                } else {
                                    setNewProduct({
                                        ...newProduct,
                                        [inputName]: e.target.value
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    const onSave = e => {
        e.preventDefault();

        const productTemp = { ...newProduct };

        if (isDuplicated(newProduct.barcode)) {
            return;
        }

        if (productTemp.name.trim() === "") {
            setNameAlert({
                alert: true,
                enText: 'Required field',
                esText: 'Campo requerido'
            });
            return;
        }

        if ((!doNotDefineQuantity) && productTemp.quantity.trim() === "") {
            setQuantityAlert({
                alert: true,
                enText: 'Required field',
                esText: 'Campo requerido'
            });
            return;
        }

        if (productTemp.priceWithoutTax.trim() === "") {
            setPriceAlert({
                alert: true,
                enText: 'Required field',
                esText: 'Campo requerido'
            });
            return;
        }

        createNewProduct(newProduct);
    }

    const onCancel = e => {
        e.preventDefault();
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/inventario', { replace: true });
        }
    }

    async function createNewProduct(product) {
        setLoading(true);

        try {
            const res = await firebase.useCreateNewProduct({
                ...product,
                "businessId": accountData.id
            });

            if (res.msg === "Limit exceeded") {
                setProductLimits({
                    "limit": res.limit,
                    "current": res.current,
                    "createNew": false
                });
                
                setAlertData({
                    type: 'error',
                    title: {
                        en: `Error trying to create the product`,
                        es: `Error al intentar crear el producto`
                    },
                    code: "Limit exceeded",
                    description: {
                        en : "You can't add more products to this business account because it has exceeded the established limit.",
                        es : "No puedes agregar más productos a esta cuenta de negocios porque has excedido el límite establecido."
                    }
                });
                setAlertActive(true);
                setLoading(false);
            } else {
                if (res.msg === "Duplicate barcode") {
                    setProductLimits({
                        "limit": res.limit,
                        "current": res.current,
                        "createNew": true
                    });
                    
                    setDuplicatedBarcode(res.duplicatedBarcode);

                    setAlertData({
                        type: 'error',
                        title: {
                            en: `Error trying to create the product`,
                            es: `Error al intentar crear el producto`
                        },
                        code: "Duplicate barcodes",
                        description: {
                            en : 'It is not possible to assign a barcode that is already associated with another product.',
                            es : 'No es posible asignar un código de barras que ya esté asociado a otro producto.'
                        }
                    });
                    setAlertActive(true);
                    setLoading(false);
                } else {

                    if (task === "shoppingCart") {
                        addToShoppingCart(res)
                        
                        setSoftAlertActive(true);
                        setSoftAlertData({
                            type : 'sucess',
                            text: {
                                en : 'The customer has been successfully selected',
                                es : 'El cliente ha sido seleccionado de manera exitosa',
                            }
                        });
    
                        if (window.history.state && window.history.state.idx > 0) {
                            navigate(-1);
                        } else {
                            navigate('/carrito-de-compras', { replace: true });
                        }
    
                    } else {
                        setSoftAlertActive(true);
                        setSoftAlertData({
                            type: 'sucess',
                            text: {
                                en: `Product created`,
                                es: `Producto creado`,
                            }
                        });

                        navigate('/detalles-de-producto', { replace: true, state: res });
                    }
                }
            }
        } catch (error) {
            setLoading(false);
            const { code } = JSON.parse(JSON.stringify(error));
            console.log(error);
            setAlertData({
                type: 'error',
                title: {
                    en: `Error trying to create the product`,
                    es: `Error al intentar crear el producto`
                },
                code: code,
                description: getErrorDescription(code)
            });
            setAlertActive(true);
        }
    }

    async function getProductLimit() {
        setLoading(true);
        setShowConnectionError(false);

        try {
            const planRef = doc(firebase.db, "plans", accountData.plan);
            const planSnap = await getDocFromServer(planRef);

            const coll = collection(firebase.db, `accounts/${accountData.id}/products`);
            const snapshot = await getCountFromServer(coll);

            setProductLimits({
                "limit": planSnap.data().products,
                "current": snapshot.data().count,
                "createNew": snapshot.data().count >= planSnap.data().products ? false : true
            })

        } catch (error) {
            console.log(error);
            setShowConnectionError(true);
        }

        setLoading(false);
    }

    useEffect(() => {
        getProductLimit();
        // eslint-disable-next-line
    }, [])

    const onTryAgain = e => {
        e.preventDefault();
        getProductLimit();
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const onClickTaxes = (itbisPercentageSTR) => {
        const text = newProduct.priceWithoutTax.trim();
        const amount = Number(text.split(',').join(''));

        if (amount) {
            let itbisPercentage = 0;

            if (itbisPercentageSTR === "16%") {
                itbisPercentage = 0.16;
            } else {
                if (itbisPercentageSTR === "18%") {
                    itbisPercentage = 0.18;
                }
            }

            const itbisAmount = Number(amount) * itbisPercentage;

            setNewProduct({
                ...newProduct,
                "priceWithoutTax": formatRationalNumber(text),
                "finalPrice": formatRationalNumber(Number(amount) + itbisAmount),
                "itbisPercentage": itbisPercentageSTR,
                "itbisAmount": formatRationalNumber(itbisAmount)
            });
        } else {
            setNewProduct({
                ...newProduct,
                "priceWithoutTax": "",
                "finalPrice": "",
                "itbisPercentage": itbisPercentageSTR,
                "itbisAmount": ""
            });
        }
    }

    const isDuplicated = (barcode) => {
        if (duplicatedBarcode) {
            if (duplicatedBarcode.trim() === barcode.trim()) {
                return true;
            } else {
                return false;
            } 
        } else {
            return false
        }
    }

    const formatAmount = () => {
        if (newProduct) {
            setNewProduct({
                ...newProduct,
                "cost": newProduct.cost === "" ? "" : formatRationalNumber(newProduct.cost),
                "priceWithoutTax": newProduct.priceWithoutTax === "" ? "" : formatRationalNumber(newProduct.priceWithoutTax),
                "finalPrice": newProduct.finalPrice === "" ? "" : formatRationalNumber(newProduct.finalPrice),
            });
        }
    }

    const getNewBarcode = async () => {
        setLoadingBarcode(true);

        try {
            const res = await firebase.useGetNewBarcode({
                "businessId": accountData.id
            });

            setNewProduct({
                ...newProduct,
                "barcode": res
            });

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: `Generated barcode.`,
                    es: `Código de barras generado.`,
                }
            });

        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error when trying to generate barcode.',
                    es: 'Error al intentar generar código de barras.',
                }
            });
        }

        setLoadingBarcode(false);
    }

    useEffect(() => {
        const newState = {
            "product": newProduct,
            "task": task
        }

        navigate(null, { replace: true, state: newState});
    // eslint-disable-next-line
    }, [newProduct]);

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Add Product' : 'Agregar Producto'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div ref={pageObserverRef} className="page-app-settings-item">
                            {accessTo.inventory ? <Fragment>
                                <PricePicker
                                    calculatePriceActive={calculatePriceActive}
                                    cost={newProduct && newProduct.cost ? newProduct.cost : ""}
                                    pageWidth={pageWidth}
                                    menuRef={menuRef}
                                    onClickClose={onClickClose}
                                    onSelectPrice={onSelectPrice}
                                />

                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? 'Add Product' : 'Agregar Producto'}
                                        text2={productLimits ? ` - ${productLimits.current.toLocaleString()} ${appLanguage.en ? "of" : "de"} ${productLimits.limit.toLocaleString()}` : null}
                                    />
                                : null}

                                {loading ? null :
                                    <div ref={headerObservedRef} className="product-details-page-botttons-section-main-cont">
                                        <div className="product-details-page-botttons-section" style={{justifyContent: "flex-end"}}>
                                            <button
                                                onClick={onCancel}
                                                style={{ marginRight: "40px"}}
                                                className="new-customer-bottons-button-save"
                                            >
                                                <img src={crossWhiteIcon} alt="Cancel" />
                                                {appLanguage.en ? <p>Cancel</p> : <p>Cancelar</p>}
                                            </button>
                                            <button 
                                                onClick={onSave}
                                                disabled={disabled}
                                                className="new-customer-bottons-button-save"
                                                style={disabled ? {
                                                    backgroundColor: "#3f526698",
                                                    color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                    cursor: "not-allowed",
                                                    marginRight: "20px"
                                                } : {
                                                    marginRight: "20px"
                                                }}
                                            >
                                                <img src={saveIcon} alt="Save" />
                                                {appLanguage.en ? <p>Save</p> : <p>Guardar</p>}
                                            </button>
                                        </div>
                                    </div>
                                }

                                <div
                                    style={{
                                        height: desktopView ? `calc(100vh - 107px - ${headerHeight}px)` : `calc(100vh - 65px - ${headerHeight}px)`,
                                        colorScheme: appTheme.dark ? "dark" : "light"
                                    }}
                                    className="customer-details-page-main-container"
                                >
                                    {loading ? <div style={{ marginTop: "90px" }} className="more-details-spinner-container"><Spinner /></div> :
                                        (showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> :
                                            (productLimits ? 
                                                (productLimits.createNew ?
                                                    <div ref={observedRef} className="product-details-page-container">
                                                        <div className="product-details-page-info-section" style={{ flexWrap: width < 850 ? "wrap" : "nowrap" }}>
                                                            <div style={{ minWidth: "145px", width: width < 500 ? "100%" : "" }}>
                                                                <div style={{height: width < 500 ? "200px" : "145px"}} className="products-inventory-product-no-img-big-cont unselectable">
                                                                    <div style={{width: width < 500 ? "200px" : "145px", height: width < 500 ? "200px" : "145px", }} className="pro-inv-prod-no-img-big-con-div">
                                                                        <img className="products-inventory-product-no-img-big" src={noPicture} alt="Product" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div style={{ width: width < 500 ? "100%" : "calc(100% - 178px)", maxWidth: width < 850 ? width < 500 ? "100%" : "calc(100% - 178px)" : "330px", marginLeft: width < 500 ? "" : "30px", marginRight: width < 850 ? "" : "30px" }}>
                                                                <div ref={nameAlert.alert ? fieldAlertReference : null} style={{ display: "flex", flexDirection: "column" }}>
                                                                    <p className="new-customer-label"><b>{appLanguage.en ? "Name: * " : "Nombre: * "}</b>{nameAlert.alert ? (appLanguage.en ? nameAlert.enText : nameAlert.esText) : ""}</p>
                                                                    <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.name}>{inputsNames.name}</label>
                                                                    <input
                                                                        name={inputsNames.name}
                                                                        id={inputsNames.name}
                                                                        value={newProduct.name.trim() === "" ? "" : newProduct.name}
                                                                        onChange={(e) => onChangeForm(e)}
                                                                        onKeyDown={e => handleKeyPress(e)}
                                                                        autoComplete="off"
                                                                        autoCorrect="off"
                                                                        maxLength={40}
                                                                        placeholder={appLanguage.en ? "Enter the name..." : "Introduzca el nombre..."}
                                                                        style={{colorScheme: appTheme.dark ? "dark" : "light",}}
                                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${nameAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light")}`}
                                                                    />
                                                                </div>
                                                                <div className="add-product-service-first-opt-cont">
                                                                        <div className="add-product-service-first-opt-item" style={{ marginRight: "8px" }}>
                                                                            <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Category: " : "Categoría: "}</b></p>
                                                                            <label className="new-customer-helper-label unselectable" htmlFor={uid(8)} >{uid(8)}</label>
                                                                            <select
                                                                                style={{
                                                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                                                    width: "100%",
                                                                                }}
                                                                                className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                                                name="category"
                                                                                value={newProduct.category}
                                                                                onChange={(e) => onChangeForm(e)}
                                                                                onKeyDown={e => handleKeyPress(e)}
                                                                            >
                                                                                {getThisBusinessProductsCategories(accountData.businessCategory).map((category, index) => {
                                                                                    return ( 
                                                                                        <option key={index} value={category}>{getCategory(category)}</option>
                                                                                    )
                                                                                })}
                                                                            </select>
                                                                        </div>
                                                                    
                                                                        {getUnitsOfMeasurementFor(newProduct.category).length > 1 ? 
                                                                            <div className="add-product-service-first-opt-item" style={{ marginLeft: "8px" }}>
                                                                                <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Rate: " : "Tarifa: "}</b></p>
                                                                                <label className="new-customer-helper-label unselectable" htmlFor={uid(8)}>{uid(8)}</label>
                                                                                <select
                                                                                    style={{
                                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                                        width: "100%",
                                                                                    }}
                                                                                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                                                    name="rate"
                                                                                    value={newProduct.rate}
                                                                                    onChange={(e) => onChangeForm(e)}
                                                                                    onKeyDown={e => handleKeyPress(e)}
                                                                                >
                                                                                    {getUnitsOfMeasurementFor(newProduct.category).map((unit, index) => {
                                                                                        return ( 
                                                                                            <option key={index} value={unit}>{getRatePer(unit)}</option>
                                                                                        )
                                                                                    })}
                                                                                </select>
                                                                            </div>
                                                                            :
                                                                            <div className="add-product-service-first-opt-item" style={{ marginLeft: "8px", }}>
                                                                                <p className="new-customer-label"><b>{appLanguage.en ? "Rate: " : "Tarifa: "}</b></p>
                                                                                <input
                                                                                    name="rate"
                                                                                    id="rate"
                                                                                    value={getRatePer(getUnitsOfMeasurementFor(newProduct.category)[0])}
                                                                                    maxLength={10}
                                                                                    autoComplete="off"
                                                                                    autoCorrect="off"
                                                                                    readOnly
                                                                                    style={{
                                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                                        border: "none",
                                                                                        outline: "none",
                                                                                        boxShadow: "none"
                                                                                    }}
                                                                                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                                                />
                                                                            </div>
                                                                        }
                                                                </div>
                                                            </div>

                                                            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Description:" : "Descripción:"}</b></p>
                                                                <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.description}>{inputsNames.description}</label>
                                                                <textarea
                                                                    autoComplete="off"
                                                                    autoCorrect="off"
                                                                    placeholder={appLanguage.en ? "You can leave an overview here..." : "Puedes dejar una descripción general aquí..."}
                                                                    style={{
                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                        marginBottom: 0,
                                                                        marginTop: "5px",
                                                                        height: "100%",
                                                                        minHeight: "100px",
                                                                    }}
                                                                    className={`new-custmer-textarea-address ${appTheme.dark ? "new-custmer-textarea-dark dar-sec-bg" : "new-custmer-textarea-light lig-sec-bg"}`}
                                                                    id={inputsNames.description}
                                                                    name={inputsNames.description}
                                                                    value={newProduct.description.trim() === "" ? "" : newProduct.description} 
                                                                    maxLength={340}
                                                                    onChange={(e) => onChangeForm(e)}
                                                                    onKeyDown={e => handleKeyPress(e)}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div style={{display: "flex", marginTop: "18px"}}>
                                                            {width >= 650 ?
                                                                <button
                                                                    className={`new-customer-bottons-button-save ${appTheme.dark ? `border-box-dark` : `border-box-light`}`}
                                                                    style={{
                                                                        margin: "20px 10px 0 0px",
                                                                        height: "38.4px"
                                                                    }}
                                                                    onClick={onClickCalculatePrice}
                                                                >
                                                                    <img src={dollarSignDark} alt="dollar Sign" />
                                                                    <p>{appLanguage.en ?'Calculate Price' : 'Calcular Precio'}</p>
                                                                </button>
                                                            : null}

                                                            <button
                                                                className={`notification-list-config-btn ${appTheme.dark ? `${isTouchEnabled() ? "settings-notification-btn-dark-no-hover" : "settings-notification-btn-dark"} border-box-dark` : `${isTouchEnabled() ? "settings-notification-btn-light-no-hover" : "settings-notification-btn-light"} border-box-light`}`}
                                                                style={{
                                                                    margin: `20px 10px 0 ${width >= 650 ? "10px" : "0px"}`,
                                                                    height: "38.4px"
                                                                }}
                                                                disabled={loading ? true : false}
                                                                onClick={doNotDefineQuantityHandler}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    checked={doNotDefineQuantity}
                                                                    readOnly
                                                                    name='customerAccounts'
                                                                    id='customerAccounts'
                                                                    style={{
                                                                        colorScheme: appTheme.dark ? "dark" : "light"
                                                                    }}
                                                                />
                                                                <p>{appLanguage.en ?'Allow selling without defining quantity' : 'Permitir vender sin definir cantidad'}</p>
                                                            </button>
                                                        </div>

                                                        <div className="product-details-page-inventory-section" style={{ flexWrap: width < 650 ? "wrap" : "nowrap" }}>
                                                            <div ref={quantityAlert.alert ? fieldAlertReference : null} className="add-product-service-first-opt-item" style={{ marginRight: "8px", width: width < 650 ? (width < 500 ? "calc(40% - 8px)" : "calc(50% - 8px)") : "33%" }}>
                                                                <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Quantity" : "Cantidad"}:</b> * {((quantityAlert.alert) && (width >= 500)) ? (appLanguage.en ? quantityAlert.enText : quantityAlert.esText) : ""}</p>
                                                                <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.quantity}>{inputsNames.quantity}</label>
                                                                <input
                                                                    name={inputsNames.quantity}
                                                                    id={inputsNames.quantity}
                                                                    inputMode="numeric"
                                                                    value={
                                                                        doNotDefineQuantity ?
                                                                            appLanguage.en ? "Not defined" : "No definida"
                                                                        :
                                                                            newProduct.quantity
                                                                    }
                                                                    onChange={(e) => onChangeForm(e)}
                                                                    onKeyDown={e => handleKeyPress(e)}
                                                                    maxLength={10}
                                                                    autoComplete="off"
                                                                    autoCorrect="off"
                                                                    placeholder={appLanguage.en ? "Enter quantity..." : "Introduzca la cantidad..."}
                                                                    readOnly={(!doNotDefineQuantity) ? false : true}
                                                                    style={(!doNotDefineQuantity) ? {
                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                    } : {
                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                        border: "none",
                                                                        outline: "none",
                                                                        boxShadow: "none"
                                                                    }}
                                                                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${quantityAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light")}`}
                                                                />
                                                            </div>
                                                            <div className="add-product-service-first-opt-item" style={{ marginLeft: "8px", marginRight: width < 650 ? "" : "8px", width: width < 650 ? (width < 500 ? "calc(60% - 8px)" : "calc(50% - 8px)") : "33%" }}>
                                                                <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Location" : "Ubicación"}:</b></p>
                                                                <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.location}>{inputsNames.location}</label>
                                                                <input
                                                                    name={inputsNames.location}
                                                                    id={inputsNames.location}
                                                                    value={newProduct.location.trim() === "" ? "" : newProduct.location} 
                                                                    onChange={(e) => onChangeForm(e)}
                                                                    onKeyDown={e => handleKeyPress(e)}
                                                                    maxLength={16}
                                                                    autoComplete="off"
                                                                    autoCorrect="off"
                                                                    placeholder={appLanguage.en ? "Enter the location..." : "Introduzca la ubicación..."}
                                                                    style={{colorScheme: appTheme.dark ? "dark" : "light",}}
                                                                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                                />
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "column", marginLeft: width < 650 ? "" : "8px", width: width < 650 ? "100%" : "40%" }}>
                                                                <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Barcode: " : "Código de barra: "}</b>{isDuplicated(newProduct.barcode) ? (
                                                                    appLanguage.en ? " *** Duplicate ***" : " *** Duplicado *** "
                                                                ) : ""}</p>
                                                                <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.barcode}>{inputsNames.barcode}</label>
                                                                
                                                                <div style={{display: "flex", width: "100%"}}>
                                                                    <input
                                                                        name={inputsNames.barcode}
                                                                        id={inputsNames.barcode}
                                                                        value={newProduct.barcode.trim() === "" ? "" : newProduct.barcode} 
                                                                        inputMode="numeric"
                                                                        onChange={(e) => onChangeForm(e)}
                                                                        onKeyDown={e => handleKeyPress(e)}
                                                                        autoComplete="off"
                                                                        autoCorrect="off"
                                                                        maxLength={16}
                                                                        placeholder={appLanguage.en ? "Enter the barcode..." : "Introduzca el código de barra..."}
                                                                        style={{
                                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                                            borderTopRightRadius: newProduct.barcode.trim() === "" ? "0px" : "10px",
                                                                            borderBottomRightRadius: newProduct.barcode.trim() === "" ? "0px" : "10px",
                                                                            border: loadingBarcode ? "none" : "",
                                                                            outline: loadingBarcode ? "none" : "",
                                                                            boxShadow: loadingBarcode ? "none" : ""
                                                                        }}
                                                                        className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${isDuplicated(newProduct.barcode) ? 'new-customer-input-alert' : appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`}
                                                                    />

                                                                    {newProduct.barcode.trim() === "" ?
                                                                        <button
                                                                            onClick={getNewBarcode}
                                                                            disabled={loadingBarcode}
                                                                            style={{ 
                                                                                color: appTheme.dark ? "white" : "black",
                                                                                marginTop: "5px",
                                                                                width: "100px",
                                                                                minWidth: "100px",
                                                                                maxWidth: "100px",
                                                                                borderTopLeftRadius: "0px",
                                                                                borderBottomLeftRadius: "0px",
                                                                                borderTopRightRadius: "10px",
                                                                                borderBottomRightRadius: "10px"
                                                                            }}
                                                                            className={`${isTouchEnabled() ? "product-picture-upload-butt-no-hover" : "product-picture-upload-butt"} unselectable`}
                                                                        >
                                                                            {loadingBarcode ? <Spinner2 /> : (appLanguage.en ? "Generate" : "Generar")}
                                                                        </button> 
                                                                    : null}

                                                                </div>
                                                            </div>
                                                        </div>

                                                        {width < 650 ?
                                                            <div style={{display: "flex", marginTop: "18px"}}>
                                                                <button
                                                                    className={`new-customer-bottons-button-save ${appTheme.dark ? `border-box-dark` : `border-box-light`}`}
                                                                    style={{
                                                                        margin: "20px 10px 0 0px",
                                                                        height: "38.4px"
                                                                    }}
                                                                    onClick={onClickCalculatePrice}
                                                                >
                                                                    <img src={dollarSignDark} alt="dollar Sign" />
                                                                    <p>{appLanguage.en ?'Calculate Price' : 'Calcular Precio'}</p>
                                                                </button>
                                                            </div>
                                                        : null}

                                                        <div className="product-details-page-billing-section" style={{ flexWrap: "wrap"}}>
                                                            <div ref={priceAlert.alert ? fieldAlertReference : null} className="add-product-service-first-opt-item" style={{marginRight: width < 500 ? "" :  "8px", width: width < 650 ? (width < 500 ? "100%" : "calc(50% - 8px)") : "calc(25% - 8px)"}}>
                                                                <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Cost" : "Costo"}:</b></p>
                                                                <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.cost}>{inputsNames.cost}</label>
                                                                <input
                                                                    name={inputsNames.cost}
                                                                    id={inputsNames.cost}
                                                                    inputMode="numeric"
                                                                    onBlur={formatAmount}
                                                                    value={newProduct.cost} 
                                                                    onChange={(e) => onChangeForm(e)}
                                                                    onKeyDown={e => handleKeyPress(e)}
                                                                    maxLength={10}
                                                                    autoComplete="off"
                                                                    autoCorrect="off"
                                                                    placeholder="RD$ 0.00"
                                                                    style={{ colorScheme: appTheme.dark ? "dark" : "light",}}
                                                                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light"}`}
                                                                />
                                                            </div>

                                                            <div className="add-product-service-first-opt-item" style={{marginRight: width < 650 ? "" : "8px", marginLeft: width < 500 ? "" : "8px", width: width < 650 ? (width < 500 ? "100%" : "calc(50% - 8px)") : "calc(25% - 16px)"}}>
                                                                <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Price without ITBIS" : "Precio sin ITBIS"}: *</b></p>
                                                                <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.priceWithoutTax}>{inputsNames.priceWithoutTax}</label>
                                                                <input
                                                                    name={inputsNames.priceWithoutTax}
                                                                    id={inputsNames.priceWithoutTax}
                                                                    inputMode="numeric"
                                                                    onBlur={formatAmount}
                                                                    value={newProduct.priceWithoutTax} 
                                                                    onChange={(e) => onChangeForm(e)}
                                                                    onKeyDown={e => handleKeyPress(e)}
                                                                    maxLength={10}
                                                                    autoComplete="off"
                                                                    autoCorrect="off"
                                                                    placeholder="RD$ 0.00"
                                                                    style={{ colorScheme: appTheme.dark ? "dark" : "light",}}
                                                                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${priceAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light")}`}
                                                                />
                                                            </div>

                                                            <div className="add-product-service-first-opt-item" style={{marginRight: width < 500 ? "" : "8px", marginLeft: width < 650 ? "" : "8px", width: width < 650 ? (width < 500 ? "100%" : "calc(50% - 8px)") : "calc(25% - 16px)"}}>
                                                                <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>ITBIS: </b>RD$ {newProduct.itbisAmount}</p>
                                                                <label className="new-customer-helper-label unselectable" htmlFor="itbisPercentage">itbisPercentage</label>
                                                                <select
                                                                    style={{
                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                        width: "100%",
                                                                    }}
                                                                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg new-customer-input-dark" : "lig-sec-bg new-customer-input-light"}`}
                                                                    name="itbisPercentage"
                                                                    value={newProduct.itbisPercentage}
                                                                    onChange={(e) => onChangeForm(e)}
                                                                    onKeyDown={e => handleKeyPress(e)}
                                                                >
                                                                    <option value="0%" >0 %</option>
                                                                    <option value="16%">16 %</option>
                                                                    <option value="18%">18 %</option>
                                                                </select>
                                                            </div>

                                                            <div className="add-product-service-first-opt-item" style={{marginLeft: width < 500 ? "" : "8px", width: width < 650 ? (width < 500 ? "100%" : "calc(50% - 8px)") : "calc(25% - 8px)"}}>
                                                                <p style={{ whiteSpace: "nowrap" }} className="new-customer-label"><b>{appLanguage.en ? "Final price" : "Precio final"}: </b></p>
                                                                <label className="new-customer-helper-label unselectable" htmlFor={inputsNames.finalPrice} >{inputsNames.finalPrice}</label>
                                                                <input
                                                                    name={inputsNames.finalPrice}
                                                                    id={inputsNames.finalPrice}
                                                                    inputMode="numeric"
                                                                    onBlur={formatAmount}
                                                                    value={newProduct.finalPrice} 
                                                                    onChange={(e) => onChangeForm(e)}
                                                                    onKeyDown={e => handleKeyPress(e)}
                                                                    maxLength={10}
                                                                    autoComplete="off"
                                                                    autoCorrect="off"
                                                                    placeholder="RD$ 0.00"
                                                                    style={{ colorScheme: appTheme.dark ? "dark" : "light",}}
                                                                    className={`new-customer-input ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"} ${priceAlert.alert ? 'new-customer-input-alert' : (appTheme.dark ? "new-customer-input-dark" : "new-customer-input-light")}`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div style={{minHeight: "50px"}}/>
                                                    </div> 
                                                :
                                                    <div className='authorize-access-page-alert-cont'>
                                                        <img src={warningIcon} alt="alert" />
                                                        {appLanguage.en ? <Fragment>
                                                            <p><b>You cannot add more products</b></p>
                                                            <p><b>{productLimits.current.toLocaleString()} of {productLimits.limit.toLocaleString()}</b> products created</p>
                                                            <br />
                                                            <p>Only {productLimits.limit.toLocaleString()} products can be created per basic account. You can delete an existing product to add another or upgrade to a more advanced plan.</p>
                                                        </Fragment> : <Fragment>
                                                            <p><b>No puedes agregar más produtos</b></p>
                                                            <p><b>{productLimits.current.toLocaleString()} de {productLimits.limit.toLocaleString()}</b> produtos creados</p>
                                                            <br />
                                                            <p>Sólo se pueden crear {productLimits.limit.toLocaleString()} produtos por plan básica. Puedes eliminar un producto existente para agregar otro o actualizarte a un plan más avanzado.</p>
                                                        </Fragment>}
                                                    </div> 
                                                )
                                            : null)
                                        )
                                    }
                                </div>
                            </Fragment> : null}
                        </div>
                        :
                        <div className="page-app-customers-item">
                            <div className="customers-options-container">
                                <div className="customers-options-container-item">
                                    <SettingsActionRequired />
                                </div>
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

const PricePicker = ({
    calculatePriceActive,
    cost,
    pageWidth,
    menuRef,
    onClickClose,
    onSelectPrice
}) => {
    const { appTheme, appLanguage, formatRationalNumber } = useContext(AppContext);

    const [tempCost, setTempCost] = useState("");
    const [tempProfitPercentage, setTempProfitPercentage] = useState("");
    const [tempEarningsAmount, setTempEarningsAmount] = useState("");
    const [tempPrice, setTempPrice] = useState("");

    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (tempPrice) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [tempPrice])

    useEffect(() => {
        if (!calculatePriceActive) {
            setTempCost("");
            setTempProfitPercentage("");
            setTempEarningsAmount("");
            setTempPrice("");
        } else {
            setTempCost(cost);
        }
    // eslint-disable-next-line
    }, [calculatePriceActive]);
    

    const onChangeCost = (e) => {
        const text = e.target.value;
        let amount = "";
        let isPoint = false;
        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        amount += text[i];
                    }
                }
            }
        }
        setTempCost(amount);

        if (!amount) {
            setTempProfitPercentage("");
            setTempEarningsAmount("");
            setTempPrice("");
        } else {
            if (amount && tempProfitPercentage) {
                const cost = parseFloat(amount.replace(/,/g, ""));
                const profitPercentage = parseFloat(tempProfitPercentage.replace(/,/g, ""));
    
                const earnings = (cost * profitPercentage) / 100;
                const price = cost + earnings;
    
                setTempEarningsAmount(formatRationalNumber(earnings));
                setTempPrice(formatRationalNumber(price));
            } else {
                setTempEarningsAmount("");
                setTempPrice("");
            }
        }
    }

    const onChangeProfitPercentage = (e) => {
        const text = e.target.value;
        let amount = "";
        let isPoint = false;
        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        amount += text[i];
                    }
                }
            }
        }
        setTempProfitPercentage(amount);

        if (tempCost && amount) {
            const cost = parseFloat(tempCost.replace(/,/g, ""));
            const profitPercentage = parseFloat(amount.replace(/,/g, ""));

            const earnings = (cost * profitPercentage) / 100;
            const price = cost + earnings;

            setTempEarningsAmount(formatRationalNumber(earnings));
            setTempPrice(formatRationalNumber(price));
        } else {
            setTempEarningsAmount("");
            setTempPrice("");
        }
    }

    const onChangeEarningsAmount = (e) => {
        const text = e.target.value;
        let amount = "";
        let isPoint = false;
        for (let i = 0; i < text.length; i++) {
            if (text[i - 3] !== ".") {
                if (text[i] === ".") {
                    if (!isPoint) {
                        amount += text[i];
                        isPoint = true;
                    }
                } else {
                    if ((!(isNaN(text[i]))) && (text[i].trim() !== "")) {
                        amount += text[i];
                    }
                }
            }
        }
        setTempEarningsAmount(amount);

        if (tempCost && amount) {
            const cost = parseFloat(tempCost.replace(/,/g, ""));
            const earnings = parseFloat(amount.replace(/,/g, ""));

            const profitPercentage = (earnings / cost) * 100;
            const price = cost + earnings;

            setTempProfitPercentage(formatRationalNumber(profitPercentage));
            setTempPrice(formatRationalNumber(price));
        } else {
            setTempProfitPercentage("");
            setTempPrice("");
        }
    }

    const formatValue = () => {
        setTempCost(tempCost.trim() === "" ? "" : formatRationalNumber(tempCost));
        setTempProfitPercentage(tempProfitPercentage.trim() === "" ? "" : formatRationalNumber(tempProfitPercentage));
        setTempEarningsAmount(tempEarningsAmount.trim() === "" ? "" : formatRationalNumber(tempEarningsAmount));
    }

    return (
        <div
            style={{
                WebkitUserSelect: "auto",
                WebkitTouchCallout: "auto",
                MozUserSelect: "auto",
                MsUserSelect: "auto",
                userSelect: "auto",
            }}
        >
            {calculatePriceActive ? <div className='picker-date-lock-screen' style={{ width: pageWidth, minHeight: "calc(100vh - 65px)" }} /> : null}

            <div ref={menuRef} className={`picker-date-genaral-card ${calculatePriceActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pageWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                <div className='picker-date-general-despcrition' style={{ display: "flex", justifyContent: "space-between", minHeight: "250px" }}>


                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}>
                        <h4 style={{ marginLeft: "15px" }}>
                            <span style={{ fontWeight: "600" }}>{appLanguage.en ? "Calculate Price" : "Calcular Precio"}</span>
                        </h4>

                        <button
                            onClick={onClickClose}
                            className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                        >
                            <img
                                className="see-details-transations-img"
                                src={appTheme.dark ? closeDarkIcon : closeLightIcon}
                                alt="close"
                            />
                        </button>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                width: "calc(100% - 30px)",
                                flexDirection: "column",
                                margin: "10px 0px"
                            }}
                        >
                            <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Cost of the product or service" : "Costo del producto o servicio"}</p>
                            <input
                                value={tempCost}
                                onChange={(e) => onChangeCost(e)}
                                onBlur={formatValue}
                                inputMode="numeric"
                                maxLength={10}
                                autoComplete="off"
                                autoCorrect="off"
                                placeholder="RD$ 0.00"
                                style={{
                                    colorScheme: appTheme.dark ? "dark" : "light",
                                    background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                    color: appTheme.dark ? "white" : "black"
                                }}
                                className={`cash-in-cash-reg-input ${(appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                            />

                            <p style={{ marginTop: "20px", whiteSpace: "nowrap", }}>{appLanguage.en ? `Profit percentage` : `Porcentaje de ganancias`} (<b>%</b>)</p>
                            <input
                                value={tempProfitPercentage}
                                onChange={(e) => onChangeProfitPercentage(e)}
                                onBlur={formatValue}
                                inputMode="numeric"
                                maxLength={10}
                                autoComplete="off"
                                autoCorrect="off"
                                placeholder="RD$ 0.00"
                                style={{
                                    colorScheme: appTheme.dark ? "dark" : "light",
                                    background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                    color: appTheme.dark ? "white" : "black"
                                }}
                                className={`cash-in-cash-reg-input ${(appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                            />

                            <p style={{ marginTop: "20px", whiteSpace: "nowrap", }}>{appLanguage.en ? `Earnings amount` : `Monto de ganancias`} (<b>$</b>)</p>
                            <input
                                value={tempEarningsAmount}
                                onChange={(e) => onChangeEarningsAmount(e)}
                                onBlur={formatValue}
                                inputMode="numeric"
                                maxLength={10}
                                autoComplete="off"
                                autoCorrect="off"
                                placeholder="RD$ 0.00"
                                style={{
                                    colorScheme: appTheme.dark ? "dark" : "light",
                                    background: appTheme.dark ? "rgb(100, 100, 100)" : "#F7F7F7",
                                    color: appTheme.dark ? "white" : "black"
                                }}
                                className={`cash-in-cash-reg-input ${(appTheme.dark ? "cash-in-cash-reg-input-dark" : "cash-in-cash-reg-input-light")}`}
                            />

                            <p style={{ marginTop: "20px", whiteSpace: "nowrap", fontSize: "16px" }}>Precio sin ITBIS: <b>RD$ {tempPrice.trim() === "" ? "" : formatRationalNumber(tempPrice)}</b></p>
                            
                        </div>

                        <button
                            onClick={() => onSelectPrice(tempCost, tempPrice)}
                            disabled={disabled}
                            className="new-customer-bottons-button-save"
                            style={disabled ? {
                                backgroundColor: "#3f526698",
                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                cursor: "not-allowed",
                                margin: "35px 20px 20px 20px",
                                minWidth: "200px"
                            } : {
                                margin: "35px 20px 20px 20px",
                                minWidth: "200px"
                            }}
                        >
                            <img src={saveIcon} alt="Select" />
                            {appLanguage.en ? <p>Select</p> : <p>Seleccionar</p>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateProductPage;