import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from '../../context/AppContext';
import { AuthContext } from "../../firebase/context";
import { Helmet } from 'react-helmet-async';

import SettingsActionRequired from "../../components/settings-action-required/settingsActionRequired";
import SectionForbidden from "../../components/sectionForbidden/sectionForbidden";

import "./dashboard.page.scss";

import { SideMenuContext } from "../../context/SideMenuContext";

import ConnectionError from "../../components/connection-error/connectionError";
import Spinner from "../../components/spinner/spinner";
import firebase from "../../firebase";
import { onSnapshot, doc } from "firebase/firestore";

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import ToolTitle from "../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../icons/bullet-point-light.png';

import emptyFolderIcon from "../../img/empty-folder.svg";

import { uid } from "uid";
import { useNavigate } from "react-router-dom";

const DashboardPage = () => {

    let observedRef = useRef(null);

    const { accountData, accessTo } = useContext(AuthContext);
    const { appLanguage, appTheme, formatRationalNumber, getNumberFromCustomString, getDayForRecharts, isTouchEnabled, getMonthNameAndFullYear } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const [loadingCustomerStatistics, setLoadingCustomerStatistics] = useState(true);
    const [isCustomerStatistics, setIsCustomerStatistics] = useState(true);
    const [showConnectionErrorCustomer, setShowConnectionErrorCustomer] = useState(false);

    const [requestForCustStat, setRequestForCustStat] = useState(false);

    const [request, setRequest] = useState(false);

    const [statisticsListener, setStatisticsListener] = useState(null);

    const [generalStatistics, setGeneralStatistics] = useState([]);
    const [customersStatistics, setCustomersStatistics] = useState([]);

    const [showConnectionError, setShowConnectionError] = useState(false);

    const [indicatorsFilters, setIndicatorsFilters] = useState([]);

    const [statisticsView, setStatisticsView] = useState("today");
    const onChangeStatisticsView = (e) => {
        setStatisticsView(e.target.value);
    }

    const [cashFlowStatisticsView, setCashFlowStatisticsView] = useState("");
    const onChangeCashFlowStatisticsView = (e) => {
        setCashFlowStatisticsView(e.target.value);
    }

    const [saleQuantityStatisticsView, setSaleQuantityStatisticsView] = useState("");
    const onChangeSaleQuantityStatisticsView = (e) => {
        setSaleQuantityStatisticsView(e.target.value);
    }

    const [customersStatisticsView, setCustomersStatisticsView] = useState("");
    const [customersIndicatorsFilters, setCustomersIndicatorsFilters] = useState([]);
    const onChangeCustomersStatisticsView = (e) => {
        setCustomersStatisticsView(e.target.value);
    }

    const [cashFlowData, setCashFlowData] = useState([]);
    const [saleQuantityData, setSaleQuantityData] = useState([]);

    const [indicators, setIndicators] = useState({});

    const [todayString, setTodayString] = useState(null);

    // using the SideMenuContext calculate the initial state
    const [width, setWidth] = useState(desktopView ? windowDimension.width - (isSideMenuMinimized ? 119.6 : 309.6) : windowDimension.width);

    useEffect(() => {
        if (observedRef) {
            if (!observedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (observedRef.current.offsetWidth !== width) {
                        setWidth(observedRef.current.offsetWidth);
                    }
                } catch { }
            });

            resizeObserver.observe(observedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [observedRef.current, windowDimension]);

    const onTryAgain = () => {
        setLoading(true);
        getGeneralStatistics();
    }

    const onTryAgainCustomerStatistics = () => {
        setRequestForCustStat(uid());
    }

    // To avoid repeat requests
    useEffect(() => {
        if (request) {
            if (accountData && accessTo) {
                if (!generalStatistics.length > 0) {
                    if (accessTo.metrics) {
                        setStatisticsListener(true);
                    }
                }
            }
        } else {
            setRequest(uid());
        }
        // eslint-disable-next-line
    }, [accountData, accessTo, request]);

    const getMonthDataForSaleQuantity = (productStatistics, statisticsViewTable) => {
        const newMonthData = [];

        if (productStatistics && statisticsViewTable) {
            const thisMonthStatistics = productStatistics.find(obj => statisticsViewTable in obj);

            if (thisMonthStatistics) {
                let currentPeriodData = null;

                for (let key of Object.keys(thisMonthStatistics)) {
                    currentPeriodData = thisMonthStatistics[key];
                }

                for (let key of Object.keys(currentPeriodData)) {
                    if (isvalidObject(key)) {

                        newMonthData.push({
                            name: getDayForRecharts(key),
                            cantidad: currentPeriodData[key].quantitySold,
                        });
                    }
                }
            }
        }

        return newMonthData;
    }

    const getMonthDataForCashFlow = (productStatistics, statisticsViewTable) => {
        const newMonthData = [];

        if (productStatistics && statisticsViewTable) {
            const thisMonthStatistics = productStatistics.find(obj => statisticsViewTable in obj);

            if (thisMonthStatistics) {
                let currentPeriodData = null;

                for (let key of Object.keys(thisMonthStatistics)) {
                    currentPeriodData = thisMonthStatistics[key];
                }

                const isAnyLosses = currentPeriodData.losses > 0 ? true : false;

                for (let key of Object.keys(currentPeriodData)) {
                    if (isvalidObject(key)) {
                        if (isAnyLosses) {
                            newMonthData.push({
                                name: getDayForRecharts(key),
                                "Ingresos": formatAmount(currentPeriodData[key].income),
                                "Pérdidas": formatAmount(currentPeriodData[key].losses),
                                "Ganancias": formatAmount(currentPeriodData[key].earnings),
                            });
                        } else {
                            newMonthData.push({
                                name: getDayForRecharts(key),
                                "Ingresos": formatAmount(currentPeriodData[key].income),
                                "Ganancias": formatAmount(currentPeriodData[key].earnings),
                            });
                        }
                    }
                }
            }
        }

        return newMonthData;
    }

    useEffect(() => {
        setSaleQuantityData(getMonthDataForSaleQuantity(generalStatistics, saleQuantityStatisticsView));
    // eslint-disable-next-line
    }, [generalStatistics, saleQuantityStatisticsView]);

    useEffect(() => {
        setCashFlowData(getMonthDataForCashFlow(generalStatistics, cashFlowStatisticsView));
    // eslint-disable-next-line
    }, [generalStatistics, cashFlowStatisticsView]);

    async function getGeneralStatistics() {
        setShowConnectionError(false);
        try {
            const res = await firebase.useGetGeneralIndicators({
                "businessId": accountData.id
            });

            if (res === null) {
                throw new Error("Statistics not found");
            }

            const monthsList = [];

            for (let [key] of Object.entries(res)) {
                monthsList.push(res[key]);
            }

            let newStatisticsViewTable = "";

            for (let key of Object.keys(monthsList[monthsList.length - 1])) {
                if (isvalidObject(key)) {
                    newStatisticsViewTable = key;
                }
            }

            setSaleQuantityStatisticsView(newStatisticsViewTable);
            setCashFlowStatisticsView(newStatisticsViewTable);

            setGeneralStatistics(res);

            setSaleQuantityData(getMonthDataForSaleQuantity(res, newStatisticsViewTable));
            setCashFlowData(getMonthDataForCashFlow(res, newStatisticsViewTable));
        } catch (error) {
            console.log(error);
            setShowConnectionError(true);
        }
        setLoading(false);
    }

    // Then I have to listen to the product statistcs dos
    useEffect(() => {
        if (accountData && statisticsListener) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/productsStatistics`, "statisticsOfAllProducts"), (doc) => {
                if (doc.data()) {
                    getGeneralStatistics();
                } else {
                    setShowConnectionError(true);
                    setLoading(false);
                }
            }, err => {
                console.error(err);
                setShowConnectionError(true);
                setLoading(false);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [statisticsListener]);

    // const [opacity, setOpacity] = useState({
    //     losses: 1,
    //     income: 1,
    //     earnings: 1,
    // });

    const isvalidObject = (key) => {
        if (
            key === "income" ||
            key === "discountsApplied" ||
            key === "earnings" ||
            key === "returnRate" ||
            key === "quantitySold" ||
            key === "losses" ||
            key === "returns"
        ) {
            return false
        } else {
            return true;
        }
    }

    const formatAmount = (num) => {
        const text = formatRationalNumber(num);
        return getNumberFromCustomString(text);
    }

    useEffect(() => {
        if (generalStatistics.length > 0) {
            const newIndicatorsFilters = [];
            const newIndicators = {};

            let newTodayString = "";
            let newTodayObject = {};
            let currentPeriodData = null;

            const thisMonthStatistics = generalStatistics[generalStatistics.length - 1];

            for (let key of Object.keys(thisMonthStatistics)) {
                currentPeriodData = thisMonthStatistics[key];
            }

            for (let key of Object.keys(currentPeriodData)) {
                if (isvalidObject(key)) {
                    newTodayString = key;
                    newTodayObject = currentPeriodData[key];
                }
            }

            newIndicators[newTodayString] = newTodayObject;

            const monthsList = [];

            for (let [key] of Object.entries(generalStatistics)) {
                monthsList.push(generalStatistics[key])
            }

            for (let i = 0; i < monthsList.length; i++) {
                for (let key of Object.keys(monthsList[i])) {
                    if (isvalidObject(key)) {
                        newIndicatorsFilters.push(key);
                    
                        const statisticsForMonth = monthsList[i][key];

                        newIndicators[key] = {
                            earnings: statisticsForMonth.earnings,
                            income: statisticsForMonth.income,
                            discountsApplied: statisticsForMonth.discountsApplied ? statisticsForMonth.discountsApplied : 0,
                            losses: statisticsForMonth.losses,
                            quantitySold: statisticsForMonth.quantitySold,
                            returnRate: statisticsForMonth.returnRate,
                            returns: statisticsForMonth.returns,
                        };
                    }
                }
            }

            setIndicators(newIndicators);
            setTodayString(newTodayString);
            setIndicatorsFilters(newIndicatorsFilters);
        }
    // eslint-disable-next-line
    }, [generalStatistics]);

    useEffect(() => {
        const monthsList = [];

        for (let [key] of Object.entries(customersStatistics)) {
            monthsList.push(key);
        }

        monthsList.sort((a, b) => {
            const [monthA, yearA] = a.split('/').map(Number);
            const [monthB, yearB] = b.split('/').map(Number);
            return yearB - yearA || monthB - monthA;
        });

        if (monthsList.length > 0) {
            setCustomersStatisticsView(monthsList[0]);
        }

        setCustomersIndicatorsFilters(monthsList);
        // eslint-disable-next-line
    }, [customersStatistics]);

    // const handleMouseEnter = (o) => {
    //     const { dataKey } = o;

    //     setOpacity((op) => ({ ...op, [dataKey]: 0.5 }));
    // };

    // const handleMouseLeave = (o) => {
    //     const { dataKey } = o;

    //     setOpacity((op) => ({ ...op, [dataKey]: 1 }));
    // };

    const getQuantitySold = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return indicators[todayString].quantitySold;
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return indicators[statisticsView].quantitySold;;
            } else {
                return 0;
            }
        }
    }

    const getReturnRate = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return formatAmount(indicators[todayString].returnRate);
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return formatAmount(indicators[statisticsView].returnRate);
            } else {
                return 0;
            }
        }
    }

    const getGrossIncome = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return formatAmount(indicators[todayString].income);
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return formatAmount(indicators[statisticsView].income);
            } else {
                return 0;
            }
        }
    }

    const getDiscountsApplied = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                if (indicators[todayString].discountsApplied) {
                    return formatAmount(indicators[todayString].discountsApplied);
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                if (indicators[statisticsView].discountsApplied) {
                    return formatAmount(indicators[statisticsView].discountsApplied);
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        }
    }

    const getLosses = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return formatAmount(indicators[todayString].losses);
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return formatAmount(indicators[statisticsView].losses);
            } else {
                return 0;
            }
        }
    }

    const getEarnings = () => {
        if (statisticsView === "today") {
            if (indicators[todayString]) {
                return formatAmount(indicators[todayString].earnings);
            } else {
                return 0;
            }
        } else {
            if (indicators[statisticsView]) {
                return formatAmount(indicators[statisticsView].earnings);
            } else {
                return 0;
            }
        }
    }

    const showCustomersStatisticsControler = () => {
        if (loadingCustomerStatistics || showConnectionErrorCustomer || (!isCustomerStatistics)) {
            return false;
        } else {
            return true;
        }
    }

    const [customersRef, setCustomersRef] = useState(null);

    const setCustomersReference = (inputElement) => {
        setCustomersRef(inputElement);
    }

    useEffect(() => {
        const bottomObserver = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    if (!requestForCustStat) {
                        setRequestForCustStat(true);
                    }
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            }
        );

        if (customersRef) {
            bottomObserver.observe(customersRef);
        }

        return () => {
            if (customersRef) {
                // eslint-disable-next-line
                bottomObserver.unobserve(customersRef);
            }
        };
        // eslint-disable-next-line
    }, [customersRef]);

    const getItemsByMonth = (period) => {
        if (customersStatistics) {
            if (customersStatistics[period]) {
                return customersStatistics[period];
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    const onClickLink = (item) => {
        if (item.customerData) {
            navigate("/ver-cliente", { state: { ...item.customerData, "id": item.customerId } });
        }
    }

    const doesTheCustomerExist = (item) => {
        if (item.customerData) {
            return true;
        } else {
            return false;
        }
    }

    async function getCustomersStatistics() {
        setLoadingCustomerStatistics(true);
        setShowConnectionErrorCustomer(false);

        try {
            const res = await firebase.useGetAllCustomersIndicators({
                "businessId": accountData.id
            });

            if (res) {
                setIsCustomerStatistics(true);
                setCustomersStatistics(res);
            } else {
                setIsCustomerStatistics(false);
            }

        } catch (error) {
            console.log(error);
            setShowConnectionErrorCustomer(true);
        }
        setLoadingCustomerStatistics(false);
    }

    useEffect(() => {
        if (requestForCustStat) {
            getCustomersStatistics();
        }
        // eslint-disable-next-line
    }, [requestForCustStat]);

    const getDisplayIndicators = () => {
        if (isCustomerStatistics && customersStatistics && customersStatistics[customersStatisticsView]) {
            return true;
        } else {
            return false;
        }
    }

    const getShowLosses = (data) => {
        if (Array.isArray(data) && data.length > 0) {
            return data[0] && "Pérdidas" in data[0];
        } else {
           return false; 
        }
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? 'Dashboard' : 'Métricas'} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`} >
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className="page-app-settings-item">
                            {accessTo.metrics ? <Fragment>
                                {desktopView ?
                                    <ToolTitle
                                        icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                        text={appLanguage.en ? "General metrics" : 'Métricas generales'}
                                    />
                                    : null}

                                <div
                                    style={{
                                        height: desktopView ? `calc(100vh - 107px)` : `calc(100vh - 136px)`,
                                        colorScheme: appTheme.dark ? "dark" : "light"
                                    }}
                                    className="customer-details-page-main-container"
                                >
                                    {loading ? <div style={{ marginTop: "90px" }} className="more-details-spinner-container"><Spinner /></div> : <Fragment>
                                        {showConnectionError ? <ConnectionError onTryAgain={onTryAgain} /> :
                                            <div ref={observedRef} className="product-details-page-container" style={{ marginTop: 0 }}>

                                                <div className={`products-indicators-sales-num-cards-metrics-background ${appTheme.dark ? "dar-sec-bg border-box-dark" : "lig-sec-bg border-box-light"}`}>
                                                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", marginBottom: "10px" }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                height: "30px",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                marginRight: "25px"
                                                            }}
                                                        >
                                                            <p style={{ padding: "3px" }}><b>{appLanguage.en ? "Indicators for " : "Indicadores para "}</b></p>
                                                        </div>

                                                        <div style={{ height: "40px", display: "flex" }}>
                                                            <select
                                                                style={{
                                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                                    width: "100%",
                                                                    minWidth: "90px"
                                                                }}
                                                                className={`products-indicators-statistics-sales-input ${appTheme.dark ? "dar-pri-bg products-indicators-statistics-sales-input-dark" : "lig-pri-bg products-indicators-statistics-sales-input-light"}`}
                                                                name="statisticsView"
                                                                value={statisticsView}
                                                                onChange={(e) => onChangeStatisticsView(e)}
                                                            >
                                                                <option value="today">
                                                                    {appLanguage.en ? "Today" : "Hoy"}
                                                                </option>

                                                                {indicatorsFilters.slice().reverse().map((period) => {
                                                                    return (
                                                                        <option key={period} value={period}>
                                                                            {getMonthNameAndFullYear(period)}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <hr
                                                        style={{
                                                            backgroundColor: "rgba(109, 109, 109, 0.5490196078)",
                                                            height: "1px",
                                                            border: "none",
                                                        }}
                                                    />

                                                    {width > 700 ?
                                                        <div style={{ display: "flex", width: "100%", }}>
                                                            <div style={{ display: "flex", width: `100%`, flexWrap: "wrap", alignContent: "flex-start" }}>
                                                                <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Quantity Sold" : "Cantidad Vendida"} {width > 385 ? <br /> : ""} <b>{getQuantitySold()}</b></p>
                                                                <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Disc. Applied" : "Desc. Aplicados"} {width > 385 ? <br /> : ""} <b>RD$ {formatRationalNumber(getDiscountsApplied())}</b></p>
                                                                {false ? <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Return Rate" : "Tasa de Devoluciones"} {width > 385 ? <br /> : ""} <b>{getReturnRate()}</b></p> : null}
                                                                <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Gross Income" : "Ingreso Bruto"} {width > 385 ? <br /> : ""} <b>RD$ {formatRationalNumber(getGrossIncome())}</b></p>
                                                                {getLosses() > 0 ? <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Losses" : "Pérdidas"} {width > 385 ? <br /> : ""} <b>RD$ {formatRationalNumber(getLosses())}</b></p> : null}
                                                                <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Earnings" : "Ganancias"} {width > 385 ? <br /> : ""} <b>RD$ {formatRationalNumber(getEarnings())}</b></p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <Fragment>
                                                            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
                                                                    <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Quantity Sold" : "Cantidad Vendida"}{width > 385 ? <br /> : ":"} <b>{getQuantitySold()}</b></p>
                                                                    <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Disc. Applied" : "Desc. Aplicados"}{width > 385 ? <br /> : ":"} <b>RD$ {formatRationalNumber(getDiscountsApplied())}</b></p>
                                                                    {false ? <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Return Rate" : "Tasa de devoluciones"}{width > 385 ? <br /> : ":"} <b>{getReturnRate()}</b></p> : null}
                                                                    <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Gross income" : "Ingreso Bruto"}{width > 385 ? <br /> : ":"} <b>RD$ {formatRationalNumber(getGrossIncome())}</b></p>
                                                                    {getLosses() > 0 ? <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Losses" : "Pérdidas"}{width > 385 ? <br /> : ":"} <b>RD$ {formatRationalNumber(getLosses())}</b></p> : null}
                                                                    <p style={{ margin: width > 385 ? "10px" : "8px", width: width > 385 ? "165px" : "100%" }}>{appLanguage.en ? "Earnings" : "Ganancias"}{width > 385 ? <br /> : ":"} <b>RD$ {formatRationalNumber(getEarnings())}</b></p>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    }
                                                </div>

                                                <div className={`products-indicators-sales-num-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}>
                                                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                height: "30px",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                marginRight: "25px"
                                                            }}
                                                        >
                                                            <p style={{ padding: "3px" }}><b>{appLanguage.en ? "Cash flow" : "Flujo de dinero"}</b></p>
                                                        </div>

                                                        <div style={{ height: "40px", display: "flex" }}>
                                                            <select
                                                                style={{
                                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                                    width: "100%",
                                                                    minWidth: "90px"
                                                                }}
                                                                className={`products-indicators-statistics-sales-input ${appTheme.dark ? "dar-pri-bg products-indicators-statistics-sales-input-dark" : "lig-pri-bg products-indicators-statistics-sales-input-light"}`}
                                                                name="cashFlowStatisticsView"
                                                                value={cashFlowStatisticsView}
                                                                onChange={(e) => onChangeCashFlowStatisticsView(e)}
                                                            >
                                                                {indicatorsFilters.slice().reverse().map((period) => {
                                                                    return (
                                                                        <option key={period} value={period}>
                                                                            {getMonthNameAndFullYear(period)}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "calc(100% + 18px)",
                                                            height: "180px",
                                                            paddingTop: "10px",
                                                            color: "black",
                                                            marginLeft: "-15px"
                                                        }}
                                                    >
                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <AreaChart
                                                                width={500}
                                                                height={400}
                                                                data={cashFlowData}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid stroke="#88888877" strokeDasharray="3 3" />
                                                                <XAxis stroke={appTheme.dark ? "#ffffff" : "#000000"} dataKey="name" />
                                                                <YAxis stroke={appTheme.dark ? "#ffffff" : "#000000"} />
                                                                <Tooltip content={<CustomTooltip />} />
                                                                {getShowLosses(cashFlowData) ? <Area type="monotone" dataKey="Pérdidas" stackId="1" stroke="#C7253E" fill="#C7253E" /> : null}
                                                                <Area type="monotone" dataKey="Ganancias" stackId="1" stroke="#6daf86" fill="#6daf86" />
                                                                <Area type="monotone" dataKey="Ingresos" stackId="1" stroke="#d6b97f" fill="#d6b97f" />
                                                            </AreaChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>

                                                <div className={`products-indicators-sales-num-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}>
                                                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>

                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                height: "30px",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                marginRight: "25px"
                                                            }}
                                                        >
                                                            <p style={{ padding: "3px" }}><b>{appLanguage.en ? "Quantity sold for " : "Cantidad vendida para "}</b></p>
                                                        </div>

                                                        <div style={{ height: "40px", display: "flex" }}>
                                                            <select
                                                                style={{
                                                                    colorScheme: appTheme.dark ? "dark" : "light",
                                                                    width: "100%",
                                                                    minWidth: "90px"
                                                                }}
                                                                className={`products-indicators-statistics-sales-input ${appTheme.dark ? "dar-pri-bg products-indicators-statistics-sales-input-dark" : "lig-pri-bg products-indicators-statistics-sales-input-light"}`}
                                                                name="saleQuantityStatisticsView"
                                                                value={saleQuantityStatisticsView}
                                                                onChange={(e) => onChangeSaleQuantityStatisticsView(e)}
                                                            >
                                                                {indicatorsFilters.slice().reverse().map((period) => {
                                                                    return (
                                                                        <option key={period} value={period}>
                                                                            {getMonthNameAndFullYear(period)}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "calc(100% + 10px)",
                                                            height: "180px",
                                                            paddingTop: "10px",
                                                            color: "black",
                                                            marginLeft: "-8px"
                                                        }}>

                                                        <ResponsiveContainer width="100%" height="100%">
                                                            <AreaChart
                                                                width={500}
                                                                height={400}
                                                                data={saleQuantityData}
                                                                margin={{
                                                                    top: 10,
                                                                    right: 30,
                                                                    left: 0,
                                                                    bottom: 0,
                                                                }}
                                                            >
                                                                <CartesianGrid stroke="#88888877" strokeDasharray="3 3" />
                                                                <XAxis stroke={appTheme.dark ? "#ffffff" : "#000000"} dataKey="name" />
                                                                <YAxis stroke={appTheme.dark ? "#ffffff" : "#000000"} />
                                                                <Tooltip />
                                                                <Area type="monotone" dataKey="cantidad" stroke="#627890" fill="#627890" />
                                                            </AreaChart>
                                                        </ResponsiveContainer>
                                                    </div>
                                                </div>


                                                <div
                                                    className={`products-indicators-sales-num-cards-metrics-background ${appTheme.dark ? "dar-sec-bg  border-box-dark" : "lig-sec-bg border-box-light"}`}
                                                    style={{
                                                        minHeight: "228.2px"
                                                    }}
                                                >
                                                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                height: "30px",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                alignContent: "center",
                                                                marginRight: "25px"
                                                            }}
                                                        >
                                                            <p style={{ padding: "3px" }}><b>{appLanguage.en ? "Frequent customers" : "Clientes frecuentes"}</b></p>
                                                        </div>

                                                        {showCustomersStatisticsControler() ?
                                                            <div style={{ height: "40px", display: "flex" }}>
                                                                <select
                                                                    style={{
                                                                        colorScheme: appTheme.dark ? "dark" : "light",
                                                                        width: "100%",
                                                                        minWidth: "90px"
                                                                    }}
                                                                    className={`products-indicators-statistics-sales-input ${appTheme.dark ? "dar-pri-bg products-indicators-statistics-sales-input-dark" : "lig-pri-bg products-indicators-statistics-sales-input-light"}`}
                                                                    name="customersStatisticsView"
                                                                    value={customersStatisticsView}
                                                                    onChange={(e) => onChangeCustomersStatisticsView(e)}
                                                                >
                                                                    {customersIndicatorsFilters.map((period) => {
                                                                        return (
                                                                            <option key={period} value={period}>
                                                                                {getMonthNameAndFullYear(period)}
                                                                            </option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        : null}
                                                    </div>

                                                    <div
                                                        ref={setCustomersReference}
                                                        style={{
                                                            width: "100%",
                                                            paddingTop: "15px",
                                                            paddingBottom: "10px",
                                                            marginLeft: "0",
                                                            overflow: "hidden",
                                                            overflowX: "auto",
                                                            colorScheme: appTheme.dark ? "dark" : "light",
                                                        }}
                                                    >
                                                        {loadingCustomerStatistics ? <div style={{ marginBottom: "40px" }}><Spinner /></div> :
                                                            (showConnectionErrorCustomer ? <div style={{ marginTop: "-50px" }}><ConnectionError onTryAgain={onTryAgainCustomerStatistics} /></div> :
                                                                (getDisplayIndicators() ?
                                                                    <table cellPadding="5" cellSpacing="5" style={{ borderCollapse: "collapse", width: "100%" }}>
                                                                        <tbody>
                                                                            <tr style={{ backgroundColor: appTheme.dark ? "#88888877" : "#b1b1b177", borderBottom: "2px solid #88888877" }}>
                                                                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "50px", border: "1px solid #88888877" }}>
                                                                                    <p style={{ fontSize: "14px", padding: "3px" }}>Top</p>
                                                                                </td>

                                                                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "80px", border: "1px solid #88888877" }}>
                                                                                    <p style={{ fontSize: "14px", padding: "3px" }}>ID</p>
                                                                                </td>

                                                                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "180px", border: "1px solid #88888877" }}>
                                                                                    <p style={{ fontSize: "14px", padding: "3px" }}>{appLanguage.en ? "Customer" : "Cliente"}</p>
                                                                                </td>

                                                                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "80px", border: "1px solid #88888877" }}>
                                                                                    <p style={{ fontSize: "14px", padding: "3px" }}>{appLanguage.en ? "Sales quantity" : "Cantidad de ventas"}</p>
                                                                                </td>

                                                                                <td style={{ textAlign: "center", fontWeight: "bold", minWidth: "100px", border: "1px solid #88888877" }}>
                                                                                    <p style={{ fontSize: "14px", padding: "3px" }}>{appLanguage.en ? "Amount Spent" : "Monto gastado"}</p>
                                                                                </td>
                                                                            </tr>

                                                                            {getItemsByMonth(customersStatisticsView).map((item, index) => (
                                                                                <tr
                                                                                    key={index}
                                                                                    onClick={() => onClickLink(item)}
                                                                                    style={{
                                                                                        borderBottom: "1px solid #ddd",
                                                                                        cursor: doesTheCustomerExist(item) ? "pointer" : "auto",
                                                                                    }}
                                                                                    className={doesTheCustomerExist(item) ? (appTheme.dark ? (isTouchEnabled() ? "inventory-table-select-tr-dark-no-hover " : "inventory-table-select-tr-dark-hover") : (isTouchEnabled() ? "inventory-table-select-tr-light-no-hover" : "inventory-table-select-tr-light-hover")) : ""}
                                                                                >
                                                                                    <td style={{ textAlign: "center", fontWeight: "400", minWidth: "50px", border: "1px solid #88888877" }}>
                                                                                        <p style={{ fontSize: "15px", padding: "10px 10px" }}>{item.top}</p>
                                                                                    </td>

                                                                                    <td style={{ textAlign: "center", fontWeight: "400", minWidth: "80px", border: "1px solid #88888877" }}>
                                                                                        <p style={{ fontSize: "15px", padding: "10px 10px" }}>{item.customerId}</p>
                                                                                    </td>

                                                                                    <td style={{ textAlign: "left", fontWeight: "400", minWidth: "180px", border: "1px solid #88888877" }}>
                                                                                        <p
                                                                                            style={{
                                                                                                fontSize: "16px",
                                                                                                padding: "10px 10px",
                                                                                                "display": "-webkit-box",
                                                                                                "WebkitBoxOrient": "vertical",
                                                                                                "WebkitLineClamp": "1",
                                                                                                "lineClamp": "1",
                                                                                                "overflow": "hidden",
                                                                                                "textOverflow": "ellipsis",
                                                                                                paddingTop: 0,
                                                                                                paddingBottom: 0,
                                                                                            }}
                                                                                        >
                                                                                            {item.customerData ? item.customerData.name : "--"}
                                                                                        </p>
                                                                                    </td>

                                                                                    <td style={{ textAlign: "center", fontWeight: "400", minWidth: "80px", border: "1px solid #88888877" }}>
                                                                                        <p style={{ fontSize: "15px", padding: "10px 10px" }}>{item.salesQuantity}</p>
                                                                                    </td>

                                                                                    <td style={{ textAlign: "right", fontWeight: "400", minWidth: "100px", border: "1px solid #88888877" }}>
                                                                                        <p style={{ fontSize: "15px", padding: "10px 10px" }}>{formatRationalNumber(item.amountSpent)}</p>
                                                                                    </td>
                                                                                </tr>

                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    :
                                                                    <div className="no-customer-yet-main-container" style={{ marginTop: "-12px" }}>
                                                                        <img className="no-customer-yet-main-img" src={emptyFolderIcon} alt="No Statistics yet" />
                                                                        <p style={{fontSize: "14px"}}><b>{appLanguage.en ? "There are still no statistics for this period." : "Aún no hay estadísticas para este período."}</b></p>
                                                                    </div>
                                                                )
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                                <div style={{ minHeight: "50px" }} />
                                            </div>
                                        }
                                    </Fragment>}
                                </div>
                            </Fragment>
                                : <SectionForbidden sectionName={appLanguage.en ? 'Dashboard' : 'Métricas'} />}
                        </div>
                        :
                        <div className="page-app-customers-item">
                            <div className="customers-options-container">
                                <div className="customers-options-container-item">
                                    <SettingsActionRequired />
                                </div>
                            </div>
                        </div>
                    }
                </Fragment> : null}
            </div>
        </Fragment>
    )
}

// Función para formatear los valores como dinero
const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value);
};

// Componente Tooltip personalizado
const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ backgroundColor: '#ffffff', padding: '10px', border: '1px solid #cccccc' }}>
                <p className="label">{`${label}`}</p>
                {payload.map((entry, index) => (
                    <p key={`item-${index}`} style={{ color: entry.color }}>
                        {`${entry.name}: ${formatCurrency(entry.value)}`}
                    </p>
                ))}
            </div>
        );
    }

    return null;
};

export default DashboardPage;