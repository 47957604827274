import { Fragment, useContext, useEffect, useState } from "react";
import "./inventoryCreateTable.scss";
import { AppContext } from "../../../context/AppContext";
import { AuthContext } from "../../../firebase/context";

import { SideMenuContext } from "../../../context/SideMenuContext";
import Spinner from "../../../components/spinner/spinner";

import warningIcon from "../../../img/warning.svg";
import ConnectionError from "../../../components/connection-error/connectionError";

import firebase from "../../../firebase";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";
import Spinner2 from "../../../components/spinner2/spinner2";

const InventoryCreateTable = ({
    onScroll,
    makeSticky,
    headerHeight,
    loading,
    fieldAlert,
    setFieldAlert,
    newRecords,
    setNewRecords,
    fieldAlertReference,
    getColumName,
    checkIfRecordEmpty,
    productLimits,
    showConnectionError,
    onTryAgain,
    duplicatedBarcodes,
    duplicatedInTable,
    removeThisDuplicatedFromTable,
    onClickCalculatePrice
}) => {

    const { appTheme, appLanguage, formatRationalNumber, isTouchEnabled, getCategory, getThisBusinessProductsCategories, getUnitsOfMeasurementFor, getRatePer } = useContext(AppContext);
    const { desktopView } = useContext(SideMenuContext);
    const { accountData } = useContext(AuthContext);
    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const [tableHeight, setTableHeight] = useState("calc(100vh - 320px)");

    const [activeField, setActiveFiel] = useState(null);

    const isFieldActive = (x, y) => {
        if (activeField) {
            if (activeField.x === x && activeField.y === y) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const isRowActive = (x) => {
        if (activeField) {
            if (activeField.x === x) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const isColumnActive = (y) => {
        if (activeField) {
            if (activeField.y === y) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (headerHeight) {
            setTableHeight(`calc(100vh - ${headerHeight + 90}px)`)
        }
    }, [headerHeight, desktopView]);

    const borderDark = "2px solid rgba(255, 255, 255, 0.075)";
    const borderLight = "2px solid rgba(0, 0, 0, 0.075)";

    const border2Dark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Light = "2px solid rgba(0, 0, 0, 0.075)";

    const widthCheck = "30px";
    const widthName = "200px";
    const widthDescription = "320px";
    const widthBarcode = "150px";
    const widthLocation = "160px";
    const widthQuantity = "90px";
    const widthCategory = "140px";
    const widthRate = "110px";
    const widthPriceWithoutITBS = "140px";
    const widthITBSpercentage = "90px";
    const widthITBSAmount = "130px";
    const widthFinalPrice = "130px";

    const setFocus = (x, y) => {
        setActiveFiel({ x, y });
    }

    function calculatePriceWithoutTax(totalWithTax, taxPercentage) {
        // Convert the tax percentage to a decimal
        let taxRate = taxPercentage / 100;
        // Calculate the price without tax
        let priceWithoutTax = totalWithTax / (1 + taxRate);
        return priceWithoutTax;
    }

    const checkField = (x, y, fieldContent) => {
        let verifiedText = "";

        switch (y) {
            case 1:
                // name
                if (fieldAlert) {
                    if (fieldAlert.x === x && fieldAlert.y === 1) {
                        setFieldAlert(null);
                    }
                }

                if (fieldContent.length <= 40) {
                    return fieldContent;
                } else {
                    for (let i = 0; ((i < fieldContent.length) && (i <= 40)); i++) {
                        verifiedText += fieldContent[i];
                    }
                    return verifiedText
                }
            case 2:
                // description
                if (fieldContent.length <= 340) {
                    return fieldContent;
                } else {
                    for (let i = 0; ((i < fieldContent.length) && (i <= 340)); i++) {
                        verifiedText += fieldContent[i];
                    }
                    return verifiedText
                }
            case 3:
                // barcode
                if (duplicatedInTable.length > 0) {
                    const currentBarcode = newRecords[x - 1][getColumName(3)];
                    if (duplicatedInTable.includes(currentBarcode.trim())) {
                        removeThisDuplicatedFromTable(currentBarcode.trim())
                    }
                }
                let barcodeNum = "";
                for (let i = 0; i < fieldContent.length; i++) {
                    if ((!(isNaN(fieldContent[i]))) && (fieldContent[i].trim() !== "")) {
                        barcodeNum += fieldContent[i];
                    }
                }
                for (let i = 0; ((i < barcodeNum.length) && (i <= 16)); i++) {
                    verifiedText += barcodeNum[i];
                }
                return verifiedText
            case 4:
                // location
                if (fieldContent.length <= 16) {
                    return fieldContent;
                } else {
                    for (let i = 0; ((i < fieldContent.length) && (i <= 16)); i++) {
                        verifiedText += fieldContent[i];
                    }
                    return verifiedText
                }
            case 5:
                // quantity
                let quantityNum = "";
                let isPointQuantity = false;

                for (let i = 0; i < fieldContent.length; i++) {
                    if (fieldContent[i - 3] !== ".") {
                        if (fieldContent[i] === ".") {
                            if (!isPointQuantity) {
                                quantityNum += fieldContent[i];
                                isPointQuantity = true;
                            }
                        } else {
                            if ((!(isNaN(fieldContent[i]))) && (fieldContent[i].trim() !== "")) {
                                quantityNum += fieldContent[i];
                            }
                        }
                    }
                }

                for (let i = 0; ((i < quantityNum.length) && (i <= 8)); i++) {
                    verifiedText += quantityNum[i];
                }

                return verifiedText
            case 6:
                // category
                return fieldContent;
            case 7:
                // rate
                return fieldContent;
            case 8:
                // priceWithoutTax
                if (fieldAlert) {
                    if (fieldAlert.x === x && fieldAlert.y === 11) {
                        setFieldAlert(null);
                    }
                }

                let priceNum = "";
                let isPointPrice = false;

                for (let i = 0; i < fieldContent.length; i++) {
                    if (fieldContent[i - 3] !== ".") {
                        if (fieldContent[i] === ".") {
                            if (!isPointPrice) {
                                priceNum += fieldContent[i];
                                isPointPrice = true;
                            }
                        } else {
                            if ((!(isNaN(fieldContent[i]))) && (fieldContent[i].trim() !== "")) {
                                priceNum += fieldContent[i];
                            }
                        }
                    }
                }

                if (!isNaN(priceNum.trim()) && priceNum.trim() !== "") {

                    let priceTaxPercentage = 0;

                    if (newRecords[x - 1][getColumName(9)] === "16%") {
                        priceTaxPercentage = 0.16;
                    } else {
                        if (newRecords[x - 1][getColumName(9)] === "18%") {
                            priceTaxPercentage = 0.18;
                        }
                    }

                    const itbisAmount = Number(priceNum) * priceTaxPercentage;

                    const priceRes = {
                        "priceWithoutTax": priceNum,
                        "finalPrice": formatRationalNumber(Number(priceNum) + itbisAmount),
                        "itbisAmount": formatRationalNumber(itbisAmount)
                    }

                    return priceRes;
                } else {
                    const priceRes = {
                        "priceWithoutTax": "",
                        "finalPrice": "",
                        "itbisAmount": ""
                    }
                    return priceRes;
                }
            case 9:
                // itbisPercentage
                const priceNumberString = newRecords[x - 1][getColumName(8)];
                const priceNumber = Number(priceNumberString.split(',').join(''));

                if (priceNumber) {

                    let itbsPercentage = 0;

                    if (fieldContent === "16%") {
                        itbsPercentage = 0.16;
                    } else {
                        if (fieldContent === "18%") {
                            itbsPercentage = 0.18;
                        }
                    }

                    const itbisAmount = Number(priceNumber) * itbsPercentage;

                    const priceRes = {
                        "priceWithoutTax": formatRationalNumber(priceNumber),
                        "finalPrice": formatRationalNumber(Number(priceNumber) + itbisAmount),
                        "itbisPercentage": fieldContent,
                        "itbisAmount": formatRationalNumber(itbisAmount)
                    }

                    return priceRes;
                } else {
                    const priceRes = {
                        "priceWithoutTax": "",
                        "finalPrice": "",
                        "itbisPercentage": fieldContent,
                        "itbisAmount": ""
                    }

                    return priceRes;
                }
            case 10:
                // itbisAmount
                return fieldContent;
            case 11:
                // finalPrice
                if (fieldAlert) {
                    if (fieldAlert.x === x && fieldAlert.y === 11) {
                        setFieldAlert(null);
                    }
                }

                let totalPriceNum = "";
                let isPointTotalPrice = false;

                let taxPercentage = 0;

                if (newRecords[x - 1][getColumName(9)] === "16%") {
                    taxPercentage = 16;
                } else {
                    if (newRecords[x - 1][getColumName(9)] === "18%") {
                        taxPercentage = 18;
                    }
                }

                for (let i = 0; i < fieldContent.length; i++) {
                    if (fieldContent[i - 3] !== ".") {
                        if (fieldContent[i] === ".") {
                            if (!isPointTotalPrice) {
                                totalPriceNum += fieldContent[i];
                                isPointTotalPrice = true;
                            }
                        } else {
                            if ((!(isNaN(fieldContent[i]))) && (fieldContent[i].trim() !== "")) {
                                totalPriceNum += fieldContent[i];
                            }
                        }
                    }
                }

                const priceWithoutTax = calculatePriceWithoutTax(Number(totalPriceNum), taxPercentage);
                const itbisAmount = Number(totalPriceNum) - priceWithoutTax;

                if (!isNaN(totalPriceNum.trim()) && totalPriceNum.trim() !== "") {
                    const priceRes = {
                        "priceWithoutTax": formatRationalNumber(priceWithoutTax),
                        "finalPrice": totalPriceNum,
                        "itbisAmount": formatRationalNumber(itbisAmount)
                    }

                    return priceRes;
                } else {
                    const priceRes = {
                        "priceWithoutTax": "",
                        "finalPrice": "",
                        "itbisAmount": ""
                    }

                    return priceRes;
                }
            case 12:
                // cost
                const text = fieldContent;

                let costAmount = "";
                let isCostPoint = false;

                for (let i = 0; i < fieldContent.length; i++) {
                    if (fieldContent[i - 3] !== ".") {
                        if (fieldContent[i] === ".") {
                            if (!isCostPoint) {
                                costAmount += fieldContent[i];
                                isCostPoint = true;
                            }
                        } else {
                            if ((!(isNaN(text[i]))) && (fieldContent[i].trim() !== "")) {
                                costAmount += fieldContent[i];
                            }
                        }
                    }
                }

                return costAmount;
            default:
                break;
        }
    }

    const formatAmount = (x) => {
        let temRecords = [...newRecords];

        const priceWithoutTax = temRecords[x - 1][getColumName(8)];
        const itbisAmount = temRecords[x - 1][getColumName(10)];
        const finalPrice = temRecords[x - 1][getColumName(11)];
        const cost = temRecords[x - 1][getColumName(12)];

        temRecords[x - 1][getColumName(8)] = priceWithoutTax.trim() === "" ? "" : formatRationalNumber(priceWithoutTax);
        temRecords[x - 1][getColumName(10)] = itbisAmount.trim() === "" ? "" : formatRationalNumber(itbisAmount);
        temRecords[x - 1][getColumName(11)] = finalPrice.trim() === "" ? "" : formatRationalNumber(finalPrice);
        temRecords[x - 1][getColumName(12)] = cost.trim() === "" ? "" : formatRationalNumber(cost);

        setNewRecords(temRecords);
        checkIfRecordEmpty(x, temRecords);
    }

    const handleChangeInput = (e, x, y) => {
        e.preventDefault();

        const fieldContent = e.target.value;

        let temRecords = [...newRecords];

        const verifiedFieldContent = checkField(x, y, fieldContent);

        if (y === 6) {
            temRecords[x - 1][getColumName(y)] = verifiedFieldContent;

            // Check if empty to reset selections
            if (checkIfRecordEmpty(temRecords[x - 1])) {
                temRecords[x - 1][getColumName(6)] = "general";
                temRecords[x - 1][getColumName(7)] = "perUnit";
                temRecords[x - 1][getColumName(9)] = "0%";
            } else {
                temRecords[x - 1][getColumName(7)] = getUnitsOfMeasurementFor(verifiedFieldContent)[0];
            }

            setNewRecords(temRecords);
        } else {
            if (y === 8 || y === 11) {
                temRecords[x - 1][getColumName(8)] = verifiedFieldContent.priceWithoutTax;
                temRecords[x - 1][getColumName(10)] = verifiedFieldContent.itbisAmount;
                temRecords[x - 1][getColumName(11)] = verifiedFieldContent.finalPrice;

                // Check if empty to reset selections
                if (checkIfRecordEmpty(temRecords[x - 1])) {
                    temRecords[x - 1][getColumName(6)] = "general";
                    temRecords[x - 1][getColumName(7)] = "perUnit";
                    temRecords[x - 1][getColumName(9)] = "0%";
                }

                setNewRecords(temRecords);
            } else {
                if (y === 9) {
                    temRecords[x - 1][getColumName(8)] = verifiedFieldContent.priceWithoutTax;
                    temRecords[x - 1][getColumName(9)] = verifiedFieldContent.itbisPercentage;
                    temRecords[x - 1][getColumName(10)] = verifiedFieldContent.itbisAmount;
                    temRecords[x - 1][getColumName(11)] = verifiedFieldContent.finalPrice;

                    // Check if empty to reset selections
                    if (checkIfRecordEmpty(temRecords[x - 1])) {
                        temRecords[x - 1][getColumName(6)] = "general";
                        temRecords[x - 1][getColumName(7)] = "perUnit";
                        temRecords[x - 1][getColumName(9)] = "0%";
                    }

                    setNewRecords(temRecords);
                    // Check if empty to reset selects
                } else {
                    temRecords[x - 1][getColumName(y)] = verifiedFieldContent;
                    
                    // Check if empty to reset selections
                    if (checkIfRecordEmpty(temRecords[x - 1])) {
                        temRecords[x - 1][getColumName(6)] = "general";
                        temRecords[x - 1][getColumName(7)] = "perUnit";
                        temRecords[x - 1][getColumName(9)] = "0%";
                    }

                    setNewRecords(temRecords);
                }
            }
        }
    };

    const isFieldAlert = (x, y) => {
        if (fieldAlert) {
            if (fieldAlert.x === x && fieldAlert.y === y) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const isBarcodeAlert = (barcode) => {
        if (duplicatedBarcodes.includes(Number(barcode)) || duplicatedInTable.includes(barcode.trim())) {
            return true;
        } else {
            return false;
        }
    }

    const showTable = (productLimits) => {
        if (productLimits) {
            if (productLimits.createNew) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }

    const [loadingBarcode, setLoadingBarcode] = useState(false);

    const getUsedBarcodes = () => {
        const usedBarcodes = [];
        if (newRecords) {
            for (let i = 0; i < newRecords.length; i++) {
                const element = newRecords[i];
                if (typeof element.barcode === "string" && element.barcode.trim() !== "") {
                    usedBarcodes.push(element.barcode.trim())
                }
            }
            return usedBarcodes;
        } else {
            return null;
        }
    };

    const getNewBarcode = async (x, y) => {
        setLoadingBarcode({ x, y });

        try {
            const res = await firebase.useGetNewBarcode({
                "businessId": accountData.id,
                "usedBarcodes": getUsedBarcodes(),
            });

            let temRecords = [...newRecords];

            temRecords[x - 1][getColumName(y)] = res;

            setNewRecords(temRecords);

            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'sucess',
                text: {
                    en: `Generated barcode.`,
                    es: `Código de barras generado.`,
                }
            });
        } catch (error) {
            console.error(error);
            setSoftAlertActive(true);
            setSoftAlertData({
                type: 'error',
                text: {
                    en: 'Error when trying to generate barcode.',
                    es: 'Error al intentar generar código de barras.',
                }
            });
        }

        setLoadingBarcode(false);
    }

    const isBarcodeReadOnly = (product, y) => {
        const x = product.number;

        if (loadingBarcode) {
            if ((loadingBarcode.x === x) && (loadingBarcode.y === y)) {
                return true;
            } else {
                return false
            }
        } else {
            return false;
        }
    }

    const showBarcodeGenerator = (product, y) => {
        const x = product.number;

        if (loadingBarcode) {
            if ((loadingBarcode.x === x) && (loadingBarcode.y === y)) {
                return true;
            } else {
                return false
            }
        } else {
            return isFieldActive(product.number, 3) && product.barcode.trim() === "";
        }
    }

    const showCalculatePrice = (product) => {
        return isFieldActive(product.number, 8) && product.priceWithoutTax.trim() === "";
    }

    return (
        <div
            className={`inventory-table-container unselectable ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
            style={{
                maxHeight: tableHeight,
                height: tableHeight,
                colorScheme: appTheme.dark ? "dark" : "light"
            }}
        >
            <div onScroll={onScroll} className="inventory-table-section-1" style={{ overflow: loading ? "hidden" : "auto" }}>
                {loading ? <div style={{ marginTop: "50px", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}><Spinner /></div> : <Fragment>
                    {showTable(productLimits) ?
                        <table className="inventory-table-create-table">
                            <thead>
                                <tr>
                                    <th className={`inventory-table-create-th inventory-table-create-td-sticky `} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? borderDark : borderLight, minWidth: widthCheck, maxWidth: widthCheck, backgroundColor: appTheme.dark ? "#405163" : "#a1b7ce", cursor: "pointer" }}>
                                        #
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthName, maxWidth: widthName, backgroundColor: isColumnActive(1) ? "#425c79" : "#627890" }}>
                                        <p>{appLanguage.en ? "Name" : "Nombre"}</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthDescription, maxWidth: widthDescription, backgroundColor: isColumnActive(2) ? "#425c79" : "#627890" }}>
                                        <p>{appLanguage.en ? "Description" : "Descripción"}</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthBarcode, maxWidth: widthBarcode, backgroundColor: isColumnActive(3) ? "#425c79" : "#627890" }}>
                                        <p>{appLanguage.en ? "Barcode" : "Código de barra"}</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthLocation, maxWidth: widthLocation, backgroundColor: isColumnActive(4) ? "#425c79" : "#627890" }}>
                                        <p>{appLanguage.en ? "Location" : "Ubicación"}</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthQuantity, maxWidth: widthQuantity, backgroundColor: isColumnActive(5) ? "#425c79" : "#627890" }}>
                                        <p>{appLanguage.en ? "Quantity" : "Cantidad"}</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthCategory, maxWidth: widthCategory, backgroundColor: isColumnActive(6) ? "#425c79" : "#627890" }}>
                                        <p>{appLanguage.en ? "Category" : "Categoría"}</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthRate, maxWidth: widthRate, backgroundColor: isColumnActive(7) ? "#425c79" : "#627890" }}>
                                        <p>{appLanguage.en ? "Rate" : "Tarifa"}</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthPriceWithoutITBS, maxWidth: widthPriceWithoutITBS, backgroundColor: isColumnActive(8) ? "#425c79" : "#627890" }}>
                                        <p>{appLanguage.en ? "Cost" : "Costo"}</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthPriceWithoutITBS, maxWidth: widthPriceWithoutITBS, backgroundColor: isColumnActive(8) ? "#425c79" : "#627890" }}>
                                        <p>{appLanguage.en ? "Price without ITBS" : "Precio sin ITBS"}</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthITBSpercentage, maxWidth: widthITBSpercentage, backgroundColor: isColumnActive(9) ? "#425c79" : "#627890" }}>
                                        <p>% ITBS </p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthITBSAmount, maxWidth: widthITBSAmount, backgroundColor: isColumnActive(10) ? "#425c79" : "#627890" }}>
                                        <p>RD$ ITBS</p>
                                    </th>
                                    <th className="inventory-table-create-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthFinalPrice, maxWidth: widthFinalPrice, backgroundColor: isColumnActive(11) ? "#425c79" : "#627890" }}>
                                        <p>{appLanguage.en ? "Final price" : "Precio final"}</p>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {newRecords ?
                                    newRecords.map((product) => {
                                        return (
                                            <tr
                                                key={product.number}
                                            >
                                                <td ref={isFieldAlert(product.number, 1) ? fieldAlertReference : null} className={`inventory-table-create-th-sticky ${appTheme.dark ? "inventory-table-create-td-sticky-dark" : "inventory-table-create-td-sticky-light"}`} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? border2Dark : border2Light, minWidth: widthCheck, maxWidth: widthCheck, backgroundColor: isRowActive(product.number) ? "#425c79" : "", color: isRowActive(product.number) ? "white" : "" }}>
                                                    <span style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                                        <p style={{ fontSize: "12px", fontWeight: "bold" }}>{product.number}</p>
                                                    </span>
                                                </td>
                                                <td>
                                                    {/** product.name */}
                                                    <textarea
                                                        inputMode="text"
                                                        maxLength={40}
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                        style={{ border: isFieldAlert(product.number, 1) ? "2px solid red" : (isFieldActive(product.number, 1) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light)), minWidth: widthName, maxWidth: widthName }}
                                                        onFocus={() => setFocus(product.number, 1)}
                                                        value={product.name}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        onChange={(e) => handleChangeInput(e, product.number, 1)}
                                                        rows={"2"}
                                                    />
                                                </td>
                                                <td>
                                                    {/** product.description */}
                                                    <textarea
                                                        inputMode="text"
                                                        maxLength={340}
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                        style={{ border: isFieldActive(product.number, 2) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light), minWidth: widthDescription, maxWidth: widthDescription }}
                                                        onFocus={() => setFocus(product.number, 2)}
                                                        value={product.description}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        onChange={(e) => handleChangeInput(e, product.number, 2)}
                                                        rows={"2"}
                                                    />
                                                </td>
                                                <td ref={isFieldAlert(product.number, 3) ? fieldAlertReference : null}>
                                                    {/** product.barcode */}
                                                    <textarea
                                                        inputMode="numeric"
                                                        maxLength={16}
                                                        readOnly={isBarcodeReadOnly(product, 3)}
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                        style={{
                                                            border: isBarcodeAlert(product.barcode) ? "2px solid red" : (isFieldActive(product.number, 3) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light)),
                                                            minWidth: widthBarcode,
                                                            maxWidth: widthBarcode,
                                                            padding: showBarcodeGenerator(product, 3) ? (loadingBarcode ? "2px 8px" : "5px 8px") : "10px 8px"
                                                        }}
                                                        onFocus={() => setFocus(product.number, 3)}
                                                        value={product.barcode}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        onChange={(e) => handleChangeInput(e, product.number, 3)}
                                                        rows={showBarcodeGenerator(product, 3) ? "1" : "2"}
                                                    />
                                                    {showBarcodeGenerator(product, 3) ?
                                                        <button
                                                            onClick={() => getNewBarcode(product.number, 3)}
                                                            disabled={loadingBarcode}
                                                            style={{
                                                                color: appTheme.dark ? "white" : "black",
                                                                width: `calc(100% - ${loadingBarcode ? "0px" : "6px"})`,
                                                                borderRadius: "5px",
                                                                minHeight: "24px",
                                                                margin: loadingBarcode ? "0px" : "3px"
                                                            }}
                                                            className={`${isTouchEnabled() ? "product-picture-upload-butt-no-hover" : "product-picture-upload-butt"} unselectable`}
                                                        >
                                                            {loadingBarcode ? <Spinner2 /> : (appLanguage.en ? "Generate code" : "Generar código")}
                                                        </button>
                                                    : null}
                                                </td>
                                                <td>
                                                    {/** product.location */}
                                                    <textarea
                                                        inputMode="text"
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                        style={{ border: isFieldActive(product.number, 4) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light), minWidth: widthLocation, maxWidth: widthLocation }}
                                                        onFocus={() => setFocus(product.number, 4)}
                                                        value={product.location}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        onChange={(e) => handleChangeInput(e, product.number, 4)}
                                                        rows={"2"}
                                                    />
                                                </td>
                                                <td>
                                                    {/** product.quantity */}
                                                    <textarea
                                                        inputMode="numeric"
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                        style={{ border: isFieldActive(product.number, 5) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light), minWidth: widthQuantity, maxWidth: widthQuantity }}
                                                        onFocus={() => setFocus(product.number, 5)}
                                                        value={product.quantity}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        onChange={(e) => handleChangeInput(e, product.number, 5)}
                                                        rows={"2"}
                                                    />
                                                </td>
                                                <td>
                                                    {/** product.category */}
                                                    {checkIfRecordEmpty(newRecords[product.number - 1]) ?
                                                        <textarea
                                                            readOnly
                                                            className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                            style={{ border: isFieldActive(product.number, 6) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light), minWidth: widthCategory, maxWidth: widthCategory }}
                                                            onFocus={() => setFocus(product.number, 6)}
                                                            value={""}
                                                            onKeyDown={e => handleKeyPress(e)}
                                                            rows={"2"}
                                                        />
                                                        :
                                                        <select
                                                            className={`inventory-table-create-td-content-select ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                            style={{ border: isFieldActive(product.number, 6) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light), minWidth: widthCategory, minHeight: isTouchEnabled() ? "61px" : "66.5px" }}
                                                            onFocus={() => setFocus(product.number, 6)}
                                                            value={product.category}
                                                            onChange={(e) => handleChangeInput(e, product.number, 6)}
                                                        >
                                                            {getThisBusinessProductsCategories(accountData.businessCategory).map((category, index) => {
                                                                return (
                                                                    <option key={index} value={category}>{getCategory(category)}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    }
                                                </td>
                                                <td>
                                                    {/** product.rate */}
                                                    {checkIfRecordEmpty(newRecords[product.number - 1]) ?
                                                        <textarea
                                                            readOnly
                                                            className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                            style={{ border: isFieldActive(product.number, 7) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light), minWidth: widthRate, maxWidth: widthRate }}
                                                            onFocus={() => setFocus(product.number, 7)}
                                                            value={""}
                                                            onKeyDown={e => handleKeyPress(e)}
                                                            rows={"2"}
                                                        />
                                                        :
                                                        getUnitsOfMeasurementFor(product.category).length > 1 ?
                                                            <select
                                                                className={`inventory-table-create-td-content-select ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                                style={{ border: isFieldActive(product.number, 7) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light), minWidth: widthRate, minHeight: isTouchEnabled() ? "61px" : "66.5px" }}
                                                                onFocus={() => setFocus(product.number, 7)}
                                                                value={product.rate}
                                                                onChange={(e) => handleChangeInput(e, product.number, 7)}
                                                            >
                                                                {getUnitsOfMeasurementFor(product.category).map((unit, index) => {
                                                                    return (
                                                                        <option key={index} value={unit}>{getRatePer(unit)}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            :
                                                            <textarea
                                                                readOnly
                                                                className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                                style={{ border: isFieldActive(product.number, 7) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light), minWidth: widthRate, maxWidth: widthRate }}
                                                                onFocus={() => setFocus(product.number, 7)}
                                                                value={getRatePer(getUnitsOfMeasurementFor(product.category)[0])}
                                                                onKeyDown={e => handleKeyPress(e)}
                                                                rows={"2"}
                                                            />
                                                    }
                                                </td>
                                                <td>
                                                    {/** product.cost */}
                                                    <textarea
                                                        inputMode="numeric"
                                                        onBlur={() => formatAmount(product.number)}
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                        style={{ border: (isFieldActive(product.number, 12) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light)), minWidth: widthPriceWithoutITBS, maxWidth: widthPriceWithoutITBS }}
                                                        onFocus={() => setFocus(product.number, 12)}
                                                        value={product.cost}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        onChange={(e) => handleChangeInput(e, product.number, 12)}
                                                        rows={"2"}
                                                    />
                                                </td>
                                                <td>
                                                    {/** product.priceWithoutTax */}
                                                    <textarea
                                                        inputMode="numeric"
                                                        onBlur={() => formatAmount(product.number)}
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                        style={{ 
                                                            border: isFieldAlert(product.number, 11) ? "2px solid red" : (isFieldActive(product.number, 8) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light)), 
                                                            minWidth: widthPriceWithoutITBS, 
                                                            maxWidth: widthPriceWithoutITBS,
                                                            padding: showCalculatePrice(product) ? (loadingBarcode ? "2px 8px" : "5px 8px") : "10px 8px"
                                                        }}
                                                        onFocus={() => setFocus(product.number, 8)}
                                                        value={product.priceWithoutTax}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        onChange={(e) => handleChangeInput(e, product.number, 8)}
                                                        rows={showCalculatePrice(product) ? "1" : "2"}
                                                    />
                                                    {showCalculatePrice(product) ?
                                                        <button
                                                            onClick={() => onClickCalculatePrice(product)}
                                                            disabled={loadingBarcode}
                                                            style={{
                                                                color: appTheme.dark ? "white" : "black",
                                                                width: `calc(100% - ${loadingBarcode ? "0px" : "6px"})`,
                                                                borderRadius: "5px",
                                                                minHeight: "24px",
                                                                margin: loadingBarcode ? "0px" : "3px"
                                                            }}
                                                            className={`${isTouchEnabled() ? "product-picture-upload-butt-no-hover" : "product-picture-upload-butt"} unselectable`}
                                                        >
                                                            {loadingBarcode ? <Spinner2 /> : (appLanguage.en ? 'Calculate Price' : 'Calcular Precio')}
                                                        </button>
                                                    : null}
                                                </td>
                                                <td>
                                                    {/** product.itbisPercentage */}
                                                    {checkIfRecordEmpty(newRecords[product.number - 1]) ?
                                                        <textarea
                                                            readOnly
                                                            className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                            style={{ border: isFieldActive(product.number, 9) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light), minWidth: widthITBSpercentage, maxWidth: widthITBSpercentage }}
                                                            onFocus={() => setFocus(product.number, 9)}
                                                            value={""}
                                                            onKeyDown={e => handleKeyPress(e)}
                                                            rows={"2"}
                                                        />
                                                        :
                                                        <select
                                                            className={`inventory-table-create-td-content-select ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                            style={{ border: isFieldActive(product.number, 9) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light), minWidth: widthITBSpercentage, minHeight: isTouchEnabled() ? "61px" : "66.5px" }}
                                                            onFocus={() => setFocus(product.number, 9)}
                                                            value={product.itbisPercentage}
                                                            onChange={(e) => handleChangeInput(e, product.number, 9)}
                                                        >
                                                            <option value="0%" >0 %</option>
                                                            <option value="16%">16 %</option>
                                                            <option value="18%">18 %</option>
                                                        </select>
                                                    }

                                                </td>
                                                <td>
                                                    {/** product.itbisAmount */}
                                                    <textarea
                                                        inputMode="numeric"
                                                        readOnly
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                        style={{ border: isFieldActive(product.number, 10) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light), minWidth: widthITBSAmount, maxWidth: widthITBSAmount }}
                                                        onFocus={() => setFocus(product.number, 10)}
                                                        value={product.itbisAmount}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        rows={"2"}
                                                    />
                                                </td>
                                                <td ref={isFieldAlert(product.number, 11) ? fieldAlertReference : null} >
                                                    {/** product.finalPrice */}
                                                    <textarea
                                                        inputMode="numeric"
                                                        onBlur={() => formatAmount(product.number)}
                                                        className={`inventory-table-create-td-content-editable ${appTheme.dark ? "dar-pri-bg" : "lig-pri-bg"}`}
                                                        style={{ border: isFieldAlert(product.number, 11) ? "2px solid red" : (isFieldActive(product.number, 11) ? "2px solid rgb(108, 167, 221)" : (appTheme.dark ? border2Dark : border2Light)), minWidth: widthITBSAmount, maxWidth: widthITBSAmount }}
                                                        onFocus={() => setFocus(product.number, 11)}
                                                        value={product.finalPrice}
                                                        onKeyDown={e => handleKeyPress(e)}
                                                        onChange={(e) => handleChangeInput(e, product.number, 11)}
                                                        rows={"2"}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })
                                    : null}
                            </tbody>
                        </table>
                        :
                        <LimitExceeded productLimits={productLimits} showConnectionError={showConnectionError} onTryAgain={onTryAgain} />
                    }
                </Fragment>}
            </div>
        </div>
    )
}

const LimitExceeded = ({ productLimits, showConnectionError, onTryAgain }) => {
    const { appLanguage } = useContext(AppContext);

    return (
        <Fragment>
            {showConnectionError ?
                <ConnectionError onTryAgain={onTryAgain} />
                :
                <div className="no-customer-yet-main-container">

                    {productLimits ? <Fragment>
                        <img className="no-customer-yet-main-img" src={warningIcon} alt="No customers yet" />
                        <p><b>{appLanguage.en ? "You cannot add more products" : "No puedes agregar más produtos"}</b></p>
                        <p><b>{productLimits.current.toLocaleString()} {appLanguage.en ? "of" : "de"} {productLimits.limit.toLocaleString()} {appLanguage.en ? "products" : "productos"}</b></p>
                        <p>{appLanguage.en ? `Only ${productLimits.limit.toLocaleString()} products can be created per basic account. You can delete an existing product to add another or upgrade to a more advanced plan.` : `Sólo se pueden crear ${productLimits.limit.toLocaleString()} produtos por plan básica. Puedes eliminar un producto existente para agregar otro o actualizarte a un plan más avanzado.`}</p>
                    </Fragment> : null}

                </div>
            }
        </Fragment>
    )
}

export default InventoryCreateTable;