import { Fragment, useContext, useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet-async";
import { AuthContext } from "../../../firebase/context";
import { AppContext } from "../../../context/AppContext";
import { SideMenuContext } from "../../../context/SideMenuContext";

import ToolTitle from "../../../appTools/appToolsComponents/tool-title/toolTitle";

import bulletPointDarkIcon from '../../../icons/bullet-point-dark.png';
import bulletPointLightIcon from '../../../icons/bullet-point-light.png';
import { uid } from "uid";

import ConnectionError from "../../../components/connection-error/connectionError";
import Spinner from "../../../components/spinner/spinner";
import SaleReceiptResponsa from "../../../receipts/saleReceiptResponsa";
import { useLocation, useNavigate } from "react-router-dom";

import firebase from "../../../firebase";
import { onSnapshot, doc } from "firebase/firestore";

import printerIconDark from "../../../icons/printer-dark.png";
import downloadIconDark from "../../../icons/download-dark.png";
import arrowSmallDark from "../../../icons/arrow-small-right-white.png";
import { SoftLoadingContext } from "../../../components/soft-loading/softLoadingContext";

import ReactToPrint from "react-to-print";
import jsPDF from 'jspdf';
import SaleReceipt from "../../../receipts/saleReceipt";
import SaleReceiptPDF from "../../../receipts/saleReceiptPDF";

const FacturaPage = () => {
    const location = useLocation();

    let headerObservedRef = useRef(null);

    const receiptRef = useRef();
    const receiptRefPDF = useRef();

    const navigate = useNavigate();

    const { accountData, accessTo } = useContext(AuthContext);
    const { appTheme, appLanguage, isTouchEnabled } = useContext(AppContext);
    const { desktopView, windowDimension, isSideMenuMinimized } = useContext(SideMenuContext);
    const { setSoftLoadingActive } = useContext(SoftLoadingContext);

    const [headerHeight, setHeaderHeight] = useState(152);

    const [request, setRequest] = useState(false);

    const [showConnectionError, setShowConnectionError] = useState(false);
    const [loading, setLoading] = useState(true);

    const [factura, setFactura] = useState(null);
    const [sale, setSale] = useState(null);

    useEffect(() => {
        if (location.state && location.state.factura) {
            setRequest(true);
            setFactura(location.state.factura);
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        if (request && factura) {
            const unsub = onSnapshot(doc(firebase.db, `accounts/${accountData.id}/sales`, factura.id), (doc) => {
                const saleData = {
                    ...doc.data(),
                    "id": doc.id,
                };

                delete saleData.searchKeys;

                if (doc.data()) {
                    setSale(saleData);
                }
                setLoading(false);
                setShowConnectionError(false);
            }, err => {
                console.error(err);
                setShowConnectionError(true);
                setLoading(false);
            });
            return () => unsub();
        }
        // eslint-disable-next-line
    }, [request, factura]);

    const [receiptData, setReceiptData] = useState(null);

    const getNewItems = (items) => {
        const newItems = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.price && item.unitValue) {
                item.unitValue = item.price;
            }
            newItems.push(item);
        }
        return newItems;
    }

    useEffect(() => {
        if (sale) {
            setReceiptData({
                ...sale.invoice,
                "comprobante": sale.comprobante ? sale.comprobante : null,
                "items": getNewItems(sale.items),
                "subtotal": sale.subtotal,
                "desc": sale.desc,
                "itbis": sale.itbis,
                "total": sale.total,
                "payments": sale.payments
            })
        } else {
            setReceiptData(null);
        }
        // eslint-disable-next-line
    }, [sale]);

    const onTryAgain = () => {
        setRequest(uid());
    }

    useEffect(() => {
        if (headerObservedRef) {
            if (!headerObservedRef.current) {
                return;
            }

            const resizeObserver = new ResizeObserver(() => {
                try {
                    if (headerObservedRef.current.offsetHeight !== headerHeight) {
                        setHeaderHeight(headerObservedRef.current.offsetHeight);
                    }
                } catch { }
            });

            resizeObserver.observe(headerObservedRef.current);

            return function cleanup() {
                resizeObserver.disconnect();
            }
        }
        // eslint-disable-next-line
    }, [headerObservedRef.current, windowDimension, isSideMenuMinimized]);

    const generatePDF = async () => {
        blinkSoftLoading();
        try {
            const doc = new jsPDF('portrait', 'pt', 'letter');
            doc.html(receiptRefPDF.current, {
                callback: function (pdf) {
                    pdf.save(`Venta_${receiptData.saleId}.pdf`);
                },
                x: 65,
                y: 0,
                html2canvas: {
                    scale: 0.74
                }
            });
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const blinkSoftLoading = () => {
        setSoftLoadingActive(true);
        setTimeout(() => {
            setSoftLoadingActive(false);
        }, 1000);
    }

    const onOpenSale = () => {
        if (sale) {
            navigate("/ver-detalles-de-venta", { state: { id: sale.id } });
        }
    }

    return (
        <Fragment>
            <Helmet htmlAttributes={{ lang: appLanguage.en ? 'en' : 'es' }}>
                <title>HazCuentas - {appLanguage.en ? "See Factura" : "Ver factura"} </title>
            </Helmet>
            <div className={`page-app-customers-container ${appTheme.dark ? 'dark-text' : 'light-text'}`}>
                {accountData && accessTo ? <Fragment>
                    {accountData.active ?
                        <div className={`page-choose-account-item`}>
                            {accessTo.taxes ? <Fragment>
                                <div ref={headerObservedRef}>
                                    {desktopView ?
                                        <ToolTitle
                                            icon={appTheme.dark ? bulletPointDarkIcon : bulletPointLightIcon}
                                            text={appLanguage.en ? "See Factura" : "Ver factura"}
                                        />
                                        : null}

                                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                        <div style={{ display: "flex", width: "100%", maxWidth: "780px", marginTop: "10px" }}>

                                            {(receiptData && (<div onClick={() => blinkSoftLoading()}>
                                                <ReactToPrint
                                                    trigger={() =>
                                                        <button
                                                            onClick={null}
                                                            disabled={loading || (!sale)}
                                                            className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                            style={(!loading) && sale ? {
                                                                margin: "10px 22px 10px 10px"
                                                            } : {
                                                                backgroundColor: "#3f526698",
                                                                color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                                cursor: "not-allowed",
                                                                margin: "10px 22px 10px 10px"
                                                            }}
                                                        >
                                                            <img src={printerIconDark} alt="Imprimir" />
                                                            <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Print" : "Imprimir"}</p>
                                                        </button>}
                                                    content={() => receiptRef.current}
                                                    documentTitle={`Venta_${receiptData.saleId}`}
                                                />
                                            </div>))}
                                            <SaleReceipt receiptData={receiptData} receiptRef={receiptRef} />

                                            <button
                                                onClick={generatePDF}
                                                disabled={loading || (!sale)}
                                                className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                style={(!loading) && sale ? {
                                                    margin: "10px 22px 10px 0px"
                                                } : {
                                                    backgroundColor: "#3f526698",
                                                    color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                    cursor: "not-allowed",
                                                    margin: "10px 22px 10px 0px"
                                                }}
                                            >
                                                <img src={downloadIconDark} alt="Download PDF" />
                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Download PDF" : "Descargar PDF"}</p>
                                            </button>
                                            <SaleReceiptPDF receiptData={receiptData} receiptRef={receiptRefPDF} />

                                            <button
                                                onClick={onOpenSale}
                                                disabled={loading || (!sale)}
                                                className={isTouchEnabled() ? "add-to-card-btn-product-no-hover" : "add-to-card-btn-product"}
                                                style={(!loading) && sale ? {
                                                    margin: "10px 22px 10px 0px",
                                                    padding: "8px 20px"
                                                } : {
                                                    backgroundColor: "#3f526698",
                                                    color: appTheme.dark ? "rgb(173, 173, 173)" : "white",
                                                    cursor: "not-allowed",
                                                    margin: "10px 22px 10px 0px",
                                                    padding: "8px 20px"
                                                }}
                                            >
                                                <img src={arrowSmallDark} style={{ width: "20px", height: "20px" }} alt="Open sale" />
                                                <p style={{ whiteSpace: "nowrap" }}>{appLanguage.en ? "Open sale" : "Abrir venta"}</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {loading ?
                                    <div
                                        style={{
                                            height: `calc(100vh - ${headerHeight + 65}px)`,
                                            overflow: "hidden",
                                            overflowY: "auto",
                                            colorScheme: appTheme.dark ? "dark" : "light",
                                            backgroundColor: appTheme.dark ? "rgb(82, 86, 89)" : "rgb(218, 220, 224)",
                                            borderTop: appTheme.dark ? "2px solid rgba(255, 255, 255, 0.075)" : "2px solid rgba(0, 0, 0, 0.075)",
                                        }}
                                    >
                                        <Spinner />
                                    </div>
                                    :
                                    <div
                                        style={{
                                            height: `calc(100vh - ${headerHeight + 65}px)`,
                                            overflow: "hidden",
                                            overflowY: "auto",
                                            colorScheme: appTheme.dark ? "dark" : "light",
                                            backgroundColor: appTheme.dark ? "rgb(82, 86, 89)" : "rgb(218, 220, 224)",
                                            borderTop: appTheme.dark ? "2px solid rgba(255, 255, 255, 0.075)" : "2px solid rgba(0, 0, 0, 0.075)",
                                        }}
                                    >
                                        {showConnectionError ?
                                            <ConnectionError onTryAgain={onTryAgain} />
                                            :
                                            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>

                                                <div style={{ display: "flex", width: "100%", maxWidth: "740px", flexDirection: "column" }}>
                                                    <div style={{ minHeight: "20px" }} />
                                                    <SaleReceiptResponsa receiptData={receiptData} receiptRef={null} />
                                                    <div style={{ minHeight: "60px" }} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </Fragment> : null}
                        </div>
                        : null}
                </Fragment> : null}
            </div>
        </Fragment>
    );
}

export default FacturaPage;