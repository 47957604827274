import "./labelOptions.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";

import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";

import { LabelOptionsContext } from "./labelOptionsContext";

import closeLightIcon from '../../../icons/cross.png';
import closeDarkIcon from '../../../icons/cross-white.png';

import downloadDarkIcon from '../../../icons/download-dark.png';
import printDarkIcon from '../../../icons/printer-dark.png';
import LabelToImg from "../../../labels/labelToImg";
import LabelToPrint from "../../../labels/labelToPrint";

const LabelOptions = ({ pickerWidth, product }) => {
    const { appTheme, appLanguage, isTouchEnabled } = useContext(AppContext);

    const { labelOptionsActive, setLabelOptionsActive } = useContext(LabelOptionsContext);

    const isLabelSettings = JSON.parse(localStorage.getItem('isLabelSettings'));

    const [labelSettings, setLabelSettings] = useState(isLabelSettings ? isLabelSettings : {
        includeName: true,
        includePrice: true,
    });

    useEffect(() => {
        localStorage.setItem("isLabelSettings", JSON.stringify(labelSettings));
    }, [labelSettings]);

    const [labelData, setLabelData] = useState(null);

    useEffect(() => {
        if (product) {
            setLabelData({
                ...labelSettings,
                barcode: product.barcode,
                name: product.name,
                finalPrice: product.finalPrice
            })
        } else {
            setLabelData(null);
        }
        // eslint-disable-next-line
    }, [product, labelSettings]);

    let menuRef = useRef();

    const labelToImgRef = useRef();
    const labelToPrintRef = useRef();

    useEffect(() => {
        if (labelOptionsActive) {
            let handler = (e) => {
                if (!menuRef.current.contains(e.target)) {
                    setLabelOptionsActive(false);
                };
            };
            document.addEventListener("mousedown", handler);
            return () => {
                document.removeEventListener("mousedown", handler);
            }
        }
    });

    const includeNameHandler = () => {
        setLabelSettings({
            ...labelSettings,
            includeName: !labelSettings.includeName
        })
    }

    const includePriceHandler = () => {
        setLabelSettings({
            ...labelSettings,
            includePrice: !labelSettings.includePrice
        })
    }

    const onClickClose = () => {
        setLabelOptionsActive(false);
    }

    const generateImage = async () => {
        if (labelToImgRef.current && product) {
            const canvas = await html2canvas(labelToImgRef.current);
            const dataURL = canvas.toDataURL("image/png");

            // Crear un enlace para descargar la imagen
            const link = document.createElement("a");
            link.href = dataURL;
            link.download = `(barcode) ${product.name}.png`;
            link.click();
        }
    };

    return (
        <div
            style={{
                WebkitUserSelect: "auto",
                WebkitTouchCallout: "auto",
                MozUserSelect: "auto",
                MsUserSelect: "auto",
                userSelect: "auto",
            }}
        >
            {labelOptionsActive ? <div className='picker-date-lock-screen' style={{ width: pickerWidth, minHeight: "calc(100vh - 65px)" }} /> : null}
            <div ref={menuRef} className={`picker-date-genaral-card ${labelOptionsActive ? 'active' : 'inactive'}`} style={{ marginLeft: `calc(${pickerWidth / 2}px - 175px)`, marginTop: "45px", background: appTheme.dark ? "#555" : "white", color: appTheme.dark ? "white" : "black" }}>
                {product ?
                    <div className='picker-date-general-despcrition' style={{ display: "flex", minHeight: "280px" }}>
                        <div
                            className="unselectable"
                            style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "8px" }}
                        >
                            <h4 style={{ marginLeft: "15px" }}>
                                <span style={{ fontWeight: "400" }}>{appLanguage.en ? "Product label" : "Etiqueta del producto"}</span>
                            </h4>
                            <button
                                onClick={onClickClose}
                                className={`see-details-transations-view unselectable ${appTheme.dark ? 'dar-box-sha-none-hover' : 'lig-box-sha-none-hover'}`}
                            >
                                <img className="see-details-transations-img" src={appTheme.dark ? closeDarkIcon : closeLightIcon} alt="close" />
                            </button>
                        </div>

                        <div
                            className="unselectable"
                            style={{
                                display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
                            }}
                        >
                            <div style={{ width: "calc(100% - 20px)", padding: "15px 0 5px 15px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <p onClick={includeNameHandler} className={`settings-auth-user-access-p ${isTouchEnabled() ? "labelOptionContainer-no-hover" : "labelOptionContainer"} `}>
                                    <input
                                        style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                        className='settings-auth-user-access-input'
                                        type="checkbox"
                                        checked={labelSettings.includeName}
                                        readOnly
                                    />

                                    <label style={{ paddingLeft: "5px", cursor: "pointer" }}>
                                        <b>{appLanguage.en ? 'Include name' : 'Incluir nombre'}</b>
                                    </label>
                                </p>

                                <p onClick={includePriceHandler} className={`settings-auth-user-access-p ${isTouchEnabled() ? "labelOptionContainer-no-hover" : "labelOptionContainer"}`}>
                                    <input
                                        style={appTheme.dark ? { colorScheme: "dark" } : { colorScheme: "light" }}
                                        className='settings-auth-user-access-input'
                                        type="checkbox"
                                        checked={labelSettings.includePrice}
                                        readOnly
                                    />

                                    <label style={{ paddingLeft: "5px", cursor: "pointer" }}>
                                        <b>{appLanguage.en ? 'Include price' : 'Incluir precio'}</b>
                                    </label>
                                </p>
                            </div>

                            <LabelToImg labelData={labelData} labelRef={labelToImgRef} />

                            <div style={{ display: "flex", width: "calc(100% - 30px)", justifyContent: "space-between", marginTop: "15px", marginBottom: "10px" }}>

                                <LabelToPrint labelData={labelData} labelRef={labelToPrintRef} />
                                {labelData && (
                                    <ReactToPrint
                                        trigger={() =>
                                            <button
                                                onClick={null}
                                                style={{ margin: "10px" }}
                                                className={isTouchEnabled() ? "product-label-btn-no-hover" : "product-label-btn"}
                                            >
                                                <img src={printDarkIcon} alt="Print" />
                                                {appLanguage.en ? <p>Print</p> : <p>Imprimir</p>}
                                            </button>
                                        }
                                        content={() => labelToPrintRef.current}
                                        documentTitle={`(barcode) ${labelData.name}.png`}
                                    />
                                )}

                                <button
                                    onClick={generateImage}
                                    className={isTouchEnabled() ? "product-label-btn-no-hover" : "product-label-btn"}
                                    style={{ margin: "10px" }}
                                >
                                    <img src={downloadDarkIcon} alt="Download" />
                                    {appLanguage.en ? <p>Download</p> : <p>Descargar</p>}
                                </button>
                            </div>
                            
                        </div>
                    </div>
                    : null}
            </div>
        </div>
    )
}

export default LabelOptions;