import { useContext, useState } from "react";
import "./inventoryUpload.scss";

import { AppContext } from "../../../context/AppContext";
import { AlertContext } from "../../../components/alert/alertContext";
import { SoftAlertContext } from "../../../components/soft-alert/softAlertContext";

import uploadIconDark from "../../../icons/upload-dark.png";

import downloadImgDark from "../../../icons/download-dark.png";
import downloadImgLight from "../../../icons/download-light.png";

import productsTemplateEn from "../../../files/Product template.xlsx"
import productsTemplateEs from "../../../files/Plantilla productos.xlsx";

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const InventoryUpload = ({
    headerHeight,
    isRecordEmpty,
    setShowPageNav,
    productLimits,
    setNavControlers,
    setShowingTable,
    setEditingRecords,
    setCurrentRecords,
    setRecordsBeenDisplay
}) => {

    const { appTheme, appLanguage, isTouchEnabled, formatRationalNumber, rateData, categoryData } = useContext(AppContext);
    const { setAlertData, setAlertActive } = useContext(AlertContext);

    const { setSoftAlertActive, setSoftAlertData } = useContext(SoftAlertContext);

    const [isDragOver, setIsDragOver] = useState(false);

    const handleDragEnter = () => {
        setIsDragOver(true);
    };

    const handleDragLeave = (e) => {
        // Check if the related target is outside the drop area
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setIsDragOver(false);
        }
    };

    const [downloading, setDownloading] = useState(false);

    const blickDownloading = () => {
        setDownloading(true);
        setTimeout(() => {
            setDownloading(false);
        }, 1400);
    }

    const downloadTemplate = async () => {
        blickDownloading();
        try {
            const response = await fetch(appLanguage.en ? productsTemplateEn : productsTemplateEs);
            const blob = await response.blob();
            saveAs(blob, `${appLanguage.en ? "Products template" : "Plantilla de productos"}.xlsx`);
        } catch (error) {
            console.error('Error fetching and saving the file:', error);
        }
    }

    const handleDrop = (e) => {
        setIsDragOver(false);

        const files = e.dataTransfer.files;

        if (files.length !== 1) {
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to open file',
                    es: 'Error al intentar abrir archivo',
                },
                code: "invalid file",
                description: {
                    en: 'You can only select one file',
                    es: 'Solo puedes seleccionar un archivo'
                }
            });
            setAlertActive(true);
            return;
        }

        const file = files[0];

        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to open file',
                    es: 'Error al intentar abrir archivo',
                },
                code: "invalid file",
                description: {
                    en: 'Please select a valid .xlsx file.',
                    es: 'Por favor, seleccione un archivo .xlsx válido.'
                }
            });
            setAlertActive(true);
            return;
        }

        readFileData(file);
    };

    const onChangeFile = (file) => {
        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setAlertData({
                type: 'error',
                title: {
                    en: 'Error trying to open file',
                    es: 'Error al intentar abrir archivo',
                },
                code: "invalid file",
                description: {
                    en: 'Please select a valid .xlsx file.',
                    es: 'Por favor, seleccione un archivo .xlsx válido.'
                }
            });
            setAlertActive(true);
            return;
        }
        readFileData(file);
    }

    const readFileData = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            // Assuming the data is in the first sheet
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            processData(jsonData);
        };

        reader.readAsArrayBuffer(file);
    };

    function isStringOrNumber(value) {
        return typeof value === 'string' || (typeof value === 'number' && !isNaN(value));
    }

    function removeAllButNumbersAndDot(input) {
        return input.replace(/[^0-9.]/g, '');
    }

    function normalizeString(input) {
        const accentsMap = {
            'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
            'Á': 'a', 'É': 'e', 'Í': 'i', 'Ó': 'o', 'Ú': 'u',
            'ñ': 'n', 'Ñ': 'n'
        };

        return input
            .toLowerCase()
            .trim()
            .replace(/[áéíóúÁÉÍÓÚñÑ]/g, match => accentsMap[match]);
    }

    const getKeyName = (key) => {
        switch (normalizeString(String(key))) {
            case "nombre":
                return "name";
            case "name":
                return "name";
            case "descripcion":
                return "description";
            case "description":
                return "description";
            case "codigo de barra":
                return "barcode";
            case "barcode":
                return "barcode";
            case "ubicacion":
                return "location";
            case "location":
                return "location";
            case "cantidad":
                return "quantity"
            case "quantity":
                return "quantity"
            case "categoria":
                return "category"
            case "category":
                return "category"
            case "tarifa":
                return "rate"
            case "rate":
                return "rate"
            case "costo":
                return "cost"
            case "cost":
                return "cost"
            case "precio sin itbis":
                return "priceWithoutTax"
            case "price without itbis":
                return "priceWithoutTax"
            case "% itbis":
                return "itbisPercentage"
            case "rd$ itbis":
                return "itbisAmount"
            case "precio final":
                return "finalPrice"
            case "final price":
                return "finalPrice"
            default:
                return null;
        }
    }

    const extractCategory = (text) => {
        for (const key in categoryData) {
            if (
                rateData[key] && 
                (rateData[key].en.toLowerCase() === text.toLowerCase() || rateData[key].es.toLowerCase() === text.toLowerCase())
            ) {
                return key;
            }
        }
        return false;
    };

    const extractRate = (text) => {
        for (const key in rateData) {
            if (
                rateData[key] && 
                (rateData[key].en.toLowerCase() === text.toLowerCase() || rateData[key].es.toLowerCase() === text.toLowerCase())
            ) {
                return key;
            }
        }
        return false;
    };

    const extractItbisPercentage = (text) => {
        switch (normalizeString(text.replace("%", ""))) {
            case ".16":
                return "16%";
            case "0.16":
                return "16%";
            case "16":
                return "16%";
            case ".18":
                return "18%";
            case "0.18":
                return "18%";
            case "18":
                return "18%";
            default:
                return false;
        }
    }

    const convertFieldIntoString = (record, num) => {
        const newRecord = {
            "number": num,
            "name": "",
            "category": "general",
            "rate": "perUnit",
            "description": "",
            "quantity": "",
            "location": "",
            "barcode": "",
            "cost": "",
            "priceWithoutTax": "",
            "itbisPercentage": "0%",
            "itbisAmount": "",
            "finalPrice": "",
        }

        for (const key in record) {
            if (record.hasOwnProperty(key)) {
                if (isStringOrNumber(record[key])) {
                    const keyName = getKeyName(key);

                    const unverifiedValue = String(record[key]).trim() === "--" ? "" : String(record[key]);
                    let cadenaConEspacios = unverifiedValue.replace(/\n/g, ' ');
                    let fieldValue = cadenaConEspacios.replace(/\s+/g, ' ');

                    let verifiedText = "";

                    if (keyName) {
                        switch (keyName) {
                            case "name":
                                if (fieldValue.length <= 40) {
                                    newRecord[keyName] = fieldValue;
                                } else {
                                    for (let i = 0; ((i < fieldValue.length) && (i <= 40)); i++) {
                                        verifiedText += fieldValue[i];
                                    }
                                    newRecord[keyName] = verifiedText;
                                }
                                break;
                            case "description":
                                if (fieldValue.length <= 340) {
                                    newRecord[keyName] = fieldValue;
                                } else {
                                    for (let i = 0; ((i < fieldValue.length) && (i <= 340)); i++) {
                                        verifiedText += fieldValue[i];
                                    }
                                    newRecord[keyName] = verifiedText;
                                }
                                break;
                            case "barcode":
                                let barcodeNum = "";
                                for (let i = 0; i < fieldValue.length; i++) {
                                    if ((!(isNaN(fieldValue[i]))) && (fieldValue[i].trim() !== "")) {
                                        barcodeNum += fieldValue[i];
                                    }
                                }
                                for (let i = 0; ((i < barcodeNum.length) && (i <= 16)); i++) {
                                    verifiedText += barcodeNum[i];
                                }
                                newRecord[keyName] = verifiedText;
                                break;
                            case "location":
                                if (fieldValue.length <= 16) {
                                    newRecord[keyName] = fieldValue;
                                } else {
                                    for (let i = 0; ((i < fieldValue.length) && (i <= 16)); i++) {
                                        verifiedText += fieldValue[i];
                                    }
                                    newRecord[keyName] = verifiedText;
                                }
                                break;
                            case "quantity":
                                let quantityNum = "";
                                for (let i = 0; i < fieldValue.length; i++) {
                                    if ((!(isNaN(fieldValue[i]))) && (fieldValue[i].trim() !== "")) {
                                        quantityNum += fieldValue[i];
                                    }
                                }
                                for (let i = 0; ((i < quantityNum.length) && (i <= 16)); i++) {
                                    verifiedText += quantityNum[i];
                                }
                                newRecord[keyName] = verifiedText;
                                break;
                            case "category":
                                const categoryValue = extractCategory(fieldValue);
                                if (categoryValue) {
                                    newRecord[keyName] = categoryValue;
                                }
                                break;
                            case "rate":
                                const rateValue = extractRate(fieldValue);
                                if (rateValue) {
                                    newRecord[keyName] = rateValue;
                                }
                                break;
                            case "cost":
                                let costNum = "";
                                let isPointCost = false;
                                const formattedCostFieldValue = removeAllButNumbersAndDot(fieldValue);

                                for (let i = 0; i < formattedCostFieldValue.length; i++) {
                                    if (formattedCostFieldValue[i - 3] !== ".") {
                                        if (formattedCostFieldValue[i] === ".") {
                                            if (!isPointCost) {
                                                costNum += formattedCostFieldValue[i];
                                                isPointCost = true;
                                            }
                                        } else {
                                            if ((!(isNaN(formattedCostFieldValue[i]))) && (formattedCostFieldValue[i].trim() !== "")) {
                                                costNum += formattedCostFieldValue[i];
                                            }
                                        }
                                    }
                                }
                                newRecord[keyName] = costNum ? formatRationalNumber(costNum) : "";
                                break;
                            case "priceWithoutTax":
                                let priceNum = "";
                                let isPointPrice = false;
                                const formattedPriceFieldValue = removeAllButNumbersAndDot(fieldValue);

                                for (let i = 0; i < formattedPriceFieldValue.length; i++) {
                                    if (formattedPriceFieldValue[i - 3] !== ".") {
                                        if (formattedPriceFieldValue[i] === ".") {
                                            if (!isPointPrice) {
                                                priceNum += formattedPriceFieldValue[i];
                                                isPointPrice = true;
                                            }
                                        } else {
                                            if ((!(isNaN(formattedPriceFieldValue[i]))) && (formattedPriceFieldValue[i].trim() !== "")) {
                                                priceNum += formattedPriceFieldValue[i];
                                            }
                                        }
                                    }
                                }
                                newRecord[keyName] = formatRationalNumber(priceNum);
                                break;
                            case "itbisPercentage":
                                const itbisPercentageValue = extractItbisPercentage(fieldValue);
                                if (itbisPercentageValue) {
                                    newRecord[keyName] = itbisPercentageValue;
                                }
                                break;
                            case "itbisAmount":
                                let itbisAmounteNum = "";
                                let isPointItbisAmount = false;
                                const formattedItbisAmountFieldValue = removeAllButNumbersAndDot(fieldValue);

                                for (let i = 0; i < formattedItbisAmountFieldValue.length; i++) {
                                    if (formattedItbisAmountFieldValue[i - 3] !== ".") {
                                        if (formattedItbisAmountFieldValue[i] === ".") {
                                            if (!isPointItbisAmount) {
                                                itbisAmounteNum += formattedItbisAmountFieldValue[i];
                                                isPointItbisAmount = true;
                                            }
                                        } else {
                                            if ((!(isNaN(formattedItbisAmountFieldValue[i]))) && (formattedItbisAmountFieldValue[i].trim() !== "")) {
                                                itbisAmounteNum += formattedItbisAmountFieldValue[i];
                                            }
                                        }
                                    }
                                }
                                newRecord[keyName] = formatRationalNumber(itbisAmounteNum);
                                break;
                            case "finalPrice":
                                let totalPriceNum = "";
                                let isPointTotalPrice = false;
                                const formattedTotalPriceFieldValue = removeAllButNumbersAndDot(fieldValue);

                                for (let i = 0; i < formattedTotalPriceFieldValue.length; i++) {
                                    if (formattedTotalPriceFieldValue[i - 3] !== ".") {
                                        if (formattedTotalPriceFieldValue[i] === ".") {
                                            if (!isPointTotalPrice) {
                                                totalPriceNum += formattedTotalPriceFieldValue[i];
                                                isPointTotalPrice = true;
                                            }
                                        } else {
                                            if ((!(isNaN(formattedTotalPriceFieldValue[i]))) && (formattedTotalPriceFieldValue[i].trim() !== "")) {
                                                totalPriceNum += formattedTotalPriceFieldValue[i];
                                            }
                                        }
                                    }
                                }
                                newRecord[keyName] = formatRationalNumber(totalPriceNum);
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
        }
        if (isRecordEmpty(newRecord)) {
            return null;
        } else {
            return newRecord;
        }
    }

    const processData = (jsonData) => {
        if (Array.isArray(jsonData)) {
            if (jsonData.length > 0) {
                const newTempRecords = [];
                const newTempBeenDisplaying = [];
                let showLimitExceededAlert = false;
                const maximumQuantity = productLimits.current >= productLimits.limit ? 0 : (productLimits.limit - productLimits.current);
                for (let i = 0; (i < jsonData.length && newTempRecords.length <= maximumQuantity); i++) {
                    const newRecord = convertFieldIntoString(jsonData[i], i + 1);
                    if (newRecord) {
                        if (newTempRecords.length < maximumQuantity) {
                            newTempRecords.push(newRecord);
                            if (newTempBeenDisplaying.length < 30) {
                                newTempBeenDisplaying.push(newRecord);
                            }
                        } else {
                            showLimitExceededAlert = true;
                        }
                    }
                }

                if (newTempRecords.length > 0) {
                    setEditingRecords(newTempRecords);
                    setCurrentRecords(newTempRecords);
                    setRecordsBeenDisplay(newTempBeenDisplaying);
                    setShowingTable(true);
                    if (newTempRecords.length > 30) {
                        setShowPageNav(true);
                        setNavControlers({
                            "page": 1,
                            "totalPage": Math.ceil(newTempRecords.length / 30),
                            "isPrevious": false,
                            "isNext": (Math.ceil(newTempRecords.length / 30) > 1 ? true : false),
                        });
                    } else {
                        setShowPageNav(false);
                        setNavControlers({
                            "page": 1,
                            "totalPage": 1,
                            "isPrevious": false,
                            "isNext": false,
                        });
                    }
                    if (showLimitExceededAlert) {
                        setSoftAlertData({
                            type: 'error',
                            text: {
                                en: 'Some records were not uploaded',
                                es: 'Algunos registros no se cargaron',
                            }
                        });
                        setSoftAlertActive(true);
                    } else {
                        setSoftAlertData({
                            type: 'success',
                            text: {
                                en: 'Records uploaded successfully',
                                es: 'Registros cargados exitosamente',
                            }
                        });
                        setSoftAlertActive(true);
                    }
                } else {
                    setAlertData({
                        type: 'error',
                        title: {
                            en: 'Error trying to open file',
                            es: 'Error al intentar abrir archivo',
                        },
                        code: "invalid file",
                        description: {
                            en: 'Please select a valid .xlsx file.',
                            es: 'Por favor, seleccione un archivo .xlsx válido.'
                        }
                    });
                    setAlertActive(true);
                }
            }
        }
    }

    return (
        <div
            className="inventory-uploading-products-main-cont"
            style={{
                height: `calc(100vh - ${65 + headerHeight}px)`,
                colorScheme: appTheme.dark ? "dark" : "light"
            }}
        >
            <div className="inventory-uploading-products-cont">
                {productLimits ? <p style={{ marginBottom: "15px" }}>{productLimits.current.toLocaleString()} {appLanguage.en ? "of" : "de"} {productLimits.limit.toLocaleString()} {appLanguage.en ? "products" : "productos"}</p> : null}
                <p> {appLanguage.en ?
                    "Upload an Excel file (.xlsx) to add multiple products simultaneously to your business account."
                    :
                    "Sube un archivo de Excel (.xlsx) para agregar múltiples productos simultáneamente a tu cuenta de negocios."
                }</p>

                <div className="inventory-uploading-products-drop-cont">
                    <div
                        className={`inventory-uploading-dropzone ${isDragOver ? 'inventory-uploading-drag-over' : ''}`}
                        onDragEnter={handleDragEnter}
                        onDragLeave={e => handleDragLeave(e)}
                        onDrop={handleDrop}
                    >
                        <p>{appLanguage.en ? "Drag and Drop .xlsx file" : "Arrastrar y soltar el archivo .xlsx"}</p>
                        <p style={{ margin: "20px" }}>{appLanguage.en ? "Or" : "Ó"}</p>
                        <label
                            htmlFor="file"
                            className={`${isTouchEnabled() ? "inventory-uploading-input-con-f-no-hover" : "inventory-uploading-input-con-f"} unselectable`}
                        >
                            <p>{appLanguage.en ? "Select File" : "Seleccionar archivo"}</p>
                            <img src={uploadIconDark} alt="Receipt" />
                            <input
                                type="file"
                                id="file"
                                name="file"
                                accept=".xlsx"
                                value=""
                                onChange={(event) => onChangeFile(event.target.files[0])}
                            />
                        </label>

                    </div>
                </div>

                <p style={{ marginTop: "50px", marginBottom: "10px" }}>{appLanguage.en ? "If you need an example of the Excel file, you can download the following template:" : "Si necesita un ejemplo del archivo Excel, puede descargar la siguiente plantilla: "} </p>

                <button
                    onClick={downloadTemplate}
                    className={`${appTheme.dark ? "dark-text" : "light-text"} ${downloading ? "skeleton-product" : ""} ${isTouchEnabled() ? "inventory-downloading-btn-product-no-hover" : "inventory-downloading-btn-product"}`}
                >
                    {downloading ? null : <img
                        src={appTheme.dark ? downloadImgDark : downloadImgLight}
                        alt="Download template"
                    />}
                    <p>{downloading ? `${appLanguage.en ? "Downloading..." : "Descargando..."}` : (appLanguage.en ? "Download template" : "Descargar plantilla")}</p>
                </button>

                <div style={{ minHeight: "35px" }} />
            </div>
        </div>
    )
}

export default InventoryUpload;