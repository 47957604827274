import { Fragment, useContext, useEffect, useState } from "react";

import { AppContext } from "../../../context/AppContext";

import emptyBox from "../../../img/empty-box.svg";
import emptySearch from "../../../img/empty-search.svg";
import { SideMenuContext } from "../../../context/SideMenuContext";
import PageNavigator from "../pageNavigator/pageNavigator";
import Spinner from "../../../components/spinner/spinner";
import ConnectionError from "../../../components/connection-error/connectionError";

const FacturasSelectTable = ({
    onScroll,
    headerHeight,
    loading,
    showConnectionError,
    tryAgain,
    results,
    onClickLink,
    activeId,
    setActiveReference,
    pageNumber,
    navControlers,
    showPageNav,
    onClickNext,
    onClickPrevious,
    dropDownMoreOpen,
    dropDownMenuOpen,
    dropDownOptionsOpen,
    selecting,
    iAmSelected,
    onClickSelectAll,
    selectingAll,
    empty,
}) => {
    const { 
        appTheme,
        appLanguage,
        formatRationalNumber,
        getTimeInString,
        getDateWithMonthShort,
        dropDownCartOpen,
        dropDownProfileMenuOpen,
        isTouchEnabled,
    } = useContext(AppContext);

    const { desktopView } = useContext(SideMenuContext);

    const [tableHeight, setTableHeight] = useState("calc(100vh - 320px)");

    useEffect(() => {
        if (headerHeight) {
            setTableHeight(`calc(100vh - ${headerHeight + 145 + (desktopView ? 0 : -41)}px)`)
        }
    }, [headerHeight, desktopView]);

    const [makeSticky, setMakeSticky] = useState(true);

    useEffect(() => {
        if (dropDownMenuOpen || dropDownMoreOpen || dropDownOptionsOpen || dropDownCartOpen || dropDownProfileMenuOpen) {
            setMakeSticky(false);
        } else {
            setMakeSticky(true);
        }
    }, [dropDownMenuOpen, dropDownMoreOpen, dropDownOptionsOpen, dropDownCartOpen, dropDownProfileMenuOpen]);

    const [ empty2, setEmpty2 ] = useState(results ? (results.length > 0 ? false : true) : true)

    useEffect(() => {
        setEmpty2(results ? (results.length > 0 ? false : true) : true)
    }, [results]);

    const borderDark = "2px solid rgba(255, 255, 255, 0.075)";
    const borderLight = "2px solid rgba(0, 0, 0, 0.075)";

    const border2Dark = "2px solid rgba(255, 255, 255, 0.075)";
    const border2Light = "2px solid rgba(0, 0, 0, 0.075)";

    const widthCheck = "30px";
    const widthRecordId = "150px";
    const widthTotal = "130px";
    const widthPending = "130px";
    const widthHour = "120px";
    const widthDate = "130px";
    const widthDgiiEstado = "170px";

    return (
        <div
            className={`inventory-table-container unselectable ${appTheme.dark ? "dar-sec-bg" : "lig-sec-bg"}`}
            style={{
                maxHeight: tableHeight,
                height: tableHeight,
                colorScheme: appTheme.dark ? "dark" : "light",
                borderRadius: "10px",
            }}
        >
            <div onScroll={onScroll} className="inventory-table-section-1" style={{overflow: loading ? "hidden" : "auto"}}>
                {loading ? 
                    <div style={{ marginTop: "50px", display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                        <Spinner />
                    </div> 
                : <Fragment>
                    {showConnectionError ? <ConnectionError onTryAgain={tryAgain} /> : <Fragment>
                        {(!empty2) ?
                            <table className="inventory-table-select-table">
                                <thead>
                                    <tr>
                                        {selecting ? 
                                            <th onClick={onClickSelectAll} className={`inventory-table-select-th inventory-table-select-td-sticky `} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? borderDark : borderLight, minWidth: widthCheck, maxWidth: widthCheck, backgroundColor: appTheme.dark ? "#405163" : "#a1b7ce", cursor: "pointer"}}>
                                                <span style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
                                                    <input 
                                                        checked={selectingAll}
                                                        className="inventory-table-select-checkbox" 
                                                        type="checkbox"
                                                        readOnly
                                                    />
                                                </span> 
                                            </th>
                                        : null}
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthRecordId, maxWidth: widthRecordId }}>
                                            <p>{"NCF"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthTotal, maxWidth: widthTotal }}>
                                            <p>{"Total"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthPending, maxWidth: widthPending }}>
                                            <p>{appLanguage.en ? "Pending" : "Pendiente"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthHour, maxWidth: widthHour }}>
                                            <p>{appLanguage.en ? "Hour" : "Hora"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthDate, maxWidth: widthDate }}>
                                            <p>{appLanguage.en ? "Date" : "Fecha"}</p>
                                        </th>
                                        <th className="inventory-table-select-th" style={{ position: makeSticky ? "sticky" : "static", border: borderDark, minWidth: widthDgiiEstado, maxWidth: widthDgiiEstado, borderTopRightRadius: "10px" }}>
                                            <p>{"Estado en la DGII"}</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {results ?
                                        results.map((record) => {
                                            return (
                                                <tr 
                                                    className={appTheme.dark ? (isTouchEnabled() ? "inventory-table-select-tr-dark-no-hover " : "inventory-table-select-tr-dark-hover") : (isTouchEnabled() ? "inventory-table-select-tr-light-no-hover" : "inventory-table-select-tr-light-hover")} 
                                                    style={
                                                        ((record.id === activeId) || iAmSelected(record.id)) ? 
                                                            {
                                                                cursor: "pointer",
                                                                backgroundColor: "#62789036",
                                                                border: "1px solid #627890a9"
                                                            } 
                                                        :
                                                            {
                                                                cursor: "pointer",
                                                            }
                                                        } 
                                                    key={record.id} 
                                                    ref={record.id === activeId ? setActiveReference : null}
                                                    onClick={() => onClickLink(record)}
                                                >
                                                    {selecting ? 
                                                        <td className={`inventory-table-select-td inventory-table-select-th-sticky ${appTheme.dark ? "inventory-table-select-td-sticky-dark" : "inventory-table-select-td-sticky-light"}`} style={{ position: makeSticky ? "sticky" : "static", border: appTheme.dark ? border2Dark : border2Light, minWidth: widthCheck, maxWidth: widthCheck }}>
                                                            <span style={{display: "flex", width: "100%", justifyContent: "center", alignItems: "center"}}>
                                                                <input 
                                                                    className="inventory-table-select-checkbox" 
                                                                    type="checkbox" 
                                                                    checked={iAmSelected(record.id)}
                                                                    onChange={() => onClickLink(record)}
                                                                />
                                                            </span>
                                                        </td> 
                                                    : null}
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthRecordId, maxWidth: widthRecordId }}>
                                                        <p style={{textAlign: "center"}}>{record.ncf ? record.ncf : "--"}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthTotal, maxWidth: widthTotal }}>
                                                        <p style={{whiteSpace: "nowrap"}}>{formatRationalNumber(record.total)}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthPending, maxWidth: widthPending }}>
                                                        <p style={{whiteSpace: "nowrap"}}>{formatRationalNumber(record.pending)}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthHour, maxWidth: widthHour }}>
                                                        <p style={{whiteSpace: "nowrap"}}>{getTimeInString(record.time)}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthDate, maxWidth: widthDate }}>
                                                       <p style={{whiteSpace: "nowrap"}}>{getDateWithMonthShort(new Date(record.time))}</p>
                                                    </td>
                                                    <td className={`inventory-table-select-td`} style={{ border: appTheme.dark ? border2Dark : border2Light, minWidth: widthDgiiEstado, maxWidth: widthDgiiEstado }}>
                                                        <p style={{whiteSpace: "nowrap", textAlign: "center"}}>
                                                            {record.dgiiEstado ? 
                                                                (record.dgiiEstado === "sent" ?
                                                                    <span className="customer-detaines-info-out-of-stock-span-cont" style={{ fontSize: "13px", background: "green" }}>{appLanguage.en ? "Sent" : "Enviado"}</span> 
                                                                :
                                                                    appLanguage.en ? "To be send" : "Por enviar"
                                                                )
                                                            : 
                                                                <span className="customer-detaines-info-out-of-stock-span-cont" style={{ fontSize: "13px", background: "gray" }}>{appLanguage.en ? "Does not apply" : "No aplica"}</span>
                                                            }
                                                        </p>
                                                    </td>

                                                </tr>
                                            );
                                        })
                                    : null}
                                </tbody>
                            </table>
                        :
                            <NoProductsYet empty={empty} />
                        }
                    </Fragment>}
                </Fragment>}
            </div>
            <div>
                {/** Aquí va la navegacion */}
                {loading || empty2 || showConnectionError ? null :
                    <PageNavigator
                        pageNumber={pageNumber}
                        navControlers={navControlers}
                        onChangePageNumber={null}
                        showPageNav={showPageNav}
                        onClickNext={onClickNext}
                        onClickPrevious={onClickPrevious}
                        empty={false}
                        elemMarginTo={"5px"}
                        elemmarginBottom={"5px"}
                    />
                }
            </div>
        </div>
    )
}

const NoProductsYet = ({empty}) => {
    const { appLanguage } = useContext(AppContext);

    const [enTitle, setEnTitle] = useState("There are no products yet");
    const [esTitle, setEsTitle] = useState("Aún no hay productos");

    const [enDesc, setEnDesc] = useState("Once you add products, they will be displayed here.");
    const [esDesc, setEsDesc] = useState("Una vez que agregues productos, estos se mostrarán aquí.");

    useEffect(() => {
        if (empty) {
            setEnTitle("There are no invoices yet");
            setEsTitle("Aún no hay facturas");
            setEnDesc("Once you create invoices, they will be displayed here.");
            setEsDesc("Una vez que crees alguna factura, estas se mostrarán aquí.");
        } else {
            setEnTitle("We couldn't find any results for your search");
            setEsTitle("No pudimos encontrar ningún resultado para tu búsqueda");
            setEnDesc('You can search by NCF or transaction code, and also filter by comprobante type.');
            setEsDesc('Puedes buscar por NCF, (Cédula o RNC), y código de transacción, y también filtrar por tipo de comprobante.');
        }
    }, [empty])

    return (
        <div className="no-customer-yet-main-container">
            <img className="no-customer-yet-main-img" src={empty ? emptyBox : emptySearch} alt="No records yet" />
            <p><b>{appLanguage.en ? enTitle : esTitle}</b></p>
            <p>{appLanguage.en ? enDesc : esDesc}</p>
        </div>
    )
}
export default FacturasSelectTable;